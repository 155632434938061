//
// entideb variables
// --------------------------------------------------


// Colors

$primary-light:             tint-color($primary, 32%);
$primary-lighter:           tint-color($primary, 68%);
$primary-dark:              shade-color($gray-700, 5%);
$primary-darker:            shade-color($gray-900, 5%);

$success-light:           #ebf5df;
$info-light:              #e3f4fc;
$warning-light:           #fcf7e6;
$danger-light:            #fae9e8;

$body-bg-dark:              darken($body-bg, 4%);
$body-bg-light:             lighten($body-bg, 2%);

$body-color-dark:           #171717 !default;
$body-color-light:          $body-bg-dark !default;


// Paragraphs

$paragraph-line-height:     1.6;
$paragraph-line-height-lg:  1.8;


// Z-index master list additions

$zindex-block-pinned:       $zindex-fixed + 1 !default;
$zindex-sidebar:            $zindex-fixed + 2 !default;
$zindex-page-overlay:       $zindex-fixed + 3 !default;
$zindex-side-overlay:       $zindex-fixed + 4 !default;
$zindex-block-fullscreen:   $zindex-fixed + 5 !default;


// Header

$header-height:             4.25rem !default;           // Best values > 50px and < 80px
$header-bg:                 $white !default;
$header-dark-bg:            shade-color($gray-800, 5%) !default;


// Sidebar and Side Overlay

$sidebar-bg:                $white !default;
$sidebar-dark-bg:           shade-color($gray-800, 5%) !default;
$sidebar-width:             240px !default;
$sidebar-mini-width:        64px !default;

$side-overlay-bg:           $white !default;
$side-overlay-width:        320px !default;

$side-transition:           .26s cubic-bezier(0.470, 0.000, 0.745, 0.715) !default;


// Main Navigation

$main-nav-link-icon-color:          rgba($primary, .7) !default;
$main-nav-link-hover-bg:            lighten($primary, 52%) !default;
$main-nav-submenu-bg:               lighten($primary, 56%) !default;

$main-nav-link-icon-dark-color:     lighten($sidebar-dark-bg, 20%) !default;
$main-nav-link-dark-hover-bg:       darken($sidebar-dark-bg, 4.5%) !default;
$main-nav-submenu-dark-bg:          darken($sidebar-dark-bg, 3%) !default;

$main-nav-transition:               .2s cubic-bezier(0.250, 0.460, 0.450, 0.940) !default;


// Layout Spaces

$space-base:                1.75rem !default;                    // Content padding
$space-mobile:              .875rem !default;                    // Content padding in mobile
$space-side:                1.5rem !default;                   // Content padding in side sections
$space-row-gutter-tiny:     .375rem !default;                   // Row with tiny gutter, even number: 2, 4, 6.. etc
$space-block:               1.625rem !default;                    // Block padding
$space-boxed:               1260px !default;                    // Boxed content max width
$space-narrow:              92% !default;                       // Narrow content max width
$space-main-max-width:      1920px !default;                    // Main content max width

// Third Party

$fa-font-path:                "../fonts/fontawesome" !default;
