//
// Lists
// --------------------------------------------------

// Base List
.list {
  @include list-unstyled;

  > li {
    position: relative;
  }

  &.list-simple > li,
  &-li-push > li {
    margin-bottom: 1.25rem;
  }

  &.list-simple-mini > li,
  &-li-push-sm > li {
    margin-bottom: .625rem;
  }

  &-icons > li {
    margin-bottom: 1.25rem;
    padding-left: .5rem;
  }
}

// Activity
.list-activity {
  > li {
    margin-bottom: .75rem;
    padding-bottom: .375rem;
    padding-left: 2rem;

    > i:first-child {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;
      text-align: center;
    }
  }  

  > li:not(:last-child) {
    border-bottom: 1px solid $body-bg;
  }
}

// Events
.list-events {
  > li {
    margin-bottom: .25rem;
  }

  .js-event {
    background-color: $info;
    transition: transform .3s ease-out;

    &::before {
      position: absolute;
      top: 50%;
      right: 10px;
      left: auto;
      transform: translateX(0) translateY(-50%);
      display: inline-block;
      color: rgba($white,.5);
      font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
      font-weight: 900;
      content: '\f073';
    }

    &:hover {
      cursor: move;
      transform: translateX(-.25rem);
    }
  }
}
