//
// Backgrounds
// --------------------------------------------------

// Original Bootstrap 4 mixin
@mixin bg-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background-color: $color !important;
  }

  a#{$parent},
  button#{$parent} {
    &:hover,
    &:focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

@mixin bg-gradient-variant($parent, $deg, $color1, $color2) {
  #{$parent} {
    background: $color1 linear-gradient($deg, $color1 0%, $color2 100%) !important;
  }
}
