//
// Breadcrumb
// --------------------------------------------------

.breadcrumb-item {
  font-weight: $font-weight-medium;
  
  + .breadcrumb-item::before {
    font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
    font-weight: 900;
    content: '\f105';
    opacity: .5;
  }
}