//
// Dropdown
// --------------------------------------------------

.dropdown-menu {
  padding-right: .5rem;
  padding-left: .5rem;
  box-shadow: $dropdown-box-shadow;

  &.dropdown-menu-md {
    min-width: 200px;
  }  

  &.dropdown-menu-lg,
  &.dropdown-menu-xl,
  &.dropdown-menu-xxl {
    min-width: 300px;
  }

  @include media-breakpoint-up(xl) {
    &.dropdown-menu-xl {
      min-width: 450px;
    }

    &.dropdown-menu-xxl {
      min-width: 600px;
    }
  }
}

.dropdown-item {
  margin-bottom: .25rem;
  border-radius: $border-radius;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
}

.dropdown-header {
  margin-bottom: .25rem;
  text-transform: uppercase;
  letter-spacing: .0625em;
}