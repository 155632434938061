//
// Reboot
// --------------------------------------------------

b,
strong {
  font-weight: $font-weight-bold;
}

// Links
a {
  transition: color .12s ease-out;

  @include media-breakpoint-up(sm) {
    &.link-fx {
      position: relative;

      &::before {
        position: absolute;
        bottom: -.25rem;
        left: 0;
        width: 100%;
        height: 2px;
        content: '';
        background-color: $link-color;
        visibility: hidden;
        transform: translateY(.375rem);
        transform-origin: 50% 50%;
        opacity: 0;
        transition: transform .12s ease-out, opacity .12s ease-out;
      }
    }
  }

  &:hover {
    &.link-fx::before {
      visibility: visible;
      transform: translateY(0);
      opacity: 1;
    }
  }
}

// Paragraphs
p {
  line-height: $paragraph-line-height;
}

.story p,
p.story {
  line-height: $paragraph-line-height;
  font-size: $font-size-lg;
  color: $gray-700;

  @include media-breakpoint-up(sm) {
    line-height: $paragraph-line-height-lg;
  }
}

.story {
  h2,
  h3,
  h4 {
    margin-top: 3rem;
  }
}