/*!
 * bootstrap-fileinput v5.5.2
 * http://plugins.krajee.com/file-input
 *
 * Krajee default styling for bootstrap-fileinput.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2022, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD-3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */
//colors
$mountain-mist: #999 !default;
$apple-blossom: #a94442 !default;
$vanilla-ice: #f2dede !default;
$oyster-pink: #ebccd1 !default;
$gallery: #eee !default;
$alto: #ddd !default;
$celeste: #ccc !default;
$green: #008000 !default;
$mine-shaft: #333 !default;
$boston-blue: #428bca !default;
$pelorous: #17a2b8 !default;
$black: #000 !default;
$white: #fff !default;
$black-20: rgba(0, 0, 0, 0.2) !default;
$black-30: rgba(0, 0, 0, 0.3) !default;
$black-40: rgba(0, 0, 0, 0.4) !default;
$mystic: #e1edf7 !default;
$perano: #a1abff !default;
$silver-chalice: #aaa !default;
$viking: #5acde2 !default;
$curious-blue: #1c94c4 !default;
$link-water: #d9edf7 !default;
$gray-1: #6c757d !default;

//fonts
$font-0: Impact !default;
$font-1: Charcoal !default;
$font-2: sans-serif !default;
$font-3: Menlo !default;
$font-4: Monaco !default;
$font-5: Consolas !default;
$font-6: "Courier New" !default;
$font-7: monospace !default;

//urls
$url-0: url(../img/loading.gif) !default;
$url-1: url(../img/loading-sm.gif) !default;

//standard measures
$radius: 4px !default;
$pad: 5px !default;
$border: 1px !default;

//operations
@function multiply($pixels, $multiplier) {
  @return $pixels * $multiplier;
}

//@extend-elements
%set-invisible {
  width: 0;
  height: 0;
}

%set-hidden {
  display: none;
}

%set-absolute {
  position: absolute;
}

%set-relative {
  position: relative;
}

%set-text-left {
  text-align: left;
}

%set-error {
  @extend %set-text-left;
  margin: 0;
}

%set-indicator {
  float: left;
  margin-top: multiply($pad, 2);
  width: multiply($pad, 3.2);
  height: multiply($pad, 3.2);
}

%set-progress {
  height: multiply($pad, 2.2);
  font-size: multiply($pad, 1.8);
}

%set-caption {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: multiply($pad, 32);
  height: multiply($pad, 3);
  margin: auto;
}

%set-object {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

%set-object-video {
  @extend %set-object;
  height: 100%;
}

%set-object-default {
  width: 100%;
}

.kv-hidden {
  @extend %set-hidden;
}

.hide-content .kv-file-content {
  @extend %set-hidden;
}

.file-input {
  @extend %set-relative;
}

.file-no-browse {
  @extend %set-absolute;
  left: 50%;
  bottom: 20%;
  width: 1px;
  height: 1px;
  font-size: 0;
  opacity: 0;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
}

.file-loading {
  input[type=file] {
    @extend %set-invisible;
  }

  &:before {
    @extend %set-relative;
    content: " Loading...";
    display: inline-block;
    padding-left: multiply($pad, 4);
    line-height: multiply($pad, 3.2);
    font-size: multiply($pad, 2.6);
    font-variant: small-caps;
    color: $mountain-mist;
    background: transparent $url-0 top left no-repeat;
  }
}

input[type=file].file-loading {
  @extend %set-invisible;
}

.file-object {
  margin: 0 0 (-$pad) 0;
  padding: 0;
}

.btn-file {
  @extend %set-relative;
  overflow: hidden;

  input[type=file] {
    @extend %set-absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    text-align: right;
    opacity: 0;
    background: none repeat scroll 0 0 transparent;
    cursor: inherit;
    display: block;
  }

  ::-ms-browse {
    font-size: multiply($pad, 2000);
    width: 100%;
    height: 100%;
  }
}

.file-caption {
  @extend %set-relative;

  .input-group {
    align-items: center;
  }

  .file-caption-name {
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border: none;
    background: none;
    outline: none;
  }

  &.icon-visible {
    .file-caption-icon {
      display: inline-block;
    }

    .file-caption-name {
      padding-left: 1.875rem;
    }

    > .input-group-lg .file-caption-name {
      padding-left: 2.1rem;
    }

    > .input-group-sm .file-caption-name {
      padding-left: 1.5rem;
    }
  }
}

.file-caption-icon {
  @extend %set-hidden;
  @extend %set-absolute;
  left: multiply($pad, 1.6);
  padding: 0.5rem;
}

.input-group-lg .file-caption-icon {
  font-size: 1.25rem;
}

.input-group-sm .file-caption-icon {
  font-size: 0.875rem;
  padding: 0.25rem;
}

.file-error-message {
  color: $apple-blossom;
  background-color: $vanilla-ice;
  margin: $pad;
  border: $border solid $oyster-pink;
  border-radius: $radius;
  padding: multiply($pad, 3);

  pre {
    @extend %set-error;
    margin: $pad 0;
  }

  ul {
    @extend %set-error;
  }
}

.file-caption-disabled {
  background-color: $gallery;
  cursor: not-allowed;
  opacity: 1;
}

.file-preview {
  @extend %set-relative;
  border-radius: multiply($radius, 1.25);
  border: $border solid $alto;
  padding: multiply($pad, 1.6);
  width: 100%;
  margin-bottom: $pad;

  .btn-xs {
    padding: multiply($pad, 0.2) $pad;
    font-size: multiply($pad, 2.4);
    line-height: 1.5;
    border-radius: multiply($radius, 0.75);
  }

  .fileinput-remove {
    @extend %set-absolute;
    top: multiply($pad, 0.2);
    right: multiply($pad, 0.2);
    line-height: multiply($pad, 2);
  }

  .clickable {
    cursor: pointer;
  }

  .kv-zoom-cache {
    display: none;
  }
}

.file-preview-image {
  font: multiply($pad, 8) $font-0, $font-1, $font-2;
  color: $green;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.krajee-default {
  &.file-preview-frame {
    @extend %set-relative;
    margin: multiply($pad, 1.6);
    border: $border solid $black-20;
    box-shadow: 0 0 multiply($pad, 0) 0 $black-20;
    padding: multiply($pad, 1.2);
    float: left;
    text-align: center;

    .kv-file-content {
      width: multiply($pad, 32);
      height: multiply($pad, 14);

      &.kv-pdf-rendered {
        width: 400px;
      }
    }

    .file-thumbnail-footer {
      height: multiply($pad, 14);
    }

    &:not(.file-preview-error):hover {
      border: $border solid $black-30;
      box-shadow: 0 0 multiply($pad, 2) 0 $black-40;
    }

    &[data-template="audio"] .kv-file-content {
      width: 240px;
      height: 55px;
    }
  }

  .file-preview-text {
    display: block;
    color: $boston-blue;
    border: $border solid $alto;
    font-family: $font-3, $font-4, $font-5, $font-6, $font-7;
    outline: none;
    padding: multiply($pad, 1.6);
    resize: none;
  }

  .file-preview-html {
    border: $border solid $alto;
    padding: multiply($pad, 1.6);
    overflow: auto;
  }

  .file-actions {
    @extend %set-text-left;
  }

  .file-other-error {
    @extend %set-text-left;
  }

  .file-other-icon {
    font-size: 3em;
    line-height: 1;
  }

  .file-footer-buttons {
    float: right;
  }

  .file-footer-caption {
    display: block;
    text-align: center;
    padding-top: multiply($pad, 0.8);
    font-size: multiply($pad, 2.2);
    color: $mountain-mist;
    margin-bottom: multiply($pad, 3);
  }

  .file-preview-error {
    opacity: 0.65;
    box-shadow: none;
  }

  .file-drag-handle {
    @extend %set-indicator;
  }

  .file-upload-indicator {
    @extend %set-indicator;
  }

  .file-thumb-progress {
    @extend %set-absolute;
    height: multiply($pad, 2.2);
    top: multiply($pad, 7.4);
    left: 0;
    right: 0;

    .progress {
      @extend %set-progress;
      color: $celeste;
    }

    .progress-bar {
      @extend %set-progress;
      font-family: Verdana, Helvetica, sans-serif;
    }
  }

  .file-thumbnail-footer {
    @extend %set-relative;
  }

  .file-caption-info {
    @extend %set-caption;
  }

  .file-size-info {
    @extend %set-caption;
  }

  &.kvsortable-ghost {
    background: $mystic;
    border: multiply($border, 2) solid $perano;
  }

  .file-preview-other:hover {
    opacity: 0.8;
  }

  .file-preview-frame:not(.file-preview-error) .file-footer-caption:hover {
    color: $black;
  }
}

.file-upload-stats {
  font-size: 9px;
  text-align: center;
  width: 100%;
}

.kv-upload-progress {
  .progress {
    height: multiply($pad, 4);
    line-height: multiply($pad, 4);
    margin: multiply($pad, 2) 0;
    overflow: hidden;
    color: $celeste;
  }

  .progress-bar {
    height: multiply($pad, 4);
    font-family: Verdana, Helvetica, sans-serif;
  }

  .file-upload-stats {
    font-size: 11px;
    margin: -10px 0 5px;
  }
}

.file-thumb-progress {
  .progress {
    background-color: #ccc;
  }

  .progress-bar {
    font-size: 0.7rem;
    font-family: Verdana, Helvetica, sans-serif;
  }
}

.file-zoom-dialog {
  .file-other-icon {
    //noinspection CssOverwrittenProperties
    font-size: 22em;
    //noinspection CssOverwrittenProperties
    font-size: 50vmin;
  }

  .modal-dialog {
    @extend %set-relative;
    width: auto;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:before {
      @extend %set-hidden;
    }

    &:after {
      @extend %set-hidden;
    }
  }

  .btn-navigate {
    @extend %set-absolute;
    margin: -1.2rem 0.1rem 0;
    padding: 0;
    font-size: 1.2rem;
    width: 2.4rem;
    height: 2.4rem;
    top: 50%;
    border-radius: 50%;
    text-align: center;

    * {
      width: auto;
    }
  }

  .floating-buttons {
    @extend %set-absolute;
    top: $pad;
    right: multiply($pad, 2);
  }

  .btn-kv-prev {
    left: 0;
  }

  .btn-kv-next {
    right: 0;
  }

  .kv-zoom-header {
    padding: 0.5rem;
  }

  .kv-zoom-body {
    padding: 0.25rem 0.5rem 0.25rem 0;
  }

  .kv-zoom-description {
    position: absolute;
    opacity: 0.8;
    font-size: 0.8rem;
    background-color: #1a1a1a;
    padding: 1rem;
    text-align: center;
    border-radius: 0.5rem;
    color: #fff;
    left: 15%;
    right: 15%;
    bottom: 15%;
  }

  .kv-desc-hide {
    float: right;
    color: #fff;
    padding: 0 0.1rem;
    background: none;
    border: none;
  }

  .kv-desc-hide:hover,
  .kv-desc-hide:focus {
    opacity: 0.7;
  }
}

.file-input-new {
  .file-preview {
    @extend %set-hidden;
  }

  .close {
    @extend %set-hidden;
  }

  .glyphicon-file {
    @extend %set-hidden;
  }

  .fileinput-remove-button {
    @extend %set-hidden;
  }

  .fileinput-upload-button {
    @extend %set-hidden;
  }

  .no-browse {
    .input-group-btn {
      @extend %set-hidden;
    }

    .form-control {
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
    }
  }
}

.file-input-ajax-new {
  .fileinput-remove-button {
    @extend %set-hidden;
  }

  .fileinput-upload-button {
    @extend %set-hidden;
  }

  .no-browse {
    .input-group-btn {
      @extend %set-hidden;
    }

    .form-control {
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
    }
  }
}

.file-caption-main {
  width: 100%;
}

.file-thumb-loading {
  background: transparent $url-0 no-repeat scroll center center content-box !important;
}

.file-drop-zone {
  border: $border dashed $silver-chalice;
  border-radius: $radius;
  min-height: multiply($pad, 52);
  text-align: center;
  vertical-align: middle;
  margin: multiply($pad, 2.4) multiply($pad, 3) multiply($pad, 2.4) multiply($pad, 2.4);
  padding: $pad;

  &.clickable {
    &:hover {
      border: multiply($border, 2) dashed $mountain-mist;
    }

    &:focus {
      border: multiply($border, 2) solid $viking;
    }
  }

  .file-preview-thumbnails {
    cursor: default;
  }
}

.file-drop-zone-title {
  color: $silver-chalice;
  font-size: 1.6em;
  padding: multiply($pad, 17) multiply($pad, 2);
  cursor: default;
}

.file-highlighted {
  border: multiply($border, 2) dashed $mountain-mist !important;
  background-color: $gallery;
}

.file-uploading {
  background: $url-1 no-repeat center bottom multiply($pad, 2);
  opacity: 0.65;
}

.file-zoom-fullscreen {
  .modal-dialog {
    min-width: 100%;
    margin: 0;
  }

  .modal-content {
    border-radius: 0;
    box-shadow: none;
    min-height: 100vh;
  }

  .kv-zoom-body {
    overflow-y: auto;
  }
}

.floating-buttons {
  z-index: 3000;

  .btn-kv {
    margin-left: multiply($pad, 0.6);
    z-index: 3000;
  }
}

.kv-zoom-actions {
  min-width: 140px;
}

.kv-zoom-actions .btn-kv {
  margin-left: multiply($pad, 0.6);
}

.file-zoom-content {
  min-height: 300px;
  text-align: center;
  white-space: nowrap;

  .file-preview-image {
    max-height: 100%;
  }

  .file-preview-video {
    max-height: 100%;
  }

  > .file-object {
    &.type-image {
      @extend %set-object;
      height: auto;
      min-height: inherit;
    }

    &.type-video {
      @extend %set-object-video;
    }

    &.type-flash {
      @extend %set-object-video;
    }

    &.type-audio {
      width: auto;
      height: multiply($pad, 6);
    }

    &.type-pdf {
      @extend %set-object-default;
    }

    &.type-html {
      @extend %set-object-default;
    }

    &.type-text {
      @extend %set-object-default;
    }

    &.type-default {
      @extend %set-object-default;
    }
  }

  &:hover {
    background: transparent;
  }
}

@media(min-width: 576px) {
  .file-zoom-dialog .modal-dialog {
    max-width: 500px;
  }
}

@media(min-width: 992px) {
  .file-zoom-dialog .modal-lg {
    max-width: 800px;
  }
}

@media(max-width: 767px) {
  .file-preview-thumbnails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .file-zoom-dialog .modal-header {
    flex-direction: column;
  }
}

@media(max-width: 350px) {
  .krajee-default.file-preview-frame:not([data-template="audio"]) .kv-file-content {
    width: 160px;
  }
}

@media(max-width: 420px) {
  .krajee-default.file-preview-frame .kv-file-content.kv-pdf-rendered {
    width: 100%;
  }
}

.file-loading[dir=rtl]:before {
  background: transparent $url-0 top right no-repeat;
  padding-left: 0;
  padding-right: multiply($pad, 4);
}

.clickable .file-drop-zone-title {
  cursor: pointer;
}

.file-sortable .file-drag-handle {
  cursor: grab;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
}

.file-grabbing, .file-grabbing * {
  cursor: not-allowed !important;
}

.file-grabbing .file-preview-thumbnails * {
  cursor: grabbing !important;
}

.file-preview-initial.sortable-chosen {
  background-color: $link-water;
  border-color: $pelorous;
  box-shadow: none;
}

.file-preview-other-frame, .file-preview-object, .kv-file-content, .kv-zoom-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-kv-rotate {
  display: none;
}

.kv-file-rotate {
  display: none;
}

.rotatable {
  &:not(.hide-rotate) {
    .btn-kv-rotate {
      display: inline-block;
    }

    .kv-file-rotate {
      display: inline-block;
    }
  }

  .file-zoom-detail {
    transform-origin: center center;
  }

  .kv-file-content {
    transform-origin: center center;

    > :first-child {
      transform-origin: center center;
    }

  }
}

.rotate-animate {
  transition: transform 0.3s ease;
}

.kv-overflow-hidden {
  overflow: hidden;
}
