//
// Pagination
// --------------------------------------------------

.page-link {
  font-weight: $font-weight-semibold;

  &:focus {
    background-color: $body-bg-dark;
    border-color: $body-bg-dark;
  }
}