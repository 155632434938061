b,
strong {
  font-weight: 700;
}

a {
  transition: color 0.12s ease-out;
}
@media (min-width: 576px) {
  a.link-fx {
    position: relative;
  }
  a.link-fx::before {
    position: absolute;
    bottom: -0.25rem;
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    background-color: #0284c7;
    visibility: hidden;
    transform: translateY(0.375rem);
    transform-origin: 50% 50%;
    opacity: 0;
    transition: transform 0.12s ease-out, opacity 0.12s ease-out;
  }
}
a:hover.link-fx::before {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

p {
  line-height: 1.6;
}

.story p,
p.story {
  line-height: 1.6;
  font-size: 1.25rem;
  color: #495057;
}
@media (min-width: 576px) {
  .story p,
  p.story {
    line-height: 1.8;
  }
}

.story h2,
.story h3,
.story h4 {
  margin-top: 3rem;
}

.content-heading {
  margin-bottom: 0.875rem;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75;
  border-bottom: 1px solid #e4e7ed;
}
.content-heading small {
  margin-top: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  color: #6c757d;
}
@media (min-width: 768px) {
  .content-heading {
    margin-bottom: 1.5rem;
    padding-top: 2.5rem;
  }
  .content-heading small {
    margin-top: 0;
  }
}
.block-content > .content-heading:first-child, .content > .content-heading:first-child {
  padding-top: 0 !important;
}
.content-heading .dropdown {
  line-height: 1.5;
}

small,
.small {
  font-weight: inherit;
}

.text-uppercase {
  letter-spacing: 0.0625em;
}

.row.g-sm {
  --bs-gutter-x: 0.375rem;
}
.row.g-sm > .col,
.row.g-sm > [class*=col-] {
  padding-left: 0.1875rem;
  padding-right: 0.1875rem;
}
.row.g-sm .push,
.row.g-sm .block, .row.g-sm.items-push > div {
  margin-bottom: 0.375rem;
}

@media (max-width: 767.98px) {
  .row:not(.g-sm):not(.g-0) {
    --bs-gutter-x: 0.875rem;
  }
  .row:not(.g-sm):not(.g-0) > .col,
  .row:not(.g-sm):not(.g-0) > [class*=col-] {
    padding-left: 0.4375rem;
    padding-right: 0.4375rem;
  }
}
.table thead th {
  text-transform: uppercase;
  letter-spacing: 0.0625em;
  font-size: 0.875rem;
}

.table:not(.table-borderless) > :not(:last-child) > :last-child > * {
  border-bottom-width: 2px;
}

.table-vcenter th,
.table-vcenter td {
  vertical-align: middle;
}

.js-table-checkable tbody tr,
.js-table-sections-header > tr {
  cursor: pointer;
}

.js-table-sections-header > tr > td:first-child > i {
  transition: transform 0.2s ease-out;
}
.js-table-sections-header + tbody {
  display: none;
}
.js-table-sections-header.show > tr > td:first-child > i {
  transform: rotate(90deg);
}
.js-table-sections-header.show + tbody {
  display: table-row-group;
}

.form-switch {
  min-height: 2em;
  line-height: 2em;
}
.form-switch .form-check-input {
  height: 1.5em;
}

.form-block {
  position: relative;
  padding: 0;
  z-index: 1;
}
.form-block .form-check-input {
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.form-block .form-check-label {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e4e7ed;
  border-radius: 0.25rem;
  cursor: pointer;
}
.form-block .form-check-label:hover {
  border-color: #d4dae3;
}
.form-block .form-check-label::before {
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  display: none;
  line-height: 1.5rem;
  color: #fff;
  font-size: 0.625rem;
  text-align: center;
  background-color: #0284c7;
  border-bottom-left-radius: 0.25rem;
  font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
  font-weight: 900;
  content: "\f00c";
  z-index: 1;
}
.form-block .form-check-input:checked ~ .form-check-label {
  border-color: #0284c7;
}
.form-block .form-check-input:focus ~ .form-check-label {
  border-color: #0284c7;
  box-shadow: 0 0 0 0.25rem rgba(2, 132, 199, 0.25);
}
.form-block .form-check-input:checked ~ .form-check-label::before {
  display: block;
}
.form-block .form-check-input:disabled + .form-check-label,
.form-block .form-check-input[readonly] + .form-check-label {
  opacity: 0.5;
  cursor: not-allowed;
}
.form-block .form-check-input:disabled:not([checked]) + .form-check-label:hover,
.form-block .form-check-input[readonly]:not([checked]) + .form-check-label:hover {
  border-color: #e4e7ed;
}

.form-control {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-control.form-control-alt {
  border-color: #f0f2f5;
  background-color: #f0f2f5;
  transition: none;
}
.form-control.form-control-alt:focus {
  border-color: #e4e7ed;
  background-color: #e4e7ed;
  box-shadow: none;
}
.form-control.form-control-alt.is-valid {
  border-color: #ebf5df;
  background-color: #ebf5df;
}
.form-control.form-control-alt.is-valid:focus {
  border-color: #dfefcc;
  background-color: #dfefcc;
}
.form-control.form-control-alt.is-invalid {
  border-color: #fae9e8;
  background-color: #fae9e8;
}
.form-control.form-control-alt.is-invalid:focus {
  border-color: #f5d5d3;
  background-color: #f5d5d3;
}

.form-select {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.form-select.form-control-alt {
  border-color: #f0f2f5;
  background-color: #f0f2f5;
  transition: none;
}
.form-select.form-control-alt:focus {
  border-color: #e4e7ed;
  background-color: #e4e7ed;
  box-shadow: none;
}
.form-select.form-control-alt.is-valid {
  border-color: #ebf5df;
  background-color: #ebf5df;
}
.form-select.form-control-alt.is-valid:focus {
  border-color: #dfefcc;
  background-color: #dfefcc;
}
.form-select.form-control-alt.is-invalid {
  border-color: #fae9e8;
  background-color: #fae9e8;
}
.form-select.form-control-alt.is-invalid:focus {
  border-color: #f5d5d3;
  background-color: #f5d5d3;
}

.input-group-text.input-group-text-alt {
  background-color: #e4e7ed;
  border-color: #e4e7ed;
}

.btn .si {
  position: relative;
  top: 1px;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #454c53;
  --bs-btn-border-color: #454c53;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #373d42;
  --bs-btn-hover-border-color: #34393e;
  --bs-btn-focus-shadow-rgb: 97, 103, 109;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #373d42;
  --bs-btn-active-border-color: #34393e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #454c53;
  --bs-btn-disabled-border-color: #454c53;
}

.btn-alt-primary {
  --bs-btn-color: #014f77;
  --bs-btn-bg: #c0e0f1;
  --bs-btn-border-color: #c0e0f1;
  --bs-btn-hover-color: #013550;
  --bs-btn-hover-bg: #81c2e3;
  --bs-btn-hover-border-color: #81c2e3;
  --bs-btn-focus-shadow-rgb: 163, 202, 223;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cde6f4;
  --bs-btn-active-border-color: #c6e3f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c0e0f1;
  --bs-btn-disabled-border-color: #c0e0f1;
}

.btn-alt-secondary {
  --bs-btn-color: #171717;
  --bs-btn-bg: #f0f2f5;
  --bs-btn-border-color: #f0f2f5;
  --bs-btn-hover-color: #141414;
  --bs-btn-hover-bg: #d8dadd;
  --bs-btn-hover-border-color: #d8dadd;
  --bs-btn-focus-shadow-rgb: 207, 209, 212;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f3f5f7;
  --bs-btn-active-border-color: #f2f3f6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f0f2f5;
  --bs-btn-disabled-border-color: #f0f2f5;
}

.btn-alt-success {
  --bs-btn-color: #0d622c;
  --bs-btn-bg: #c5e8d2;
  --bs-btn-border-color: #c5e8d2;
  --bs-btn-hover-color: #09411e;
  --bs-btn-hover-bg: #8bd1a5;
  --bs-btn-hover-border-color: #8bd1a5;
  --bs-btn-focus-shadow-rgb: 169, 212, 185;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d1eddb;
  --bs-btn-active-border-color: #cbead7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c5e8d2;
  --bs-btn-disabled-border-color: #c5e8d2;
}

.btn-alt-info {
  --bs-btn-color: #05576b;
  --bs-btn-bg: #c1e4ec;
  --bs-btn-border-color: #c1e4ec;
  --bs-btn-hover-color: #033a47;
  --bs-btn-hover-bg: #84c8d9;
  --bs-btn-hover-border-color: #84c8d9;
  --bs-btn-focus-shadow-rgb: 165, 207, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cde9f0;
  --bs-btn-active-border-color: #c7e7ee;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c1e4ec;
  --bs-btn-disabled-border-color: #c1e4ec;
}

.btn-alt-warning {
  --bs-btn-color: #824704;
  --bs-btn-bg: #f6ddc1;
  --bs-btn-border-color: #f6ddc1;
  --bs-btn-hover-color: #573002;
  --bs-btn-hover-bg: #ecbb83;
  --bs-btn-hover-border-color: #ecbb83;
  --bs-btn-focus-shadow-rgb: 229, 199, 165;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8e4cd;
  --bs-btn-active-border-color: #f7e0c7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f6ddc1;
  --bs-btn-disabled-border-color: #f6ddc1;
}

.btn-alt-danger {
  --bs-btn-color: #841717;
  --bs-btn-bg: #f6c9c9;
  --bs-btn-border-color: #f6c9c9;
  --bs-btn-hover-color: #580f0f;
  --bs-btn-hover-bg: #ee9393;
  --bs-btn-hover-border-color: #ee9393;
  --bs-btn-focus-shadow-rgb: 229, 174, 174;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8d4d4;
  --bs-btn-active-border-color: #f7cece;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f6c9c9;
  --bs-btn-disabled-border-color: #f6c9c9;
}

.page-header-dark #page-header .btn-alt-secondary,
.sidebar-dark #sidebar .btn-alt-secondary,
#sidebar .bg-header-dark .content-header .btn-alt-secondary,
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-alt-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #31373d;
  --bs-btn-border-color: #31373d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #50555a;
  --bs-btn-hover-border-color: #50555a;
  --bs-btn-focus-shadow-rgb: 80, 85, 90;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #272c31;
  --bs-btn-active-border-color: #25292e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #31373d;
  --bs-btn-disabled-border-color: #31373d;
}

.fade {
  transition: opacity 0.15s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade.fade-up, .fade.fade-right, .fade.fade-left {
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .fade.fade-up, .fade.fade-right, .fade.fade-left {
    transition: none;
  }
}
.fade.fade-up:not(.show) {
  transform: translateY(100px);
}
.fade.fade-right:not(.show) {
  transform: translateX(-100px);
}
.fade.fade-left:not(.show) {
  transform: translateX(100px);
}

.dropdown-menu {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
.dropdown-menu.dropdown-menu-md {
  min-width: 200px;
}
.dropdown-menu.dropdown-menu-lg, .dropdown-menu.dropdown-menu-xl, .dropdown-menu.dropdown-menu-xxl {
  min-width: 300px;
}
@media (min-width: 1200px) {
  .dropdown-menu.dropdown-menu-xl {
    min-width: 450px;
  }
  .dropdown-menu.dropdown-menu-xxl {
    min-width: 600px;
  }
}

.dropdown-item {
  margin-bottom: 0.25rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.dropdown-header {
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
}

.nav-link {
  color: #3e444a;
  font-weight: 600;
}
.nav-link:hover, .nav-link:focus {
  color: #0284c7;
}

.nav-pills .nav-link {
  color: #3e444a;
}
.nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
  background-color: #f0f2f5;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0284c7;
}

.nav-tabs-block {
  background-color: #f6f7f9;
  border-bottom: none;
}
.nav-tabs-block .nav-item {
  margin-bottom: 0;
}
.nav-tabs-block .nav-link {
  padding-top: 12px;
  padding-bottom: 12px;
  border-color: transparent;
  border-radius: 0;
}
.nav-tabs-block .nav-link:hover, .nav-tabs-block .nav-link:focus {
  color: #0284c7;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-block .nav-link.active,
.nav-tabs-block .nav-item.show .nav-link {
  color: #3e444a;
  background-color: #fff;
  border-color: transparent;
}

.nav-tabs-alt {
  background-color: transparent;
  border-bottom-color: #e4e7ed;
}
.nav-tabs-alt .nav-item {
  margin-bottom: -2px;
}
.nav-tabs-alt .nav-link {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-alt .nav-link:hover, .nav-tabs-alt .nav-link:focus {
  color: #0284c7;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -2px #0284c7;
}
.nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-item.show .nav-link {
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -2px #0284c7;
}

.nav-users {
  padding-left: 0;
  list-style: none;
}
.nav-users > li:last-child > a {
  border-bottom: none;
}
.nav-users a {
  position: relative;
  padding: 12px 8px 8px 71px;
  display: block;
  min-height: 62px;
  font-weight: 600;
  border-bottom: 1px solid #f0f2f5;
}
.nav-users a > img {
  position: absolute;
  left: 12px;
  top: 10px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.nav-users a > i {
  position: absolute;
  left: 40px;
  top: 40px;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
}
.nav-users a:hover {
  background-color: #f6f7f9;
}

.nav-items {
  padding-left: 0;
  list-style: none;
}
.nav-items a:hover {
  background-color: #f6f7f9;
}
.nav-items a:active {
  background-color: #f0f2f5;
}
.nav-items > li:not(:last-child) > a {
  border-bottom: 1px solid #f0f2f5;
}

.breadcrumb-item {
  font-weight: 500;
}
.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
  font-weight: 900;
  content: "\f105";
  opacity: 0.5;
}

.page-link {
  font-weight: 600;
}
.page-link:focus {
  background-color: #e4e7ed;
  border-color: #e4e7ed;
}

.modal-dialog.modal-dialog-top {
  margin-top: 0 !important;
}

.modal.fade .modal-dialog {
  transition-duration: 0.25s;
}
.modal.fade .modal-dialog.modal-dialog-popin {
  transform: scale(1.25, 1.25);
}
.modal.fade .modal-dialog.modal-dialog-popout {
  transform: scale(0.75, 0.75);
}
.modal.fade .modal-dialog.modal-dialog-slideup {
  transform: translate(0, 30%);
}
.modal.fade .modal-dialog.modal-dialog-slideright {
  transform: translate(-30%, 0);
}
.modal.fade .modal-dialog.modal-dialog-slideleft {
  transform: translate(30%, 0);
}
.modal.fade .modal-dialog.modal-dialog-fromright {
  transform: translateX(30%) rotate(10deg) scale(0.75);
}
.modal.fade .modal-dialog.modal-dialog-fromleft {
  transform: translateX(-30%) rotate(-10deg) scale(0.75);
}
.modal.show .modal-dialog.modal-dialog-popin, .modal.show .modal-dialog.modal-dialog-popout {
  transform: scale(1, 1);
}
.modal.show .modal-dialog.modal-dialog-slideup, .modal.show .modal-dialog.modal-dialog-slideright, .modal.show .modal-dialog.modal-dialog-slideleft {
  transform: translate(0, 0);
}
.modal.show .modal-dialog.modal-dialog-fromright, .modal.show .modal-dialog.modal-dialog-fromleft {
  transform: translateX(0) rotate(0) scale(1);
}

@media print {
  #page-container,
  #main-container {
    padding: 0 !important;
  }
  #page-overlay,
  #page-loader,
  #page-header,
  #page-footer,
  #sidebar,
  #side-overlay,
  .block-options {
    display: none !important;
  }
  .block {
    box-shadow: none !important;
  }
}
#page-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
}

#page-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(0, 0, 0, 0.4);
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  z-index: 1033;
  opacity: 0;
  transition: opacity 0.26s cubic-bezier(0.47, 0, 0.745, 0.715), transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
  transform: translateX(100%);
}
.sidebar-r #page-overlay {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  transform: translateX(-100%);
}
@media (min-width: 992px) {
  .side-overlay-o.enable-page-overlay #page-overlay {
    transform: translateX(0);
    opacity: 1;
  }
}

#main-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 100%;
}

.content {
  width: 100%;
  margin: 0 auto;
  padding: 0.875rem 0.875rem 1px;
  overflow-x: visible;
}
.content > .pull-t,
.content > .pull-y {
  margin-top: -0.875rem;
}
.content > .pull-b,
.content > .pull-y {
  margin-bottom: -1px;
}
.content > .pull-r,
.content > .pull-x {
  margin-right: -0.875rem;
}
.content > .pull-r,
.content > .pull-x {
  margin-left: -0.875rem;
}
.content > .pull {
  margin: -0.875rem -0.875rem -1px;
}
.content.content-full {
  padding-bottom: 0.875rem;
}
.content.content-full > .pull-b,
.content.content-full > .pull-y,
.content.content-full > .pull {
  margin-bottom: -0.875rem;
}

.content p,
.content .push,
.content .block,
.content .items-push > div {
  margin-bottom: 0.875rem;
}
.content .items-push-2x > div {
  margin-bottom: 1.75rem;
}
.content .items-push-3x > div {
  margin-bottom: 2.625rem;
}
@media (min-width: 768px) {
  .content {
    width: 100%;
    margin: 0 auto;
    padding: 1.75rem 1.75rem 1px;
    overflow-x: visible;
  }
  .content > .pull-t,
  .content > .pull-y {
    margin-top: -1.75rem;
  }
  .content > .pull-b,
  .content > .pull-y {
    margin-bottom: -1px;
  }
  .content > .pull-r,
  .content > .pull-x {
    margin-right: -1.75rem;
  }
  .content > .pull-r,
  .content > .pull-x {
    margin-left: -1.75rem;
  }
  .content > .pull {
    margin: -1.75rem -1.75rem -1px;
  }
  .content.content-full {
    padding-bottom: 1.75rem;
  }
  .content.content-full > .pull-b,
  .content.content-full > .pull-y,
  .content.content-full > .pull {
    margin-bottom: -1.75rem;
  }
  .content p,
  .content .push,
  .content .block,
  .content .items-push > div {
    margin-bottom: 1.75rem;
  }
  .content .items-push-2x > div {
    margin-bottom: 3.5rem;
  }
  .content .items-push-3x > div {
    margin-bottom: 5.25rem;
  }
}
@media (min-width: 1200px) {
  .content.content-narrow {
    width: 92%;
  }
}
.content.content-boxed {
  max-width: 1260px;
}
.content.content-top {
  padding-top: 5.125rem;
}
@media (min-width: 992px) {
  .content.content-top {
    padding-top: 6rem;
  }
}

.content-section-top {
  padding-top: 4.25rem;
}

.content-max-width {
  max-width: 1920px;
}

.content-side {
  width: 100%;
  margin: 0 auto;
  padding: 1.5rem 1.5rem 1px;
  overflow-x: hidden;
}
.content-side > .pull-t,
.content-side > .pull-y {
  margin-top: -1.5rem;
}
.content-side > .pull-b,
.content-side > .pull-y {
  margin-bottom: -1px;
}
.content-side > .pull-r,
.content-side > .pull-x {
  margin-right: -1.5rem;
}
.content-side > .pull-r,
.content-side > .pull-x {
  margin-left: -1.5rem;
}
.content-side > .pull {
  margin: -1.5rem -1.5rem -1px;
}
.content-side.content-side-full {
  padding-bottom: 1.5rem;
}
.content-side.content-side-full > .pull-b,
.content-side.content-side-full > .pull-y,
.content-side.content-side-full > .pull {
  margin-bottom: -1.5rem;
}

.content-side p,
.content-side .push,
.content-side .block,
.content-side .items-push > div {
  margin-bottom: 1.5rem;
}
.content-side .items-push-2x > div {
  margin-bottom: 3rem;
}
.content-side .items-push-3x > div {
  margin-bottom: 4.5rem;
}
.content-side.content-side-user {
  display: flex;
  align-items: center;
  min-height: 9rem;
  background-color: #f6f7f9;
}

.sidebar-dark .content-side.content-side-user {
  background-color: #2b3136;
}

#page-header {
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
}

.overlay-header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transform: translateY(-100%);
  will-change: opacity, transform;
  z-index: 1;
}
.overlay-header.show {
  opacity: 1;
  transform: translateY(0);
}

.content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 4.25rem;
}
#page-header .content-header {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
@media (min-width: 768px) {
  #page-header .content-header {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}

#sidebar .content-header, #side-overlay .content-header {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.smini-visible,
.smini-visible-block {
  display: none;
}

.smini-show {
  opacity: 0;
}

.smini-show,
.smini-hide {
  transition: opacity 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
}

#sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1032;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: visible;
  transform: translateX(-100%) translateY(0) translateZ(0);
  -webkit-overflow-scrolling: touch;
  will-change: transform;
}
.side-trans-enabled #sidebar {
  transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.sidebar-r #sidebar {
  right: 0;
  left: auto;
  transform: translateX(100%) translateY(0) translateZ(0);
}
#sidebar .js-sidebar-scroll {
  height: calc(100vh - 4.25rem);
  overflow-y: auto;
}
#sidebar .js-sidebar-scroll.full-height {
  height: 100%;
}
@media (max-width: 991.98px) {
  .sidebar-o-xs #sidebar {
    transform: translateX(0) translateY(0) translateZ(0);
  }
}
@media (min-width: 992px) {
  #sidebar {
    width: 240px;
  }
  .sidebar-o #sidebar {
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .sidebar-mini.sidebar-o #sidebar {
    overflow-x: hidden;
    transform: translateX(-176px) translateY(0) translateZ(0);
    transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  .sidebar-mini.sidebar-o.sidebar-r #sidebar {
    transform: translateX(176px) translateY(0) translateZ(0);
  }
  .sidebar-mini.sidebar-o #sidebar .content-side, .sidebar-mini.sidebar-o #sidebar .content-header {
    width: 240px;
    transform: translateX(176px) translateY(0) translateZ(0);
    transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
    will-change: transform;
  }
  .sidebar-mini.sidebar-o #sidebar .content-header {
    position: relative;
    z-index: 1;
  }
  .sidebar-mini.sidebar-o #sidebar:hover, .sidebar-mini.sidebar-o #sidebar:hover .content-side, .sidebar-mini.sidebar-o.sidebar-r #sidebar .content-side, .sidebar-mini.sidebar-o #sidebar:hover .content-header, .sidebar-mini.sidebar-o.sidebar-r #sidebar .content-header {
    transform: translateX(0);
  }
  .sidebar-mini.sidebar-o #sidebar .nav-main,
  .sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-link-icon {
    transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  .sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-heading,
  .sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-link-name,
  .sidebar-mini.sidebar-o #sidebar .nav-main .nav-main-link-badge {
    transition: opacity 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-hide {
    opacity: 0;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-show {
    opacity: 1;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-hidden {
    display: none;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-visible {
    display: inline-block;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .smini-visible-block {
    display: block;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main {
    transform: translateX(-1rem);
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link-icon {
    transform: translateX(0.75rem);
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-heading,
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link-name,
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link-badge {
    opacity: 0;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-link.active,
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main .nav-main-item.open > .nav-main-link-submenu {
    border-radius: 0;
  }
  .sidebar-mini.sidebar-o #sidebar:not(:hover) .nav-main > .nav-main-item > .nav-main-submenu {
    display: none;
  }
}

#side-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1034;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  transform: translateX(100%) translateY(0) translateZ(0);
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  will-change: transform;
}
.side-trans-enabled #side-overlay {
  transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715), opacity 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.sidebar-r #side-overlay {
  right: auto;
  left: 0;
  transform: translateX(-100%) translateY(0) translateZ(0);
}
.side-overlay-o #side-overlay {
  transform: translateX(0) translateY(0) translateZ(0);
  opacity: 1;
}
@media (min-width: 992px) {
  #side-overlay {
    width: 320px;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
    transform: translateX(110%) translateY(0) translateZ(0);
  }
  .sidebar-r #side-overlay {
    transform: translateX(-110%) translateY(0) translateZ(0);
  }
  .side-overlay-hover #side-overlay {
    transform: translateX(304px) translateY(0) translateZ(0);
    opacity: 1;
  }
  .sidebar-r.side-overlay-hover #side-overlay {
    transform: translateX(-304px) translateY(0) translateZ(0);
    opacity: 1;
  }
  .side-overlay-hover #side-overlay:hover, .side-overlay-o #side-overlay, .side-overlay-o.side-overlay-hover #side-overlay {
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.15);
    transform: translateX(0) translateY(0) translateZ(0);
    opacity: 1;
  }
}

#page-container > #page-header .content-header,
#page-container > #page-header .content,
#page-container > #main-container .content,
#page-container > #page-footer .content {
  max-width: 1920px;
}
@media (min-width: 1200px) {
  #page-container.main-content-narrow > #page-header .content-header,
  #page-container.main-content-narrow > #page-header .content,
  #page-container.main-content-narrow > #main-container .content,
  #page-container.main-content-narrow > #page-footer .content {
    width: 92%;
  }
}
#page-container.main-content-boxed > #page-header .content-header,
#page-container.main-content-boxed > #page-header .content,
#page-container.main-content-boxed > #main-container .content,
#page-container.main-content-boxed > #page-footer .content {
  max-width: 1260px;
}
@media (min-width: 992px) {
  #page-container.page-header-modern #page-header {
    background-color: #f0f2f5;
    box-shadow: none !important;
  }
  #page-container.page-header-modern #page-header > .content-header {
    padding-top: 1.25rem;
  }
}
#page-container.page-header-dark #page-header {
  color: #cbd2dd;
  background-color: #31373d;
}
#page-container.page-header-glass #page-header {
  position: absolute;
  background-color: transparent;
}
#page-container.page-header-glass.page-header-fixed #page-header {
  transition: background-color 0.2s linear;
  will-change: background-color;
  box-shadow: none;
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll #page-header {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02);
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll.page-header-dark #page-header {
  background-color: #31373d;
  box-shadow: none;
}
#page-container.page-header-glass #main-container, #page-container.page-header-glass.page-header-fixed #main-container {
  padding-top: 0;
}
#page-container.page-header-fixed #page-header {
  position: fixed;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.02);
}
#page-container.page-header-fixed.page-header-dark #page-header {
  box-shadow: none;
}
#page-container.page-header-fixed #main-container {
  padding-top: 4.25rem;
}
#page-container.page-header-fixed #page-header, #page-container.page-header-glass #page-header {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  min-width: 320px;
  max-width: 100%;
  width: auto;
}
@media (min-width: 992px) {
  #page-container.page-header-fixed.sidebar-o #page-header, #page-container.page-header-glass.sidebar-o #page-header {
    padding-left: 240px;
  }
  #page-container.page-header-fixed.sidebar-o #page-header .overlay-header, #page-container.page-header-glass.sidebar-o #page-header .overlay-header {
    left: 240px;
  }
  #page-container.page-header-fixed.sidebar-r.sidebar-o #page-header, #page-container.page-header-glass.sidebar-r.sidebar-o #page-header {
    padding-right: 240px;
    padding-left: 0;
  }
  #page-container.page-header-fixed.sidebar-r.sidebar-o #page-header .overlay-header, #page-container.page-header-glass.sidebar-r.sidebar-o #page-header .overlay-header {
    right: 240px;
    left: 0;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-o #page-header, #page-container.page-header-glass.sidebar-mini.sidebar-o #page-header {
    padding-left: 64px;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-o #page-header .overlay-header, #page-container.page-header-glass.sidebar-mini.sidebar-o #page-header .overlay-header {
    left: 64px;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-r.sidebar-o #page-header, #page-container.page-header-glass.sidebar-mini.sidebar-r.sidebar-o #page-header {
    padding-right: 64px;
    padding-left: 0;
  }
  #page-container.page-header-fixed.sidebar-mini.sidebar-r.sidebar-o #page-header .overlay-header, #page-container.page-header-glass.sidebar-mini.sidebar-r.sidebar-o #page-header .overlay-header {
    right: 64px;
    left: 0;
  }
}
@media (min-width: 992px) {
  #page-container.page-header-modern:not(.sidebar-dark) #sidebar {
    box-shadow: 0.25rem 0 0.625rem #e4e7ed;
  }
  #page-container.page-header-modern.sidebar-r:not(.sidebar-dark) #sidebar {
    box-shadow: -0.25rem 0 0.625rem #e4e7ed;
  }
}
#page-container.sidebar-dark #sidebar {
  color: #e4e7ed;
  background-color: #31373d;
  box-shadow: none;
}
#page-container.side-scroll #sidebar .js-sidebar-scroll,
#page-container.side-scroll #side-overlay {
  overflow-y: visible;
}
@media (min-width: 992px) {
  #page-container.side-scroll #sidebar .content-header,
  #page-container.side-scroll #sidebar .content-side {
    width: 240px !important;
  }
  #page-container.side-scroll #side-overlay .content-header,
  #page-container.side-scroll #side-overlay .content-side {
    width: 320px !important;
  }
}
@media (min-width: 992px) {
  #page-container.sidebar-o {
    padding-left: 240px;
  }
  #page-container.sidebar-r.sidebar-o {
    padding-right: 240px;
    padding-left: 0;
  }
  #page-container.sidebar-mini.sidebar-o {
    padding-left: 64px;
  }
  #page-container.sidebar-mini.sidebar-o.sidebar-r {
    padding-right: 64px;
    padding-left: 0;
  }
  #page-container.sidebar-mini.sidebar-o #sidebar:not(:hover) .content-header {
    width: 64px !important;
    margin-left: 0;
  }
}

.hero {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 500px;
}
.hero.hero-sm {
  min-height: 300px;
}
.hero.hero-lg {
  min-height: 800px;
}
.hero-inner {
  flex: 0 0 auto;
  width: 100%;
}
.hero-meta {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  text-align: center;
}

.hero-static {
  min-height: 100vh;
}

.hero-bubbles {
  position: relative;
  overflow: hidden;
}
.hero-bubbles .hero-bubble {
  position: absolute;
  display: inline-block;
  border-radius: 100%;
  opacity: 0.1;
  z-index: 0;
  width: 2rem;
  height: 2rem;
  filter: blur(1rem);
}
.hero-bubbles .hero-bubble .hero-bubble-sm {
  width: 1.5rem;
  height: 1.5rem;
}
.hero-bubbles .hero-bubble .hero-bubble-lg {
  width: 2.75rem;
  height: 2.75rem;
}
.hero-bubbles .hero-bubble:nth-child(odd) {
  animation: hero-bubble 20s infinite ease-in;
}
.hero-bubbles .hero-bubble:nth-child(even) {
  animation: hero-bubble 20s infinite ease-in reverse;
}

@keyframes hero-bubble {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.5) translateY(1rem);
  }
  50% {
    opacity: 0.5;
  }
  66% {
    transform: scale(0.5) translateY(0);
  }
  100% {
    transform: scale(1);
    opacity: 0.2;
  }
}
.block {
  margin-bottom: 1.75rem;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(220, 225, 232, 0.5), 0 1px 2px rgba(220, 225, 232, 0.5);
}
.block .block, .content-side .block {
  box-shadow: none;
}

.block-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem 1.625rem;
  transition: opacity 0.2s ease-out;
}
.block-header.block-header-rtl {
  flex-direction: row-reverse;
}
.block-header.block-header-rtl .block-title {
  text-align: right;
}
.block-header.block-header-rtl .block-options {
  padding-right: 1.625rem;
  padding-left: 0;
}
.block-header-default {
  background-color: #f6f7f9;
}

.block-title {
  flex: 1 1 auto;
  min-height: 1.75rem;
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75;
}
.block-title small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  opacity: 0.75;
}

.block-content {
  transition: opacity 0.2s ease-out;
  width: 100%;
  margin: 0 auto;
  padding: 1.625rem 1.625rem 1px;
  overflow-x: visible;
}
.block-content > .pull-t,
.block-content > .pull-y {
  margin-top: -1.625rem;
}
.block-content > .pull-b,
.block-content > .pull-y {
  margin-bottom: -1px;
}
.block-content > .pull-r,
.block-content > .pull-x {
  margin-right: -1.625rem;
}
.block-content > .pull-r,
.block-content > .pull-x {
  margin-left: -1.625rem;
}
.block-content > .pull {
  margin: -1.625rem -1.625rem -1px;
}
.block-content.block-content-full {
  padding-bottom: 1.625rem;
}
.block-content.block-content-full > .pull-b,
.block-content.block-content-full > .pull-y,
.block-content.block-content-full > .pull {
  margin-bottom: -1.625rem;
}

.block-content p,
.block-content .push,
.block-content .block,
.block-content .items-push > div {
  margin-bottom: 1.625rem;
}
.block-content .items-push-2x > div {
  margin-bottom: 3.25rem;
}
.block-content .items-push-3x > div {
  margin-bottom: 4.875rem;
}
.block-content.block-content-sm {
  padding-top: 0.8125rem;
}
.block-content.block-content-sm .pull-t,
.block-content.block-content-sm .pull-t-b,
.block-content.block-content-sm .pull-all {
  margin-top: -0.8125rem;
}
.block-content.block-content-sm.block-content-full {
  padding-bottom: 0.8125rem;
}
.block-content.block-content-sm.block-content-full .pull-b,
.block-content.block-content-sm.block-content-full .pull-y,
.block-content.block-content-sm.block-content-full .pull {
  margin-bottom: -0.8125rem;
}

.block.block-bordered {
  border: 1px solid #e4e7ed;
  box-shadow: none;
}
.block.block-rounded {
  border-radius: 0.875rem 1rem;
}
.block.block-rounded > .block-header,
.block.block-rounded > .nav-tabs {
  border-top-right-radius: 0.875rem 1rem;
  border-top-left-radius: 0.875rem 1rem;
}
.block.block-rounded > .block-header:last-child,
.block.block-rounded > .nav-tabs:last-child {
  border-bottom-right-radius: 0.875rem 1rem;
  border-bottom-left-radius: 0.875rem 1rem;
}
.block.block-rounded.block-mode-hidden > .block-header.block-header-default {
  border-bottom-right-radius: 0.875rem 1rem;
  border-bottom-left-radius: 0.875rem 1rem;
}
.block.block-rounded > .block-content:first-child {
  border-top-right-radius: 0.875rem 1rem;
  border-top-left-radius: 0.875rem 1rem;
}
.block.block-rounded > .block-content:last-child {
  border-bottom-right-radius: 0.875rem 1rem;
  border-bottom-left-radius: 0.875rem 1rem;
}
.block.block-themed > .block-header {
  border-bottom: none;
  color: #fff;
  background-color: #0284c7;
}
.block.block-themed > .block-header > .block-title {
  color: rgba(255, 255, 255, 0.9);
}
.block.block-themed > .block-header > .block-title small {
  color: rgba(255, 255, 255, 0.7);
}
.block.block-transparent {
  background-color: transparent;
  box-shadow: none;
}

.block.block-mode-hidden.block-bordered > .block-header {
  border-bottom: none;
}
.block.block-mode-hidden > .block-content {
  display: none;
}
.block.block-mode-loading {
  position: relative;
  overflow: hidden;
}
.block.block-mode-loading::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 9;
  content: " ";
  background-color: rgba(255, 255, 255, 0.85);
}
.block.block-mode-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  color: #454c53;
  font-family: Simple-Line-Icons;
  font-size: 1.125rem;
  text-align: center;
  z-index: 10;
  content: "\e09a";
  animation: fa-spin 1.75s infinite linear;
  border-radius: 1.5rem;
  box-shadow: 0 0 1rem 1rem rgba(69, 76, 83, 0.05);
}
.block.block-mode-loading.block-mode-hidden::after {
  margin: -1rem 0 0 -1rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  box-shadow: 0 0 0.5rem 0.5rem rgba(69, 76, 83, 0.05);
}
.block.block-mode-loading.block-mode-loading-dark::before {
  background-color: rgba(0, 0, 0, 0.85);
}
.block.block-mode-loading.block-mode-loading-dark::after {
  color: #fff;
  box-shadow: 0 0 1rem 1rem rgba(255, 255, 255, 0.5);
}
.block.block-mode-loading.block-mode-loading-location::after {
  content: "\e06e";
}
.block.block-mode-loading.block-mode-loading-energy::after {
  content: "\e020";
}
.block.block-mode-loading.block-mode-loading-refresh::after {
  font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
  font-weight: 900;
  content: "\f021";
}
.block.block-mode-loading.block-mode-loading-sun::after {
  font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
  font-weight: 900;
  content: "\f185";
}
.block.block-mode-loading.block-mode-loading-repeat::after {
  font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
  font-weight: 900;
  content: "\f01e";
}
.block.block-mode-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1035;
  margin-bottom: 0;
  overflow-y: auto;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
}
.block.block-mode-fullscreen.block-bordered {
  border: none;
}
.block.block-mode-fullscreen.block-rounded {
  border-radius: 0;
}
.block.block-mode-fullscreen.block-transparent {
  background-color: #fff;
}
.block.block-mode-pinned {
  position: fixed;
  right: 0.75rem;
  bottom: 0;
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 300px;
  z-index: 1031;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.block.block-mode-pinned:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.block.block-mode-pinned > .block-content {
  max-height: 250px;
  overflow-y: auto;
}
@media (min-width: 576px) {
  .block.block-mode-pinned {
    max-width: 400px;
  }
}

a.block {
  display: block;
  color: #3e444a;
  font-weight: normal;
  transition: all 0.12s ease-out;
}
a.block:hover {
  color: #3e444a;
  opacity: 0.65;
}
a.block:active {
  opacity: 1;
}
a.block.block-link-rotate:hover {
  transform: rotate(1deg);
  opacity: 1;
}
a.block.block-link-rotate:active {
  transform: rotate(0deg);
}
a.block.block-link-pop:hover {
  box-shadow: 0 0.125rem 2rem #d8dde5;
  transform: translateY(-3px);
  opacity: 1;
}
a.block.block-link-pop:active {
  box-shadow: 0 0.125rem 0.625rem #edeff3;
  transform: translateY(0);
}
a.block.block-link-shadow:hover {
  box-shadow: 0 0 2.125rem #d8dde5;
  opacity: 1;
}
a.block.block-link-shadow:active {
  box-shadow: 0 0 0.625rem #e4e7ed;
}

.block.block-fx-rotate {
  transform: rotate(1deg);
  opacity: 1;
}
.block.block-fx-pop {
  box-shadow: 0 0.125rem 1rem #d8dde5;
  transform: translateY(-3px);
  opacity: 1;
}
.block.block-fx-shadow {
  box-shadow: 0 0 2.125rem #d8dde5;
  opacity: 1;
}

.block-options {
  flex: 0 0 auto;
  padding-right: 1.625rem;
}
.block-options .block-options-item {
  padding: 0 0.25rem;
  line-height: 1.2;
}
.block.block-themed > .block-header .block-options .block-options-item {
  color: #fff;
}
.block-options .block-options-item,
.block-options .dropdown {
  display: inline-block;
}

.block-sticky-options {
  position: relative;
}
.block-sticky-options .block-options {
  position: absolute;
  top: 0.875rem;
  right: 1.625rem;
}
.block-sticky-options .block-options.block-options-left {
  right: auto;
  left: 1.625rem;
  padding-right: 0.625rem;
  padding-left: 0;
}

.btn-block-option {
  display: inline-block;
  padding: 0.375rem 0.25rem;
  line-height: 1;
  color: #6c757d;
  background: none;
  border: none;
  cursor: pointer;
}
.btn-block-option.dropdown-toggle:after {
  position: relative;
  top: 2px;
}
.btn-block-option .si {
  position: relative;
  top: 1px;
}
.btn-block-option:hover {
  text-decoration: none;
  color: #212529;
}
a.btn-block-option:focus,
.active > a.btn-block-option,
.show > button.btn-block-option {
  text-decoration: none;
  color: #212529;
}

.btn-block-option:focus {
  outline: none !important;
  color: #000;
}
.btn-block-option:active {
  color: #6c757d;
}
.block.block-themed .btn-block-option {
  color: #fff;
  opacity: 0.7;
}
.block.block-themed .btn-block-option:hover {
  color: #fff;
  opacity: 1;
}
a.block.block-themed .btn-block-option:focus,
.active > a.block.block-themed .btn-block-option,
.show > button.block.block-themed .btn-block-option {
  color: #fff;
  opacity: 1;
}

.block.block-themed .btn-block-option:focus {
  color: #fff;
  opacity: 1;
}
.block.block-themed .btn-block-option:active {
  color: #fff;
  opacity: 0.6;
}

#page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0284c7;
  z-index: 999998;
  transition: transform 0.35s ease-out;
  transform: translateY(-100%);
  will-change: transform;
}
#page-loader.show {
  transform: translateY(0);
}
#page-loader::after, #page-loader::before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  border-radius: 100%;
  content: "";
  z-index: 999999;
}
#page-loader::after {
  margin-top: -100px;
  margin-left: -100px;
  width: 200px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 3px solid rgba(255, 255, 255, 0.75);
  animation: page-loader-inner 1.5s infinite ease-out;
}
#page-loader::before {
  margin-top: -30px;
  margin-left: -30px;
  width: 60px;
  height: 60px;
  background-color: white;
  animation: page-loader-outer 1.5s infinite ease-in;
}

@keyframes page-loader-inner {
  0%, 40% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes page-loader-outer {
  0% {
    transform: scale(1);
  }
  40%, 100% {
    transform: scale(0);
    opacity: 0;
  }
}
.nav-main {
  padding-left: 0;
  list-style: none;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.nav-main-heading {
  padding: 1.5rem 1.5rem 0.375rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
  color: #79848f;
}

.nav-main-link {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  min-height: 2.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0.0125em;
  color: #4a5158;
}
.nav-main-link .nav-main-link-icon {
  flex: 0 0 auto;
  display: inline-block;
  margin-right: 0.625rem;
  min-width: 1.25rem;
  font-size: 1rem;
  text-align: center;
  color: #a3aab2;
}
.nav-main-link .nav-main-link-name {
  flex: 1 1 auto;
  display: inline-block;
  max-width: 100%;
}
.nav-main-link .nav-main-link-badge {
  flex: 0 0 auto;
  display: inline-block;
  margin-left: 0.625rem;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  font-size: 0.75rem;
}
.nav-main-link:hover, .nav-main-link:focus, .nav-main-link.active {
  color: #040405;
}
.nav-main-link:hover .nav-main-link-icon, .nav-main-link:focus .nav-main-link-icon, .nav-main-link.active .nav-main-link-icon {
  color: #0284c7;
}
.nav-main-link.nav-main-link-submenu {
  padding-right: 2rem;
}
.nav-main-link.nav-main-link-submenu::before, .nav-main-link.nav-main-link-submenu::after {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  display: block;
  margin-top: -0.5rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
  font-weight: 900;
  font-size: 0.75rem;
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.nav-main-link.nav-main-link-submenu::before {
  content: "\f104";
  opacity: 0.4;
}
.nav-main-link.nav-main-link-submenu::after {
  content: "\f103";
  opacity: 0;
  transform: rotate(90deg);
}

.nav-main-submenu {
  padding-left: 3.375rem;
  list-style: none;
  height: 0;
  overflow: hidden;
  background-color: #f6f7f9;
}
.nav-main-submenu > .nav-main-item {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateY(-1rem);
}
.nav-main-submenu .nav-main-heading {
  padding: 1.25rem 0 0.25rem;
}
.nav-main-submenu .nav-main-link {
  margin: 0;
  padding-left: 0;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  min-height: 2.125rem;
  font-size: 0.875rem;
  color: #79848f;
}
.nav-main-submenu .nav-main-link:hover, .nav-main-submenu .nav-main-link:focus, .nav-main-submenu .nav-main-link.active {
  color: #1b1e20;
}
.nav-main-submenu .nav-main-submenu {
  padding-left: 0.75rem;
}

.nav-main-item.open > .nav-main-link-submenu {
  color: #000;
}
.nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon {
  color: #0284c7;
}
.nav-main-item.open > .nav-main-link-submenu::before {
  opacity: 0;
  transform: rotate(-90deg);
}
.nav-main-item.open > .nav-main-link-submenu::after {
  opacity: 0.4;
  transform: rotate(0);
}
.nav-main-item.open > .nav-main-submenu {
  height: auto;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.nav-main-item.open > .nav-main-submenu > .nav-main-item {
  opacity: 1;
  transform: translateY(0);
}

.nav-main-horizontal {
  margin: 0;
}

@media (min-width: 992px) {
  .nav-main-horizontal {
    display: flex;
    flex-wrap: wrap;
  }
  .nav-main-horizontal .nav-main-heading {
    display: none;
  }
  .nav-main-horizontal > .nav-main-item {
    position: relative;
    display: inline-block;
  }
  .nav-main-horizontal > .nav-main-item:not(:last-child) {
    margin-right: 0.25rem;
  }
  .nav-main-horizontal > .nav-main-item > .nav-main-link {
    padding-left: 1rem;
  }
  .nav-main-horizontal > .nav-main-item > .nav-main-link:not(.nav-main-link-submenu) {
    padding-right: 1rem;
  }
  .nav-main-horizontal .nav-main-link-submenu::before {
    content: "\f107";
  }
  .nav-main-horizontal .nav-main-submenu {
    position: absolute;
    left: 0;
    width: 230px;
    padding-left: 0;
    z-index: 995;
    background-color: #f0f2f5;
    box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.08);
  }
  .nav-main-horizontal .nav-main-submenu.nav-main-submenu-right {
    left: auto;
    right: 0;
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-link {
    padding-left: 1rem;
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-link-submenu::before {
    content: "\f105";
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-item.open > .nav-main-link-submenu::before {
    transform: scaleX(-1);
  }
  .nav-main-horizontal .nav-main-item.open > .nav-main-link-submenu::before {
    transform: scaleY(-1);
  }
  .nav-main-horizontal .nav-main-item.open > .nav-main-submenu {
    height: auto;
    margin-top: -1px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: visible;
  }
  .nav-main-horizontal .nav-main-item.open > .nav-main-submenu > .nav-main-item {
    transform: translateX(0);
  }
  .nav-main-horizontal .nav-main-submenu .nav-main-submenu {
    top: -0.5rem;
    left: auto;
    right: -100%;
    margin-top: 0;
  }
  .nav-main-horizontal.nav-main-horizontal-center {
    justify-content: center;
  }
  .nav-main-horizontal.nav-main-horizontal-justify > .nav-main-item {
    flex: 1 1 auto;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu {
    opacity: 1;
    color: #000;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu > .nav-main-link-icon {
    color: #000;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu::before {
    transform: scaleY(-1);
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu {
    height: auto;
    margin-top: -1px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: visible;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu .nav-main-submenu {
    margin-top: 0;
  }
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu > .nav-main-item {
    opacity: 1;
    transform: translateX(0);
  }
  .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover > .nav-main-link-submenu::before {
    transform: scaleX(-1);
  }
}
.nav-main-dark .nav-main-link,
.sidebar-dark #sidebar .nav-main-link,
.page-header-dark #page-header .nav-main-link {
  color: rgba(228, 231, 237, 0.75);
  font-weight: 400;
}
.nav-main-dark .nav-main-link > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link > .nav-main-link-icon {
  color: rgba(255, 255, 255, 0.3);
}
.nav-main-dark .nav-main-link:hover, .nav-main-dark .nav-main-link:focus, .nav-main-dark .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-link:focus,
.sidebar-dark #sidebar .nav-main-link.active,
.page-header-dark #page-header .nav-main-link:hover,
.page-header-dark #page-header .nav-main-link:focus,
.page-header-dark #page-header .nav-main-link.active {
  color: #fff;
}
.nav-main-dark .nav-main-link:hover > .nav-main-link-icon, .nav-main-dark .nav-main-link:focus > .nav-main-link-icon, .nav-main-dark .nav-main-link.active > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link:hover > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link:focus > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link.active > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link:hover > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link:focus > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link.active > .nav-main-link-icon {
  color: #fff;
}
.nav-main-dark .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-submenu,
.page-header-dark #page-header .nav-main-submenu {
  background-color: #2a2f35;
}
.nav-main-dark .nav-main-submenu .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-link {
  color: rgba(228, 231, 237, 0.5);
}
.nav-main-dark .nav-main-submenu .nav-main-link:hover, .nav-main-dark .nav-main-submenu .nav-main-link:focus, .nav-main-dark .nav-main-submenu .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link:focus,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link.active,
.page-header-dark #page-header .nav-main-submenu .nav-main-link:hover,
.page-header-dark #page-header .nav-main-submenu .nav-main-link:focus,
.page-header-dark #page-header .nav-main-submenu .nav-main-link.active {
  color: #fff;
  background-color: transparent;
}
.nav-main-dark .nav-main-item.open > .nav-main-link-submenu,
.nav-main-dark .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-item.open > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-link-submenu > .nav-main-link-icon {
  color: #fff;
}
.nav-main-dark .nav-main-item.open > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-submenu {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-main-dark .nav-main-submenu .nav-main-item.open .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-item.open .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-main-dark.nav-main-horizontal .nav-main-submenu,
  .sidebar-dark #sidebar .nav-main-horizontal .nav-main-submenu,
  .page-header-dark #page-header .nav-main-horizontal .nav-main-submenu {
    background-color: #2a2f35 !important;
    box-shadow: none;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu,
  .sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu,
  .page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu {
    color: #fff;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu > .nav-main-link-icon,
  .sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu > .nav-main-link-icon,
  .page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu > .nav-main-link-icon {
    color: #fff;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link,
  .sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link,
  .page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link {
    background-color: transparent;
  }
}
.img-avatar {
  display: inline-block !important;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.img-avatar.img-avatar16 {
  width: 16px;
  height: 16px;
}
.img-avatar.img-avatar20 {
  width: 20px;
  height: 20px;
}
.img-avatar.img-avatar32 {
  width: 32px;
  height: 32px;
}
.img-avatar.img-avatar48 {
  width: 48px;
  height: 48px;
}
.img-avatar.img-avatar96 {
  width: 96px;
  height: 96px;
}
.img-avatar.img-avatar128 {
  width: 128px;
  height: 128px;
}
.img-avatar-thumb {
  margin: 0.25rem;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.4);
}
.img-avatar.img-avatar-rounded {
  border-radius: 0.25rem;
}
.img-avatar.img-avatar-square {
  border-radius: 0;
}

.img-thumb {
  padding: 0.375rem;
  background-color: #fff;
  border-radius: 0.25rem;
}

.img-link {
  display: inline-block;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
}
.img-link:hover {
  transform: scale(1.04);
  opacity: 0.75;
}
.img-link:active {
  transform: scale(1);
  opacity: 1;
}
.img-link.img-link-simple:hover {
  transform: scale(1);
}

.img-link-zoom-in {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.img-fluid.img-fluid-100,
.img-fluid-100 .img-fluid {
  width: 100%;
}

.list {
  padding-left: 0;
  list-style: none;
}
.list > li {
  position: relative;
}
.list.list-simple > li, .list-li-push > li {
  margin-bottom: 1.25rem;
}
.list.list-simple-mini > li, .list-li-push-sm > li {
  margin-bottom: 0.625rem;
}
.list-icons > li {
  margin-bottom: 1.25rem;
  padding-left: 0.5rem;
}

.list-activity > li {
  margin-bottom: 0.75rem;
  padding-bottom: 0.375rem;
  padding-left: 2rem;
}
.list-activity > li > i:first-child {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
}
.list-activity > li:not(:last-child) {
  border-bottom: 1px solid #f0f2f5;
}

.list-events > li {
  margin-bottom: 0.25rem;
}
.list-events .js-event {
  background-color: #0891B2;
  transition: transform 0.3s ease-out;
}
.list-events .js-event::before {
  position: absolute;
  top: 50%;
  right: 10px;
  left: auto;
  transform: translateX(0) translateY(-50%);
  display: inline-block;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
  font-weight: 900;
  content: "\f073";
}
.list-events .js-event:hover {
  cursor: move;
  transform: translateX(-0.25rem);
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}
a.item {
  will-change: opacity;
}
a.item:hover, a.item:focus {
  opacity: 0.6;
}
a.item:active {
  opacity: 1;
}
a.item.item-link-pop {
  will-change: transform;
}
a.item.item-link-pop:hover, a.item.item-link-pop:focus {
  opacity: 1;
  transform: scale(1.1);
}
a.item.item-link-pop:active {
  transform: scale(1);
}

.item.item-tiny {
  width: 1rem;
  height: 1rem;
}
.item.item-2x {
  width: 6rem;
  height: 6rem;
}
.item.item-3x {
  width: 8rem;
  height: 8rem;
}
.item.item-circle {
  border-radius: 50%;
}
.item.item-rounded {
  border-radius: 0.375rem;
}
.item.item-rounded-lg {
  border-radius: 1.75rem;
}
.item.item-rounded-lg.item-2x {
  border-radius: 2.25rem;
}
.item.item-rounded-lg.item-3x {
  border-radius: 3rem;
}

.options-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: block;
}
.options-container .options-item {
  transition: transform 0.35s ease-out;
  will-change: transform;
}
.options-container .options-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: 2;
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;
  will-change: opacity, transform;
}
.options-container .options-overlay-content {
  text-align: center;
}
.options-container:hover .options-overlay {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767.98px) {
  .options-container .options-overlay {
    display: none;
  }
  .options-container:hover .options-overlay {
    display: block;
  }
}

.fx-item-zoom-in:hover .options-item {
  transform: scale(1.2, 1.2);
}

.fx-item-rotate-r:hover .options-item {
  transform: scale(1.4) rotate(8deg);
}

.fx-item-rotate-l:hover .options-item {
  transform: scale(1.4) rotate(-8deg);
}

.fx-overlay-slide-top .options-overlay {
  transform: translateY(100%);
}
.fx-overlay-slide-top:hover .options-overlay {
  transform: translateY(0);
}

.fx-overlay-slide-right .options-overlay {
  transform: translateX(-100%);
}
.fx-overlay-slide-right:hover .options-overlay {
  transform: translateX(0);
}

.fx-overlay-slide-down .options-overlay {
  transform: translateY(-100%);
}
.fx-overlay-slide-down:hover .options-overlay {
  transform: translateY(0);
}

.fx-overlay-slide-left .options-overlay {
  transform: translateX(100%);
}
.fx-overlay-slide-left:hover .options-overlay {
  transform: translateX(0);
}

.fx-overlay-zoom-in .options-overlay {
  transform: scale(0, 0);
}
.fx-overlay-zoom-in:hover .options-overlay {
  transform: scale(1, 1);
}

.fx-overlay-zoom-out .options-overlay {
  transform: scale(2, 2);
}
.fx-overlay-zoom-out:hover .options-overlay {
  transform: scale(1, 1);
}

.timeline {
  position: relative;
  padding-top: 1.625rem;
  padding-left: 0;
  list-style: none;
}
.timeline .timeline-event {
  position: relative;
}
.timeline .timeline-event:not(:last-child) {
  margin-bottom: 0.5rem;
}
.timeline .timeline-event-time {
  margin: 0;
  padding: 0.625rem 1.25rem;
  min-height: 50px;
  text-align: right;
  color: #6c757d;
  line-height: 30px;
  font-size: 0.875rem;
  background-color: #f6f7f9;
  border-radius: 1rem;
}
.timeline .timeline-event-icon {
  position: absolute;
  top: 0.5rem;
  left: 0.625rem;
  width: 2.125rem;
  height: 2.125rem;
  line-height: 2.125rem;
  color: #fff;
  text-align: center;
  border-radius: 0.625rem;
}
.timeline .timeline-event-block {
  padding: 0.75rem 0.75rem 1px;
}
.timeline .timeline-event-block > p:first-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline-modern {
    margin-bottom: 0;
  }
  .timeline-modern::before {
    position: absolute;
    top: 0;
    left: 119px;
    bottom: 0;
    display: block;
    width: 6px;
    content: "";
    background-color: #f6f7f9;
    z-index: 1;
  }
  .timeline-modern .timeline-event {
    min-height: 40px;
    z-index: 2;
  }
  .timeline-modern .timeline-event:last-child {
    margin-bottom: 0;
  }
  .timeline-modern .timeline-event-time {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding-right: 0;
    padding-left: 0;
    width: 90px;
    line-height: normal;
    background-color: transparent;
  }
  .timeline-modern .timeline-event-icon {
    top: 3px;
    left: 105px;
    z-index: 2 !important;
  }
  .timeline-modern .timeline-event-block {
    padding-top: 0.5rem;
    padding-left: 155px;
  }
}
.ribbon {
  position: relative;
  min-height: 3.25rem;
}

.ribbon-box {
  position: absolute;
  top: 1rem;
  right: 0;
  padding: 0 0.75rem;
  height: 2rem;
  line-height: 2rem;
  z-index: 5;
  font-weight: 500;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.ribbon-box::before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  content: "";
}

.ribbon-bookmark .ribbon-box {
  padding-left: 0.625rem;
  border-radius: 0 !important;
}
.ribbon-bookmark .ribbon-box::before {
  top: 0;
  right: 100%;
  height: 2rem;
  border: 1rem solid;
  border-left-width: 0.625rem;
  border-right-width: 0;
}

.ribbon-modern .ribbon-box {
  top: 1rem;
  right: -0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem !important;
}
.ribbon-modern .ribbon-box::before {
  display: none;
}

.ribbon-left .ribbon-box {
  right: auto;
  left: 0;
  border-radius: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.ribbon-left.ribbon-bookmark .ribbon-box {
  padding-left: 0.75rem;
  padding-right: 0.625rem;
}
.ribbon-left.ribbon-bookmark .ribbon-box::before {
  right: auto;
  left: 100%;
  border-left-width: 0;
  border-right-width: 0.625rem;
}
.ribbon-left.ribbon-modern .ribbon-box {
  left: -0.5rem;
}

.ribbon-bottom .ribbon-box {
  top: auto;
  bottom: 0.75rem;
}

.ribbon-light .ribbon-box {
  color: #3e444a;
  background-color: #e4e7ed;
}
.ribbon-light.ribbon-bookmark .ribbon-box::before {
  border-color: #e4e7ed;
  border-left-color: transparent;
}
.ribbon-light.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #e4e7ed;
  border-right-color: transparent;
}

.ribbon-dark .ribbon-box {
  color: #fff;
  background-color: #343a40;
}
.ribbon-dark.ribbon-bookmark .ribbon-box::before {
  border-color: #343a40;
  border-left-color: transparent;
}
.ribbon-dark.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #343a40;
  border-right-color: transparent;
}

.ribbon-primary .ribbon-box {
  color: #fff;
  background-color: #0284c7;
}
.ribbon-primary.ribbon-bookmark .ribbon-box::before {
  border-color: #0284c7;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #0284c7;
  border-right-color: transparent;
}

.ribbon-success .ribbon-box {
  color: #fff;
  background-color: #16A34A;
}
.ribbon-success.ribbon-bookmark .ribbon-box::before {
  border-color: #16A34A;
  border-left-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #16A34A;
  border-right-color: transparent;
}

.ribbon-info .ribbon-box {
  color: #fff;
  background-color: #0891B2;
}
.ribbon-info.ribbon-bookmark .ribbon-box::before {
  border-color: #0891B2;
  border-left-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #0891B2;
  border-right-color: transparent;
}

.ribbon-warning .ribbon-box {
  color: #fff;
  background-color: #d97706;
}
.ribbon-warning.ribbon-bookmark .ribbon-box::before {
  border-color: #d97706;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #d97706;
  border-right-color: transparent;
}

.ribbon-danger .ribbon-box {
  color: #fff;
  background-color: #dc2626;
}
.ribbon-danger.ribbon-bookmark .ribbon-box::before {
  border-color: #dc2626;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #dc2626;
  border-right-color: transparent;
}

.ribbon-glass .ribbon-box {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.4);
}
.ribbon-glass.ribbon-bookmark .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.4);
  border-left-color: transparent;
}
.ribbon-glass.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.4);
  border-right-color: transparent;
}

.bg-black-5 {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.bg-black-10 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.bg-black-25 {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.bg-black-75 {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.bg-black-90 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.bg-black-95 {
  background-color: rgba(0, 0, 0, 0.95) !important;
}

.bg-white-5 {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.bg-white-10 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.bg-white-25 {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.bg-white-75 {
  background-color: rgba(255, 255, 255, 0.75) !important;
}
.bg-white-90 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.bg-white-95 {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.bg-image {
  background-position: 0% 50%;
  background-size: cover;
}
.bg-image-top {
  background-position-y: 0%;
}
.bg-image-center {
  background-position-x: 50%;
}
.bg-image-bottom {
  background-position-y: 100%;
}
@media (min-width: 1200px) {
  .bg-image-fixed {
    background-attachment: fixed;
  }
}

.bg-pattern {
  background-repeat: repeat;
}

.bg-video {
  width: 100%;
  transform: translateZ(0);
}

.bg-primary-op {
  background-color: rgba(2, 132, 199, 0.75) !important;
}

a.bg-primary-op:hover, a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
  background-color: rgba(1, 99, 149, 0.75) !important;
}

.bg-primary {
  background-color: #0284c7 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #016395 !important;
}

.bg-primary-dark {
  background-color: #454c53 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #2e3337 !important;
}

.bg-primary-dark-op {
  background-color: rgba(69, 76, 83, 0.8) !important;
}

a.bg-primary-dark-op:hover, a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
  background-color: rgba(46, 51, 55, 0.8) !important;
}

.bg-primary-darker {
  background-color: #1f2327 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #080a0b !important;
}

.bg-primary-light {
  background-color: #53abd9 !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #2d95cc !important;
}

.bg-primary-lighter {
  background-color: #aed8ed !important;
}

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #84c4e4 !important;
}

.bg-success {
  background-color: #16A34A !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #107636 !important;
}

.bg-success-light {
  background-color: #ebf5df !important;
}

a.bg-success-light:hover, a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
  background-color: #d3e9b8 !important;
}

.bg-warning {
  background-color: #d97706 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #a75c05 !important;
}

.bg-warning-light {
  background-color: #fcf7e6 !important;
}

a.bg-warning-light:hover, a.bg-warning-light:focus,
button.bg-warning-light:hover,
button.bg-warning-light:focus {
  background-color: #f7e8b8 !important;
}

.bg-info {
  background-color: #0891B2 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #066981 !important;
}

.bg-info-light {
  background-color: #e3f4fc !important;
}

a.bg-info-light:hover, a.bg-info-light:focus,
button.bg-info-light:hover,
button.bg-info-light:focus {
  background-color: #b5e2f7 !important;
}

.bg-danger {
  background-color: #dc2626 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #b21d1d !important;
}

.bg-danger-light {
  background-color: #fae9e8 !important;
}

a.bg-danger-light:hover, a.bg-danger-light:focus,
button.bg-danger-light:hover,
button.bg-danger-light:focus {
  background-color: #f1c1be !important;
}

.bg-success-op {
  background-color: rgba(235, 245, 223, 0.95) !important;
}

a.bg-success-op:hover, a.bg-success-op:focus,
button.bg-success-op:hover,
button.bg-success-op:focus {
  background-color: rgba(211, 233, 184, 0.95) !important;
}

.bg-warning-op {
  background-color: rgba(252, 247, 230, 0.95) !important;
}

a.bg-warning-op:hover, a.bg-warning-op:focus,
button.bg-warning-op:hover,
button.bg-warning-op:focus {
  background-color: rgba(247, 232, 184, 0.95) !important;
}

.bg-info-op {
  background-color: rgba(227, 244, 252, 0.95) !important;
}

a.bg-info-op:hover, a.bg-info-op:focus,
button.bg-info-op:hover,
button.bg-info-op:focus {
  background-color: rgba(181, 226, 247, 0.95) !important;
}

.bg-danger-op {
  background-color: rgba(250, 233, 232, 0.95) !important;
}

a.bg-danger-op:hover, a.bg-danger-op:focus,
button.bg-danger-op:hover,
button.bg-danger-op:focus {
  background-color: rgba(241, 193, 190, 0.95) !important;
}

.bg-body {
  background-color: #f0f2f5 !important;
}

a.bg-body:hover, a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
  background-color: #d1d7e1 !important;
}

.bg-body-light {
  background-color: #f6f7f9 !important;
}

a.bg-body-light:hover, a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
  background-color: #d8dde5 !important;
}

.bg-body-dark {
  background-color: #e4e7ed !important;
}

a.bg-body-dark:hover, a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
  background-color: #c5cdd8 !important;
}

.bg-body-extra-light {
  background-color: #fff !important;
}

a.bg-body-extra-light:hover, a.bg-body-extra-light:focus,
button.bg-body-extra-light:hover,
button.bg-body-extra-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-muted {
  background-color: #6c757d !important;
}

a.bg-muted:hover, a.bg-muted:focus,
button.bg-muted:hover,
button.bg-muted:focus {
  background-color: #545b62 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-gray {
  background-color: #ced4da !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #b1bbc4 !important;
}

.bg-gray-dark {
  background-color: #6c757d !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #545b62 !important;
}

.bg-gray-darker {
  background-color: #343a40 !important;
}

a.bg-gray-darker:hover, a.bg-gray-darker:focus,
button.bg-gray-darker:hover,
button.bg-gray-darker:focus {
  background-color: #1d2124 !important;
}

.bg-gray-light {
  background-color: #e9ecef !important;
}

a.bg-gray-light:hover, a.bg-gray-light:focus,
button.bg-gray-light:hover,
button.bg-gray-light:focus {
  background-color: #cbd3da !important;
}

.bg-gray-lighter {
  background-color: #f8f9fa !important;
}

a.bg-gray-lighter:hover, a.bg-gray-lighter:focus,
button.bg-gray-lighter:hover,
button.bg-gray-lighter:focus {
  background-color: #dae0e5 !important;
}

.bg-header-light {
  background-color: #fff !important;
}

a.bg-header-light:hover, a.bg-header-light:focus,
button.bg-header-light:hover,
button.bg-header-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-header-dark {
  background-color: #31373d !important;
}

a.bg-header-dark:hover, a.bg-header-dark:focus,
button.bg-header-dark:hover,
button.bg-header-dark:focus {
  background-color: #1a1e21 !important;
}

.bg-sidebar-light {
  background-color: #fff !important;
}

a.bg-sidebar-light:hover, a.bg-sidebar-light:focus,
button.bg-sidebar-light:hover,
button.bg-sidebar-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-sidebar-dark {
  background-color: #31373d !important;
}

a.bg-sidebar-dark:hover, a.bg-sidebar-dark:focus,
button.bg-sidebar-dark:hover,
button.bg-sidebar-dark:focus {
  background-color: #1a1e21 !important;
}

.bg-gd-primary {
  background: #01527b linear-gradient(135deg, #01527b 0%, #0295e0 100%) !important;
}

.bg-gd-dusk {
  background: #8f55f2 linear-gradient(135deg, #8f55f2 0%, #0284c7 100%) !important;
}

.bg-gd-cherry {
  background: #df5454 linear-gradient(135deg, #df5454 0%, #c32424 100%) !important;
}

.bg-gd-aqua {
  background: #2facb2 linear-gradient(135deg, #2facb2 0%, #36b3a0 100%) !important;
}

.bg-gd-emerald {
  background: #32a67f linear-gradient(135deg, #32a67f 0%, #2facb2 100%) !important;
}

.bg-gd-sea {
  background: #026da4 linear-gradient(135deg, #026da4 0%, #0891B2 100%) !important;
}

.bg-gd-leaf {
  background: #d97706 linear-gradient(135deg, #d97706 0%, #16A34A 100%) !important;
}

.bg-gd-lake {
  background: #0891B2 linear-gradient(135deg, #0891B2 0%, #16A34A 100%) !important;
}

.bg-gd-sun {
  background: #dc2626 linear-gradient(135deg, #dc2626 0%, #d97706 100%) !important;
}

.bg-gd-light {
  background: #f6f7f9 linear-gradient(0deg, #f6f7f9 0%, #fff 100%) !important;
}

.bg-default {
  background-color: #0284c7 !important;
}

a.bg-default:hover, a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #016395 !important;
}

.bg-default-op {
  background-color: rgba(2, 132, 199, 0.75) !important;
}

a.bg-default-op:hover, a.bg-default-op:focus,
button.bg-default-op:hover,
button.bg-default-op:focus {
  background-color: rgba(1, 99, 149, 0.75) !important;
}

.bg-default-dark {
  background-color: #454c53 !important;
}

a.bg-default-dark:hover, a.bg-default-dark:focus,
button.bg-default-dark:hover,
button.bg-default-dark:focus {
  background-color: #2e3337 !important;
}

.bg-default-dark-op {
  background-color: rgba(69, 76, 83, 0.83) !important;
}

a.bg-default-dark-op:hover, a.bg-default-dark-op:focus,
button.bg-default-dark-op:hover,
button.bg-default-dark-op:focus {
  background-color: rgba(46, 51, 55, 0.83) !important;
}

.bg-default-darker {
  background-color: #1f2327 !important;
}

a.bg-default-darker:hover, a.bg-default-darker:focus,
button.bg-default-darker:hover,
button.bg-default-darker:focus {
  background-color: #080a0b !important;
}

.bg-default-light {
  background-color: #53abd9 !important;
}

a.bg-default-light:hover, a.bg-default-light:focus,
button.bg-default-light:hover,
button.bg-default-light:focus {
  background-color: #2d95cc !important;
}

.bg-default-lighter {
  background-color: #aed8ed !important;
}

a.bg-default-lighter:hover, a.bg-default-lighter:focus,
button.bg-default-lighter:hover,
button.bg-default-lighter:focus {
  background-color: #84c4e4 !important;
}

.bg-gd-default {
  background: #01527b linear-gradient(135deg, #01527b 0%, #0295e0 100%) !important;
}

.bg-elegance {
  background-color: #8f55f2 !important;
}

a.bg-elegance:hover, a.bg-elegance:focus,
button.bg-elegance:hover,
button.bg-elegance:focus {
  background-color: #7026ee !important;
}

.bg-elegance-op {
  background-color: rgba(143, 85, 242, 0.75) !important;
}

a.bg-elegance-op:hover, a.bg-elegance-op:focus,
button.bg-elegance-op:hover,
button.bg-elegance-op:focus {
  background-color: rgba(112, 38, 238, 0.75) !important;
}

.bg-elegance-dark {
  background-color: #454c53 !important;
}

a.bg-elegance-dark:hover, a.bg-elegance-dark:focus,
button.bg-elegance-dark:hover,
button.bg-elegance-dark:focus {
  background-color: #2e3337 !important;
}

.bg-elegance-dark-op {
  background-color: rgba(69, 76, 83, 0.83) !important;
}

a.bg-elegance-dark-op:hover, a.bg-elegance-dark-op:focus,
button.bg-elegance-dark-op:hover,
button.bg-elegance-dark-op:focus {
  background-color: rgba(46, 51, 55, 0.83) !important;
}

.bg-elegance-darker {
  background-color: #1f2327 !important;
}

a.bg-elegance-darker:hover, a.bg-elegance-darker:focus,
button.bg-elegance-darker:hover,
button.bg-elegance-darker:focus {
  background-color: #080a0b !important;
}

.bg-elegance-light {
  background-color: #bb97f7 !important;
}

a.bg-elegance-light:hover, a.bg-elegance-light:focus,
button.bg-elegance-light:hover,
button.bg-elegance-light:focus {
  background-color: #9b68f3 !important;
}

.bg-elegance-lighter {
  background-color: #e0d0fb !important;
}

a.bg-elegance-lighter:hover, a.bg-elegance-lighter:focus,
button.bg-elegance-lighter:hover,
button.bg-elegance-lighter:focus {
  background-color: #c1a1f8 !important;
}

.bg-gd-elegance {
  background: #6112e9 linear-gradient(135deg, #6112e9 0%, #9f6df4 100%) !important;
}

.bg-pulse {
  background-color: #db3f3f !important;
}

a.bg-pulse:hover, a.bg-pulse:focus,
button.bg-pulse:hover,
button.bg-pulse:focus {
  background-color: #c32424 !important;
}

.bg-pulse-op {
  background-color: rgba(219, 63, 63, 0.75) !important;
}

a.bg-pulse-op:hover, a.bg-pulse-op:focus,
button.bg-pulse-op:hover,
button.bg-pulse-op:focus {
  background-color: rgba(195, 36, 36, 0.75) !important;
}

.bg-pulse-dark {
  background-color: #393939 !important;
}

a.bg-pulse-dark:hover, a.bg-pulse-dark:focus,
button.bg-pulse-dark:hover,
button.bg-pulse-dark:focus {
  background-color: #202020 !important;
}

.bg-pulse-dark-op {
  background-color: rgba(57, 57, 57, 0.83) !important;
}

a.bg-pulse-dark-op:hover, a.bg-pulse-dark-op:focus,
button.bg-pulse-dark-op:hover,
button.bg-pulse-dark-op:focus {
  background-color: rgba(32, 32, 32, 0.83) !important;
}

.bg-pulse-darker {
  background-color: #252525 !important;
}

a.bg-pulse-darker:hover, a.bg-pulse-darker:focus,
button.bg-pulse-darker:hover,
button.bg-pulse-darker:focus {
  background-color: #0c0c0c !important;
}

.bg-pulse-light {
  background-color: #e67b7b !important;
}

a.bg-pulse-light:hover, a.bg-pulse-light:focus,
button.bg-pulse-light:hover,
button.bg-pulse-light:focus {
  background-color: #de5050 !important;
}

.bg-pulse-lighter {
  background-color: #f5c8c8 !important;
}

a.bg-pulse-lighter:hover, a.bg-pulse-lighter:focus,
button.bg-pulse-lighter:hover,
button.bg-pulse-lighter:focus {
  background-color: #ed9d9d !important;
}

.bg-gd-pulse {
  background: #ad2020 linear-gradient(135deg, #ad2020 0%, #df5454 100%) !important;
}

.bg-flat {
  background-color: #36b3a0 !important;
}

a.bg-flat:hover, a.bg-flat:focus,
button.bg-flat:hover,
button.bg-flat:focus {
  background-color: #2a8c7d !important;
}

.bg-flat-op {
  background-color: rgba(54, 179, 160, 0.75) !important;
}

a.bg-flat-op:hover, a.bg-flat-op:focus,
button.bg-flat-op:hover,
button.bg-flat-op:focus {
  background-color: rgba(42, 140, 125, 0.75) !important;
}

.bg-flat-dark {
  background-color: #1a353c !important;
}

a.bg-flat-dark:hover, a.bg-flat-dark:focus,
button.bg-flat-dark:hover,
button.bg-flat-dark:focus {
  background-color: #0b1618 !important;
}

.bg-flat-dark-op {
  background-color: rgba(26, 53, 60, 0.83) !important;
}

a.bg-flat-dark-op:hover, a.bg-flat-dark-op:focus,
button.bg-flat-dark-op:hover,
button.bg-flat-dark-op:focus {
  background-color: rgba(11, 22, 24, 0.83) !important;
}

.bg-flat-darker {
  background-color: #0b2830 !important;
}

a.bg-flat-darker:hover, a.bg-flat-darker:focus,
button.bg-flat-darker:hover,
button.bg-flat-darker:focus {
  background-color: #010507 !important;
}

.bg-flat-light {
  background-color: #61cfbf !important;
}

a.bg-flat-light:hover, a.bg-flat-light:focus,
button.bg-flat-light:hover,
button.bg-flat-light:focus {
  background-color: #3bc3ae !important;
}

.bg-flat-lighter {
  background-color: #a8e5db !important;
}

a.bg-flat-lighter:hover, a.bg-flat-lighter:focus,
button.bg-flat-lighter:hover,
button.bg-flat-lighter:focus {
  background-color: #80d9cb !important;
}

.bg-gd-flat {
  background: #24786b linear-gradient(135deg, #24786b 0%, #3ec5b0 100%) !important;
}

.bg-corporate {
  background-color: #2facb2 !important;
}

a.bg-corporate:hover, a.bg-corporate:focus,
button.bg-corporate:hover,
button.bg-corporate:focus {
  background-color: #24858a !important;
}

.bg-corporate-op {
  background-color: rgba(47, 172, 178, 0.75) !important;
}

a.bg-corporate-op:hover, a.bg-corporate-op:focus,
button.bg-corporate-op:hover,
button.bg-corporate-op:focus {
  background-color: rgba(36, 133, 138, 0.75) !important;
}

.bg-corporate-dark {
  background-color: #323d4c !important;
}

a.bg-corporate-dark:hover, a.bg-corporate-dark:focus,
button.bg-corporate-dark:hover,
button.bg-corporate-dark:focus {
  background-color: #1e242d !important;
}

.bg-corporate-dark-op {
  background-color: rgba(50, 61, 76, 0.83) !important;
}

a.bg-corporate-dark-op:hover, a.bg-corporate-dark-op:focus,
button.bg-corporate-dark-op:hover,
button.bg-corporate-dark-op:focus {
  background-color: rgba(30, 36, 45, 0.83) !important;
}

.bg-corporate-darker {
  background-color: #252d38 !important;
}

a.bg-corporate-darker:hover, a.bg-corporate-darker:focus,
button.bg-corporate-darker:hover,
button.bg-corporate-darker:focus {
  background-color: #111419 !important;
}

.bg-corporate-light {
  background-color: #5aced3 !important;
}

a.bg-corporate-light:hover, a.bg-corporate-light:focus,
button.bg-corporate-light:hover,
button.bg-corporate-light:focus {
  background-color: #34bfc6 !important;
}

.bg-corporate-lighter {
  background-color: #afe7ea !important;
}

a.bg-corporate-lighter:hover, a.bg-corporate-lighter:focus,
button.bg-corporate-lighter:hover,
button.bg-corporate-lighter:focus {
  background-color: #86dbdf !important;
}

.bg-gd-corporate {
  background: #1f7275 linear-gradient(135deg, #1f7275 0%, #34bfc6 100%) !important;
}

.bg-earth {
  background-color: #32a67f !important;
}

a.bg-earth:hover, a.bg-earth:focus,
button.bg-earth:hover,
button.bg-earth:focus {
  background-color: #267f61 !important;
}

.bg-earth-op {
  background-color: rgba(50, 166, 127, 0.75) !important;
}

a.bg-earth-op:hover, a.bg-earth-op:focus,
button.bg-earth-op:hover,
button.bg-earth-op:focus {
  background-color: rgba(38, 127, 97, 0.75) !important;
}

.bg-earth-dark {
  background-color: #343434 !important;
}

a.bg-earth-dark:hover, a.bg-earth-dark:focus,
button.bg-earth-dark:hover,
button.bg-earth-dark:focus {
  background-color: #1b1b1b !important;
}

.bg-earth-dark-op {
  background-color: rgba(52, 52, 52, 0.83) !important;
}

a.bg-earth-dark-op:hover, a.bg-earth-dark-op:focus,
button.bg-earth-dark-op:hover,
button.bg-earth-dark-op:focus {
  background-color: rgba(27, 27, 27, 0.83) !important;
}

.bg-earth-darker {
  background-color: #222 !important;
}

a.bg-earth-darker:hover, a.bg-earth-darker:focus,
button.bg-earth-darker:hover,
button.bg-earth-darker:focus {
  background-color: #090909 !important;
}

.bg-earth-light {
  background-color: #5ccea7 !important;
}

a.bg-earth-light:hover, a.bg-earth-light:focus,
button.bg-earth-light:hover,
button.bg-earth-light:focus {
  background-color: #39be91 !important;
}

.bg-earth-lighter {
  background-color: #baeada !important;
}

a.bg-earth-lighter:hover, a.bg-earth-lighter:focus,
button.bg-earth-lighter:hover,
button.bg-earth-lighter:focus {
  background-color: #93dec5 !important;
}

.bg-gd-earth {
  background: #206b52 linear-gradient(135deg, #206b52 0%, #38ba8e 100%) !important;
}

.border-white-op {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-white-op-t {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-white-op-r {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-white-op-b {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-white-op-l {
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-black-op {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-black-op-t {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-black-op-r {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-black-op-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-black-op-l {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.skew-y-1 {
  transform: skewY(1deg);
}

.-skew-y-1 {
  transform: skewY(-1deg);
}

@media (min-width: 992px) {
  .h100-scroll {
    height: 100vh;
    overflow-y: auto;
  }
}
.no-transition {
  transition: none !important;
}

.click-ripple {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  transform: scale(0);
}
.click-ripple.animate {
  animation: click-ripple 0.9s ease-out;
}

@keyframes click-ripple {
  50% {
    opacity: 0.6;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}
.space-x-0 > * + * {
  margin-left: 0;
}

.space-x-1 > * + * {
  margin-left: 0.25rem;
}

.space-x-2 > * + * {
  margin-left: 0.5rem;
}

.space-x-3 > * + * {
  margin-left: 1rem;
}

.space-x-4 > * + * {
  margin-left: 1.5rem;
}

.space-x-5 > * + * {
  margin-left: 3rem;
}

.space-x-6 > * + * {
  margin-left: 4.5rem;
}

.space-x-7 > * + * {
  margin-left: 6rem;
}

.space-x-8 > * + * {
  margin-left: 9rem;
}

.space-x-9 > * + * {
  margin-left: 12rem;
}

.space-x-10 > * + * {
  margin-left: 18rem;
}

.space-y-0 > * + * {
  margin-top: 0;
}

.space-y-1 > * + * {
  margin-top: 0.25rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

.space-y-3 > * + * {
  margin-top: 1rem;
}

.space-y-4 > * + * {
  margin-top: 1.5rem;
}

.space-y-5 > * + * {
  margin-top: 3rem;
}

.space-y-6 > * + * {
  margin-top: 4.5rem;
}

.space-y-7 > * + * {
  margin-top: 6rem;
}

.space-y-8 > * + * {
  margin-top: 9rem;
}

.space-y-9 > * + * {
  margin-top: 12rem;
}

.space-y-10 > * + * {
  margin-top: 18rem;
}

.text-back {
  position: absolute;
  top: -3rem;
  right: 0;
  font-size: 500%;
  font-weight: 700;
  opacity: 0.05;
  z-index: 0;
}

.text-primary {
  color: #0284c7 !important;
}

a.text-primary.link-fx::before {
  background-color: #0284c7 !important;
}
a.text-primary:hover, a.text-primary:focus {
  color: #016395 !important;
}

.text-primary-dark {
  color: #454c53 !important;
}

a.text-primary-dark.link-fx::before {
  background-color: #454c53 !important;
}
a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #2e3337 !important;
}

.text-primary-darker {
  color: #1f2327 !important;
}

a.text-primary-darker.link-fx::before {
  background-color: #1f2327 !important;
}
a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: #080a0b !important;
}

.text-primary-light {
  color: #53abd9 !important;
}

a.text-primary-light.link-fx::before {
  background-color: #53abd9 !important;
}
a.text-primary-light:hover, a.text-primary-light:focus {
  color: #2d95cc !important;
}

.text-primary-lighter {
  color: #aed8ed !important;
}

a.text-primary-lighter.link-fx::before {
  background-color: #aed8ed !important;
}
a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #84c4e4 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark.link-fx::before {
  background-color: #343a40 !important;
}
a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-success {
  color: #16A34A !important;
}

a.text-success.link-fx::before {
  background-color: #16A34A !important;
}
a.text-success:hover, a.text-success:focus {
  color: #107636 !important;
}

.text-success-light {
  color: #ebf5df !important;
}

a.text-success-light.link-fx::before {
  background-color: #ebf5df !important;
}
a.text-success-light:hover, a.text-success-light:focus {
  color: #d3e9b8 !important;
}

.text-warning {
  color: #d97706 !important;
}

a.text-warning.link-fx::before {
  background-color: #d97706 !important;
}
a.text-warning:hover, a.text-warning:focus {
  color: #a75c05 !important;
}

.text-warning-light {
  color: #fcf7e6 !important;
}

a.text-warning-light.link-fx::before {
  background-color: #fcf7e6 !important;
}
a.text-warning-light:hover, a.text-warning-light:focus {
  color: #f7e8b8 !important;
}

.text-info {
  color: #0891B2 !important;
}

a.text-info.link-fx::before {
  background-color: #0891B2 !important;
}
a.text-info:hover, a.text-info:focus {
  color: #066981 !important;
}

.text-info-light {
  color: #e3f4fc !important;
}

a.text-info-light.link-fx::before {
  background-color: #e3f4fc !important;
}
a.text-info-light:hover, a.text-info-light:focus {
  color: #b5e2f7 !important;
}

.text-danger {
  color: #dc2626 !important;
}

a.text-danger.link-fx::before {
  background-color: #dc2626 !important;
}
a.text-danger:hover, a.text-danger:focus {
  color: #b21d1d !important;
}

.text-danger-light {
  color: #fae9e8 !important;
}

a.text-danger-light.link-fx::before {
  background-color: #fae9e8 !important;
}
a.text-danger-light:hover, a.text-danger-light:focus {
  color: #f1c1be !important;
}

.text-body-bg {
  color: #f0f2f5 !important;
}

a.text-body-bg.link-fx::before {
  background-color: #f0f2f5 !important;
}
a.text-body-bg:hover, a.text-body-bg:focus {
  color: #d1d7e1 !important;
}

.text-body-bg-dark {
  color: #e4e7ed !important;
}

a.text-body-bg-dark.link-fx::before {
  background-color: #e4e7ed !important;
}
a.text-body-bg-dark:hover, a.text-body-bg-dark:focus {
  color: #c5cdd8 !important;
}

.text-body-bg-light {
  color: #f6f7f9 !important;
}

a.text-body-bg-light.link-fx::before {
  background-color: #f6f7f9 !important;
}
a.text-body-bg-light:hover, a.text-body-bg-light:focus {
  color: #d8dde5 !important;
}

.text-body-color {
  color: #3e444a !important;
}

a.text-body-color.link-fx::before {
  background-color: #3e444a !important;
}
a.text-body-color:hover, a.text-body-color:focus {
  color: #272b2e !important;
}

.text-body-color-dark {
  color: #171717 !important;
}

a.text-body-color-dark.link-fx::before {
  background-color: #171717 !important;
}
a.text-body-color-dark:hover, a.text-body-color-dark:focus {
  color: black !important;
}

.text-body-color-light {
  color: #e4e7ed !important;
}

a.text-body-color-light.link-fx::before {
  background-color: #e4e7ed !important;
}
a.text-body-color-light:hover, a.text-body-color-light:focus {
  color: #c5cdd8 !important;
}

.text-muted {
  color: #6c757d !important;
}

a.text-muted.link-fx::before {
  background-color: #6c757d !important;
}
a.text-muted:hover, a.text-muted:focus {
  color: #545b62 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white.link-fx::before {
  background-color: #fff !important;
}
a.text-white:hover, a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-white-25 {
  color: rgba(255, 255, 255, 0.25) !important;
}

a.text-white-25.link-fx::before {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
a.text-white-25:hover, a.text-white-25:focus {
  color: rgba(230, 230, 230, 0.25) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

a.text-white-50.link-fx::before {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
a.text-white-50:hover, a.text-white-50:focus {
  color: rgba(230, 230, 230, 0.5) !important;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

a.text-white-75.link-fx::before {
  background-color: rgba(255, 255, 255, 0.75) !important;
}
a.text-white-75:hover, a.text-white-75:focus {
  color: rgba(230, 230, 230, 0.75) !important;
}

.text-black {
  color: #000 !important;
}

a.text-black.link-fx::before {
  background-color: #000 !important;
}
a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-black-25 {
  color: rgba(0, 0, 0, 0.25) !important;
}

a.text-black-25.link-fx::before {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
a.text-black-25:hover, a.text-black-25:focus {
  color: rgba(0, 0, 0, 0.25) !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

a.text-black-50.link-fx::before {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
a.text-black-50:hover, a.text-black-50:focus {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-75 {
  color: rgba(0, 0, 0, 0.75) !important;
}

a.text-black-75.link-fx::before {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
a.text-black-75:hover, a.text-black-75:focus {
  color: rgba(0, 0, 0, 0.75) !important;
}

.text-gray {
  color: #ced4da !important;
}

a.text-gray.link-fx::before {
  background-color: #ced4da !important;
}
a.text-gray:hover, a.text-gray:focus {
  color: #b1bbc4 !important;
}

.text-gray-dark {
  color: #6c757d !important;
}

a.text-gray-dark.link-fx::before {
  background-color: #6c757d !important;
}
a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #545b62 !important;
}

.text-gray-darker {
  color: #343a40 !important;
}

a.text-gray-darker.link-fx::before {
  background-color: #343a40 !important;
}
a.text-gray-darker:hover, a.text-gray-darker:focus {
  color: #1d2124 !important;
}

.text-gray-light {
  color: #e9ecef !important;
}

a.text-gray-light.link-fx::before {
  background-color: #e9ecef !important;
}
a.text-gray-light:hover, a.text-gray-light:focus {
  color: #cbd3da !important;
}

.text-gray-lighter {
  color: #f8f9fa !important;
}

a.text-gray-lighter.link-fx::before {
  background-color: #f8f9fa !important;
}
a.text-gray-lighter:hover, a.text-gray-lighter:focus {
  color: #dae0e5 !important;
}

.text-dual {
  color: #454c53 !important;
}

a.text-dual.link-fx::before {
  background-color: #454c53 !important;
}
a.text-dual:hover, a.text-dual:focus {
  color: #2e3337 !important;
}

.page-header-dark #page-header .text-dual,
.sidebar-dark #sidebar .text-dual {
  color: #e4e7ed !important;
}
.page-header-dark #page-header a.text-dual.link-fx::before,
.sidebar-dark #sidebar a.text-dual.link-fx::before {
  background-color: #e4e7ed !important;
}
.page-header-dark #page-header a.text-dual:hover, .page-header-dark #page-header a.text-dual:focus,
.sidebar-dark #sidebar a.text-dual:hover,
.sidebar-dark #sidebar a.text-dual:focus {
  color: #c5cdd8 !important;
}

.text-default {
  color: #0284c7 !important;
}

a.text-default.link-fx::before {
  background-color: #0284c7 !important;
}
a.text-default:hover, a.text-default:focus {
  color: #016395 !important;
}

.text-default-dark {
  color: #454c53 !important;
}

a.text-default-dark.link-fx::before {
  background-color: #454c53 !important;
}
a.text-default-dark:hover, a.text-default-dark:focus {
  color: #2e3337 !important;
}

.text-default-darker {
  color: #1f2327 !important;
}

a.text-default-darker.link-fx::before {
  background-color: #1f2327 !important;
}
a.text-default-darker:hover, a.text-default-darker:focus {
  color: #080a0b !important;
}

.text-default-light {
  color: #53abd9 !important;
}

a.text-default-light.link-fx::before {
  background-color: #53abd9 !important;
}
a.text-default-light:hover, a.text-default-light:focus {
  color: #2d95cc !important;
}

.text-default-lighter {
  color: #aed8ed !important;
}

a.text-default-lighter.link-fx::before {
  background-color: #aed8ed !important;
}
a.text-default-lighter:hover, a.text-default-lighter:focus {
  color: #84c4e4 !important;
}

.text-elegance {
  color: #8f55f2 !important;
}

a.text-elegance.link-fx::before {
  background-color: #8f55f2 !important;
}
a.text-elegance:hover, a.text-elegance:focus {
  color: #7026ee !important;
}

.text-elegance-dark {
  color: #454c53 !important;
}

a.text-elegance-dark.link-fx::before {
  background-color: #454c53 !important;
}
a.text-elegance-dark:hover, a.text-elegance-dark:focus {
  color: #2e3337 !important;
}

.text-elegance-darker {
  color: #1f2327 !important;
}

a.text-elegance-darker.link-fx::before {
  background-color: #1f2327 !important;
}
a.text-elegance-darker:hover, a.text-elegance-darker:focus {
  color: #080a0b !important;
}

.text-elegance-light {
  color: #bb97f7 !important;
}

a.text-elegance-light.link-fx::before {
  background-color: #bb97f7 !important;
}
a.text-elegance-light:hover, a.text-elegance-light:focus {
  color: #9b68f3 !important;
}

.text-elegance-lighter {
  color: #e0d0fb !important;
}

a.text-elegance-lighter.link-fx::before {
  background-color: #e0d0fb !important;
}
a.text-elegance-lighter:hover, a.text-elegance-lighter:focus {
  color: #c1a1f8 !important;
}

.text-pulse {
  color: #db3f3f !important;
}

a.text-pulse.link-fx::before {
  background-color: #db3f3f !important;
}
a.text-pulse:hover, a.text-pulse:focus {
  color: #c32424 !important;
}

.text-pulse-dark {
  color: #393939 !important;
}

a.text-pulse-dark.link-fx::before {
  background-color: #393939 !important;
}
a.text-pulse-dark:hover, a.text-pulse-dark:focus {
  color: #202020 !important;
}

.text-pulse-darker {
  color: #252525 !important;
}

a.text-pulse-darker.link-fx::before {
  background-color: #252525 !important;
}
a.text-pulse-darker:hover, a.text-pulse-darker:focus {
  color: #0c0c0c !important;
}

.text-pulse-light {
  color: #e67b7b !important;
}

a.text-pulse-light.link-fx::before {
  background-color: #e67b7b !important;
}
a.text-pulse-light:hover, a.text-pulse-light:focus {
  color: #de5050 !important;
}

.text-pulse-lighter {
  color: #f5c8c8 !important;
}

a.text-pulse-lighter.link-fx::before {
  background-color: #f5c8c8 !important;
}
a.text-pulse-lighter:hover, a.text-pulse-lighter:focus {
  color: #ed9d9d !important;
}

.text-flat {
  color: #36b3a0 !important;
}

a.text-flat.link-fx::before {
  background-color: #36b3a0 !important;
}
a.text-flat:hover, a.text-flat:focus {
  color: #2a8c7d !important;
}

.text-flat-dark {
  color: #1a353c !important;
}

a.text-flat-dark.link-fx::before {
  background-color: #1a353c !important;
}
a.text-flat-dark:hover, a.text-flat-dark:focus {
  color: #0b1618 !important;
}

.text-flat-darker {
  color: #0b2830 !important;
}

a.text-flat-darker.link-fx::before {
  background-color: #0b2830 !important;
}
a.text-flat-darker:hover, a.text-flat-darker:focus {
  color: #010507 !important;
}

.text-flat-light {
  color: #61cfbf !important;
}

a.text-flat-light.link-fx::before {
  background-color: #61cfbf !important;
}
a.text-flat-light:hover, a.text-flat-light:focus {
  color: #3bc3ae !important;
}

.text-flat-lighter {
  color: #a8e5db !important;
}

a.text-flat-lighter.link-fx::before {
  background-color: #a8e5db !important;
}
a.text-flat-lighter:hover, a.text-flat-lighter:focus {
  color: #80d9cb !important;
}

.text-corporate {
  color: #2facb2 !important;
}

a.text-corporate.link-fx::before {
  background-color: #2facb2 !important;
}
a.text-corporate:hover, a.text-corporate:focus {
  color: #24858a !important;
}

.text-corporate-dark {
  color: #323d4c !important;
}

a.text-corporate-dark.link-fx::before {
  background-color: #323d4c !important;
}
a.text-corporate-dark:hover, a.text-corporate-dark:focus {
  color: #1e242d !important;
}

.text-corporate-darker {
  color: #252d38 !important;
}

a.text-corporate-darker.link-fx::before {
  background-color: #252d38 !important;
}
a.text-corporate-darker:hover, a.text-corporate-darker:focus {
  color: #111419 !important;
}

.text-corporate-light {
  color: #5aced3 !important;
}

a.text-corporate-light.link-fx::before {
  background-color: #5aced3 !important;
}
a.text-corporate-light:hover, a.text-corporate-light:focus {
  color: #34bfc6 !important;
}

.text-corporate-lighter {
  color: #afe7ea !important;
}

a.text-corporate-lighter.link-fx::before {
  background-color: #afe7ea !important;
}
a.text-corporate-lighter:hover, a.text-corporate-lighter:focus {
  color: #86dbdf !important;
}

.text-earth {
  color: #32a67f !important;
}

a.text-earth.link-fx::before {
  background-color: #32a67f !important;
}
a.text-earth:hover, a.text-earth:focus {
  color: #267f61 !important;
}

.text-earth-dark {
  color: #343434 !important;
}

a.text-earth-dark.link-fx::before {
  background-color: #343434 !important;
}
a.text-earth-dark:hover, a.text-earth-dark:focus {
  color: #1b1b1b !important;
}

.text-earth-darker {
  color: #222 !important;
}

a.text-earth-darker.link-fx::before {
  background-color: #222 !important;
}
a.text-earth-darker:hover, a.text-earth-darker:focus {
  color: #090909 !important;
}

.text-earth-light {
  color: #5ccea7 !important;
}

a.text-earth-light.link-fx::before {
  background-color: #5ccea7 !important;
}
a.text-earth-light:hover, a.text-earth-light:focus {
  color: #39be91 !important;
}

.text-earth-lighter {
  color: #baeada !important;
}

a.text-earth-lighter.link-fx::before {
  background-color: #baeada !important;
}
a.text-earth-lighter:hover, a.text-earth-lighter:focus {
  color: #93dec5 !important;
}

.animated {
  animation-duration: 1.2s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.bounceIn,
.animated.bounceOut {
  animation-duration: 0.75s;
}

.animated.flipOutX,
.animated.flipOutY {
  animation-duration: 0.75s;
}

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
.flash {
  animation-name: flash;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  animation-name: shake;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  transform-origin: top center;
  animation-name: swing;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.tada {
  animation-name: tada;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes wobble {
  0% {
    transform: none;
  }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    transform: none;
  }
}
.wobble {
  animation-name: wobble;
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px);
    animation-timing-function: ease-in;
  }
}
.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.flipInX {
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.flipInY {
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  100% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
.flipOutY {
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

@keyframes lightSpeedIn {
  0% {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-5deg);
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  0% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  100% {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}
.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}
.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
  }
  100% {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  animation-name: hinge;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  }
}
.rollIn {
  animation-name: rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}
.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutDown {
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center;
  }
}
.zoomOutLeft {
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center;
  }
}
.zoomOutRight {
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
.zoomOutUp {
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  0% {
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
.slideInLeft {
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  0% {
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY(100%);
  }
}
.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    transform: translateX(-100%);
  }
}
.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    transform: translateX(100%);
  }
}
.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY(-100%);
  }
}
.slideOutUp {
  animation-name: slideOutUp;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fasr,
.fal,
.fa-light,
.fasl,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fass,
.fa-sharp-solid,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
}

.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad,
.fa-classic.fa-duotone,
.fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fass,
.fasr,
.fasl,
.fa-sharp {
  font-family: "Font Awesome 6 Sharp";
}

.fass,
.fa-sharp {
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}
@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  8%, 24% {
    transform: rotate(-18deg);
  }
  12%, 28% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%, 100% {
    transform: rotate(0deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\30 ";
}

.fa-1::before {
  content: "\31 ";
}

.fa-2::before {
  content: "\32 ";
}

.fa-3::before {
  content: "\33 ";
}

.fa-4::before {
  content: "\34 ";
}

.fa-5::before {
  content: "\35 ";
}

.fa-6::before {
  content: "\36 ";
}

.fa-7::before {
  content: "\37 ";
}

.fa-8::before {
  content: "\38 ";
}

.fa-9::before {
  content: "\39 ";
}

.fa-fill-drip::before {
  content: "\f576";
}

.fa-arrows-to-circle::before {
  content: "\e4bd";
}

.fa-circle-chevron-right::before {
  content: "\f138";
}

.fa-chevron-circle-right::before {
  content: "\f138";
}

.fa-wagon-covered::before {
  content: "\f8ee";
}

.fa-line-height::before {
  content: "\f871";
}

.fa-bagel::before {
  content: "\e3d7";
}

.fa-transporter-7::before {
  content: "\e2a8";
}

.fa-at::before {
  content: "\@";
}

.fa-rectangles-mixed::before {
  content: "\e323";
}

.fa-phone-arrow-up-right::before {
  content: "\e224";
}

.fa-phone-arrow-up::before {
  content: "\e224";
}

.fa-phone-outgoing::before {
  content: "\e224";
}

.fa-trash-can::before {
  content: "\f2ed";
}

.fa-trash-alt::before {
  content: "\f2ed";
}

.fa-circle-l::before {
  content: "\e114";
}

.fa-head-side-goggles::before {
  content: "\f6ea";
}

.fa-head-vr::before {
  content: "\f6ea";
}

.fa-text-height::before {
  content: "\f034";
}

.fa-user-xmark::before {
  content: "\f235";
}

.fa-user-times::before {
  content: "\f235";
}

.fa-face-hand-yawn::before {
  content: "\e379";
}

.fa-gauge-simple-min::before {
  content: "\f62d";
}

.fa-tachometer-slowest::before {
  content: "\f62d";
}

.fa-stethoscope::before {
  content: "\f0f1";
}

.fa-coffin::before {
  content: "\f6c6";
}

.fa-message::before {
  content: "\f27a";
}

.fa-comment-alt::before {
  content: "\f27a";
}

.fa-salad::before {
  content: "\f81e";
}

.fa-bowl-salad::before {
  content: "\f81e";
}

.fa-info::before {
  content: "\f129";
}

.fa-robot-astromech::before {
  content: "\e2d2";
}

.fa-ring-diamond::before {
  content: "\e5ab";
}

.fa-fondue-pot::before {
  content: "\e40d";
}

.fa-theta::before {
  content: "\f69e";
}

.fa-face-hand-peeking::before {
  content: "\e481";
}

.fa-square-user::before {
  content: "\e283";
}

.fa-down-left-and-up-right-to-center::before {
  content: "\f422";
}

.fa-compress-alt::before {
  content: "\f422";
}

.fa-explosion::before {
  content: "\e4e9";
}

.fa-file-lines::before {
  content: "\f15c";
}

.fa-file-alt::before {
  content: "\f15c";
}

.fa-file-text::before {
  content: "\f15c";
}

.fa-wave-square::before {
  content: "\f83e";
}

.fa-ring::before {
  content: "\f70b";
}

.fa-building-un::before {
  content: "\e4d9";
}

.fa-dice-three::before {
  content: "\f527";
}

.fa-tire-pressure-warning::before {
  content: "\f633";
}

.fa-wifi-fair::before {
  content: "\f6ab";
}

.fa-wifi-2::before {
  content: "\f6ab";
}

.fa-calendar-days::before {
  content: "\f073";
}

.fa-calendar-alt::before {
  content: "\f073";
}

.fa-mp3-player::before {
  content: "\f8ce";
}

.fa-anchor-circle-check::before {
  content: "\e4aa";
}

.fa-tally-4::before {
  content: "\e297";
}

.fa-rectangle-history::before {
  content: "\e4a2";
}

.fa-building-circle-arrow-right::before {
  content: "\e4d1";
}

.fa-volleyball::before {
  content: "\f45f";
}

.fa-volleyball-ball::before {
  content: "\f45f";
}

.fa-sun-haze::before {
  content: "\f765";
}

.fa-text-size::before {
  content: "\f894";
}

.fa-ufo::before {
  content: "\e047";
}

.fa-fork::before {
  content: "\f2e3";
}

.fa-utensil-fork::before {
  content: "\f2e3";
}

.fa-arrows-up-to-line::before {
  content: "\e4c2";
}

.fa-mobile-signal::before {
  content: "\e1ef";
}

.fa-barcode-scan::before {
  content: "\f465";
}

.fa-sort-down::before {
  content: "\f0dd";
}

.fa-sort-desc::before {
  content: "\f0dd";
}

.fa-folder-arrow-down::before {
  content: "\e053";
}

.fa-folder-download::before {
  content: "\e053";
}

.fa-circle-minus::before {
  content: "\f056";
}

.fa-minus-circle::before {
  content: "\f056";
}

.fa-face-icicles::before {
  content: "\e37c";
}

.fa-shovel::before {
  content: "\f713";
}

.fa-door-open::before {
  content: "\f52b";
}

.fa-films::before {
  content: "\e17a";
}

.fa-right-from-bracket::before {
  content: "\f2f5";
}

.fa-sign-out-alt::before {
  content: "\f2f5";
}

.fa-face-glasses::before {
  content: "\e377";
}

.fa-nfc::before {
  content: "\e1f7";
}

.fa-atom::before {
  content: "\f5d2";
}

.fa-soap::before {
  content: "\e06e";
}

.fa-icons::before {
  content: "\f86d";
}

.fa-heart-music-camera-bolt::before {
  content: "\f86d";
}

.fa-microphone-lines-slash::before {
  content: "\f539";
}

.fa-microphone-alt-slash::before {
  content: "\f539";
}

.fa-closed-captioning-slash::before {
  content: "\e135";
}

.fa-calculator-simple::before {
  content: "\f64c";
}

.fa-calculator-alt::before {
  content: "\f64c";
}

.fa-bridge-circle-check::before {
  content: "\e4c9";
}

.fa-sliders-up::before {
  content: "\f3f1";
}

.fa-sliders-v::before {
  content: "\f3f1";
}

.fa-location-minus::before {
  content: "\f609";
}

.fa-map-marker-minus::before {
  content: "\f609";
}

.fa-pump-medical::before {
  content: "\e06a";
}

.fa-fingerprint::before {
  content: "\f577";
}

.fa-ski-boot::before {
  content: "\e3cc";
}

.fa-standard-definition::before {
  content: "\e28a";
}

.fa-rectangle-sd::before {
  content: "\e28a";
}

.fa-h1::before {
  content: "\f313";
}

.fa-hand-point-right::before {
  content: "\f0a4";
}

.fa-magnifying-glass-location::before {
  content: "\f689";
}

.fa-search-location::before {
  content: "\f689";
}

.fa-message-bot::before {
  content: "\e3b8";
}

.fa-forward-step::before {
  content: "\f051";
}

.fa-step-forward::before {
  content: "\f051";
}

.fa-face-smile-beam::before {
  content: "\f5b8";
}

.fa-smile-beam::before {
  content: "\f5b8";
}

.fa-light-ceiling::before {
  content: "\e016";
}

.fa-message-exclamation::before {
  content: "\f4a5";
}

.fa-comment-alt-exclamation::before {
  content: "\f4a5";
}

.fa-bowl-scoop::before {
  content: "\e3de";
}

.fa-bowl-shaved-ice::before {
  content: "\e3de";
}

.fa-square-x::before {
  content: "\e286";
}

.fa-utility-pole-double::before {
  content: "\e2c4";
}

.fa-flag-checkered::before {
  content: "\f11e";
}

.fa-chevrons-up::before {
  content: "\f325";
}

.fa-chevron-double-up::before {
  content: "\f325";
}

.fa-football::before {
  content: "\f44e";
}

.fa-football-ball::before {
  content: "\f44e";
}

.fa-user-vneck::before {
  content: "\e461";
}

.fa-school-circle-exclamation::before {
  content: "\e56c";
}

.fa-crop::before {
  content: "\f125";
}

.fa-angles-down::before {
  content: "\f103";
}

.fa-angle-double-down::before {
  content: "\f103";
}

.fa-users-rectangle::before {
  content: "\e594";
}

.fa-people-roof::before {
  content: "\e537";
}

.fa-square-arrow-right::before {
  content: "\f33b";
}

.fa-arrow-square-right::before {
  content: "\f33b";
}

.fa-location-plus::before {
  content: "\f60a";
}

.fa-map-marker-plus::before {
  content: "\f60a";
}

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca";
}

.fa-people-line::before {
  content: "\e534";
}

.fa-beer-mug-empty::before {
  content: "\f0fc";
}

.fa-beer::before {
  content: "\f0fc";
}

.fa-crate-empty::before {
  content: "\e151";
}

.fa-diagram-predecessor::before {
  content: "\e477";
}

.fa-transporter::before {
  content: "\e042";
}

.fa-calendar-circle-user::before {
  content: "\e471";
}

.fa-arrow-up-long::before {
  content: "\f176";
}

.fa-long-arrow-up::before {
  content: "\f176";
}

.fa-person-carry-box::before {
  content: "\f4cf";
}

.fa-person-carry::before {
  content: "\f4cf";
}

.fa-fire-flame-simple::before {
  content: "\f46a";
}

.fa-burn::before {
  content: "\f46a";
}

.fa-person::before {
  content: "\f183";
}

.fa-male::before {
  content: "\f183";
}

.fa-laptop::before {
  content: "\f109";
}

.fa-file-csv::before {
  content: "\f6dd";
}

.fa-menorah::before {
  content: "\f676";
}

.fa-union::before {
  content: "\f6a2";
}

.fa-chevrons-left::before {
  content: "\f323";
}

.fa-chevron-double-left::before {
  content: "\f323";
}

.fa-circle-heart::before {
  content: "\f4c7";
}

.fa-heart-circle::before {
  content: "\f4c7";
}

.fa-truck-plane::before {
  content: "\e58f";
}

.fa-record-vinyl::before {
  content: "\f8d9";
}

.fa-bring-forward::before {
  content: "\f856";
}

.fa-square-p::before {
  content: "\e279";
}

.fa-face-grin-stars::before {
  content: "\f587";
}

.fa-grin-stars::before {
  content: "\f587";
}

.fa-sigma::before {
  content: "\f68b";
}

.fa-camera-movie::before {
  content: "\f8a9";
}

.fa-bong::before {
  content: "\f55c";
}

.fa-clarinet::before {
  content: "\f8ad";
}

.fa-truck-flatbed::before {
  content: "\e2b6";
}

.fa-spaghetti-monster-flying::before {
  content: "\f67b";
}

.fa-pastafarianism::before {
  content: "\f67b";
}

.fa-arrow-down-up-across-line::before {
  content: "\e4af";
}

.fa-leaf-heart::before {
  content: "\f4cb";
}

.fa-house-building::before {
  content: "\e1b1";
}

.fa-cheese-swiss::before {
  content: "\f7f0";
}

.fa-spoon::before {
  content: "\f2e5";
}

.fa-utensil-spoon::before {
  content: "\f2e5";
}

.fa-jar-wheat::before {
  content: "\e517";
}

.fa-envelopes-bulk::before {
  content: "\f674";
}

.fa-mail-bulk::before {
  content: "\f674";
}

.fa-file-circle-exclamation::before {
  content: "\e4eb";
}

.fa-bow-arrow::before {
  content: "\f6b9";
}

.fa-cart-xmark::before {
  content: "\e0dd";
}

.fa-hexagon-xmark::before {
  content: "\f2ee";
}

.fa-times-hexagon::before {
  content: "\f2ee";
}

.fa-xmark-hexagon::before {
  content: "\f2ee";
}

.fa-circle-h::before {
  content: "\f47e";
}

.fa-hospital-symbol::before {
  content: "\f47e";
}

.fa-merge::before {
  content: "\e526";
}

.fa-pager::before {
  content: "\f815";
}

.fa-cart-minus::before {
  content: "\e0db";
}

.fa-address-book::before {
  content: "\f2b9";
}

.fa-contact-book::before {
  content: "\f2b9";
}

.fa-pan-frying::before {
  content: "\e42c";
}

.fa-grid::before {
  content: "\e195";
}

.fa-grid-3::before {
  content: "\e195";
}

.fa-football-helmet::before {
  content: "\f44f";
}

.fa-hand-love::before {
  content: "\e1a5";
}

.fa-trees::before {
  content: "\f724";
}

.fa-strikethrough::before {
  content: "\f0cc";
}

.fa-page::before {
  content: "\e428";
}

.fa-k::before {
  content: "K";
}

.fa-diagram-previous::before {
  content: "\e478";
}

.fa-gauge-min::before {
  content: "\f628";
}

.fa-tachometer-alt-slowest::before {
  content: "\f628";
}

.fa-folder-grid::before {
  content: "\e188";
}

.fa-eggplant::before {
  content: "\e16c";
}

.fa-ram::before {
  content: "\f70a";
}

.fa-landmark-flag::before {
  content: "\e51c";
}

.fa-lips::before {
  content: "\f600";
}

.fa-pencil::before {
  content: "\f303";
}

.fa-pencil-alt::before {
  content: "\f303";
}

.fa-backward::before {
  content: "\f04a";
}

.fa-caret-right::before {
  content: "\f0da";
}

.fa-comments::before {
  content: "\f086";
}

.fa-paste::before {
  content: "\f0ea";
}

.fa-file-clipboard::before {
  content: "\f0ea";
}

.fa-desktop-arrow-down::before {
  content: "\e155";
}

.fa-code-pull-request::before {
  content: "\e13c";
}

.fa-pumpkin::before {
  content: "\f707";
}

.fa-clipboard-list::before {
  content: "\f46d";
}

.fa-pen-field::before {
  content: "\e211";
}

.fa-blueberries::before {
  content: "\e2e8";
}

.fa-truck-ramp-box::before {
  content: "\f4de";
}

.fa-truck-loading::before {
  content: "\f4de";
}

.fa-note::before {
  content: "\e1ff";
}

.fa-arrow-down-to-square::before {
  content: "\e096";
}

.fa-user-check::before {
  content: "\f4fc";
}

.fa-cloud-xmark::before {
  content: "\e35f";
}

.fa-vial-virus::before {
  content: "\e597";
}

.fa-book-blank::before {
  content: "\f5d9";
}

.fa-book-alt::before {
  content: "\f5d9";
}

.fa-golf-flag-hole::before {
  content: "\e3ac";
}

.fa-message-arrow-down::before {
  content: "\e1db";
}

.fa-comment-alt-arrow-down::before {
  content: "\e1db";
}

.fa-face-unamused::before {
  content: "\e39f";
}

.fa-sheet-plastic::before {
  content: "\e571";
}

.fa-circle-9::before {
  content: "\e0f6";
}

.fa-blog::before {
  content: "\f781";
}

.fa-user-ninja::before {
  content: "\f504";
}

.fa-pencil-slash::before {
  content: "\e215";
}

.fa-bowling-pins::before {
  content: "\f437";
}

.fa-person-arrow-up-from-line::before {
  content: "\e539";
}

.fa-down-right::before {
  content: "\e16b";
}

.fa-scroll-torah::before {
  content: "\f6a0";
}

.fa-torah::before {
  content: "\f6a0";
}

.fa-webhook::before {
  content: "\e5d5";
}

.fa-blinds-open::before {
  content: "\f8fc";
}

.fa-fence::before {
  content: "\e303";
}

.fa-up::before {
  content: "\f357";
}

.fa-arrow-alt-up::before {
  content: "\f357";
}

.fa-broom-ball::before {
  content: "\f458";
}

.fa-quidditch::before {
  content: "\f458";
}

.fa-quidditch-broom-ball::before {
  content: "\f458";
}

.fa-drumstick::before {
  content: "\f6d6";
}

.fa-square-v::before {
  content: "\e284";
}

.fa-face-awesome::before {
  content: "\e409";
}

.fa-gave-dandy::before {
  content: "\e409";
}

.fa-dial-off::before {
  content: "\e162";
}

.fa-toggle-off::before {
  content: "\f204";
}

.fa-face-smile-horns::before {
  content: "\e391";
}

.fa-box-archive::before {
  content: "\f187";
}

.fa-archive::before {
  content: "\f187";
}

.fa-grapes::before {
  content: "\e306";
}

.fa-person-drowning::before {
  content: "\e545";
}

.fa-dial-max::before {
  content: "\e15e";
}

.fa-circle-m::before {
  content: "\e115";
}

.fa-calendar-image::before {
  content: "\e0d4";
}

.fa-circle-caret-down::before {
  content: "\f32d";
}

.fa-caret-circle-down::before {
  content: "\f32d";
}

.fa-arrow-down-9-1::before {
  content: "\f886";
}

.fa-sort-numeric-desc::before {
  content: "\f886";
}

.fa-sort-numeric-down-alt::before {
  content: "\f886";
}

.fa-face-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-shish-kebab::before {
  content: "\f821";
}

.fa-spray-can::before {
  content: "\f5bd";
}

.fa-alarm-snooze::before {
  content: "\f845";
}

.fa-scarecrow::before {
  content: "\f70d";
}

.fa-truck-monster::before {
  content: "\f63b";
}

.fa-gift-card::before {
  content: "\f663";
}

.fa-w::before {
  content: "W";
}

.fa-code-pull-request-draft::before {
  content: "\e3fa";
}

.fa-square-b::before {
  content: "\e264";
}

.fa-elephant::before {
  content: "\f6da";
}

.fa-earth-africa::before {
  content: "\f57c";
}

.fa-globe-africa::before {
  content: "\f57c";
}

.fa-rainbow::before {
  content: "\f75b";
}

.fa-circle-notch::before {
  content: "\f1ce";
}

.fa-tablet-screen-button::before {
  content: "\f3fa";
}

.fa-tablet-alt::before {
  content: "\f3fa";
}

.fa-paw::before {
  content: "\f1b0";
}

.fa-message-question::before {
  content: "\e1e3";
}

.fa-cloud::before {
  content: "\f0c2";
}

.fa-trowel-bricks::before {
  content: "\e58a";
}

.fa-square-3::before {
  content: "\e258";
}

.fa-face-flushed::before {
  content: "\f579";
}

.fa-flushed::before {
  content: "\f579";
}

.fa-hospital-user::before {
  content: "\f80d";
}

.fa-microwave::before {
  content: "\e01b";
}

.fa-chf-sign::before {
  content: "\e602";
}

.fa-tent-arrow-left-right::before {
  content: "\e57f";
}

.fa-cart-circle-arrow-up::before {
  content: "\e3f0";
}

.fa-trash-clock::before {
  content: "\e2b0";
}

.fa-gavel::before {
  content: "\f0e3";
}

.fa-legal::before {
  content: "\f0e3";
}

.fa-sprinkler-ceiling::before {
  content: "\e44c";
}

.fa-browsers::before {
  content: "\e0cb";
}

.fa-trillium::before {
  content: "\e588";
}

.fa-music-slash::before {
  content: "\f8d1";
}

.fa-truck-ramp::before {
  content: "\f4e0";
}

.fa-binoculars::before {
  content: "\f1e5";
}

.fa-microphone-slash::before {
  content: "\f131";
}

.fa-box-tissue::before {
  content: "\e05b";
}

.fa-circle-c::before {
  content: "\e101";
}

.fa-star-christmas::before {
  content: "\f7d4";
}

.fa-chart-bullet::before {
  content: "\e0e1";
}

.fa-motorcycle::before {
  content: "\f21c";
}

.fa-tree-christmas::before {
  content: "\f7db";
}

.fa-tire-flat::before {
  content: "\f632";
}

.fa-sunglasses::before {
  content: "\f892";
}

.fa-badge::before {
  content: "\f335";
}

.fa-message-pen::before {
  content: "\f4a4";
}

.fa-comment-alt-edit::before {
  content: "\f4a4";
}

.fa-message-edit::before {
  content: "\f4a4";
}

.fa-bell-concierge::before {
  content: "\f562";
}

.fa-concierge-bell::before {
  content: "\f562";
}

.fa-pen-ruler::before {
  content: "\f5ae";
}

.fa-pencil-ruler::before {
  content: "\f5ae";
}

.fa-arrow-progress::before {
  content: "\e5df";
}

.fa-chess-rook-piece::before {
  content: "\f448";
}

.fa-chess-rook-alt::before {
  content: "\f448";
}

.fa-square-root::before {
  content: "\f697";
}

.fa-album-collection-circle-plus::before {
  content: "\e48e";
}

.fa-people-arrows::before {
  content: "\e068";
}

.fa-people-arrows-left-right::before {
  content: "\e068";
}

.fa-face-angry-horns::before {
  content: "\e368";
}

.fa-mars-and-venus-burst::before {
  content: "\e523";
}

.fa-tombstone::before {
  content: "\f720";
}

.fa-square-caret-right::before {
  content: "\f152";
}

.fa-caret-square-right::before {
  content: "\f152";
}

.fa-scissors::before {
  content: "\f0c4";
}

.fa-cut::before {
  content: "\f0c4";
}

.fa-list-music::before {
  content: "\f8c9";
}

.fa-sun-plant-wilt::before {
  content: "\e57a";
}

.fa-toilets-portable::before {
  content: "\e584";
}

.fa-hockey-puck::before {
  content: "\f453";
}

.fa-mustache::before {
  content: "\e5bc";
}

.fa-hyphen::before {
  content: "\-";
}

.fa-table::before {
  content: "\f0ce";
}

.fa-user-chef::before {
  content: "\e3d2";
}

.fa-message-image::before {
  content: "\e1e0";
}

.fa-comment-alt-image::before {
  content: "\e1e0";
}

.fa-users-medical::before {
  content: "\f830";
}

.fa-sensor-triangle-exclamation::before {
  content: "\e029";
}

.fa-sensor-alert::before {
  content: "\e029";
}

.fa-magnifying-glass-arrow-right::before {
  content: "\e521";
}

.fa-tachograph-digital::before {
  content: "\f566";
}

.fa-digital-tachograph::before {
  content: "\f566";
}

.fa-face-mask::before {
  content: "\e37f";
}

.fa-pickleball::before {
  content: "\e435";
}

.fa-star-sharp-half::before {
  content: "\e28c";
}

.fa-users-slash::before {
  content: "\e073";
}

.fa-clover::before {
  content: "\e139";
}

.fa-meat::before {
  content: "\f814";
}

.fa-reply::before {
  content: "\f3e5";
}

.fa-mail-reply::before {
  content: "\f3e5";
}

.fa-star-and-crescent::before {
  content: "\f699";
}

.fa-empty-set::before {
  content: "\f656";
}

.fa-house-fire::before {
  content: "\e50c";
}

.fa-square-minus::before {
  content: "\f146";
}

.fa-minus-square::before {
  content: "\f146";
}

.fa-helicopter::before {
  content: "\f533";
}

.fa-bird::before {
  content: "\e469";
}

.fa-compass::before {
  content: "\f14e";
}

.fa-square-caret-down::before {
  content: "\f150";
}

.fa-caret-square-down::before {
  content: "\f150";
}

.fa-heart-half-stroke::before {
  content: "\e1ac";
}

.fa-heart-half-alt::before {
  content: "\e1ac";
}

.fa-file-circle-question::before {
  content: "\e4ef";
}

.fa-laptop-code::before {
  content: "\f5fc";
}

.fa-joystick::before {
  content: "\f8c5";
}

.fa-grill-fire::before {
  content: "\e5a4";
}

.fa-rectangle-vertical-history::before {
  content: "\e237";
}

.fa-swatchbook::before {
  content: "\f5c3";
}

.fa-prescription-bottle::before {
  content: "\f485";
}

.fa-bars::before {
  content: "\f0c9";
}

.fa-navicon::before {
  content: "\f0c9";
}

.fa-keyboard-left::before {
  content: "\e1c3";
}

.fa-people-group::before {
  content: "\e533";
}

.fa-hourglass-end::before {
  content: "\f253";
}

.fa-hourglass-3::before {
  content: "\f253";
}

.fa-heart-crack::before {
  content: "\f7a9";
}

.fa-heart-broken::before {
  content: "\f7a9";
}

.fa-face-beam-hand-over-mouth::before {
  content: "\e47c";
}

.fa-droplet-percent::before {
  content: "\f750";
}

.fa-humidity::before {
  content: "\f750";
}

.fa-square-up-right::before {
  content: "\f360";
}

.fa-external-link-square-alt::before {
  content: "\f360";
}

.fa-face-kiss-beam::before {
  content: "\f597";
}

.fa-kiss-beam::before {
  content: "\f597";
}

.fa-corn::before {
  content: "\f6c7";
}

.fa-roller-coaster::before {
  content: "\e324";
}

.fa-photo-film-music::before {
  content: "\e228";
}

.fa-radar::before {
  content: "\e024";
}

.fa-sickle::before {
  content: "\f822";
}

.fa-film::before {
  content: "\f008";
}

.fa-coconut::before {
  content: "\e2f6";
}

.fa-ruler-horizontal::before {
  content: "\f547";
}

.fa-shield-cross::before {
  content: "\f712";
}

.fa-cassette-tape::before {
  content: "\f8ab";
}

.fa-square-terminal::before {
  content: "\e32a";
}

.fa-people-robbery::before {
  content: "\e536";
}

.fa-lightbulb::before {
  content: "\f0eb";
}

.fa-caret-left::before {
  content: "\f0d9";
}

.fa-comment-middle::before {
  content: "\e149";
}

.fa-trash-can-list::before {
  content: "\e2ab";
}

.fa-block::before {
  content: "\e46a";
}

.fa-circle-exclamation::before {
  content: "\f06a";
}

.fa-exclamation-circle::before {
  content: "\f06a";
}

.fa-school-circle-xmark::before {
  content: "\e56d";
}

.fa-arrow-right-from-bracket::before {
  content: "\f08b";
}

.fa-sign-out::before {
  content: "\f08b";
}

.fa-face-frown-slight::before {
  content: "\e376";
}

.fa-circle-chevron-down::before {
  content: "\f13a";
}

.fa-chevron-circle-down::before {
  content: "\f13a";
}

.fa-sidebar-flip::before {
  content: "\e24f";
}

.fa-unlock-keyhole::before {
  content: "\f13e";
}

.fa-unlock-alt::before {
  content: "\f13e";
}

.fa-temperature-list::before {
  content: "\e299";
}

.fa-cloud-showers-heavy::before {
  content: "\f740";
}

.fa-headphones-simple::before {
  content: "\f58f";
}

.fa-headphones-alt::before {
  content: "\f58f";
}

.fa-sitemap::before {
  content: "\f0e8";
}

.fa-pipe-section::before {
  content: "\e438";
}

.fa-space-station-moon-construction::before {
  content: "\e034";
}

.fa-space-station-moon-alt::before {
  content: "\e034";
}

.fa-circle-dollar-to-slot::before {
  content: "\f4b9";
}

.fa-donate::before {
  content: "\f4b9";
}

.fa-memory::before {
  content: "\f538";
}

.fa-face-sleeping::before {
  content: "\e38d";
}

.fa-road-spikes::before {
  content: "\e568";
}

.fa-fire-burner::before {
  content: "\e4f1";
}

.fa-squirrel::before {
  content: "\f71a";
}

.fa-arrow-up-to-line::before {
  content: "\f341";
}

.fa-arrow-to-top::before {
  content: "\f341";
}

.fa-flag::before {
  content: "\f024";
}

.fa-face-cowboy-hat::before {
  content: "\e36e";
}

.fa-hanukiah::before {
  content: "\f6e6";
}

.fa-chart-scatter-3d::before {
  content: "\e0e8";
}

.fa-display-chart-up::before {
  content: "\e5e3";
}

.fa-square-code::before {
  content: "\e267";
}

.fa-feather::before {
  content: "\f52d";
}

.fa-volume-low::before {
  content: "\f027";
}

.fa-volume-down::before {
  content: "\f027";
}

.fa-xmark-to-slot::before {
  content: "\f771";
}

.fa-times-to-slot::before {
  content: "\f771";
}

.fa-vote-nay::before {
  content: "\f771";
}

.fa-box-taped::before {
  content: "\f49a";
}

.fa-box-alt::before {
  content: "\f49a";
}

.fa-comment-slash::before {
  content: "\f4b3";
}

.fa-swords::before {
  content: "\f71d";
}

.fa-cloud-sun-rain::before {
  content: "\f743";
}

.fa-album::before {
  content: "\f89f";
}

.fa-circle-n::before {
  content: "\e118";
}

.fa-compress::before {
  content: "\f066";
}

.fa-wheat-awn::before {
  content: "\e2cd";
}

.fa-wheat-alt::before {
  content: "\e2cd";
}

.fa-ankh::before {
  content: "\f644";
}

.fa-hands-holding-child::before {
  content: "\e4fa";
}

.fa-asterisk::before {
  content: "\*";
}

.fa-key-skeleton-left-right::before {
  content: "\e3b4";
}

.fa-comment-lines::before {
  content: "\f4b0";
}

.fa-luchador-mask::before {
  content: "\f455";
}

.fa-luchador::before {
  content: "\f455";
}

.fa-mask-luchador::before {
  content: "\f455";
}

.fa-square-check::before {
  content: "\f14a";
}

.fa-check-square::before {
  content: "\f14a";
}

.fa-shredder::before {
  content: "\f68a";
}

.fa-book-open-cover::before {
  content: "\e0c0";
}

.fa-book-open-alt::before {
  content: "\e0c0";
}

.fa-sandwich::before {
  content: "\f81f";
}

.fa-peseta-sign::before {
  content: "\e221";
}

.fa-square-parking-slash::before {
  content: "\f617";
}

.fa-parking-slash::before {
  content: "\f617";
}

.fa-train-tunnel::before {
  content: "\e454";
}

.fa-heading::before {
  content: "\f1dc";
}

.fa-header::before {
  content: "\f1dc";
}

.fa-ghost::before {
  content: "\f6e2";
}

.fa-face-anguished::before {
  content: "\e369";
}

.fa-hockey-sticks::before {
  content: "\f454";
}

.fa-abacus::before {
  content: "\f640";
}

.fa-film-simple::before {
  content: "\f3a0";
}

.fa-film-alt::before {
  content: "\f3a0";
}

.fa-list::before {
  content: "\f03a";
}

.fa-list-squares::before {
  content: "\f03a";
}

.fa-tree-palm::before {
  content: "\f82b";
}

.fa-square-phone-flip::before {
  content: "\f87b";
}

.fa-phone-square-alt::before {
  content: "\f87b";
}

.fa-cart-plus::before {
  content: "\f217";
}

.fa-gamepad::before {
  content: "\f11b";
}

.fa-border-center-v::before {
  content: "\f89d";
}

.fa-circle-dot::before {
  content: "\f192";
}

.fa-dot-circle::before {
  content: "\f192";
}

.fa-clipboard-medical::before {
  content: "\e133";
}

.fa-face-dizzy::before {
  content: "\f567";
}

.fa-dizzy::before {
  content: "\f567";
}

.fa-egg::before {
  content: "\f7fb";
}

.fa-up-to-line::before {
  content: "\f34d";
}

.fa-arrow-alt-to-top::before {
  content: "\f34d";
}

.fa-house-medical-circle-xmark::before {
  content: "\e513";
}

.fa-watch-fitness::before {
  content: "\f63e";
}

.fa-clock-nine-thirty::before {
  content: "\e34d";
}

.fa-campground::before {
  content: "\f6bb";
}

.fa-folder-plus::before {
  content: "\f65e";
}

.fa-jug::before {
  content: "\f8c6";
}

.fa-futbol::before {
  content: "\f1e3";
}

.fa-futbol-ball::before {
  content: "\f1e3";
}

.fa-soccer-ball::before {
  content: "\f1e3";
}

.fa-snow-blowing::before {
  content: "\f761";
}

.fa-paintbrush::before {
  content: "\f1fc";
}

.fa-paint-brush::before {
  content: "\f1fc";
}

.fa-lock::before {
  content: "\f023";
}

.fa-arrow-down-from-line::before {
  content: "\f345";
}

.fa-arrow-from-top::before {
  content: "\f345";
}

.fa-gas-pump::before {
  content: "\f52f";
}

.fa-signal-bars-slash::before {
  content: "\f694";
}

.fa-signal-alt-slash::before {
  content: "\f694";
}

.fa-monkey::before {
  content: "\f6fb";
}

.fa-rectangle-pro::before {
  content: "\e235";
}

.fa-pro::before {
  content: "\e235";
}

.fa-house-night::before {
  content: "\e010";
}

.fa-hot-tub-person::before {
  content: "\f593";
}

.fa-hot-tub::before {
  content: "\f593";
}

.fa-blanket::before {
  content: "\f498";
}

.fa-map-location::before {
  content: "\f59f";
}

.fa-map-marked::before {
  content: "\f59f";
}

.fa-house-flood-water::before {
  content: "\e50e";
}

.fa-comments-question-check::before {
  content: "\e14f";
}

.fa-tree::before {
  content: "\f1bb";
}

.fa-arrows-cross::before {
  content: "\e0a2";
}

.fa-backpack::before {
  content: "\f5d4";
}

.fa-square-small::before {
  content: "\e27e";
}

.fa-folder-arrow-up::before {
  content: "\e054";
}

.fa-folder-upload::before {
  content: "\e054";
}

.fa-bridge-lock::before {
  content: "\e4cc";
}

.fa-crosshairs-simple::before {
  content: "\e59f";
}

.fa-sack-dollar::before {
  content: "\f81d";
}

.fa-pen-to-square::before {
  content: "\f044";
}

.fa-edit::before {
  content: "\f044";
}

.fa-square-sliders::before {
  content: "\f3f0";
}

.fa-sliders-h-square::before {
  content: "\f3f0";
}

.fa-car-side::before {
  content: "\f5e4";
}

.fa-message-middle-top::before {
  content: "\e1e2";
}

.fa-comment-middle-top-alt::before {
  content: "\e1e2";
}

.fa-lightbulb-on::before {
  content: "\f672";
}

.fa-knife::before {
  content: "\f2e4";
}

.fa-utensil-knife::before {
  content: "\f2e4";
}

.fa-share-nodes::before {
  content: "\f1e0";
}

.fa-share-alt::before {
  content: "\f1e0";
}

.fa-display-chart-up-circle-dollar::before {
  content: "\e5e6";
}

.fa-wave-sine::before {
  content: "\f899";
}

.fa-heart-circle-minus::before {
  content: "\e4ff";
}

.fa-circle-w::before {
  content: "\e12c";
}

.fa-circle-calendar::before {
  content: "\e102";
}

.fa-calendar-circle::before {
  content: "\e102";
}

.fa-hourglass-half::before {
  content: "\f252";
}

.fa-hourglass-2::before {
  content: "\f252";
}

.fa-microscope::before {
  content: "\f610";
}

.fa-sunset::before {
  content: "\f767";
}

.fa-sink::before {
  content: "\e06d";
}

.fa-calendar-exclamation::before {
  content: "\f334";
}

.fa-truck-container-empty::before {
  content: "\e2b5";
}

.fa-hand-heart::before {
  content: "\f4bc";
}

.fa-bag-shopping::before {
  content: "\f290";
}

.fa-shopping-bag::before {
  content: "\f290";
}

.fa-arrow-down-z-a::before {
  content: "\f881";
}

.fa-sort-alpha-desc::before {
  content: "\f881";
}

.fa-sort-alpha-down-alt::before {
  content: "\f881";
}

.fa-mitten::before {
  content: "\f7b5";
}

.fa-reply-clock::before {
  content: "\e239";
}

.fa-reply-time::before {
  content: "\e239";
}

.fa-person-rays::before {
  content: "\e54d";
}

.fa-right::before {
  content: "\f356";
}

.fa-arrow-alt-right::before {
  content: "\f356";
}

.fa-circle-f::before {
  content: "\e10e";
}

.fa-users::before {
  content: "\f0c0";
}

.fa-face-pleading::before {
  content: "\e386";
}

.fa-eye-slash::before {
  content: "\f070";
}

.fa-flask-vial::before {
  content: "\e4f3";
}

.fa-police-box::before {
  content: "\e021";
}

.fa-cucumber::before {
  content: "\e401";
}

.fa-head-side-brain::before {
  content: "\f808";
}

.fa-hand::before {
  content: "\f256";
}

.fa-hand-paper::before {
  content: "\f256";
}

.fa-person-biking-mountain::before {
  content: "\f84b";
}

.fa-biking-mountain::before {
  content: "\f84b";
}

.fa-utensils-slash::before {
  content: "\e464";
}

.fa-print-magnifying-glass::before {
  content: "\f81a";
}

.fa-print-search::before {
  content: "\f81a";
}

.fa-folder-bookmark::before {
  content: "\e186";
}

.fa-om::before {
  content: "\f679";
}

.fa-pi::before {
  content: "\f67e";
}

.fa-flask-round-potion::before {
  content: "\f6e1";
}

.fa-flask-potion::before {
  content: "\f6e1";
}

.fa-face-shush::before {
  content: "\e38c";
}

.fa-worm::before {
  content: "\e599";
}

.fa-house-circle-xmark::before {
  content: "\e50b";
}

.fa-plug::before {
  content: "\f1e6";
}

.fa-calendar-circle-exclamation::before {
  content: "\e46e";
}

.fa-square-i::before {
  content: "\e272";
}

.fa-chevron-up::before {
  content: "\f077";
}

.fa-face-saluting::before {
  content: "\e484";
}

.fa-gauge-simple-low::before {
  content: "\f62c";
}

.fa-tachometer-slow::before {
  content: "\f62c";
}

.fa-face-persevering::before {
  content: "\e385";
}

.fa-circle-camera::before {
  content: "\e103";
}

.fa-camera-circle::before {
  content: "\e103";
}

.fa-hand-spock::before {
  content: "\f259";
}

.fa-spider-web::before {
  content: "\f719";
}

.fa-circle-microphone::before {
  content: "\e116";
}

.fa-microphone-circle::before {
  content: "\e116";
}

.fa-book-arrow-up::before {
  content: "\e0ba";
}

.fa-popsicle::before {
  content: "\e43e";
}

.fa-command::before {
  content: "\e142";
}

.fa-blinds::before {
  content: "\f8fb";
}

.fa-stopwatch::before {
  content: "\f2f2";
}

.fa-saxophone::before {
  content: "\f8dc";
}

.fa-square-2::before {
  content: "\e257";
}

.fa-field-hockey-stick-ball::before {
  content: "\f44c";
}

.fa-field-hockey::before {
  content: "\f44c";
}

.fa-arrow-up-square-triangle::before {
  content: "\f88b";
}

.fa-sort-shapes-up-alt::before {
  content: "\f88b";
}

.fa-face-scream::before {
  content: "\e38b";
}

.fa-square-m::before {
  content: "\e276";
}

.fa-camera-web::before {
  content: "\f832";
}

.fa-webcam::before {
  content: "\f832";
}

.fa-comment-arrow-down::before {
  content: "\e143";
}

.fa-lightbulb-cfl::before {
  content: "\e5a6";
}

.fa-window-frame-open::before {
  content: "\e050";
}

.fa-face-kiss::before {
  content: "\f596";
}

.fa-kiss::before {
  content: "\f596";
}

.fa-bridge-circle-xmark::before {
  content: "\e4cb";
}

.fa-period::before {
  content: "\.";
}

.fa-face-grin-tongue::before {
  content: "\f589";
}

.fa-grin-tongue::before {
  content: "\f589";
}

.fa-up-to-dotted-line::before {
  content: "\e457";
}

.fa-thought-bubble::before {
  content: "\e32e";
}

.fa-skeleton-ribs::before {
  content: "\e5cb";
}

.fa-raygun::before {
  content: "\e025";
}

.fa-flute::before {
  content: "\f8b9";
}

.fa-acorn::before {
  content: "\f6ae";
}

.fa-video-arrow-up-right::before {
  content: "\e2c9";
}

.fa-grate-droplet::before {
  content: "\e194";
}

.fa-seal-exclamation::before {
  content: "\e242";
}

.fa-chess-bishop::before {
  content: "\f43a";
}

.fa-message-sms::before {
  content: "\e1e5";
}

.fa-coffee-beans::before {
  content: "\e13f";
}

.fa-hat-witch::before {
  content: "\f6e7";
}

.fa-face-grin-wink::before {
  content: "\f58c";
}

.fa-grin-wink::before {
  content: "\f58c";
}

.fa-clock-three-thirty::before {
  content: "\e357";
}

.fa-ear-deaf::before {
  content: "\f2a4";
}

.fa-deaf::before {
  content: "\f2a4";
}

.fa-deafness::before {
  content: "\f2a4";
}

.fa-hard-of-hearing::before {
  content: "\f2a4";
}

.fa-alarm-clock::before {
  content: "\f34e";
}

.fa-eclipse::before {
  content: "\f749";
}

.fa-face-relieved::before {
  content: "\e389";
}

.fa-road-circle-check::before {
  content: "\e564";
}

.fa-dice-five::before {
  content: "\f523";
}

.fa-octagon-minus::before {
  content: "\f308";
}

.fa-minus-octagon::before {
  content: "\f308";
}

.fa-square-rss::before {
  content: "\f143";
}

.fa-rss-square::before {
  content: "\f143";
}

.fa-face-zany::before {
  content: "\e3a4";
}

.fa-tricycle::before {
  content: "\e5c3";
}

.fa-land-mine-on::before {
  content: "\e51b";
}

.fa-square-arrow-up-left::before {
  content: "\e263";
}

.fa-i-cursor::before {
  content: "\f246";
}

.fa-chart-mixed-up-circle-dollar::before {
  content: "\e5d9";
}

.fa-salt-shaker::before {
  content: "\e446";
}

.fa-stamp::before {
  content: "\f5bf";
}

.fa-file-plus::before {
  content: "\f319";
}

.fa-draw-square::before {
  content: "\f5ef";
}

.fa-toilet-paper-under-slash::before {
  content: "\e2a1";
}

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1";
}

.fa-stairs::before {
  content: "\e289";
}

.fa-drone-front::before {
  content: "\f860";
}

.fa-drone-alt::before {
  content: "\f860";
}

.fa-glass-empty::before {
  content: "\e191";
}

.fa-dial-high::before {
  content: "\e15c";
}

.fa-user-helmet-safety::before {
  content: "\f82c";
}

.fa-user-construction::before {
  content: "\f82c";
}

.fa-user-hard-hat::before {
  content: "\f82c";
}

.fa-i::before {
  content: "I";
}

.fa-hryvnia-sign::before {
  content: "\f6f2";
}

.fa-hryvnia::before {
  content: "\f6f2";
}

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092";
}

.fa-pills::before {
  content: "\f484";
}

.fa-face-grin-wide::before {
  content: "\f581";
}

.fa-grin-alt::before {
  content: "\f581";
}

.fa-tooth::before {
  content: "\f5c9";
}

.fa-basketball-hoop::before {
  content: "\f435";
}

.fa-objects-align-bottom::before {
  content: "\e3bb";
}

.fa-v::before {
  content: "V";
}

.fa-sparkles::before {
  content: "\f890";
}

.fa-squid::before {
  content: "\e450";
}

.fa-leafy-green::before {
  content: "\e41d";
}

.fa-circle-arrow-up-right::before {
  content: "\e0fc";
}

.fa-calendars::before {
  content: "\e0d7";
}

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6";
}

.fa-bicycle::before {
  content: "\f206";
}

.fa-hammer-war::before {
  content: "\f6e4";
}

.fa-circle-d::before {
  content: "\e104";
}

.fa-spider-black-widow::before {
  content: "\f718";
}

.fa-staff-snake::before {
  content: "\e579";
}

.fa-rod-asclepius::before {
  content: "\e579";
}

.fa-rod-snake::before {
  content: "\e579";
}

.fa-staff-aesculapius::before {
  content: "\e579";
}

.fa-pear::before {
  content: "\e20c";
}

.fa-head-side-cough-slash::before {
  content: "\e062";
}

.fa-triangle::before {
  content: "\f2ec";
}

.fa-apartment::before {
  content: "\e468";
}

.fa-truck-medical::before {
  content: "\f0f9";
}

.fa-ambulance::before {
  content: "\f0f9";
}

.fa-pepper::before {
  content: "\e432";
}

.fa-piano::before {
  content: "\f8d4";
}

.fa-gun-squirt::before {
  content: "\e19d";
}

.fa-wheat-awn-circle-exclamation::before {
  content: "\e598";
}

.fa-snowman::before {
  content: "\f7d0";
}

.fa-user-alien::before {
  content: "\e04a";
}

.fa-shield-check::before {
  content: "\f2f7";
}

.fa-mortar-pestle::before {
  content: "\f5a7";
}

.fa-road-barrier::before {
  content: "\e562";
}

.fa-chart-candlestick::before {
  content: "\e0e2";
}

.fa-briefcase-blank::before {
  content: "\e0c8";
}

.fa-school::before {
  content: "\f549";
}

.fa-igloo::before {
  content: "\f7ae";
}

.fa-bracket-round::before {
  content: "\(";
}

.fa-parenthesis::before {
  content: "\(";
}

.fa-joint::before {
  content: "\f595";
}

.fa-horse-saddle::before {
  content: "\f8c3";
}

.fa-mug-marshmallows::before {
  content: "\f7b7";
}

.fa-filters::before {
  content: "\e17e";
}

.fa-bell-on::before {
  content: "\f8fa";
}

.fa-angle-right::before {
  content: "\f105";
}

.fa-dial-med::before {
  content: "\e15f";
}

.fa-horse::before {
  content: "\f6f0";
}

.fa-q::before {
  content: "Q";
}

.fa-monitor-waveform::before {
  content: "\f611";
}

.fa-monitor-heart-rate::before {
  content: "\f611";
}

.fa-link-simple::before {
  content: "\e1cd";
}

.fa-whistle::before {
  content: "\f460";
}

.fa-g::before {
  content: "G";
}

.fa-wine-glass-crack::before {
  content: "\f4bb";
}

.fa-fragile::before {
  content: "\f4bb";
}

.fa-slot-machine::before {
  content: "\e3ce";
}

.fa-notes-medical::before {
  content: "\f481";
}

.fa-car-wash::before {
  content: "\f5e6";
}

.fa-escalator::before {
  content: "\e171";
}

.fa-comment-image::before {
  content: "\e148";
}

.fa-temperature-half::before {
  content: "\f2c9";
}

.fa-temperature-2::before {
  content: "\f2c9";
}

.fa-thermometer-2::before {
  content: "\f2c9";
}

.fa-thermometer-half::before {
  content: "\f2c9";
}

.fa-dong-sign::before {
  content: "\e169";
}

.fa-donut::before {
  content: "\e406";
}

.fa-doughnut::before {
  content: "\e406";
}

.fa-capsules::before {
  content: "\f46b";
}

.fa-poo-storm::before {
  content: "\f75a";
}

.fa-poo-bolt::before {
  content: "\f75a";
}

.fa-tally-1::before {
  content: "\e294";
}

.fa-face-frown-open::before {
  content: "\f57a";
}

.fa-frown-open::before {
  content: "\f57a";
}

.fa-square-dashed::before {
  content: "\e269";
}

.fa-square-j::before {
  content: "\e273";
}

.fa-hand-point-up::before {
  content: "\f0a6";
}

.fa-money-bill::before {
  content: "\f0d6";
}

.fa-arrow-up-big-small::before {
  content: "\f88e";
}

.fa-sort-size-up::before {
  content: "\f88e";
}

.fa-barcode-read::before {
  content: "\f464";
}

.fa-baguette::before {
  content: "\e3d8";
}

.fa-bowl-soft-serve::before {
  content: "\e46b";
}

.fa-face-holding-back-tears::before {
  content: "\e482";
}

.fa-square-up::before {
  content: "\f353";
}

.fa-arrow-alt-square-up::before {
  content: "\f353";
}

.fa-train-subway-tunnel::before {
  content: "\e2a3";
}

.fa-subway-tunnel::before {
  content: "\e2a3";
}

.fa-square-exclamation::before {
  content: "\f321";
}

.fa-exclamation-square::before {
  content: "\f321";
}

.fa-semicolon::before {
  content: "\;";
}

.fa-bookmark::before {
  content: "\f02e";
}

.fa-fan-table::before {
  content: "\e004";
}

.fa-align-justify::before {
  content: "\f039";
}

.fa-battery-low::before {
  content: "\e0b1";
}

.fa-battery-1::before {
  content: "\e0b1";
}

.fa-credit-card-front::before {
  content: "\f38a";
}

.fa-brain-arrow-curved-right::before {
  content: "\f677";
}

.fa-mind-share::before {
  content: "\f677";
}

.fa-umbrella-beach::before {
  content: "\f5ca";
}

.fa-helmet-un::before {
  content: "\e503";
}

.fa-location-smile::before {
  content: "\f60d";
}

.fa-map-marker-smile::before {
  content: "\f60d";
}

.fa-arrow-left-to-line::before {
  content: "\f33e";
}

.fa-arrow-to-left::before {
  content: "\f33e";
}

.fa-bullseye::before {
  content: "\f140";
}

.fa-sushi::before {
  content: "\e48a";
}

.fa-nigiri::before {
  content: "\e48a";
}

.fa-message-captions::before {
  content: "\e1de";
}

.fa-comment-alt-captions::before {
  content: "\e1de";
}

.fa-trash-list::before {
  content: "\e2b1";
}

.fa-bacon::before {
  content: "\f7e5";
}

.fa-option::before {
  content: "\e318";
}

.fa-hand-point-down::before {
  content: "\f0a7";
}

.fa-arrow-up-from-bracket::before {
  content: "\e09a";
}

.fa-trash-plus::before {
  content: "\e2b2";
}

.fa-objects-align-top::before {
  content: "\e3c0";
}

.fa-folder::before {
  content: "\f07b";
}

.fa-folder-blank::before {
  content: "\f07b";
}

.fa-face-anxious-sweat::before {
  content: "\e36a";
}

.fa-credit-card-blank::before {
  content: "\f389";
}

.fa-file-waveform::before {
  content: "\f478";
}

.fa-file-medical-alt::before {
  content: "\f478";
}

.fa-microchip-ai::before {
  content: "\e1ec";
}

.fa-mug::before {
  content: "\f874";
}

.fa-plane-up-slash::before {
  content: "\e22e";
}

.fa-radiation::before {
  content: "\f7b9";
}

.fa-pen-circle::before {
  content: "\e20e";
}

.fa-bag-seedling::before {
  content: "\e5f2";
}

.fa-chart-simple::before {
  content: "\e473";
}

.fa-crutches::before {
  content: "\f7f8";
}

.fa-circle-parking::before {
  content: "\f615";
}

.fa-parking-circle::before {
  content: "\f615";
}

.fa-mars-stroke::before {
  content: "\f229";
}

.fa-leaf-oak::before {
  content: "\f6f7";
}

.fa-square-bolt::before {
  content: "\e265";
}

.fa-vial::before {
  content: "\f492";
}

.fa-gauge::before {
  content: "\f624";
}

.fa-dashboard::before {
  content: "\f624";
}

.fa-gauge-med::before {
  content: "\f624";
}

.fa-tachometer-alt-average::before {
  content: "\f624";
}

.fa-wand-magic-sparkles::before {
  content: "\e2ca";
}

.fa-magic-wand-sparkles::before {
  content: "\e2ca";
}

.fa-lambda::before {
  content: "\f66e";
}

.fa-e::before {
  content: "E";
}

.fa-pizza::before {
  content: "\f817";
}

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea";
}

.fa-h3::before {
  content: "\f315";
}

.fa-pen-clip::before {
  content: "\f305";
}

.fa-pen-alt::before {
  content: "\f305";
}

.fa-bridge-circle-exclamation::before {
  content: "\e4ca";
}

.fa-badge-percent::before {
  content: "\f646";
}

.fa-user::before {
  content: "\f007";
}

.fa-sensor::before {
  content: "\e028";
}

.fa-comma::before {
  content: "\,";
}

.fa-school-circle-check::before {
  content: "\e56b";
}

.fa-toilet-paper-under::before {
  content: "\e2a0";
}

.fa-toilet-paper-reverse::before {
  content: "\e2a0";
}

.fa-light-emergency::before {
  content: "\e41f";
}

.fa-arrow-down-to-arc::before {
  content: "\e4ae";
}

.fa-dumpster::before {
  content: "\f793";
}

.fa-van-shuttle::before {
  content: "\f5b6";
}

.fa-shuttle-van::before {
  content: "\f5b6";
}

.fa-building-user::before {
  content: "\e4da";
}

.fa-light-switch::before {
  content: "\e017";
}

.fa-square-caret-left::before {
  content: "\f191";
}

.fa-caret-square-left::before {
  content: "\f191";
}

.fa-highlighter::before {
  content: "\f591";
}

.fa-wave-pulse::before {
  content: "\f5f8";
}

.fa-heart-rate::before {
  content: "\f5f8";
}

.fa-key::before {
  content: "\f084";
}

.fa-hat-santa::before {
  content: "\f7a7";
}

.fa-tamale::before {
  content: "\e451";
}

.fa-box-check::before {
  content: "\f467";
}

.fa-bullhorn::before {
  content: "\f0a1";
}

.fa-steak::before {
  content: "\f824";
}

.fa-location-crosshairs-slash::before {
  content: "\f603";
}

.fa-location-slash::before {
  content: "\f603";
}

.fa-person-dolly::before {
  content: "\f4d0";
}

.fa-globe::before {
  content: "\f0ac";
}

.fa-synagogue::before {
  content: "\f69b";
}

.fa-file-chart-column::before {
  content: "\f659";
}

.fa-file-chart-line::before {
  content: "\f659";
}

.fa-person-half-dress::before {
  content: "\e548";
}

.fa-folder-image::before {
  content: "\e18a";
}

.fa-calendar-pen::before {
  content: "\f333";
}

.fa-calendar-edit::before {
  content: "\f333";
}

.fa-road-bridge::before {
  content: "\e563";
}

.fa-face-smile-tear::before {
  content: "\e393";
}

.fa-message-plus::before {
  content: "\f4a8";
}

.fa-comment-alt-plus::before {
  content: "\f4a8";
}

.fa-location-arrow::before {
  content: "\f124";
}

.fa-c::before {
  content: "C";
}

.fa-tablet-button::before {
  content: "\f10a";
}

.fa-rectangle-history-circle-user::before {
  content: "\e4a4";
}

.fa-building-lock::before {
  content: "\e4d6";
}

.fa-chart-line-up::before {
  content: "\e0e5";
}

.fa-mailbox::before {
  content: "\f813";
}

.fa-truck-bolt::before {
  content: "\e3d0";
}

.fa-pizza-slice::before {
  content: "\f818";
}

.fa-money-bill-wave::before {
  content: "\f53a";
}

.fa-chart-area::before {
  content: "\f1fe";
}

.fa-area-chart::before {
  content: "\f1fe";
}

.fa-house-flag::before {
  content: "\e50d";
}

.fa-circle-three-quarters-stroke::before {
  content: "\e5d4";
}

.fa-person-circle-minus::before {
  content: "\e540";
}

.fa-scalpel::before {
  content: "\f61d";
}

.fa-ban::before {
  content: "\f05e";
}

.fa-cancel::before {
  content: "\f05e";
}

.fa-bell-exclamation::before {
  content: "\f848";
}

.fa-circle-bookmark::before {
  content: "\e100";
}

.fa-bookmark-circle::before {
  content: "\e100";
}

.fa-egg-fried::before {
  content: "\f7fc";
}

.fa-face-weary::before {
  content: "\e3a1";
}

.fa-uniform-martial-arts::before {
  content: "\e3d1";
}

.fa-camera-rotate::before {
  content: "\e0d8";
}

.fa-sun-dust::before {
  content: "\f764";
}

.fa-comment-text::before {
  content: "\e14d";
}

.fa-spray-can-sparkles::before {
  content: "\f5d0";
}

.fa-air-freshener::before {
  content: "\f5d0";
}

.fa-signal-bars::before {
  content: "\f690";
}

.fa-signal-alt::before {
  content: "\f690";
}

.fa-signal-alt-4::before {
  content: "\f690";
}

.fa-signal-bars-strong::before {
  content: "\f690";
}

.fa-diamond-exclamation::before {
  content: "\e405";
}

.fa-star::before {
  content: "\f005";
}

.fa-dial-min::before {
  content: "\e161";
}

.fa-repeat::before {
  content: "\f363";
}

.fa-cross::before {
  content: "\f654";
}

.fa-page-caret-down::before {
  content: "\e429";
}

.fa-file-caret-down::before {
  content: "\e429";
}

.fa-box::before {
  content: "\f466";
}

.fa-venus-mars::before {
  content: "\f228";
}

.fa-clock-seven-thirty::before {
  content: "\e351";
}

.fa-arrow-pointer::before {
  content: "\f245";
}

.fa-mouse-pointer::before {
  content: "\f245";
}

.fa-clock-four-thirty::before {
  content: "\e34b";
}

.fa-signal-bars-good::before {
  content: "\f693";
}

.fa-signal-alt-3::before {
  content: "\f693";
}

.fa-cactus::before {
  content: "\f8a7";
}

.fa-lightbulb-gear::before {
  content: "\e5fd";
}

.fa-maximize::before {
  content: "\f31e";
}

.fa-expand-arrows-alt::before {
  content: "\f31e";
}

.fa-charging-station::before {
  content: "\f5e7";
}

.fa-shapes::before {
  content: "\f61f";
}

.fa-triangle-circle-square::before {
  content: "\f61f";
}

.fa-plane-tail::before {
  content: "\e22c";
}

.fa-gauge-simple-max::before {
  content: "\f62b";
}

.fa-tachometer-fastest::before {
  content: "\f62b";
}

.fa-circle-u::before {
  content: "\e127";
}

.fa-shield-slash::before {
  content: "\e24b";
}

.fa-square-phone-hangup::before {
  content: "\e27a";
}

.fa-phone-square-down::before {
  content: "\e27a";
}

.fa-arrow-up-left::before {
  content: "\e09d";
}

.fa-transporter-1::before {
  content: "\e043";
}

.fa-peanuts::before {
  content: "\e431";
}

.fa-shuffle::before {
  content: "\f074";
}

.fa-random::before {
  content: "\f074";
}

.fa-person-running::before {
  content: "\f70c";
}

.fa-running::before {
  content: "\f70c";
}

.fa-mobile-retro::before {
  content: "\e527";
}

.fa-grip-lines-vertical::before {
  content: "\f7a5";
}

.fa-bin-bottles-recycle::before {
  content: "\e5f6";
}

.fa-arrow-up-from-square::before {
  content: "\e09c";
}

.fa-file-dashed-line::before {
  content: "\f877";
}

.fa-page-break::before {
  content: "\f877";
}

.fa-bracket-curly-right::before {
  content: "\}";
}

.fa-spider::before {
  content: "\f717";
}

.fa-clock-three::before {
  content: "\e356";
}

.fa-hands-bound::before {
  content: "\e4f9";
}

.fa-scalpel-line-dashed::before {
  content: "\f61e";
}

.fa-scalpel-path::before {
  content: "\f61e";
}

.fa-file-invoice-dollar::before {
  content: "\f571";
}

.fa-pipe-smoking::before {
  content: "\e3c4";
}

.fa-face-astonished::before {
  content: "\e36b";
}

.fa-window::before {
  content: "\f40e";
}

.fa-plane-circle-exclamation::before {
  content: "\e556";
}

.fa-ear::before {
  content: "\f5f0";
}

.fa-file-lock::before {
  content: "\e3a6";
}

.fa-diagram-venn::before {
  content: "\e15a";
}

.fa-x-ray::before {
  content: "\f497";
}

.fa-goal-net::before {
  content: "\e3ab";
}

.fa-coffin-cross::before {
  content: "\e051";
}

.fa-spell-check::before {
  content: "\f891";
}

.fa-location-xmark::before {
  content: "\f60e";
}

.fa-map-marker-times::before {
  content: "\f60e";
}

.fa-map-marker-xmark::before {
  content: "\f60e";
}

.fa-circle-quarter-stroke::before {
  content: "\e5d3";
}

.fa-lasso::before {
  content: "\f8c8";
}

.fa-slash::before {
  content: "\f715";
}

.fa-person-to-portal::before {
  content: "\e022";
}

.fa-portal-enter::before {
  content: "\e022";
}

.fa-calendar-star::before {
  content: "\f736";
}

.fa-computer-mouse::before {
  content: "\f8cc";
}

.fa-mouse::before {
  content: "\f8cc";
}

.fa-arrow-right-to-bracket::before {
  content: "\f090";
}

.fa-sign-in::before {
  content: "\f090";
}

.fa-pegasus::before {
  content: "\f703";
}

.fa-files-medical::before {
  content: "\f7fd";
}

.fa-nfc-lock::before {
  content: "\e1f8";
}

.fa-person-ski-lift::before {
  content: "\f7c8";
}

.fa-ski-lift::before {
  content: "\f7c8";
}

.fa-square-6::before {
  content: "\e25b";
}

.fa-shop-slash::before {
  content: "\e070";
}

.fa-store-alt-slash::before {
  content: "\e070";
}

.fa-wind-turbine::before {
  content: "\f89b";
}

.fa-sliders-simple::before {
  content: "\e253";
}

.fa-grid-round::before {
  content: "\e5da";
}

.fa-badge-sheriff::before {
  content: "\f8a2";
}

.fa-server::before {
  content: "\f233";
}

.fa-virus-covid-slash::before {
  content: "\e4a9";
}

.fa-intersection::before {
  content: "\f668";
}

.fa-shop-lock::before {
  content: "\e4a5";
}

.fa-family::before {
  content: "\e300";
}

.fa-hourglass-start::before {
  content: "\f251";
}

.fa-hourglass-1::before {
  content: "\f251";
}

.fa-user-hair-buns::before {
  content: "\e3d3";
}

.fa-blender-phone::before {
  content: "\f6b6";
}

.fa-hourglass-clock::before {
  content: "\e41b";
}

.fa-person-seat-reclined::before {
  content: "\e21f";
}

.fa-paper-plane-top::before {
  content: "\e20a";
}

.fa-paper-plane-alt::before {
  content: "\e20a";
}

.fa-send::before {
  content: "\e20a";
}

.fa-message-arrow-up::before {
  content: "\e1dc";
}

.fa-comment-alt-arrow-up::before {
  content: "\e1dc";
}

.fa-lightbulb-exclamation::before {
  content: "\f671";
}

.fa-layer-minus::before {
  content: "\f5fe";
}

.fa-layer-group-minus::before {
  content: "\f5fe";
}

.fa-chart-pie-simple-circle-currency::before {
  content: "\e604";
}

.fa-circle-e::before {
  content: "\e109";
}

.fa-building-wheat::before {
  content: "\e4db";
}

.fa-gauge-max::before {
  content: "\f626";
}

.fa-tachometer-alt-fastest::before {
  content: "\f626";
}

.fa-person-breastfeeding::before {
  content: "\e53a";
}

.fa-apostrophe::before {
  content: "\'";
}

.fa-fire-hydrant::before {
  content: "\e17f";
}

.fa-right-to-bracket::before {
  content: "\f2f6";
}

.fa-sign-in-alt::before {
  content: "\f2f6";
}

.fa-video-plus::before {
  content: "\f4e1";
}

.fa-square-right::before {
  content: "\f352";
}

.fa-arrow-alt-square-right::before {
  content: "\f352";
}

.fa-comment-smile::before {
  content: "\f4b4";
}

.fa-venus::before {
  content: "\f221";
}

.fa-passport::before {
  content: "\f5ab";
}

.fa-inbox-in::before {
  content: "\f310";
}

.fa-inbox-arrow-down::before {
  content: "\f310";
}

.fa-heart-pulse::before {
  content: "\f21e";
}

.fa-heartbeat::before {
  content: "\f21e";
}

.fa-circle-8::before {
  content: "\e0f5";
}

.fa-clouds-moon::before {
  content: "\f745";
}

.fa-clock-ten-thirty::before {
  content: "\e355";
}

.fa-people-carry-box::before {
  content: "\f4ce";
}

.fa-people-carry::before {
  content: "\f4ce";
}

.fa-folder-user::before {
  content: "\e18e";
}

.fa-trash-can-xmark::before {
  content: "\e2ae";
}

.fa-temperature-high::before {
  content: "\f769";
}

.fa-microchip::before {
  content: "\f2db";
}

.fa-left-long-to-line::before {
  content: "\e41e";
}

.fa-crown::before {
  content: "\f521";
}

.fa-weight-hanging::before {
  content: "\f5cd";
}

.fa-xmarks-lines::before {
  content: "\e59a";
}

.fa-file-prescription::before {
  content: "\f572";
}

.fa-calendar-range::before {
  content: "\e0d6";
}

.fa-flower-daffodil::before {
  content: "\f800";
}

.fa-hand-back-point-up::before {
  content: "\e1a2";
}

.fa-weight-scale::before {
  content: "\f496";
}

.fa-weight::before {
  content: "\f496";
}

.fa-star-exclamation::before {
  content: "\f2f3";
}

.fa-books::before {
  content: "\f5db";
}

.fa-user-group::before {
  content: "\f500";
}

.fa-user-friends::before {
  content: "\f500";
}

.fa-arrow-up-a-z::before {
  content: "\f15e";
}

.fa-sort-alpha-up::before {
  content: "\f15e";
}

.fa-layer-plus::before {
  content: "\f5ff";
}

.fa-layer-group-plus::before {
  content: "\f5ff";
}

.fa-play-pause::before {
  content: "\e22f";
}

.fa-block-question::before {
  content: "\e3dd";
}

.fa-snooze::before {
  content: "\f880";
}

.fa-zzz::before {
  content: "\f880";
}

.fa-scanner-image::before {
  content: "\f8f3";
}

.fa-tv-retro::before {
  content: "\f401";
}

.fa-square-t::before {
  content: "\e280";
}

.fa-farm::before {
  content: "\f864";
}

.fa-barn-silo::before {
  content: "\f864";
}

.fa-chess-knight::before {
  content: "\f441";
}

.fa-bars-sort::before {
  content: "\e0ae";
}

.fa-pallet-boxes::before {
  content: "\f483";
}

.fa-palette-boxes::before {
  content: "\f483";
}

.fa-pallet-alt::before {
  content: "\f483";
}

.fa-face-laugh-squint::before {
  content: "\f59b";
}

.fa-laugh-squint::before {
  content: "\f59b";
}

.fa-code-simple::before {
  content: "\e13d";
}

.fa-bolt-slash::before {
  content: "\e0b8";
}

.fa-panel-fire::before {
  content: "\e42f";
}

.fa-binary-circle-check::before {
  content: "\e33c";
}

.fa-comment-minus::before {
  content: "\f4b1";
}

.fa-burrito::before {
  content: "\f7ed";
}

.fa-violin::before {
  content: "\f8ed";
}

.fa-objects-column::before {
  content: "\e3c1";
}

.fa-square-chevron-down::before {
  content: "\f329";
}

.fa-chevron-square-down::before {
  content: "\f329";
}

.fa-comment-plus::before {
  content: "\f4b2";
}

.fa-triangle-instrument::before {
  content: "\f8e2";
}

.fa-triangle-music::before {
  content: "\f8e2";
}

.fa-wheelchair::before {
  content: "\f193";
}

.fa-user-pilot-tie::before {
  content: "\e2c1";
}

.fa-piano-keyboard::before {
  content: "\f8d5";
}

.fa-bed-empty::before {
  content: "\f8f9";
}

.fa-circle-arrow-up::before {
  content: "\f0aa";
}

.fa-arrow-circle-up::before {
  content: "\f0aa";
}

.fa-toggle-on::before {
  content: "\f205";
}

.fa-rectangle-vertical::before {
  content: "\f2fb";
}

.fa-rectangle-portrait::before {
  content: "\f2fb";
}

.fa-person-walking::before {
  content: "\f554";
}

.fa-walking::before {
  content: "\f554";
}

.fa-l::before {
  content: "L";
}

.fa-signal-stream::before {
  content: "\f8dd";
}

.fa-down-to-bracket::before {
  content: "\e4e7";
}

.fa-circle-z::before {
  content: "\e130";
}

.fa-stars::before {
  content: "\f762";
}

.fa-fire::before {
  content: "\f06d";
}

.fa-bed-pulse::before {
  content: "\f487";
}

.fa-procedures::before {
  content: "\f487";
}

.fa-house-day::before {
  content: "\e00e";
}

.fa-shuttle-space::before {
  content: "\f197";
}

.fa-space-shuttle::before {
  content: "\f197";
}

.fa-shirt-long-sleeve::before {
  content: "\e3c7";
}

.fa-chart-pie-simple::before {
  content: "\f64e";
}

.fa-chart-pie-alt::before {
  content: "\f64e";
}

.fa-face-laugh::before {
  content: "\f599";
}

.fa-laugh::before {
  content: "\f599";
}

.fa-folder-open::before {
  content: "\f07c";
}

.fa-album-collection-circle-user::before {
  content: "\e48f";
}

.fa-candy::before {
  content: "\e3e7";
}

.fa-bowl-hot::before {
  content: "\f823";
}

.fa-soup::before {
  content: "\f823";
}

.fa-flatbread::before {
  content: "\e40b";
}

.fa-heart-circle-plus::before {
  content: "\e500";
}

.fa-code-fork::before {
  content: "\e13b";
}

.fa-city::before {
  content: "\f64f";
}

.fa-signal-bars-weak::before {
  content: "\f691";
}

.fa-signal-alt-1::before {
  content: "\f691";
}

.fa-microphone-lines::before {
  content: "\f3c9";
}

.fa-microphone-alt::before {
  content: "\f3c9";
}

.fa-clock-twelve::before {
  content: "\e358";
}

.fa-pepper-hot::before {
  content: "\f816";
}

.fa-citrus-slice::before {
  content: "\e2f5";
}

.fa-sheep::before {
  content: "\f711";
}

.fa-unlock::before {
  content: "\f09c";
}

.fa-colon-sign::before {
  content: "\e140";
}

.fa-headset::before {
  content: "\f590";
}

.fa-badger-honey::before {
  content: "\f6b4";
}

.fa-h4::before {
  content: "\f86a";
}

.fa-store-slash::before {
  content: "\e071";
}

.fa-road-circle-xmark::before {
  content: "\e566";
}

.fa-signal-slash::before {
  content: "\f695";
}

.fa-user-minus::before {
  content: "\f503";
}

.fa-mars-stroke-up::before {
  content: "\f22a";
}

.fa-mars-stroke-v::before {
  content: "\f22a";
}

.fa-champagne-glasses::before {
  content: "\f79f";
}

.fa-glass-cheers::before {
  content: "\f79f";
}

.fa-taco::before {
  content: "\f826";
}

.fa-hexagon-plus::before {
  content: "\f300";
}

.fa-plus-hexagon::before {
  content: "\f300";
}

.fa-clipboard::before {
  content: "\f328";
}

.fa-house-circle-exclamation::before {
  content: "\e50a";
}

.fa-file-arrow-up::before {
  content: "\f574";
}

.fa-file-upload::before {
  content: "\f574";
}

.fa-wifi::before {
  content: "\f1eb";
}

.fa-wifi-3::before {
  content: "\f1eb";
}

.fa-wifi-strong::before {
  content: "\f1eb";
}

.fa-messages::before {
  content: "\f4b6";
}

.fa-comments-alt::before {
  content: "\f4b6";
}

.fa-bath::before {
  content: "\f2cd";
}

.fa-bathtub::before {
  content: "\f2cd";
}

.fa-umbrella-simple::before {
  content: "\e2bc";
}

.fa-umbrella-alt::before {
  content: "\e2bc";
}

.fa-rectangle-history-circle-plus::before {
  content: "\e4a3";
}

.fa-underline::before {
  content: "\f0cd";
}

.fa-prescription-bottle-pill::before {
  content: "\e5c0";
}

.fa-user-pen::before {
  content: "\f4ff";
}

.fa-user-edit::before {
  content: "\f4ff";
}

.fa-binary-slash::before {
  content: "\e33e";
}

.fa-square-o::before {
  content: "\e278";
}

.fa-signature::before {
  content: "\f5b7";
}

.fa-stroopwafel::before {
  content: "\f551";
}

.fa-bold::before {
  content: "\f032";
}

.fa-anchor-lock::before {
  content: "\e4ad";
}

.fa-building-ngo::before {
  content: "\e4d7";
}

.fa-transporter-3::before {
  content: "\e045";
}

.fa-engine-warning::before {
  content: "\f5f2";
}

.fa-engine-exclamation::before {
  content: "\f5f2";
}

.fa-circle-down-right::before {
  content: "\e108";
}

.fa-square-k::before {
  content: "\e274";
}

.fa-manat-sign::before {
  content: "\e1d5";
}

.fa-money-check-pen::before {
  content: "\f872";
}

.fa-money-check-edit::before {
  content: "\f872";
}

.fa-not-equal::before {
  content: "\f53e";
}

.fa-border-top-left::before {
  content: "\f853";
}

.fa-border-style::before {
  content: "\f853";
}

.fa-map-location-dot::before {
  content: "\f5a0";
}

.fa-map-marked-alt::before {
  content: "\f5a0";
}

.fa-tilde::before {
  content: "\~";
}

.fa-jedi::before {
  content: "\f669";
}

.fa-square-poll-vertical::before {
  content: "\f681";
}

.fa-poll::before {
  content: "\f681";
}

.fa-arrow-down-square-triangle::before {
  content: "\f889";
}

.fa-sort-shapes-down-alt::before {
  content: "\f889";
}

.fa-mug-hot::before {
  content: "\f7b6";
}

.fa-dog-leashed::before {
  content: "\f6d4";
}

.fa-car-battery::before {
  content: "\f5df";
}

.fa-battery-car::before {
  content: "\f5df";
}

.fa-face-downcast-sweat::before {
  content: "\e371";
}

.fa-mailbox-flag-up::before {
  content: "\e5bb";
}

.fa-memo-circle-info::before {
  content: "\e49a";
}

.fa-gift::before {
  content: "\f06b";
}

.fa-dice-two::before {
  content: "\f528";
}

.fa-volume::before {
  content: "\f6a8";
}

.fa-volume-medium::before {
  content: "\f6a8";
}

.fa-transporter-5::before {
  content: "\e2a6";
}

.fa-gauge-circle-bolt::before {
  content: "\e496";
}

.fa-coin-front::before {
  content: "\e3fc";
}

.fa-file-slash::before {
  content: "\e3a7";
}

.fa-message-arrow-up-right::before {
  content: "\e1dd";
}

.fa-treasure-chest::before {
  content: "\f723";
}

.fa-chess-queen::before {
  content: "\f445";
}

.fa-paintbrush-fine::before {
  content: "\f5a9";
}

.fa-paint-brush-alt::before {
  content: "\f5a9";
}

.fa-paint-brush-fine::before {
  content: "\f5a9";
}

.fa-paintbrush-alt::before {
  content: "\f5a9";
}

.fa-glasses::before {
  content: "\f530";
}

.fa-hood-cloak::before {
  content: "\f6ef";
}

.fa-square-quote::before {
  content: "\e329";
}

.fa-up-left::before {
  content: "\e2bd";
}

.fa-bring-front::before {
  content: "\f857";
}

.fa-chess-board::before {
  content: "\f43c";
}

.fa-burger-cheese::before {
  content: "\f7f1";
}

.fa-cheeseburger::before {
  content: "\f7f1";
}

.fa-building-circle-check::before {
  content: "\e4d2";
}

.fa-repeat-1::before {
  content: "\f365";
}

.fa-arrow-down-to-line::before {
  content: "\f33d";
}

.fa-arrow-to-bottom::before {
  content: "\f33d";
}

.fa-grid-5::before {
  content: "\e199";
}

.fa-right-long-to-line::before {
  content: "\e444";
}

.fa-person-chalkboard::before {
  content: "\e53d";
}

.fa-mars-stroke-right::before {
  content: "\f22b";
}

.fa-mars-stroke-h::before {
  content: "\f22b";
}

.fa-hand-back-fist::before {
  content: "\f255";
}

.fa-hand-rock::before {
  content: "\f255";
}

.fa-grid-round-5::before {
  content: "\e5de";
}

.fa-tally::before {
  content: "\f69c";
}

.fa-tally-5::before {
  content: "\f69c";
}

.fa-square-caret-up::before {
  content: "\f151";
}

.fa-caret-square-up::before {
  content: "\f151";
}

.fa-cloud-showers-water::before {
  content: "\e4e4";
}

.fa-chart-bar::before {
  content: "\f080";
}

.fa-bar-chart::before {
  content: "\f080";
}

.fa-hands-bubbles::before {
  content: "\e05e";
}

.fa-hands-wash::before {
  content: "\e05e";
}

.fa-less-than-equal::before {
  content: "\f537";
}

.fa-train::before {
  content: "\f238";
}

.fa-up-from-dotted-line::before {
  content: "\e456";
}

.fa-eye-low-vision::before {
  content: "\f2a8";
}

.fa-low-vision::before {
  content: "\f2a8";
}

.fa-traffic-light-go::before {
  content: "\f638";
}

.fa-face-exhaling::before {
  content: "\e480";
}

.fa-sensor-fire::before {
  content: "\e02a";
}

.fa-user-unlock::before {
  content: "\e058";
}

.fa-hexagon-divide::before {
  content: "\e1ad";
}

.fa-00::before {
  content: "\e467";
}

.fa-crow::before {
  content: "\f520";
}

.fa-cassette-betamax::before {
  content: "\f8a4";
}

.fa-betamax::before {
  content: "\f8a4";
}

.fa-sailboat::before {
  content: "\e445";
}

.fa-window-restore::before {
  content: "\f2d2";
}

.fa-nfc-magnifying-glass::before {
  content: "\e1f9";
}

.fa-file-binary::before {
  content: "\e175";
}

.fa-circle-v::before {
  content: "\e12a";
}

.fa-square-plus::before {
  content: "\f0fe";
}

.fa-plus-square::before {
  content: "\f0fe";
}

.fa-bowl-scoops::before {
  content: "\e3df";
}

.fa-mistletoe::before {
  content: "\f7b4";
}

.fa-custard::before {
  content: "\e403";
}

.fa-lacrosse-stick::before {
  content: "\e3b5";
}

.fa-hockey-mask::before {
  content: "\f6ee";
}

.fa-sunrise::before {
  content: "\f766";
}

.fa-panel-ews::before {
  content: "\e42e";
}

.fa-torii-gate::before {
  content: "\f6a1";
}

.fa-cloud-exclamation::before {
  content: "\e491";
}

.fa-message-lines::before {
  content: "\f4a6";
}

.fa-comment-alt-lines::before {
  content: "\f4a6";
}

.fa-frog::before {
  content: "\f52e";
}

.fa-bucket::before {
  content: "\e4cf";
}

.fa-floppy-disk-pen::before {
  content: "\e182";
}

.fa-image::before {
  content: "\f03e";
}

.fa-window-frame::before {
  content: "\e04f";
}

.fa-microphone::before {
  content: "\f130";
}

.fa-cow::before {
  content: "\f6c8";
}

.fa-file-zip::before {
  content: "\e5ee";
}

.fa-square-ring::before {
  content: "\e44f";
}

.fa-down-from-line::before {
  content: "\f349";
}

.fa-arrow-alt-from-top::before {
  content: "\f349";
}

.fa-caret-up::before {
  content: "\f0d8";
}

.fa-shield-xmark::before {
  content: "\e24c";
}

.fa-shield-times::before {
  content: "\e24c";
}

.fa-screwdriver::before {
  content: "\f54a";
}

.fa-circle-sort-down::before {
  content: "\e031";
}

.fa-sort-circle-down::before {
  content: "\e031";
}

.fa-folder-closed::before {
  content: "\e185";
}

.fa-house-tsunami::before {
  content: "\e515";
}

.fa-square-nfi::before {
  content: "\e576";
}

.fa-forklift::before {
  content: "\f47a";
}

.fa-arrow-up-from-ground-water::before {
  content: "\e4b5";
}

.fa-bracket-square-right::before {
  content: "\]";
}

.fa-martini-glass::before {
  content: "\f57b";
}

.fa-glass-martini-alt::before {
  content: "\f57b";
}

.fa-rotate-left::before {
  content: "\f2ea";
}

.fa-rotate-back::before {
  content: "\f2ea";
}

.fa-rotate-backward::before {
  content: "\f2ea";
}

.fa-undo-alt::before {
  content: "\f2ea";
}

.fa-table-columns::before {
  content: "\f0db";
}

.fa-columns::before {
  content: "\f0db";
}

.fa-square-a::before {
  content: "\e25f";
}

.fa-tick::before {
  content: "\e32f";
}

.fa-lemon::before {
  content: "\f094";
}

.fa-head-side-mask::before {
  content: "\e063";
}

.fa-handshake::before {
  content: "\f2b5";
}

.fa-gem::before {
  content: "\f3a5";
}

.fa-dolly::before {
  content: "\f472";
}

.fa-dolly-box::before {
  content: "\f472";
}

.fa-smoking::before {
  content: "\f48d";
}

.fa-minimize::before {
  content: "\f78c";
}

.fa-compress-arrows-alt::before {
  content: "\f78c";
}

.fa-refrigerator::before {
  content: "\e026";
}

.fa-monument::before {
  content: "\f5a6";
}

.fa-octagon-xmark::before {
  content: "\f2f0";
}

.fa-times-octagon::before {
  content: "\f2f0";
}

.fa-xmark-octagon::before {
  content: "\f2f0";
}

.fa-align-slash::before {
  content: "\f846";
}

.fa-snowplow::before {
  content: "\f7d2";
}

.fa-angles-right::before {
  content: "\f101";
}

.fa-angle-double-right::before {
  content: "\f101";
}

.fa-truck-ramp-couch::before {
  content: "\f4dd";
}

.fa-truck-couch::before {
  content: "\f4dd";
}

.fa-cannabis::before {
  content: "\f55f";
}

.fa-circle-play::before {
  content: "\f144";
}

.fa-play-circle::before {
  content: "\f144";
}

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0";
}

.fa-tablets::before {
  content: "\f490";
}

.fa-360-degrees::before {
  content: "\e2dc";
}

.fa-ethernet::before {
  content: "\f796";
}

.fa-euro-sign::before {
  content: "\f153";
}

.fa-eur::before {
  content: "\f153";
}

.fa-euro::before {
  content: "\f153";
}

.fa-chair::before {
  content: "\f6c0";
}

.fa-circle-check::before {
  content: "\f058";
}

.fa-check-circle::before {
  content: "\f058";
}

.fa-square-dashed-circle-plus::before {
  content: "\e5c2";
}

.fa-money-simple-from-bracket::before {
  content: "\e313";
}

.fa-bat::before {
  content: "\f6b5";
}

.fa-circle-stop::before {
  content: "\f28d";
}

.fa-stop-circle::before {
  content: "\f28d";
}

.fa-head-side-headphones::before {
  content: "\f8c2";
}

.fa-phone-rotary::before {
  content: "\f8d3";
}

.fa-compass-drafting::before {
  content: "\f568";
}

.fa-drafting-compass::before {
  content: "\f568";
}

.fa-plate-wheat::before {
  content: "\e55a";
}

.fa-calendar-circle-minus::before {
  content: "\e46f";
}

.fa-chopsticks::before {
  content: "\e3f7";
}

.fa-car-wrench::before {
  content: "\f5e3";
}

.fa-car-mechanic::before {
  content: "\f5e3";
}

.fa-icicles::before {
  content: "\f7ad";
}

.fa-person-shelter::before {
  content: "\e54f";
}

.fa-neuter::before {
  content: "\f22c";
}

.fa-id-badge::before {
  content: "\f2c1";
}

.fa-kazoo::before {
  content: "\f8c7";
}

.fa-marker::before {
  content: "\f5a1";
}

.fa-bin-bottles::before {
  content: "\e5f5";
}

.fa-face-laugh-beam::before {
  content: "\f59a";
}

.fa-laugh-beam::before {
  content: "\f59a";
}

.fa-square-arrow-down-left::before {
  content: "\e261";
}

.fa-battery-bolt::before {
  content: "\f376";
}

.fa-tree-large::before {
  content: "\f7dd";
}

.fa-helicopter-symbol::before {
  content: "\e502";
}

.fa-aperture::before {
  content: "\e2df";
}

.fa-universal-access::before {
  content: "\f29a";
}

.fa-gear-complex::before {
  content: "\e5e9";
}

.fa-file-magnifying-glass::before {
  content: "\f865";
}

.fa-file-search::before {
  content: "\f865";
}

.fa-up-right::before {
  content: "\e2be";
}

.fa-circle-chevron-up::before {
  content: "\f139";
}

.fa-chevron-circle-up::before {
  content: "\f139";
}

.fa-user-police::before {
  content: "\e333";
}

.fa-lari-sign::before {
  content: "\e1c8";
}

.fa-volcano::before {
  content: "\f770";
}

.fa-teddy-bear::before {
  content: "\e3cf";
}

.fa-stocking::before {
  content: "\f7d5";
}

.fa-person-walking-dashed-line-arrow-right::before {
  content: "\e553";
}

.fa-image-slash::before {
  content: "\e1b7";
}

.fa-mask-snorkel::before {
  content: "\e3b7";
}

.fa-smoke::before {
  content: "\f760";
}

.fa-sterling-sign::before {
  content: "\f154";
}

.fa-gbp::before {
  content: "\f154";
}

.fa-pound-sign::before {
  content: "\f154";
}

.fa-battery-exclamation::before {
  content: "\e0b0";
}

.fa-viruses::before {
  content: "\e076";
}

.fa-square-person-confined::before {
  content: "\e577";
}

.fa-user-tie::before {
  content: "\f508";
}

.fa-arrow-down-long::before {
  content: "\f175";
}

.fa-long-arrow-down::before {
  content: "\f175";
}

.fa-tent-arrow-down-to-line::before {
  content: "\e57e";
}

.fa-certificate::before {
  content: "\f0a3";
}

.fa-crystal-ball::before {
  content: "\e362";
}

.fa-reply-all::before {
  content: "\f122";
}

.fa-mail-reply-all::before {
  content: "\f122";
}

.fa-suitcase::before {
  content: "\f0f2";
}

.fa-person-skating::before {
  content: "\f7c5";
}

.fa-skating::before {
  content: "\f7c5";
}

.fa-star-shooting::before {
  content: "\e036";
}

.fa-binary-lock::before {
  content: "\e33d";
}

.fa-filter-circle-dollar::before {
  content: "\f662";
}

.fa-funnel-dollar::before {
  content: "\f662";
}

.fa-camera-retro::before {
  content: "\f083";
}

.fa-circle-arrow-down::before {
  content: "\f0ab";
}

.fa-arrow-circle-down::before {
  content: "\f0ab";
}

.fa-comment-pen::before {
  content: "\f4ae";
}

.fa-comment-edit::before {
  content: "\f4ae";
}

.fa-file-import::before {
  content: "\f56f";
}

.fa-arrow-right-to-file::before {
  content: "\f56f";
}

.fa-banjo::before {
  content: "\f8a3";
}

.fa-square-arrow-up-right::before {
  content: "\f14c";
}

.fa-external-link-square::before {
  content: "\f14c";
}

.fa-light-emergency-on::before {
  content: "\e420";
}

.fa-kerning::before {
  content: "\f86f";
}

.fa-box-open::before {
  content: "\f49e";
}

.fa-square-f::before {
  content: "\e270";
}

.fa-scroll::before {
  content: "\f70e";
}

.fa-spa::before {
  content: "\f5bb";
}

.fa-arrow-left-from-line::before {
  content: "\f344";
}

.fa-arrow-from-right::before {
  content: "\f344";
}

.fa-strawberry::before {
  content: "\e32b";
}

.fa-location-pin-lock::before {
  content: "\e51f";
}

.fa-pause::before {
  content: "\f04c";
}

.fa-clock-eight-thirty::before {
  content: "\e346";
}

.fa-plane-engines::before {
  content: "\f3de";
}

.fa-plane-alt::before {
  content: "\f3de";
}

.fa-hill-avalanche::before {
  content: "\e507";
}

.fa-temperature-empty::before {
  content: "\f2cb";
}

.fa-temperature-0::before {
  content: "\f2cb";
}

.fa-thermometer-0::before {
  content: "\f2cb";
}

.fa-thermometer-empty::before {
  content: "\f2cb";
}

.fa-bomb::before {
  content: "\f1e2";
}

.fa-gauge-low::before {
  content: "\f627";
}

.fa-tachometer-alt-slow::before {
  content: "\f627";
}

.fa-registered::before {
  content: "\f25d";
}

.fa-trash-can-plus::before {
  content: "\e2ac";
}

.fa-address-card::before {
  content: "\f2bb";
}

.fa-contact-card::before {
  content: "\f2bb";
}

.fa-vcard::before {
  content: "\f2bb";
}

.fa-scale-unbalanced-flip::before {
  content: "\f516";
}

.fa-balance-scale-right::before {
  content: "\f516";
}

.fa-globe-snow::before {
  content: "\f7a3";
}

.fa-subscript::before {
  content: "\f12c";
}

.fa-diamond-turn-right::before {
  content: "\f5eb";
}

.fa-directions::before {
  content: "\f5eb";
}

.fa-integral::before {
  content: "\f667";
}

.fa-burst::before {
  content: "\e4dc";
}

.fa-house-laptop::before {
  content: "\e066";
}

.fa-laptop-house::before {
  content: "\e066";
}

.fa-face-tired::before {
  content: "\f5c8";
}

.fa-tired::before {
  content: "\f5c8";
}

.fa-money-bills::before {
  content: "\e1f3";
}

.fa-blinds-raised::before {
  content: "\f8fd";
}

.fa-smog::before {
  content: "\f75f";
}

.fa-ufo-beam::before {
  content: "\e048";
}

.fa-circle-caret-up::before {
  content: "\f331";
}

.fa-caret-circle-up::before {
  content: "\f331";
}

.fa-user-vneck-hair-long::before {
  content: "\e463";
}

.fa-square-a-lock::before {
  content: "\e44d";
}

.fa-crutch::before {
  content: "\f7f7";
}

.fa-gas-pump-slash::before {
  content: "\f5f4";
}

.fa-cloud-arrow-up::before {
  content: "\f0ee";
}

.fa-cloud-upload::before {
  content: "\f0ee";
}

.fa-cloud-upload-alt::before {
  content: "\f0ee";
}

.fa-palette::before {
  content: "\f53f";
}

.fa-transporter-4::before {
  content: "\e2a5";
}

.fa-chart-mixed-up-circle-currency::before {
  content: "\e5d8";
}

.fa-objects-align-right::before {
  content: "\e3bf";
}

.fa-arrows-turn-right::before {
  content: "\e4c0";
}

.fa-vest::before {
  content: "\e085";
}

.fa-pig::before {
  content: "\f706";
}

.fa-inbox-full::before {
  content: "\e1ba";
}

.fa-circle-envelope::before {
  content: "\e10c";
}

.fa-envelope-circle::before {
  content: "\e10c";
}

.fa-triangle-person-digging::before {
  content: "\f85d";
}

.fa-construction::before {
  content: "\f85d";
}

.fa-ferry::before {
  content: "\e4ea";
}

.fa-bullseye-arrow::before {
  content: "\f648";
}

.fa-arrows-down-to-people::before {
  content: "\e4b9";
}

.fa-seedling::before {
  content: "\f4d8";
}

.fa-sprout::before {
  content: "\f4d8";
}

.fa-clock-seven::before {
  content: "\e350";
}

.fa-left-right::before {
  content: "\f337";
}

.fa-arrows-alt-h::before {
  content: "\f337";
}

.fa-boxes-packing::before {
  content: "\e4c7";
}

.fa-circle-arrow-left::before {
  content: "\f0a8";
}

.fa-arrow-circle-left::before {
  content: "\f0a8";
}

.fa-flashlight::before {
  content: "\f8b8";
}

.fa-group-arrows-rotate::before {
  content: "\e4f6";
}

.fa-bowl-food::before {
  content: "\e4c6";
}

.fa-square-9::before {
  content: "\e25e";
}

.fa-candy-cane::before {
  content: "\f786";
}

.fa-arrow-down-wide-short::before {
  content: "\f160";
}

.fa-sort-amount-asc::before {
  content: "\f160";
}

.fa-sort-amount-down::before {
  content: "\f160";
}

.fa-square-dollar::before {
  content: "\f2e9";
}

.fa-dollar-square::before {
  content: "\f2e9";
}

.fa-usd-square::before {
  content: "\f2e9";
}

.fa-phone-arrow-right::before {
  content: "\e5be";
}

.fa-hand-holding-seedling::before {
  content: "\f4bf";
}

.fa-message-check::before {
  content: "\f4a2";
}

.fa-comment-alt-check::before {
  content: "\f4a2";
}

.fa-cloud-bolt::before {
  content: "\f76c";
}

.fa-thunderstorm::before {
  content: "\f76c";
}

.fa-chart-line-up-down::before {
  content: "\e5d7";
}

.fa-text-slash::before {
  content: "\f87d";
}

.fa-remove-format::before {
  content: "\f87d";
}

.fa-watch::before {
  content: "\f2e1";
}

.fa-circle-down-left::before {
  content: "\e107";
}

.fa-text::before {
  content: "\f893";
}

.fa-projector::before {
  content: "\f8d6";
}

.fa-face-smile-wink::before {
  content: "\f4da";
}

.fa-smile-wink::before {
  content: "\f4da";
}

.fa-tombstone-blank::before {
  content: "\f721";
}

.fa-tombstone-alt::before {
  content: "\f721";
}

.fa-chess-king-piece::before {
  content: "\f440";
}

.fa-chess-king-alt::before {
  content: "\f440";
}

.fa-circle-6::before {
  content: "\e0f3";
}

.fa-left::before {
  content: "\f355";
}

.fa-arrow-alt-left::before {
  content: "\f355";
}

.fa-file-word::before {
  content: "\f1c2";
}

.fa-file-powerpoint::before {
  content: "\f1c4";
}

.fa-square-down::before {
  content: "\f350";
}

.fa-arrow-alt-square-down::before {
  content: "\f350";
}

.fa-objects-align-center-vertical::before {
  content: "\e3bd";
}

.fa-arrows-left-right::before {
  content: "\f07e";
}

.fa-arrows-h::before {
  content: "\f07e";
}

.fa-house-lock::before {
  content: "\e510";
}

.fa-cloud-arrow-down::before {
  content: "\f0ed";
}

.fa-cloud-download::before {
  content: "\f0ed";
}

.fa-cloud-download-alt::before {
  content: "\f0ed";
}

.fa-wreath::before {
  content: "\f7e2";
}

.fa-children::before {
  content: "\e4e1";
}

.fa-meter-droplet::before {
  content: "\e1ea";
}

.fa-chalkboard::before {
  content: "\f51b";
}

.fa-blackboard::before {
  content: "\f51b";
}

.fa-user-large-slash::before {
  content: "\f4fa";
}

.fa-user-alt-slash::before {
  content: "\f4fa";
}

.fa-signal-strong::before {
  content: "\f68f";
}

.fa-signal-4::before {
  content: "\f68f";
}

.fa-lollipop::before {
  content: "\e424";
}

.fa-lollypop::before {
  content: "\e424";
}

.fa-list-tree::before {
  content: "\e1d2";
}

.fa-envelope-open::before {
  content: "\f2b6";
}

.fa-draw-circle::before {
  content: "\f5ed";
}

.fa-cat-space::before {
  content: "\e001";
}

.fa-handshake-simple-slash::before {
  content: "\e05f";
}

.fa-handshake-alt-slash::before {
  content: "\e05f";
}

.fa-rabbit-running::before {
  content: "\f709";
}

.fa-rabbit-fast::before {
  content: "\f709";
}

.fa-memo-pad::before {
  content: "\e1da";
}

.fa-mattress-pillow::before {
  content: "\e525";
}

.fa-alarm-plus::before {
  content: "\f844";
}

.fa-alicorn::before {
  content: "\f6b0";
}

.fa-comment-question::before {
  content: "\e14b";
}

.fa-gingerbread-man::before {
  content: "\f79d";
}

.fa-guarani-sign::before {
  content: "\e19a";
}

.fa-burger-fries::before {
  content: "\e0cd";
}

.fa-mug-tea::before {
  content: "\f875";
}

.fa-border-top::before {
  content: "\f855";
}

.fa-arrows-rotate::before {
  content: "\f021";
}

.fa-refresh::before {
  content: "\f021";
}

.fa-sync::before {
  content: "\f021";
}

.fa-circle-book-open::before {
  content: "\e0ff";
}

.fa-book-circle::before {
  content: "\e0ff";
}

.fa-arrows-to-dotted-line::before {
  content: "\e0a6";
}

.fa-fire-extinguisher::before {
  content: "\f134";
}

.fa-garage-open::before {
  content: "\e00b";
}

.fa-shelves-empty::before {
  content: "\e246";
}

.fa-cruzeiro-sign::before {
  content: "\e152";
}

.fa-watch-apple::before {
  content: "\e2cb";
}

.fa-watch-calculator::before {
  content: "\f8f0";
}

.fa-list-dropdown::before {
  content: "\e1cf";
}

.fa-cabinet-filing::before {
  content: "\f64b";
}

.fa-burger-soda::before {
  content: "\f858";
}

.fa-square-arrow-up::before {
  content: "\f33c";
}

.fa-arrow-square-up::before {
  content: "\f33c";
}

.fa-greater-than-equal::before {
  content: "\f532";
}

.fa-pallet-box::before {
  content: "\e208";
}

.fa-face-confounded::before {
  content: "\e36c";
}

.fa-shield-halved::before {
  content: "\f3ed";
}

.fa-shield-alt::before {
  content: "\f3ed";
}

.fa-truck-plow::before {
  content: "\f7de";
}

.fa-book-atlas::before {
  content: "\f558";
}

.fa-atlas::before {
  content: "\f558";
}

.fa-virus::before {
  content: "\e074";
}

.fa-grid-round-2::before {
  content: "\e5db";
}

.fa-comment-middle-top::before {
  content: "\e14a";
}

.fa-envelope-circle-check::before {
  content: "\e4e8";
}

.fa-layer-group::before {
  content: "\f5fd";
}

.fa-restroom-simple::before {
  content: "\e23a";
}

.fa-arrows-to-dot::before {
  content: "\e4be";
}

.fa-border-outer::before {
  content: "\f851";
}

.fa-hashtag-lock::before {
  content: "\e415";
}

.fa-clock-two-thirty::before {
  content: "\e35b";
}

.fa-archway::before {
  content: "\f557";
}

.fa-heart-circle-check::before {
  content: "\e4fd";
}

.fa-house-chimney-crack::before {
  content: "\f6f1";
}

.fa-house-damage::before {
  content: "\f6f1";
}

.fa-file-zipper::before {
  content: "\f1c6";
}

.fa-file-archive::before {
  content: "\f1c6";
}

.fa-heart-half::before {
  content: "\e1ab";
}

.fa-comment-check::before {
  content: "\f4ac";
}

.fa-square::before {
  content: "\f0c8";
}

.fa-memo::before {
  content: "\e1d8";
}

.fa-martini-glass-empty::before {
  content: "\f000";
}

.fa-glass-martini::before {
  content: "\f000";
}

.fa-couch::before {
  content: "\f4b8";
}

.fa-cedi-sign::before {
  content: "\e0df";
}

.fa-italic::before {
  content: "\f033";
}

.fa-glass-citrus::before {
  content: "\f869";
}

.fa-calendar-lines-pen::before {
  content: "\e472";
}

.fa-church::before {
  content: "\f51d";
}

.fa-person-snowmobiling::before {
  content: "\f7d1";
}

.fa-snowmobile::before {
  content: "\f7d1";
}

.fa-face-hushed::before {
  content: "\e37b";
}

.fa-comments-dollar::before {
  content: "\f653";
}

.fa-pickaxe::before {
  content: "\e5bf";
}

.fa-link-simple-slash::before {
  content: "\e1ce";
}

.fa-democrat::before {
  content: "\f747";
}

.fa-face-confused::before {
  content: "\e36d";
}

.fa-pinball::before {
  content: "\e229";
}

.fa-z::before {
  content: "Z";
}

.fa-person-skiing::before {
  content: "\f7c9";
}

.fa-skiing::before {
  content: "\f7c9";
}

.fa-deer::before {
  content: "\f78e";
}

.fa-input-pipe::before {
  content: "\e1be";
}

.fa-road-lock::before {
  content: "\e567";
}

.fa-a::before {
  content: "A";
}

.fa-bookmark-slash::before {
  content: "\e0c2";
}

.fa-temperature-arrow-down::before {
  content: "\e03f";
}

.fa-temperature-down::before {
  content: "\e03f";
}

.fa-mace::before {
  content: "\f6f8";
}

.fa-feather-pointed::before {
  content: "\f56b";
}

.fa-feather-alt::before {
  content: "\f56b";
}

.fa-sausage::before {
  content: "\f820";
}

.fa-trash-can-clock::before {
  content: "\e2aa";
}

.fa-p::before {
  content: "P";
}

.fa-broom-wide::before {
  content: "\e5d1";
}

.fa-snowflake::before {
  content: "\f2dc";
}

.fa-stomach::before {
  content: "\f623";
}

.fa-newspaper::before {
  content: "\f1ea";
}

.fa-rectangle-ad::before {
  content: "\f641";
}

.fa-ad::before {
  content: "\f641";
}

.fa-guitar-electric::before {
  content: "\f8be";
}

.fa-arrow-turn-down-right::before {
  content: "\e3d6";
}

.fa-moon-cloud::before {
  content: "\f754";
}

.fa-bread-slice-butter::before {
  content: "\e3e1";
}

.fa-circle-arrow-right::before {
  content: "\f0a9";
}

.fa-arrow-circle-right::before {
  content: "\f0a9";
}

.fa-user-group-crown::before {
  content: "\f6a5";
}

.fa-users-crown::before {
  content: "\f6a5";
}

.fa-circle-i::before {
  content: "\e111";
}

.fa-toilet-paper-check::before {
  content: "\e5b2";
}

.fa-filter-circle-xmark::before {
  content: "\e17b";
}

.fa-locust::before {
  content: "\e520";
}

.fa-sort::before {
  content: "\f0dc";
}

.fa-unsorted::before {
  content: "\f0dc";
}

.fa-list-ol::before {
  content: "\f0cb";
}

.fa-list-1-2::before {
  content: "\f0cb";
}

.fa-list-numeric::before {
  content: "\f0cb";
}

.fa-chart-waterfall::before {
  content: "\e0eb";
}

.fa-sparkle::before {
  content: "\e5d6";
}

.fa-face-party::before {
  content: "\e383";
}

.fa-kidneys::before {
  content: "\f5fb";
}

.fa-wifi-exclamation::before {
  content: "\e2cf";
}

.fa-chart-network::before {
  content: "\f78a";
}

.fa-person-dress-burst::before {
  content: "\e544";
}

.fa-dice-d4::before {
  content: "\f6d0";
}

.fa-money-check-dollar::before {
  content: "\f53d";
}

.fa-money-check-alt::before {
  content: "\f53d";
}

.fa-vector-square::before {
  content: "\f5cb";
}

.fa-bread-slice::before {
  content: "\f7ec";
}

.fa-language::before {
  content: "\f1ab";
}

.fa-wheat-awn-slash::before {
  content: "\e338";
}

.fa-face-kiss-wink-heart::before {
  content: "\f598";
}

.fa-kiss-wink-heart::before {
  content: "\f598";
}

.fa-dagger::before {
  content: "\f6cb";
}

.fa-podium::before {
  content: "\f680";
}

.fa-memo-circle-check::before {
  content: "\e1d9";
}

.fa-route-highway::before {
  content: "\f61a";
}

.fa-down-to-line::before {
  content: "\f34a";
}

.fa-arrow-alt-to-bottom::before {
  content: "\f34a";
}

.fa-filter::before {
  content: "\f0b0";
}

.fa-square-g::before {
  content: "\e271";
}

.fa-circle-phone::before {
  content: "\e11b";
}

.fa-phone-circle::before {
  content: "\e11b";
}

.fa-clipboard-prescription::before {
  content: "\f5e8";
}

.fa-user-nurse-hair::before {
  content: "\e45d";
}

.fa-question::before {
  content: "\?";
}

.fa-file-signature::before {
  content: "\f573";
}

.fa-toggle-large-on::before {
  content: "\e5b1";
}

.fa-up-down-left-right::before {
  content: "\f0b2";
}

.fa-arrows-alt::before {
  content: "\f0b2";
}

.fa-dryer-heat::before {
  content: "\f862";
}

.fa-dryer-alt::before {
  content: "\f862";
}

.fa-house-chimney-user::before {
  content: "\e065";
}

.fa-hand-holding-heart::before {
  content: "\f4be";
}

.fa-arrow-up-small-big::before {
  content: "\f88f";
}

.fa-sort-size-up-alt::before {
  content: "\f88f";
}

.fa-train-track::before {
  content: "\e453";
}

.fa-puzzle-piece::before {
  content: "\f12e";
}

.fa-money-check::before {
  content: "\f53c";
}

.fa-star-half-stroke::before {
  content: "\f5c0";
}

.fa-star-half-alt::before {
  content: "\f5c0";
}

.fa-file-exclamation::before {
  content: "\f31a";
}

.fa-code::before {
  content: "\f121";
}

.fa-whiskey-glass::before {
  content: "\f7a0";
}

.fa-glass-whiskey::before {
  content: "\f7a0";
}

.fa-moon-stars::before {
  content: "\f755";
}

.fa-building-circle-exclamation::before {
  content: "\e4d3";
}

.fa-clothes-hanger::before {
  content: "\e136";
}

.fa-mobile-notch::before {
  content: "\e1ee";
}

.fa-mobile-iphone::before {
  content: "\e1ee";
}

.fa-magnifying-glass-chart::before {
  content: "\e522";
}

.fa-arrow-up-right-from-square::before {
  content: "\f08e";
}

.fa-external-link::before {
  content: "\f08e";
}

.fa-cubes-stacked::before {
  content: "\e4e6";
}

.fa-images-user::before {
  content: "\e1b9";
}

.fa-won-sign::before {
  content: "\f159";
}

.fa-krw::before {
  content: "\f159";
}

.fa-won::before {
  content: "\f159";
}

.fa-image-polaroid-user::before {
  content: "\e1b6";
}

.fa-virus-covid::before {
  content: "\e4a8";
}

.fa-square-ellipsis::before {
  content: "\e26e";
}

.fa-pie::before {
  content: "\f705";
}

.fa-chess-knight-piece::before {
  content: "\f442";
}

.fa-chess-knight-alt::before {
  content: "\f442";
}

.fa-austral-sign::before {
  content: "\e0a9";
}

.fa-cloud-plus::before {
  content: "\e35e";
}

.fa-f::before {
  content: "F";
}

.fa-leaf::before {
  content: "\f06c";
}

.fa-bed-bunk::before {
  content: "\f8f8";
}

.fa-road::before {
  content: "\f018";
}

.fa-taxi::before {
  content: "\f1ba";
}

.fa-cab::before {
  content: "\f1ba";
}

.fa-person-circle-plus::before {
  content: "\e541";
}

.fa-chart-pie::before {
  content: "\f200";
}

.fa-pie-chart::before {
  content: "\f200";
}

.fa-bolt-lightning::before {
  content: "\e0b7";
}

.fa-clock-eight::before {
  content: "\e345";
}

.fa-sack-xmark::before {
  content: "\e56a";
}

.fa-file-excel::before {
  content: "\f1c3";
}

.fa-file-contract::before {
  content: "\f56c";
}

.fa-fish-fins::before {
  content: "\e4f2";
}

.fa-circle-q::before {
  content: "\e11e";
}

.fa-building-flag::before {
  content: "\e4d5";
}

.fa-face-grin-beam::before {
  content: "\f582";
}

.fa-grin-beam::before {
  content: "\f582";
}

.fa-object-ungroup::before {
  content: "\f248";
}

.fa-face-disguise::before {
  content: "\e370";
}

.fa-circle-arrow-down-right::before {
  content: "\e0fa";
}

.fa-alien-8bit::before {
  content: "\f8f6";
}

.fa-alien-monster::before {
  content: "\f8f6";
}

.fa-hand-point-ribbon::before {
  content: "\e1a6";
}

.fa-poop::before {
  content: "\f619";
}

.fa-object-exclude::before {
  content: "\e49c";
}

.fa-telescope::before {
  content: "\e03e";
}

.fa-location-pin::before {
  content: "\f041";
}

.fa-map-marker::before {
  content: "\f041";
}

.fa-square-list::before {
  content: "\e489";
}

.fa-kaaba::before {
  content: "\f66b";
}

.fa-toilet-paper::before {
  content: "\f71e";
}

.fa-helmet-safety::before {
  content: "\f807";
}

.fa-hard-hat::before {
  content: "\f807";
}

.fa-hat-hard::before {
  content: "\f807";
}

.fa-comment-code::before {
  content: "\e147";
}

.fa-sim-cards::before {
  content: "\e251";
}

.fa-starship::before {
  content: "\e039";
}

.fa-eject::before {
  content: "\f052";
}

.fa-circle-right::before {
  content: "\f35a";
}

.fa-arrow-alt-circle-right::before {
  content: "\f35a";
}

.fa-plane-circle-check::before {
  content: "\e555";
}

.fa-seal::before {
  content: "\e241";
}

.fa-user-cowboy::before {
  content: "\f8ea";
}

.fa-hexagon-vertical-nft::before {
  content: "\e505";
}

.fa-face-rolling-eyes::before {
  content: "\f5a5";
}

.fa-meh-rolling-eyes::before {
  content: "\f5a5";
}

.fa-bread-loaf::before {
  content: "\f7eb";
}

.fa-rings-wedding::before {
  content: "\f81b";
}

.fa-object-group::before {
  content: "\f247";
}

.fa-french-fries::before {
  content: "\f803";
}

.fa-chart-line::before {
  content: "\f201";
}

.fa-line-chart::before {
  content: "\f201";
}

.fa-calendar-arrow-down::before {
  content: "\e0d0";
}

.fa-calendar-download::before {
  content: "\e0d0";
}

.fa-send-back::before {
  content: "\f87e";
}

.fa-mask-ventilator::before {
  content: "\e524";
}

.fa-signature-lock::before {
  content: "\e3ca";
}

.fa-arrow-right::before {
  content: "\f061";
}

.fa-signs-post::before {
  content: "\f277";
}

.fa-map-signs::before {
  content: "\f277";
}

.fa-octagon-plus::before {
  content: "\f301";
}

.fa-plus-octagon::before {
  content: "\f301";
}

.fa-cash-register::before {
  content: "\f788";
}

.fa-person-circle-question::before {
  content: "\e542";
}

.fa-melon-slice::before {
  content: "\e311";
}

.fa-space-station-moon::before {
  content: "\e033";
}

.fa-message-smile::before {
  content: "\f4aa";
}

.fa-comment-alt-smile::before {
  content: "\f4aa";
}

.fa-cup-straw::before {
  content: "\e363";
}

.fa-left-from-line::before {
  content: "\f348";
}

.fa-arrow-alt-from-right::before {
  content: "\f348";
}

.fa-h::before {
  content: "H";
}

.fa-basket-shopping-simple::before {
  content: "\e0af";
}

.fa-shopping-basket-alt::before {
  content: "\e0af";
}

.fa-hands-holding-heart::before {
  content: "\f4c3";
}

.fa-hands-heart::before {
  content: "\f4c3";
}

.fa-clock-nine::before {
  content: "\e34c";
}

.fa-tarp::before {
  content: "\e57b";
}

.fa-face-sleepy::before {
  content: "\e38e";
}

.fa-hand-horns::before {
  content: "\e1a9";
}

.fa-screwdriver-wrench::before {
  content: "\f7d9";
}

.fa-tools::before {
  content: "\f7d9";
}

.fa-arrows-to-eye::before {
  content: "\e4bf";
}

.fa-circle-three-quarters::before {
  content: "\e125";
}

.fa-trophy-star::before {
  content: "\f2eb";
}

.fa-trophy-alt::before {
  content: "\f2eb";
}

.fa-plug-circle-bolt::before {
  content: "\e55b";
}

.fa-face-thermometer::before {
  content: "\e39a";
}

.fa-grid-round-4::before {
  content: "\e5dd";
}

.fa-shirt-running::before {
  content: "\e3c8";
}

.fa-book-circle-arrow-up::before {
  content: "\e0bd";
}

.fa-face-nauseated::before {
  content: "\e381";
}

.fa-heart::before {
  content: "\f004";
}

.fa-file-chart-pie::before {
  content: "\f65a";
}

.fa-mars-and-venus::before {
  content: "\f224";
}

.fa-house-user::before {
  content: "\e1b0";
}

.fa-home-user::before {
  content: "\e1b0";
}

.fa-circle-arrow-down-left::before {
  content: "\e0f9";
}

.fa-dumpster-fire::before {
  content: "\f794";
}

.fa-hexagon-minus::before {
  content: "\f307";
}

.fa-minus-hexagon::before {
  content: "\f307";
}

.fa-left-to-line::before {
  content: "\f34b";
}

.fa-arrow-alt-to-left::before {
  content: "\f34b";
}

.fa-house-crack::before {
  content: "\e3b1";
}

.fa-paw-simple::before {
  content: "\f701";
}

.fa-paw-alt::before {
  content: "\f701";
}

.fa-arrow-left-long-to-line::before {
  content: "\e3d4";
}

.fa-brackets-round::before {
  content: "\e0c5";
}

.fa-parentheses::before {
  content: "\e0c5";
}

.fa-martini-glass-citrus::before {
  content: "\f561";
}

.fa-cocktail::before {
  content: "\f561";
}

.fa-user-shakespeare::before {
  content: "\e2c2";
}

.fa-arrow-right-to-arc::before {
  content: "\e4b2";
}

.fa-face-surprise::before {
  content: "\f5c2";
}

.fa-surprise::before {
  content: "\f5c2";
}

.fa-bottle-water::before {
  content: "\e4c5";
}

.fa-circle-pause::before {
  content: "\f28b";
}

.fa-pause-circle::before {
  content: "\f28b";
}

.fa-gauge-circle-plus::before {
  content: "\e498";
}

.fa-folders::before {
  content: "\f660";
}

.fa-angel::before {
  content: "\f779";
}

.fa-value-absolute::before {
  content: "\f6a6";
}

.fa-rabbit::before {
  content: "\f708";
}

.fa-toilet-paper-slash::before {
  content: "\e072";
}

.fa-circle-euro::before {
  content: "\e5ce";
}

.fa-apple-whole::before {
  content: "\f5d1";
}

.fa-apple-alt::before {
  content: "\f5d1";
}

.fa-kitchen-set::before {
  content: "\e51a";
}

.fa-diamond-half::before {
  content: "\e5b7";
}

.fa-lock-keyhole::before {
  content: "\f30d";
}

.fa-lock-alt::before {
  content: "\f30d";
}

.fa-r::before {
  content: "R";
}

.fa-temperature-quarter::before {
  content: "\f2ca";
}

.fa-temperature-1::before {
  content: "\f2ca";
}

.fa-thermometer-1::before {
  content: "\f2ca";
}

.fa-thermometer-quarter::before {
  content: "\f2ca";
}

.fa-square-info::before {
  content: "\f30f";
}

.fa-info-square::before {
  content: "\f30f";
}

.fa-wifi-slash::before {
  content: "\f6ac";
}

.fa-toilet-paper-xmark::before {
  content: "\e5b3";
}

.fa-hands-holding-dollar::before {
  content: "\f4c5";
}

.fa-hands-usd::before {
  content: "\f4c5";
}

.fa-cube::before {
  content: "\f1b2";
}

.fa-arrow-down-triangle-square::before {
  content: "\f888";
}

.fa-sort-shapes-down::before {
  content: "\f888";
}

.fa-bitcoin-sign::before {
  content: "\e0b4";
}

.fa-shutters::before {
  content: "\e449";
}

.fa-shield-dog::before {
  content: "\e573";
}

.fa-solar-panel::before {
  content: "\f5ba";
}

.fa-lock-open::before {
  content: "\f3c1";
}

.fa-table-tree::before {
  content: "\e293";
}

.fa-house-chimney-heart::before {
  content: "\e1b2";
}

.fa-tally-3::before {
  content: "\e296";
}

.fa-elevator::before {
  content: "\e16d";
}

.fa-money-bill-transfer::before {
  content: "\e528";
}

.fa-money-bill-trend-up::before {
  content: "\e529";
}

.fa-house-flood-water-circle-arrow-right::before {
  content: "\e50f";
}

.fa-square-poll-horizontal::before {
  content: "\f682";
}

.fa-poll-h::before {
  content: "\f682";
}

.fa-circle::before {
  content: "\f111";
}

.fa-cart-circle-exclamation::before {
  content: "\e3f2";
}

.fa-sword::before {
  content: "\f71c";
}

.fa-backward-fast::before {
  content: "\f049";
}

.fa-fast-backward::before {
  content: "\f049";
}

.fa-recycle::before {
  content: "\f1b8";
}

.fa-user-astronaut::before {
  content: "\f4fb";
}

.fa-interrobang::before {
  content: "\e5ba";
}

.fa-plane-slash::before {
  content: "\e069";
}

.fa-circle-dashed::before {
  content: "\e105";
}

.fa-trademark::before {
  content: "\f25c";
}

.fa-basketball::before {
  content: "\f434";
}

.fa-basketball-ball::before {
  content: "\f434";
}

.fa-fork-knife::before {
  content: "\f2e6";
}

.fa-utensils-alt::before {
  content: "\f2e6";
}

.fa-satellite-dish::before {
  content: "\f7c0";
}

.fa-badge-check::before {
  content: "\f336";
}

.fa-circle-up::before {
  content: "\f35b";
}

.fa-arrow-alt-circle-up::before {
  content: "\f35b";
}

.fa-slider::before {
  content: "\e252";
}

.fa-mobile-screen-button::before {
  content: "\f3cd";
}

.fa-mobile-alt::before {
  content: "\f3cd";
}

.fa-clock-one-thirty::before {
  content: "\e34f";
}

.fa-inbox-out::before {
  content: "\f311";
}

.fa-inbox-arrow-up::before {
  content: "\f311";
}

.fa-cloud-slash::before {
  content: "\e137";
}

.fa-volume-high::before {
  content: "\f028";
}

.fa-volume-up::before {
  content: "\f028";
}

.fa-users-rays::before {
  content: "\e593";
}

.fa-wallet::before {
  content: "\f555";
}

.fa-octagon-check::before {
  content: "\e426";
}

.fa-flatbread-stuffed::before {
  content: "\e40c";
}

.fa-clipboard-check::before {
  content: "\f46c";
}

.fa-cart-circle-plus::before {
  content: "\e3f3";
}

.fa-truck-clock::before {
  content: "\f48c";
}

.fa-shipping-timed::before {
  content: "\f48c";
}

.fa-pool-8-ball::before {
  content: "\e3c5";
}

.fa-file-audio::before {
  content: "\f1c7";
}

.fa-turn-down-left::before {
  content: "\e331";
}

.fa-lock-hashtag::before {
  content: "\e423";
}

.fa-chart-radar::before {
  content: "\e0e7";
}

.fa-staff::before {
  content: "\f71b";
}

.fa-burger::before {
  content: "\f805";
}

.fa-hamburger::before {
  content: "\f805";
}

.fa-utility-pole::before {
  content: "\e2c3";
}

.fa-transporter-6::before {
  content: "\e2a7";
}

.fa-wrench::before {
  content: "\f0ad";
}

.fa-bugs::before {
  content: "\e4d0";
}

.fa-vector-polygon::before {
  content: "\e2c7";
}

.fa-diagram-nested::before {
  content: "\e157";
}

.fa-rupee-sign::before {
  content: "\f156";
}

.fa-rupee::before {
  content: "\f156";
}

.fa-file-image::before {
  content: "\f1c5";
}

.fa-circle-question::before {
  content: "\f059";
}

.fa-question-circle::before {
  content: "\f059";
}

.fa-image-user::before {
  content: "\e1b8";
}

.fa-buoy::before {
  content: "\e5b5";
}

.fa-plane-departure::before {
  content: "\f5b0";
}

.fa-handshake-slash::before {
  content: "\e060";
}

.fa-book-bookmark::before {
  content: "\e0bb";
}

.fa-border-center-h::before {
  content: "\f89c";
}

.fa-can-food::before {
  content: "\e3e6";
}

.fa-typewriter::before {
  content: "\f8e7";
}

.fa-arrow-right-from-arc::before {
  content: "\e4b1";
}

.fa-circle-k::before {
  content: "\e113";
}

.fa-face-hand-over-mouth::before {
  content: "\e378";
}

.fa-popcorn::before {
  content: "\f819";
}

.fa-house-water::before {
  content: "\f74f";
}

.fa-house-flood::before {
  content: "\f74f";
}

.fa-object-subtract::before {
  content: "\e49e";
}

.fa-code-branch::before {
  content: "\f126";
}

.fa-warehouse-full::before {
  content: "\f495";
}

.fa-warehouse-alt::before {
  content: "\f495";
}

.fa-hat-cowboy::before {
  content: "\f8c0";
}

.fa-bridge::before {
  content: "\e4c8";
}

.fa-phone-flip::before {
  content: "\f879";
}

.fa-phone-alt::before {
  content: "\f879";
}

.fa-arrow-down-from-dotted-line::before {
  content: "\e090";
}

.fa-file-doc::before {
  content: "\e5ed";
}

.fa-square-quarters::before {
  content: "\e44e";
}

.fa-truck-front::before {
  content: "\e2b7";
}

.fa-cat::before {
  content: "\f6be";
}

.fa-trash-xmark::before {
  content: "\e2b4";
}

.fa-circle-caret-left::before {
  content: "\f32e";
}

.fa-caret-circle-left::before {
  content: "\f32e";
}

.fa-files::before {
  content: "\e178";
}

.fa-anchor-circle-exclamation::before {
  content: "\e4ab";
}

.fa-face-clouds::before {
  content: "\e47d";
}

.fa-user-crown::before {
  content: "\f6a4";
}

.fa-truck-field::before {
  content: "\e58d";
}

.fa-route::before {
  content: "\f4d7";
}

.fa-cart-circle-check::before {
  content: "\e3f1";
}

.fa-clipboard-question::before {
  content: "\e4e3";
}

.fa-panorama::before {
  content: "\e209";
}

.fa-comment-medical::before {
  content: "\f7f5";
}

.fa-teeth-open::before {
  content: "\f62f";
}

.fa-user-tie-hair-long::before {
  content: "\e460";
}

.fa-file-circle-minus::before {
  content: "\e4ed";
}

.fa-head-side-medical::before {
  content: "\f809";
}

.fa-tags::before {
  content: "\f02c";
}

.fa-wine-glass::before {
  content: "\f4e3";
}

.fa-forward-fast::before {
  content: "\f050";
}

.fa-fast-forward::before {
  content: "\f050";
}

.fa-face-meh-blank::before {
  content: "\f5a4";
}

.fa-meh-blank::before {
  content: "\f5a4";
}

.fa-user-robot::before {
  content: "\e04b";
}

.fa-square-parking::before {
  content: "\f540";
}

.fa-parking::before {
  content: "\f540";
}

.fa-card-diamond::before {
  content: "\e3ea";
}

.fa-face-zipper::before {
  content: "\e3a5";
}

.fa-face-raised-eyebrow::before {
  content: "\e388";
}

.fa-house-signal::before {
  content: "\e012";
}

.fa-square-chevron-up::before {
  content: "\f32c";
}

.fa-chevron-square-up::before {
  content: "\f32c";
}

.fa-bars-progress::before {
  content: "\f828";
}

.fa-tasks-alt::before {
  content: "\f828";
}

.fa-faucet-drip::before {
  content: "\e006";
}

.fa-arrows-to-line::before {
  content: "\e0a7";
}

.fa-dolphin::before {
  content: "\e168";
}

.fa-arrow-up-right::before {
  content: "\e09f";
}

.fa-circle-r::before {
  content: "\e120";
}

.fa-cart-flatbed::before {
  content: "\f474";
}

.fa-dolly-flatbed::before {
  content: "\f474";
}

.fa-ban-smoking::before {
  content: "\f54d";
}

.fa-smoking-ban::before {
  content: "\f54d";
}

.fa-circle-sort-up::before {
  content: "\e032";
}

.fa-sort-circle-up::before {
  content: "\e032";
}

.fa-terminal::before {
  content: "\f120";
}

.fa-mobile-button::before {
  content: "\f10b";
}

.fa-house-medical-flag::before {
  content: "\e514";
}

.fa-basket-shopping::before {
  content: "\f291";
}

.fa-shopping-basket::before {
  content: "\f291";
}

.fa-tape::before {
  content: "\f4db";
}

.fa-chestnut::before {
  content: "\e3f6";
}

.fa-bus-simple::before {
  content: "\f55e";
}

.fa-bus-alt::before {
  content: "\f55e";
}

.fa-eye::before {
  content: "\f06e";
}

.fa-face-sad-cry::before {
  content: "\f5b3";
}

.fa-sad-cry::before {
  content: "\f5b3";
}

.fa-heat::before {
  content: "\e00c";
}

.fa-ticket-airline::before {
  content: "\e29a";
}

.fa-boot-heeled::before {
  content: "\e33f";
}

.fa-arrows-minimize::before {
  content: "\e0a5";
}

.fa-compress-arrows::before {
  content: "\e0a5";
}

.fa-audio-description::before {
  content: "\f29e";
}

.fa-person-military-to-person::before {
  content: "\e54c";
}

.fa-file-shield::before {
  content: "\e4f0";
}

.fa-hexagon::before {
  content: "\f312";
}

.fa-manhole::before {
  content: "\e1d6";
}

.fa-user-slash::before {
  content: "\f506";
}

.fa-pen::before {
  content: "\f304";
}

.fa-tower-observation::before {
  content: "\e586";
}

.fa-floppy-disks::before {
  content: "\e183";
}

.fa-toilet-paper-blank-under::before {
  content: "\e29f";
}

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f";
}

.fa-file-code::before {
  content: "\f1c9";
}

.fa-signal::before {
  content: "\f012";
}

.fa-signal-5::before {
  content: "\f012";
}

.fa-signal-perfect::before {
  content: "\f012";
}

.fa-pump::before {
  content: "\e442";
}

.fa-bus::before {
  content: "\f207";
}

.fa-heart-circle-xmark::before {
  content: "\e501";
}

.fa-arrow-up-left-from-circle::before {
  content: "\e09e";
}

.fa-house-chimney::before {
  content: "\e3af";
}

.fa-home-lg::before {
  content: "\e3af";
}

.fa-window-maximize::before {
  content: "\f2d0";
}

.fa-dryer::before {
  content: "\f861";
}

.fa-face-frown::before {
  content: "\f119";
}

.fa-frown::before {
  content: "\f119";
}

.fa-chess-bishop-piece::before {
  content: "\f43b";
}

.fa-chess-bishop-alt::before {
  content: "\f43b";
}

.fa-shirt-tank-top::before {
  content: "\e3c9";
}

.fa-diploma::before {
  content: "\f5ea";
}

.fa-scroll-ribbon::before {
  content: "\f5ea";
}

.fa-screencast::before {
  content: "\e23e";
}

.fa-walker::before {
  content: "\f831";
}

.fa-prescription::before {
  content: "\f5b1";
}

.fa-shop::before {
  content: "\f54f";
}

.fa-store-alt::before {
  content: "\f54f";
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-save::before {
  content: "\f0c7";
}

.fa-vihara::before {
  content: "\f6a7";
}

.fa-face-kiss-closed-eyes::before {
  content: "\e37d";
}

.fa-scale-unbalanced::before {
  content: "\f515";
}

.fa-balance-scale-left::before {
  content: "\f515";
}

.fa-file-user::before {
  content: "\f65c";
}

.fa-user-police-tie::before {
  content: "\e334";
}

.fa-face-tongue-money::before {
  content: "\e39d";
}

.fa-tennis-ball::before {
  content: "\f45e";
}

.fa-square-l::before {
  content: "\e275";
}

.fa-sort-up::before {
  content: "\f0de";
}

.fa-sort-asc::before {
  content: "\f0de";
}

.fa-calendar-arrow-up::before {
  content: "\e0d1";
}

.fa-calendar-upload::before {
  content: "\e0d1";
}

.fa-comment-dots::before {
  content: "\f4ad";
}

.fa-commenting::before {
  content: "\f4ad";
}

.fa-plant-wilt::before {
  content: "\e5aa";
}

.fa-scarf::before {
  content: "\f7c1";
}

.fa-album-circle-plus::before {
  content: "\e48c";
}

.fa-user-nurse-hair-long::before {
  content: "\e45e";
}

.fa-diamond::before {
  content: "\f219";
}

.fa-square-left::before {
  content: "\f351";
}

.fa-arrow-alt-square-left::before {
  content: "\f351";
}

.fa-face-grin-squint::before {
  content: "\f585";
}

.fa-grin-squint::before {
  content: "\f585";
}

.fa-circle-ellipsis-vertical::before {
  content: "\e10b";
}

.fa-hand-holding-dollar::before {
  content: "\f4c0";
}

.fa-hand-holding-usd::before {
  content: "\f4c0";
}

.fa-grid-dividers::before {
  content: "\e3ad";
}

.fa-bacterium::before {
  content: "\e05a";
}

.fa-hand-pointer::before {
  content: "\f25a";
}

.fa-drum-steelpan::before {
  content: "\f56a";
}

.fa-hand-scissors::before {
  content: "\f257";
}

.fa-hands-praying::before {
  content: "\f684";
}

.fa-praying-hands::before {
  content: "\f684";
}

.fa-face-pensive::before {
  content: "\e384";
}

.fa-user-music::before {
  content: "\f8eb";
}

.fa-arrow-rotate-right::before {
  content: "\f01e";
}

.fa-arrow-right-rotate::before {
  content: "\f01e";
}

.fa-arrow-rotate-forward::before {
  content: "\f01e";
}

.fa-redo::before {
  content: "\f01e";
}

.fa-messages-dollar::before {
  content: "\f652";
}

.fa-comments-alt-dollar::before {
  content: "\f652";
}

.fa-sensor-on::before {
  content: "\e02b";
}

.fa-balloon::before {
  content: "\e2e3";
}

.fa-biohazard::before {
  content: "\f780";
}

.fa-chess-queen-piece::before {
  content: "\f446";
}

.fa-chess-queen-alt::before {
  content: "\f446";
}

.fa-location-crosshairs::before {
  content: "\f601";
}

.fa-location::before {
  content: "\f601";
}

.fa-mars-double::before {
  content: "\f227";
}

.fa-house-person-leave::before {
  content: "\e00f";
}

.fa-house-leave::before {
  content: "\e00f";
}

.fa-house-person-depart::before {
  content: "\e00f";
}

.fa-ruler-triangle::before {
  content: "\f61c";
}

.fa-card-club::before {
  content: "\e3e9";
}

.fa-child-dress::before {
  content: "\e59c";
}

.fa-users-between-lines::before {
  content: "\e591";
}

.fa-lungs-virus::before {
  content: "\e067";
}

.fa-spinner-third::before {
  content: "\f3f4";
}

.fa-face-grin-tears::before {
  content: "\f588";
}

.fa-grin-tears::before {
  content: "\f588";
}

.fa-phone::before {
  content: "\f095";
}

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd";
}

.fa-mouse-alt::before {
  content: "\f8cd";
}

.fa-calendar-xmark::before {
  content: "\f273";
}

.fa-calendar-times::before {
  content: "\f273";
}

.fa-child-reaching::before {
  content: "\e59d";
}

.fa-table-layout::before {
  content: "\e290";
}

.fa-narwhal::before {
  content: "\f6fe";
}

.fa-ramp-loading::before {
  content: "\f4d4";
}

.fa-calendar-circle-plus::before {
  content: "\e470";
}

.fa-toothbrush::before {
  content: "\f635";
}

.fa-border-inner::before {
  content: "\f84e";
}

.fa-paw-claws::before {
  content: "\f702";
}

.fa-kiwi-fruit::before {
  content: "\e30c";
}

.fa-traffic-light-slow::before {
  content: "\f639";
}

.fa-rectangle-code::before {
  content: "\e322";
}

.fa-head-side-virus::before {
  content: "\e064";
}

.fa-keyboard-brightness::before {
  content: "\e1c0";
}

.fa-books-medical::before {
  content: "\f7e8";
}

.fa-lightbulb-slash::before {
  content: "\f673";
}

.fa-house-blank::before {
  content: "\e487";
}

.fa-home-blank::before {
  content: "\e487";
}

.fa-square-5::before {
  content: "\e25a";
}

.fa-square-heart::before {
  content: "\f4c8";
}

.fa-heart-square::before {
  content: "\f4c8";
}

.fa-puzzle::before {
  content: "\e443";
}

.fa-user-gear::before {
  content: "\f4fe";
}

.fa-user-cog::before {
  content: "\f4fe";
}

.fa-pipe-circle-check::before {
  content: "\e436";
}

.fa-arrow-up-1-9::before {
  content: "\f163";
}

.fa-sort-numeric-up::before {
  content: "\f163";
}

.fa-octagon-exclamation::before {
  content: "\e204";
}

.fa-dial-low::before {
  content: "\e15d";
}

.fa-door-closed::before {
  content: "\f52a";
}

.fa-laptop-mobile::before {
  content: "\f87a";
}

.fa-phone-laptop::before {
  content: "\f87a";
}

.fa-conveyor-belt-boxes::before {
  content: "\f46f";
}

.fa-conveyor-belt-alt::before {
  content: "\f46f";
}

.fa-shield-virus::before {
  content: "\e06c";
}

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e";
}

.fa-starfighter-alt-advanced::before {
  content: "\e28e";
}

.fa-dice-six::before {
  content: "\f526";
}

.fa-starfighter-twin-ion-engine::before {
  content: "\e038";
}

.fa-starfighter-alt::before {
  content: "\e038";
}

.fa-rocket-launch::before {
  content: "\e027";
}

.fa-mosquito-net::before {
  content: "\e52c";
}

.fa-vent-damper::before {
  content: "\e465";
}

.fa-bridge-water::before {
  content: "\e4ce";
}

.fa-ban-bug::before {
  content: "\f7f9";
}

.fa-debug::before {
  content: "\f7f9";
}

.fa-person-booth::before {
  content: "\f756";
}

.fa-text-width::before {
  content: "\f035";
}

.fa-garage-car::before {
  content: "\e00a";
}

.fa-square-kanban::before {
  content: "\e488";
}

.fa-hat-wizard::before {
  content: "\f6e8";
}

.fa-pen-fancy::before {
  content: "\f5ac";
}

.fa-coffee-pot::before {
  content: "\e002";
}

.fa-mouse-field::before {
  content: "\e5a8";
}

.fa-person-digging::before {
  content: "\f85e";
}

.fa-digging::before {
  content: "\f85e";
}

.fa-shower-down::before {
  content: "\e24d";
}

.fa-shower-alt::before {
  content: "\e24d";
}

.fa-box-circle-check::before {
  content: "\e0c4";
}

.fa-brightness::before {
  content: "\e0c9";
}

.fa-car-side-bolt::before {
  content: "\e344";
}

.fa-ornament::before {
  content: "\f7b8";
}

.fa-phone-arrow-down-left::before {
  content: "\e223";
}

.fa-phone-arrow-down::before {
  content: "\e223";
}

.fa-phone-incoming::before {
  content: "\e223";
}

.fa-cloud-word::before {
  content: "\e138";
}

.fa-hand-fingers-crossed::before {
  content: "\e1a3";
}

.fa-trash::before {
  content: "\f1f8";
}

.fa-gauge-simple::before {
  content: "\f629";
}

.fa-gauge-simple-med::before {
  content: "\f629";
}

.fa-tachometer-average::before {
  content: "\f629";
}

.fa-arrow-down-small-big::before {
  content: "\f88d";
}

.fa-sort-size-down-alt::before {
  content: "\f88d";
}

.fa-book-medical::before {
  content: "\f7e6";
}

.fa-face-melting::before {
  content: "\e483";
}

.fa-poo::before {
  content: "\f2fe";
}

.fa-pen-clip-slash::before {
  content: "\e20f";
}

.fa-pen-alt-slash::before {
  content: "\e20f";
}

.fa-quote-right::before {
  content: "\f10e";
}

.fa-quote-right-alt::before {
  content: "\f10e";
}

.fa-scroll-old::before {
  content: "\f70f";
}

.fa-guitars::before {
  content: "\f8bf";
}

.fa-phone-xmark::before {
  content: "\e227";
}

.fa-hose::before {
  content: "\e419";
}

.fa-clock-six::before {
  content: "\e352";
}

.fa-shirt::before {
  content: "\f553";
}

.fa-t-shirt::before {
  content: "\f553";
}

.fa-tshirt::before {
  content: "\f553";
}

.fa-billboard::before {
  content: "\e5cd";
}

.fa-square-r::before {
  content: "\e27c";
}

.fa-cubes::before {
  content: "\f1b3";
}

.fa-envelope-open-dollar::before {
  content: "\f657";
}

.fa-divide::before {
  content: "\f529";
}

.fa-sun-cloud::before {
  content: "\f763";
}

.fa-lamp-floor::before {
  content: "\e015";
}

.fa-square-7::before {
  content: "\e25c";
}

.fa-tenge-sign::before {
  content: "\f7d7";
}

.fa-tenge::before {
  content: "\f7d7";
}

.fa-headphones::before {
  content: "\f025";
}

.fa-hands-holding::before {
  content: "\f4c2";
}

.fa-campfire::before {
  content: "\f6ba";
}

.fa-circle-ampersand::before {
  content: "\e0f8";
}

.fa-snowflakes::before {
  content: "\f7cf";
}

.fa-hands-clapping::before {
  content: "\e1a8";
}

.fa-republican::before {
  content: "\f75e";
}

.fa-leaf-maple::before {
  content: "\f6f6";
}

.fa-arrow-left::before {
  content: "\f060";
}

.fa-person-circle-xmark::before {
  content: "\e543";
}

.fa-ruler::before {
  content: "\f545";
}

.fa-cup-straw-swoosh::before {
  content: "\e364";
}

.fa-temperature-sun::before {
  content: "\f76a";
}

.fa-temperature-hot::before {
  content: "\f76a";
}

.fa-align-left::before {
  content: "\f036";
}

.fa-dice-d6::before {
  content: "\f6d1";
}

.fa-restroom::before {
  content: "\f7bd";
}

.fa-high-definition::before {
  content: "\e1ae";
}

.fa-rectangle-hd::before {
  content: "\e1ae";
}

.fa-j::before {
  content: "J";
}

.fa-galaxy::before {
  content: "\e008";
}

.fa-users-viewfinder::before {
  content: "\e595";
}

.fa-file-video::before {
  content: "\f1c8";
}

.fa-cherries::before {
  content: "\e0ec";
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-external-link-alt::before {
  content: "\f35d";
}

.fa-circle-sort::before {
  content: "\e030";
}

.fa-sort-circle::before {
  content: "\e030";
}

.fa-table-cells::before {
  content: "\f00a";
}

.fa-th::before {
  content: "\f00a";
}

.fa-file-pdf::before {
  content: "\f1c1";
}

.fa-siren::before {
  content: "\e02d";
}

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1";
}

.fa-image-landscape::before {
  content: "\e1b5";
}

.fa-landscape::before {
  content: "\e1b5";
}

.fa-tank-water::before {
  content: "\e452";
}

.fa-curling-stone::before {
  content: "\f44a";
}

.fa-curling::before {
  content: "\f44a";
}

.fa-gamepad-modern::before {
  content: "\e5a2";
}

.fa-gamepad-alt::before {
  content: "\e5a2";
}

.fa-messages-question::before {
  content: "\e1e7";
}

.fa-book-bible::before {
  content: "\f647";
}

.fa-bible::before {
  content: "\f647";
}

.fa-o::before {
  content: "O";
}

.fa-suitcase-medical::before {
  content: "\f0fa";
}

.fa-medkit::before {
  content: "\f0fa";
}

.fa-briefcase-arrow-right::before {
  content: "\e2f2";
}

.fa-expand-wide::before {
  content: "\f320";
}

.fa-clock-eleven-thirty::before {
  content: "\e348";
}

.fa-rv::before {
  content: "\f7be";
}

.fa-user-secret::before {
  content: "\f21b";
}

.fa-otter::before {
  content: "\f700";
}

.fa-dreidel::before {
  content: "\f792";
}

.fa-person-dress::before {
  content: "\f182";
}

.fa-female::before {
  content: "\f182";
}

.fa-comment-dollar::before {
  content: "\f651";
}

.fa-business-time::before {
  content: "\f64a";
}

.fa-briefcase-clock::before {
  content: "\f64a";
}

.fa-flower-tulip::before {
  content: "\f801";
}

.fa-people-pants-simple::before {
  content: "\e21a";
}

.fa-cloud-drizzle::before {
  content: "\f738";
}

.fa-table-cells-large::before {
  content: "\f009";
}

.fa-th-large::before {
  content: "\f009";
}

.fa-book-tanakh::before {
  content: "\f827";
}

.fa-tanakh::before {
  content: "\f827";
}

.fa-solar-system::before {
  content: "\e02f";
}

.fa-seal-question::before {
  content: "\e243";
}

.fa-phone-volume::before {
  content: "\f2a0";
}

.fa-volume-control-phone::before {
  content: "\f2a0";
}

.fa-disc-drive::before {
  content: "\f8b5";
}

.fa-hat-cowboy-side::before {
  content: "\f8c1";
}

.fa-table-rows::before {
  content: "\e292";
}

.fa-rows::before {
  content: "\e292";
}

.fa-location-exclamation::before {
  content: "\f608";
}

.fa-map-marker-exclamation::before {
  content: "\f608";
}

.fa-face-fearful::before {
  content: "\e375";
}

.fa-clipboard-user::before {
  content: "\f7f3";
}

.fa-bus-school::before {
  content: "\f5dd";
}

.fa-film-slash::before {
  content: "\e179";
}

.fa-square-arrow-down-right::before {
  content: "\e262";
}

.fa-book-sparkles::before {
  content: "\f6b8";
}

.fa-book-spells::before {
  content: "\f6b8";
}

.fa-washing-machine::before {
  content: "\f898";
}

.fa-washer::before {
  content: "\f898";
}

.fa-child::before {
  content: "\f1ae";
}

.fa-lira-sign::before {
  content: "\f195";
}

.fa-user-visor::before {
  content: "\e04c";
}

.fa-file-plus-minus::before {
  content: "\e177";
}

.fa-chess-clock-flip::before {
  content: "\f43e";
}

.fa-chess-clock-alt::before {
  content: "\f43e";
}

.fa-satellite::before {
  content: "\f7bf";
}

.fa-plane-lock::before {
  content: "\e558";
}

.fa-steering-wheel::before {
  content: "\f622";
}

.fa-tag::before {
  content: "\f02b";
}

.fa-stretcher::before {
  content: "\f825";
}

.fa-book-section::before {
  content: "\e0c1";
}

.fa-book-law::before {
  content: "\e0c1";
}

.fa-inboxes::before {
  content: "\e1bb";
}

.fa-coffee-bean::before {
  content: "\e13e";
}

.fa-circle-yen::before {
  content: "\e5d0";
}

.fa-brackets-curly::before {
  content: "\f7ea";
}

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c";
}

.fa-ellipsis-v-alt::before {
  content: "\f39c";
}

.fa-comment::before {
  content: "\f075";
}

.fa-square-1::before {
  content: "\e256";
}

.fa-cake-candles::before {
  content: "\f1fd";
}

.fa-birthday-cake::before {
  content: "\f1fd";
}

.fa-cake::before {
  content: "\f1fd";
}

.fa-head-side::before {
  content: "\f6e9";
}

.fa-envelope::before {
  content: "\f0e0";
}

.fa-dolly-empty::before {
  content: "\f473";
}

.fa-face-tissue::before {
  content: "\e39c";
}

.fa-angles-up::before {
  content: "\f102";
}

.fa-angle-double-up::before {
  content: "\f102";
}

.fa-bin-recycle::before {
  content: "\e5f7";
}

.fa-paperclip::before {
  content: "\f0c6";
}

.fa-chart-line-down::before {
  content: "\f64d";
}

.fa-arrow-right-to-city::before {
  content: "\e4b3";
}

.fa-lock-a::before {
  content: "\e422";
}

.fa-ribbon::before {
  content: "\f4d6";
}

.fa-lungs::before {
  content: "\f604";
}

.fa-person-pinball::before {
  content: "\e21d";
}

.fa-arrow-up-9-1::before {
  content: "\f887";
}

.fa-sort-numeric-up-alt::before {
  content: "\f887";
}

.fa-apple-core::before {
  content: "\e08f";
}

.fa-circle-y::before {
  content: "\e12f";
}

.fa-h6::before {
  content: "\e413";
}

.fa-litecoin-sign::before {
  content: "\e1d3";
}

.fa-circle-small::before {
  content: "\e122";
}

.fa-border-none::before {
  content: "\f850";
}

.fa-arrow-turn-down-left::before {
  content: "\e2e1";
}

.fa-circle-nodes::before {
  content: "\e4e2";
}

.fa-parachute-box::before {
  content: "\f4cd";
}

.fa-message-medical::before {
  content: "\f7f4";
}

.fa-comment-alt-medical::before {
  content: "\f7f4";
}

.fa-rugby-ball::before {
  content: "\e3c6";
}

.fa-comment-music::before {
  content: "\f8b0";
}

.fa-indent::before {
  content: "\f03c";
}

.fa-tree-deciduous::before {
  content: "\f400";
}

.fa-tree-alt::before {
  content: "\f400";
}

.fa-puzzle-piece-simple::before {
  content: "\e231";
}

.fa-puzzle-piece-alt::before {
  content: "\e231";
}

.fa-truck-field-un::before {
  content: "\e58e";
}

.fa-nfc-trash::before {
  content: "\e1fd";
}

.fa-hourglass::before {
  content: "\f254";
}

.fa-hourglass-empty::before {
  content: "\f254";
}

.fa-mountain::before {
  content: "\f6fc";
}

.fa-file-xmark::before {
  content: "\f317";
}

.fa-file-times::before {
  content: "\f317";
}

.fa-house-heart::before {
  content: "\f4c9";
}

.fa-home-heart::before {
  content: "\f4c9";
}

.fa-house-chimney-blank::before {
  content: "\e3b0";
}

.fa-meter-bolt::before {
  content: "\e1e9";
}

.fa-user-doctor::before {
  content: "\f0f0";
}

.fa-user-md::before {
  content: "\f0f0";
}

.fa-slash-back::before {
  content: "\\";
}

.fa-circle-info::before {
  content: "\f05a";
}

.fa-info-circle::before {
  content: "\f05a";
}

.fa-fishing-rod::before {
  content: "\e3a8";
}

.fa-hammer-crash::before {
  content: "\e414";
}

.fa-message-heart::before {
  content: "\e5c9";
}

.fa-cloud-meatball::before {
  content: "\f73b";
}

.fa-camera-polaroid::before {
  content: "\f8aa";
}

.fa-camera::before {
  content: "\f030";
}

.fa-camera-alt::before {
  content: "\f030";
}

.fa-square-virus::before {
  content: "\e578";
}

.fa-cart-arrow-up::before {
  content: "\e3ee";
}

.fa-meteor::before {
  content: "\f753";
}

.fa-car-on::before {
  content: "\e4dd";
}

.fa-sleigh::before {
  content: "\f7cc";
}

.fa-arrow-down-1-9::before {
  content: "\f162";
}

.fa-sort-numeric-asc::before {
  content: "\f162";
}

.fa-sort-numeric-down::before {
  content: "\f162";
}

.fa-buoy-mooring::before {
  content: "\e5b6";
}

.fa-square-4::before {
  content: "\e259";
}

.fa-hand-holding-droplet::before {
  content: "\f4c1";
}

.fa-hand-holding-water::before {
  content: "\f4c1";
}

.fa-tricycle-adult::before {
  content: "\e5c4";
}

.fa-waveform::before {
  content: "\f8f1";
}

.fa-water::before {
  content: "\f773";
}

.fa-star-sharp-half-stroke::before {
  content: "\e28d";
}

.fa-star-sharp-half-alt::before {
  content: "\e28d";
}

.fa-nfc-signal::before {
  content: "\e1fb";
}

.fa-plane-prop::before {
  content: "\e22b";
}

.fa-calendar-check::before {
  content: "\f274";
}

.fa-clock-desk::before {
  content: "\e134";
}

.fa-calendar-clock::before {
  content: "\e0d2";
}

.fa-calendar-time::before {
  content: "\e0d2";
}

.fa-braille::before {
  content: "\f2a1";
}

.fa-prescription-bottle-medical::before {
  content: "\f486";
}

.fa-prescription-bottle-alt::before {
  content: "\f486";
}

.fa-plate-utensils::before {
  content: "\e43b";
}

.fa-family-pants::before {
  content: "\e302";
}

.fa-hose-reel::before {
  content: "\e41a";
}

.fa-house-window::before {
  content: "\e3b3";
}

.fa-landmark::before {
  content: "\f66f";
}

.fa-truck::before {
  content: "\f0d1";
}

.fa-crosshairs::before {
  content: "\f05b";
}

.fa-cloud-rainbow::before {
  content: "\f73e";
}

.fa-person-cane::before {
  content: "\e53c";
}

.fa-alien::before {
  content: "\f8f5";
}

.fa-tent::before {
  content: "\e57d";
}

.fa-laptop-binary::before {
  content: "\e5e7";
}

.fa-vest-patches::before {
  content: "\e086";
}

.fa-people-dress-simple::before {
  content: "\e218";
}

.fa-check-double::before {
  content: "\f560";
}

.fa-arrow-down-a-z::before {
  content: "\f15d";
}

.fa-sort-alpha-asc::before {
  content: "\f15d";
}

.fa-sort-alpha-down::before {
  content: "\f15d";
}

.fa-bowling-ball-pin::before {
  content: "\e0c3";
}

.fa-bell-school-slash::before {
  content: "\f5d6";
}

.fa-plus-large::before {
  content: "\e59e";
}

.fa-money-bill-wheat::before {
  content: "\e52a";
}

.fa-camera-viewfinder::before {
  content: "\e0da";
}

.fa-screenshot::before {
  content: "\e0da";
}

.fa-message-music::before {
  content: "\f8af";
}

.fa-comment-alt-music::before {
  content: "\f8af";
}

.fa-car-building::before {
  content: "\f859";
}

.fa-border-bottom-right::before {
  content: "\f854";
}

.fa-border-style-alt::before {
  content: "\f854";
}

.fa-octagon::before {
  content: "\f306";
}

.fa-comment-arrow-up-right::before {
  content: "\e145";
}

.fa-octagon-divide::before {
  content: "\e203";
}

.fa-cookie::before {
  content: "\f563";
}

.fa-arrow-rotate-left::before {
  content: "\f0e2";
}

.fa-arrow-left-rotate::before {
  content: "\f0e2";
}

.fa-arrow-rotate-back::before {
  content: "\f0e2";
}

.fa-arrow-rotate-backward::before {
  content: "\f0e2";
}

.fa-undo::before {
  content: "\f0e2";
}

.fa-tv-music::before {
  content: "\f8e6";
}

.fa-hard-drive::before {
  content: "\f0a0";
}

.fa-hdd::before {
  content: "\f0a0";
}

.fa-reel::before {
  content: "\e238";
}

.fa-face-grin-squint-tears::before {
  content: "\f586";
}

.fa-grin-squint-tears::before {
  content: "\f586";
}

.fa-dumbbell::before {
  content: "\f44b";
}

.fa-rectangle-list::before {
  content: "\f022";
}

.fa-list-alt::before {
  content: "\f022";
}

.fa-tarp-droplet::before {
  content: "\e57c";
}

.fa-alarm-exclamation::before {
  content: "\f843";
}

.fa-house-medical-circle-check::before {
  content: "\e511";
}

.fa-traffic-cone::before {
  content: "\f636";
}

.fa-grate::before {
  content: "\e193";
}

.fa-arrow-down-right::before {
  content: "\e093";
}

.fa-person-skiing-nordic::before {
  content: "\f7ca";
}

.fa-skiing-nordic::before {
  content: "\f7ca";
}

.fa-calendar-plus::before {
  content: "\f271";
}

.fa-person-from-portal::before {
  content: "\e023";
}

.fa-portal-exit::before {
  content: "\e023";
}

.fa-plane-arrival::before {
  content: "\f5af";
}

.fa-cowbell-circle-plus::before {
  content: "\f8b4";
}

.fa-cowbell-more::before {
  content: "\f8b4";
}

.fa-circle-left::before {
  content: "\f359";
}

.fa-arrow-alt-circle-left::before {
  content: "\f359";
}

.fa-distribute-spacing-vertical::before {
  content: "\e366";
}

.fa-signal-bars-fair::before {
  content: "\f692";
}

.fa-signal-alt-2::before {
  content: "\f692";
}

.fa-sportsball::before {
  content: "\e44b";
}

.fa-game-console-handheld-crank::before {
  content: "\e5b9";
}

.fa-train-subway::before {
  content: "\f239";
}

.fa-subway::before {
  content: "\f239";
}

.fa-chart-gantt::before {
  content: "\e0e4";
}

.fa-face-smile-upside-down::before {
  content: "\e395";
}

.fa-ball-pile::before {
  content: "\f77e";
}

.fa-badge-dollar::before {
  content: "\f645";
}

.fa-money-bills-simple::before {
  content: "\e1f4";
}

.fa-money-bills-alt::before {
  content: "\e1f4";
}

.fa-list-timeline::before {
  content: "\e1d1";
}

.fa-indian-rupee-sign::before {
  content: "\e1bc";
}

.fa-indian-rupee::before {
  content: "\e1bc";
}

.fa-inr::before {
  content: "\e1bc";
}

.fa-crop-simple::before {
  content: "\f565";
}

.fa-crop-alt::before {
  content: "\f565";
}

.fa-money-bill-1::before {
  content: "\f3d1";
}

.fa-money-bill-alt::before {
  content: "\f3d1";
}

.fa-left-long::before {
  content: "\f30a";
}

.fa-long-arrow-alt-left::before {
  content: "\f30a";
}

.fa-keyboard-down::before {
  content: "\e1c2";
}

.fa-circle-up-right::before {
  content: "\e129";
}

.fa-cloud-bolt-moon::before {
  content: "\f76d";
}

.fa-thunderstorm-moon::before {
  content: "\f76d";
}

.fa-dna::before {
  content: "\f471";
}

.fa-virus-slash::before {
  content: "\e075";
}

.fa-bracket-round-right::before {
  content: "\)";
}

.fa-circle-sterling::before {
  content: "\e5cf";
}

.fa-circle-5::before {
  content: "\e0f2";
}

.fa-minus::before {
  content: "\f068";
}

.fa-subtract::before {
  content: "\f068";
}

.fa-fire-flame::before {
  content: "\f6df";
}

.fa-flame::before {
  content: "\f6df";
}

.fa-right-to-line::before {
  content: "\f34c";
}

.fa-arrow-alt-to-right::before {
  content: "\f34c";
}

.fa-gif::before {
  content: "\e190";
}

.fa-chess::before {
  content: "\f439";
}

.fa-trash-slash::before {
  content: "\e2b3";
}

.fa-arrow-left-long::before {
  content: "\f177";
}

.fa-long-arrow-left::before {
  content: "\f177";
}

.fa-plug-circle-check::before {
  content: "\e55c";
}

.fa-font-case::before {
  content: "\f866";
}

.fa-street-view::before {
  content: "\f21d";
}

.fa-arrow-down-left::before {
  content: "\e091";
}

.fa-franc-sign::before {
  content: "\e18f";
}

.fa-flask-round-poison::before {
  content: "\f6e0";
}

.fa-flask-poison::before {
  content: "\f6e0";
}

.fa-volume-off::before {
  content: "\f026";
}

.fa-book-circle-arrow-right::before {
  content: "\e0bc";
}

.fa-chart-user::before {
  content: "\f6a3";
}

.fa-user-chart::before {
  content: "\f6a3";
}

.fa-hands-asl-interpreting::before {
  content: "\f2a3";
}

.fa-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-asl-interpreting::before {
  content: "\f2a3";
}

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-presentation-screen::before {
  content: "\f685";
}

.fa-presentation::before {
  content: "\f685";
}

.fa-circle-bolt::before {
  content: "\e0fe";
}

.fa-face-smile-halo::before {
  content: "\e38f";
}

.fa-cart-circle-arrow-down::before {
  content: "\e3ef";
}

.fa-house-person-return::before {
  content: "\e011";
}

.fa-house-person-arrive::before {
  content: "\e011";
}

.fa-house-return::before {
  content: "\e011";
}

.fa-message-xmark::before {
  content: "\f4ab";
}

.fa-comment-alt-times::before {
  content: "\f4ab";
}

.fa-message-times::before {
  content: "\f4ab";
}

.fa-file-certificate::before {
  content: "\f5f3";
}

.fa-file-award::before {
  content: "\f5f3";
}

.fa-user-doctor-hair-long::before {
  content: "\e459";
}

.fa-camera-security::before {
  content: "\f8fe";
}

.fa-camera-home::before {
  content: "\f8fe";
}

.fa-gear::before {
  content: "\f013";
}

.fa-cog::before {
  content: "\f013";
}

.fa-droplet-slash::before {
  content: "\f5c7";
}

.fa-tint-slash::before {
  content: "\f5c7";
}

.fa-book-heart::before {
  content: "\f499";
}

.fa-mosque::before {
  content: "\f678";
}

.fa-duck::before {
  content: "\f6d8";
}

.fa-mosquito::before {
  content: "\e52b";
}

.fa-star-of-david::before {
  content: "\f69a";
}

.fa-flag-swallowtail::before {
  content: "\f74c";
}

.fa-flag-alt::before {
  content: "\f74c";
}

.fa-person-military-rifle::before {
  content: "\e54b";
}

.fa-car-garage::before {
  content: "\f5e2";
}

.fa-cart-shopping::before {
  content: "\f07a";
}

.fa-shopping-cart::before {
  content: "\f07a";
}

.fa-book-font::before {
  content: "\e0bf";
}

.fa-shield-plus::before {
  content: "\e24a";
}

.fa-vials::before {
  content: "\f493";
}

.fa-eye-dropper-full::before {
  content: "\e172";
}

.fa-distribute-spacing-horizontal::before {
  content: "\e365";
}

.fa-tablet-rugged::before {
  content: "\f48f";
}

.fa-temperature-snow::before {
  content: "\f768";
}

.fa-temperature-frigid::before {
  content: "\f768";
}

.fa-moped::before {
  content: "\e3b9";
}

.fa-face-smile-plus::before {
  content: "\f5b9";
}

.fa-smile-plus::before {
  content: "\f5b9";
}

.fa-radio-tuner::before {
  content: "\f8d8";
}

.fa-radio-alt::before {
  content: "\f8d8";
}

.fa-face-swear::before {
  content: "\e399";
}

.fa-water-arrow-down::before {
  content: "\f774";
}

.fa-water-lower::before {
  content: "\f774";
}

.fa-scanner-touchscreen::before {
  content: "\f48a";
}

.fa-circle-7::before {
  content: "\e0f4";
}

.fa-plug-circle-plus::before {
  content: "\e55f";
}

.fa-person-ski-jumping::before {
  content: "\f7c7";
}

.fa-ski-jump::before {
  content: "\f7c7";
}

.fa-place-of-worship::before {
  content: "\f67f";
}

.fa-water-arrow-up::before {
  content: "\f775";
}

.fa-water-rise::before {
  content: "\f775";
}

.fa-waveform-lines::before {
  content: "\f8f2";
}

.fa-waveform-path::before {
  content: "\f8f2";
}

.fa-split::before {
  content: "\e254";
}

.fa-film-canister::before {
  content: "\f8b7";
}

.fa-film-cannister::before {
  content: "\f8b7";
}

.fa-folder-xmark::before {
  content: "\f65f";
}

.fa-folder-times::before {
  content: "\f65f";
}

.fa-toilet-paper-blank::before {
  content: "\f71f";
}

.fa-toilet-paper-alt::before {
  content: "\f71f";
}

.fa-tablet-screen::before {
  content: "\f3fc";
}

.fa-tablet-android-alt::before {
  content: "\f3fc";
}

.fa-hexagon-vertical-nft-slanted::before {
  content: "\e506";
}

.fa-folder-music::before {
  content: "\e18d";
}

.fa-display-medical::before {
  content: "\e166";
}

.fa-desktop-medical::before {
  content: "\e166";
}

.fa-share-all::before {
  content: "\f367";
}

.fa-peapod::before {
  content: "\e31c";
}

.fa-chess-clock::before {
  content: "\f43d";
}

.fa-axe::before {
  content: "\f6b2";
}

.fa-square-d::before {
  content: "\e268";
}

.fa-grip-vertical::before {
  content: "\f58e";
}

.fa-mobile-signal-out::before {
  content: "\e1f0";
}

.fa-arrow-turn-up::before {
  content: "\f148";
}

.fa-level-up::before {
  content: "\f148";
}

.fa-u::before {
  content: "U";
}

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b";
}

.fa-square-root-variable::before {
  content: "\f698";
}

.fa-square-root-alt::before {
  content: "\f698";
}

.fa-light-switch-on::before {
  content: "\e019";
}

.fa-arrow-down-arrow-up::before {
  content: "\f883";
}

.fa-sort-alt::before {
  content: "\f883";
}

.fa-raindrops::before {
  content: "\f75c";
}

.fa-dash::before {
  content: "\e404";
}

.fa-minus-large::before {
  content: "\e404";
}

.fa-clock::before {
  content: "\f017";
}

.fa-clock-four::before {
  content: "\f017";
}

.fa-input-numeric::before {
  content: "\e1bd";
}

.fa-truck-tow::before {
  content: "\e2b8";
}

.fa-backward-step::before {
  content: "\f048";
}

.fa-step-backward::before {
  content: "\f048";
}

.fa-pallet::before {
  content: "\f482";
}

.fa-car-bolt::before {
  content: "\e341";
}

.fa-arrows-maximize::before {
  content: "\f31d";
}

.fa-expand-arrows::before {
  content: "\f31d";
}

.fa-faucet::before {
  content: "\e005";
}

.fa-cloud-sleet::before {
  content: "\f741";
}

.fa-lamp-street::before {
  content: "\e1c5";
}

.fa-list-radio::before {
  content: "\e1d0";
}

.fa-pen-nib-slash::before {
  content: "\e4a1";
}

.fa-baseball-bat-ball::before {
  content: "\f432";
}

.fa-square-up-left::before {
  content: "\e282";
}

.fa-overline::before {
  content: "\f876";
}

.fa-s::before {
  content: "S";
}

.fa-timeline::before {
  content: "\e29c";
}

.fa-keyboard::before {
  content: "\f11c";
}

.fa-arrows-from-dotted-line::before {
  content: "\e0a3";
}

.fa-usb-drive::before {
  content: "\f8e9";
}

.fa-ballot::before {
  content: "\f732";
}

.fa-caret-down::before {
  content: "\f0d7";
}

.fa-location-dot-slash::before {
  content: "\f605";
}

.fa-map-marker-alt-slash::before {
  content: "\f605";
}

.fa-cards::before {
  content: "\e3ed";
}

.fa-house-chimney-medical::before {
  content: "\f7f2";
}

.fa-clinic-medical::before {
  content: "\f7f2";
}

.fa-boxing-glove::before {
  content: "\f438";
}

.fa-glove-boxing::before {
  content: "\f438";
}

.fa-temperature-three-quarters::before {
  content: "\f2c8";
}

.fa-temperature-3::before {
  content: "\f2c8";
}

.fa-thermometer-3::before {
  content: "\f2c8";
}

.fa-thermometer-three-quarters::before {
  content: "\f2c8";
}

.fa-bell-school::before {
  content: "\f5d5";
}

.fa-mobile-screen::before {
  content: "\f3cf";
}

.fa-mobile-android-alt::before {
  content: "\f3cf";
}

.fa-plane-up::before {
  content: "\e22d";
}

.fa-folder-heart::before {
  content: "\e189";
}

.fa-circle-location-arrow::before {
  content: "\f602";
}

.fa-location-circle::before {
  content: "\f602";
}

.fa-face-head-bandage::before {
  content: "\e37a";
}

.fa-sushi-roll::before {
  content: "\e48b";
}

.fa-maki-roll::before {
  content: "\e48b";
}

.fa-makizushi::before {
  content: "\e48b";
}

.fa-car-bump::before {
  content: "\f5e0";
}

.fa-piggy-bank::before {
  content: "\f4d3";
}

.fa-racquet::before {
  content: "\f45a";
}

.fa-car-mirrors::before {
  content: "\e343";
}

.fa-industry-windows::before {
  content: "\f3b3";
}

.fa-industry-alt::before {
  content: "\f3b3";
}

.fa-bolt-auto::before {
  content: "\e0b6";
}

.fa-battery-half::before {
  content: "\f242";
}

.fa-battery-3::before {
  content: "\f242";
}

.fa-flux-capacitor::before {
  content: "\f8ba";
}

.fa-mountain-city::before {
  content: "\e52e";
}

.fa-coins::before {
  content: "\f51e";
}

.fa-honey-pot::before {
  content: "\e418";
}

.fa-olive::before {
  content: "\e316";
}

.fa-khanda::before {
  content: "\f66d";
}

.fa-filter-list::before {
  content: "\e17c";
}

.fa-outlet::before {
  content: "\e01c";
}

.fa-sliders::before {
  content: "\f1de";
}

.fa-sliders-h::before {
  content: "\f1de";
}

.fa-cauldron::before {
  content: "\f6bf";
}

.fa-people::before {
  content: "\e216";
}

.fa-folder-tree::before {
  content: "\f802";
}

.fa-network-wired::before {
  content: "\f6ff";
}

.fa-croissant::before {
  content: "\f7f6";
}

.fa-map-pin::before {
  content: "\f276";
}

.fa-hamsa::before {
  content: "\f665";
}

.fa-cent-sign::before {
  content: "\e3f5";
}

.fa-swords-laser::before {
  content: "\e03d";
}

.fa-flask::before {
  content: "\f0c3";
}

.fa-person-pregnant::before {
  content: "\e31e";
}

.fa-square-u::before {
  content: "\e281";
}

.fa-wand-sparkles::before {
  content: "\f72b";
}

.fa-router::before {
  content: "\f8da";
}

.fa-ellipsis-vertical::before {
  content: "\f142";
}

.fa-ellipsis-v::before {
  content: "\f142";
}

.fa-sword-laser-alt::before {
  content: "\e03c";
}

.fa-ticket::before {
  content: "\f145";
}

.fa-power-off::before {
  content: "\f011";
}

.fa-coin::before {
  content: "\f85c";
}

.fa-laptop-slash::before {
  content: "\e1c7";
}

.fa-right-long::before {
  content: "\f30b";
}

.fa-long-arrow-alt-right::before {
  content: "\f30b";
}

.fa-circle-b::before {
  content: "\e0fd";
}

.fa-person-dress-simple::before {
  content: "\e21c";
}

.fa-pipe-collar::before {
  content: "\e437";
}

.fa-lights-holiday::before {
  content: "\f7b2";
}

.fa-citrus::before {
  content: "\e2f4";
}

.fa-flag-usa::before {
  content: "\f74d";
}

.fa-laptop-file::before {
  content: "\e51d";
}

.fa-tty::before {
  content: "\f1e4";
}

.fa-teletype::before {
  content: "\f1e4";
}

.fa-chart-tree-map::before {
  content: "\e0ea";
}

.fa-diagram-next::before {
  content: "\e476";
}

.fa-person-rifle::before {
  content: "\e54e";
}

.fa-clock-five-thirty::before {
  content: "\e34a";
}

.fa-pipe-valve::before {
  content: "\e439";
}

.fa-arrow-up-from-arc::before {
  content: "\e4b4";
}

.fa-face-spiral-eyes::before {
  content: "\e485";
}

.fa-compress-wide::before {
  content: "\f326";
}

.fa-circle-phone-hangup::before {
  content: "\e11d";
}

.fa-phone-circle-down::before {
  content: "\e11d";
}

.fa-gear-complex-code::before {
  content: "\e5eb";
}

.fa-house-medical-circle-exclamation::before {
  content: "\e512";
}

.fa-badminton::before {
  content: "\e33a";
}

.fa-closed-captioning::before {
  content: "\f20a";
}

.fa-person-hiking::before {
  content: "\f6ec";
}

.fa-hiking::before {
  content: "\f6ec";
}

.fa-right-from-line::before {
  content: "\f347";
}

.fa-arrow-alt-from-left::before {
  content: "\f347";
}

.fa-venus-double::before {
  content: "\f226";
}

.fa-images::before {
  content: "\f302";
}

.fa-calculator::before {
  content: "\f1ec";
}

.fa-shuttlecock::before {
  content: "\f45b";
}

.fa-user-hair::before {
  content: "\e45a";
}

.fa-eye-evil::before {
  content: "\f6db";
}

.fa-people-pulling::before {
  content: "\e535";
}

.fa-n::before {
  content: "N";
}

.fa-garage::before {
  content: "\e009";
}

.fa-cable-car::before {
  content: "\f7da";
}

.fa-tram::before {
  content: "\f7da";
}

.fa-shovel-snow::before {
  content: "\f7c3";
}

.fa-cloud-rain::before {
  content: "\f73d";
}

.fa-face-lying::before {
  content: "\e37e";
}

.fa-sprinkler::before {
  content: "\e035";
}

.fa-building-circle-xmark::before {
  content: "\e4d4";
}

.fa-person-sledding::before {
  content: "\f7cb";
}

.fa-sledding::before {
  content: "\f7cb";
}

.fa-game-console-handheld::before {
  content: "\f8bb";
}

.fa-ship::before {
  content: "\f21a";
}

.fa-clock-six-thirty::before {
  content: "\e353";
}

.fa-battery-slash::before {
  content: "\f377";
}

.fa-tugrik-sign::before {
  content: "\e2ba";
}

.fa-arrows-down-to-line::before {
  content: "\e4b8";
}

.fa-download::before {
  content: "\f019";
}

.fa-shelves::before {
  content: "\f480";
}

.fa-inventory::before {
  content: "\f480";
}

.fa-cloud-snow::before {
  content: "\f742";
}

.fa-face-grin::before {
  content: "\f580";
}

.fa-grin::before {
  content: "\f580";
}

.fa-delete-left::before {
  content: "\f55a";
}

.fa-backspace::before {
  content: "\f55a";
}

.fa-oven::before {
  content: "\e01d";
}

.fa-cloud-binary::before {
  content: "\e601";
}

.fa-eye-dropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-empty::before {
  content: "\f1fb";
}

.fa-eyedropper::before {
  content: "\f1fb";
}

.fa-comment-captions::before {
  content: "\e146";
}

.fa-comments-question::before {
  content: "\e14e";
}

.fa-scribble::before {
  content: "\e23f";
}

.fa-rotate-exclamation::before {
  content: "\e23c";
}

.fa-file-circle-check::before {
  content: "\e5a0";
}

.fa-glass::before {
  content: "\f804";
}

.fa-loader::before {
  content: "\e1d4";
}

.fa-forward::before {
  content: "\f04e";
}

.fa-user-pilot::before {
  content: "\e2c0";
}

.fa-mobile::before {
  content: "\f3ce";
}

.fa-mobile-android::before {
  content: "\f3ce";
}

.fa-mobile-phone::before {
  content: "\f3ce";
}

.fa-code-pull-request-closed::before {
  content: "\e3f9";
}

.fa-face-meh::before {
  content: "\f11a";
}

.fa-meh::before {
  content: "\f11a";
}

.fa-align-center::before {
  content: "\f037";
}

.fa-book-skull::before {
  content: "\f6b7";
}

.fa-book-dead::before {
  content: "\f6b7";
}

.fa-id-card::before {
  content: "\f2c2";
}

.fa-drivers-license::before {
  content: "\f2c2";
}

.fa-face-dotted::before {
  content: "\e47f";
}

.fa-face-worried::before {
  content: "\e3a3";
}

.fa-outdent::before {
  content: "\f03b";
}

.fa-dedent::before {
  content: "\f03b";
}

.fa-heart-circle-exclamation::before {
  content: "\e4fe";
}

.fa-house::before {
  content: "\f015";
}

.fa-home::before {
  content: "\f015";
}

.fa-home-alt::before {
  content: "\f015";
}

.fa-home-lg-alt::before {
  content: "\f015";
}

.fa-vector-circle::before {
  content: "\e2c6";
}

.fa-car-circle-bolt::before {
  content: "\e342";
}

.fa-calendar-week::before {
  content: "\f784";
}

.fa-flying-disc::before {
  content: "\e3a9";
}

.fa-laptop-medical::before {
  content: "\f812";
}

.fa-square-down-right::before {
  content: "\e26c";
}

.fa-b::before {
  content: "B";
}

.fa-seat-airline::before {
  content: "\e244";
}

.fa-moon-over-sun::before {
  content: "\f74a";
}

.fa-eclipse-alt::before {
  content: "\f74a";
}

.fa-pipe::before {
  content: "\|";
}

.fa-file-medical::before {
  content: "\f477";
}

.fa-potato::before {
  content: "\e440";
}

.fa-dice-one::before {
  content: "\f525";
}

.fa-circle-a::before {
  content: "\e0f7";
}

.fa-helmet-battle::before {
  content: "\f6eb";
}

.fa-butter::before {
  content: "\e3e4";
}

.fa-blanket-fire::before {
  content: "\e3da";
}

.fa-kiwi-bird::before {
  content: "\f535";
}

.fa-castle::before {
  content: "\e0de";
}

.fa-golf-club::before {
  content: "\f451";
}

.fa-arrow-right-arrow-left::before {
  content: "\f0ec";
}

.fa-exchange::before {
  content: "\f0ec";
}

.fa-rotate-right::before {
  content: "\f2f9";
}

.fa-redo-alt::before {
  content: "\f2f9";
}

.fa-rotate-forward::before {
  content: "\f2f9";
}

.fa-utensils::before {
  content: "\f2e7";
}

.fa-cutlery::before {
  content: "\f2e7";
}

.fa-arrow-up-wide-short::before {
  content: "\f161";
}

.fa-sort-amount-up::before {
  content: "\f161";
}

.fa-chart-pie-simple-circle-dollar::before {
  content: "\e605";
}

.fa-balloons::before {
  content: "\e2e4";
}

.fa-mill-sign::before {
  content: "\e1ed";
}

.fa-bowl-rice::before {
  content: "\e2eb";
}

.fa-timeline-arrow::before {
  content: "\e29d";
}

.fa-skull::before {
  content: "\f54c";
}

.fa-game-board-simple::before {
  content: "\f868";
}

.fa-game-board-alt::before {
  content: "\f868";
}

.fa-circle-video::before {
  content: "\e12b";
}

.fa-video-circle::before {
  content: "\e12b";
}

.fa-chart-scatter-bubble::before {
  content: "\e0e9";
}

.fa-house-turret::before {
  content: "\e1b4";
}

.fa-banana::before {
  content: "\e2e5";
}

.fa-hand-holding-skull::before {
  content: "\e1a4";
}

.fa-people-dress::before {
  content: "\e217";
}

.fa-loveseat::before {
  content: "\f4cc";
}

.fa-couch-small::before {
  content: "\f4cc";
}

.fa-tower-broadcast::before {
  content: "\f519";
}

.fa-broadcast-tower::before {
  content: "\f519";
}

.fa-truck-pickup::before {
  content: "\f63c";
}

.fa-block-quote::before {
  content: "\e0b5";
}

.fa-up-long::before {
  content: "\f30c";
}

.fa-long-arrow-alt-up::before {
  content: "\f30c";
}

.fa-stop::before {
  content: "\f04d";
}

.fa-code-merge::before {
  content: "\f387";
}

.fa-money-check-dollar-pen::before {
  content: "\f873";
}

.fa-money-check-edit-alt::before {
  content: "\f873";
}

.fa-up-from-line::before {
  content: "\f346";
}

.fa-arrow-alt-from-bottom::before {
  content: "\f346";
}

.fa-upload::before {
  content: "\f093";
}

.fa-hurricane::before {
  content: "\f751";
}

.fa-grid-round-2-plus::before {
  content: "\e5dc";
}

.fa-people-pants::before {
  content: "\e219";
}

.fa-mound::before {
  content: "\e52d";
}

.fa-windsock::before {
  content: "\f777";
}

.fa-circle-half::before {
  content: "\e110";
}

.fa-brake-warning::before {
  content: "\e0c7";
}

.fa-toilet-portable::before {
  content: "\e583";
}

.fa-compact-disc::before {
  content: "\f51f";
}

.fa-file-arrow-down::before {
  content: "\f56d";
}

.fa-file-download::before {
  content: "\f56d";
}

.fa-saxophone-fire::before {
  content: "\f8db";
}

.fa-sax-hot::before {
  content: "\f8db";
}

.fa-camera-web-slash::before {
  content: "\f833";
}

.fa-webcam-slash::before {
  content: "\f833";
}

.fa-folder-medical::before {
  content: "\e18c";
}

.fa-folder-gear::before {
  content: "\e187";
}

.fa-folder-cog::before {
  content: "\e187";
}

.fa-hand-wave::before {
  content: "\e1a7";
}

.fa-arrow-up-arrow-down::before {
  content: "\e099";
}

.fa-sort-up-down::before {
  content: "\e099";
}

.fa-caravan::before {
  content: "\f8ff";
}

.fa-shield-cat::before {
  content: "\e572";
}

.fa-message-slash::before {
  content: "\f4a9";
}

.fa-comment-alt-slash::before {
  content: "\f4a9";
}

.fa-bolt::before {
  content: "\f0e7";
}

.fa-zap::before {
  content: "\f0e7";
}

.fa-trash-can-check::before {
  content: "\e2a9";
}

.fa-glass-water::before {
  content: "\e4f4";
}

.fa-oil-well::before {
  content: "\e532";
}

.fa-person-simple::before {
  content: "\e220";
}

.fa-vault::before {
  content: "\e2c5";
}

.fa-mars::before {
  content: "\f222";
}

.fa-toilet::before {
  content: "\f7d8";
}

.fa-plane-circle-xmark::before {
  content: "\e557";
}

.fa-yen-sign::before {
  content: "\f157";
}

.fa-cny::before {
  content: "\f157";
}

.fa-jpy::before {
  content: "\f157";
}

.fa-rmb::before {
  content: "\f157";
}

.fa-yen::before {
  content: "\f157";
}

.fa-gear-code::before {
  content: "\e5e8";
}

.fa-notes::before {
  content: "\e202";
}

.fa-ruble-sign::before {
  content: "\f158";
}

.fa-rouble::before {
  content: "\f158";
}

.fa-rub::before {
  content: "\f158";
}

.fa-ruble::before {
  content: "\f158";
}

.fa-trash-undo::before {
  content: "\f895";
}

.fa-trash-arrow-turn-left::before {
  content: "\f895";
}

.fa-champagne-glass::before {
  content: "\f79e";
}

.fa-glass-champagne::before {
  content: "\f79e";
}

.fa-objects-align-center-horizontal::before {
  content: "\e3bc";
}

.fa-sun::before {
  content: "\f185";
}

.fa-trash-can-slash::before {
  content: "\e2ad";
}

.fa-trash-alt-slash::before {
  content: "\e2ad";
}

.fa-screen-users::before {
  content: "\f63d";
}

.fa-users-class::before {
  content: "\f63d";
}

.fa-guitar::before {
  content: "\f7a6";
}

.fa-square-arrow-left::before {
  content: "\f33a";
}

.fa-arrow-square-left::before {
  content: "\f33a";
}

.fa-square-8::before {
  content: "\e25d";
}

.fa-face-smile-hearts::before {
  content: "\e390";
}

.fa-brackets-square::before {
  content: "\f7e9";
}

.fa-brackets::before {
  content: "\f7e9";
}

.fa-laptop-arrow-down::before {
  content: "\e1c6";
}

.fa-hockey-stick-puck::before {
  content: "\e3ae";
}

.fa-house-tree::before {
  content: "\e1b3";
}

.fa-signal-fair::before {
  content: "\f68d";
}

.fa-signal-2::before {
  content: "\f68d";
}

.fa-face-laugh-wink::before {
  content: "\f59c";
}

.fa-laugh-wink::before {
  content: "\f59c";
}

.fa-circle-dollar::before {
  content: "\f2e8";
}

.fa-dollar-circle::before {
  content: "\f2e8";
}

.fa-usd-circle::before {
  content: "\f2e8";
}

.fa-horse-head::before {
  content: "\f7ab";
}

.fa-arrows-repeat::before {
  content: "\f364";
}

.fa-repeat-alt::before {
  content: "\f364";
}

.fa-bore-hole::before {
  content: "\e4c3";
}

.fa-industry::before {
  content: "\f275";
}

.fa-image-polaroid::before {
  content: "\f8c4";
}

.fa-wave-triangle::before {
  content: "\f89a";
}

.fa-person-running-fast::before {
  content: "\e5ff";
}

.fa-circle-down::before {
  content: "\f358";
}

.fa-arrow-alt-circle-down::before {
  content: "\f358";
}

.fa-grill::before {
  content: "\e5a3";
}

.fa-arrows-turn-to-dots::before {
  content: "\e4c1";
}

.fa-chart-mixed::before {
  content: "\f643";
}

.fa-analytics::before {
  content: "\f643";
}

.fa-florin-sign::before {
  content: "\e184";
}

.fa-arrow-down-short-wide::before {
  content: "\f884";
}

.fa-sort-amount-desc::before {
  content: "\f884";
}

.fa-sort-amount-down-alt::before {
  content: "\f884";
}

.fa-less-than::before {
  content: "\<";
}

.fa-display-code::before {
  content: "\e165";
}

.fa-desktop-code::before {
  content: "\e165";
}

.fa-face-drooling::before {
  content: "\e372";
}

.fa-oil-temperature::before {
  content: "\f614";
}

.fa-oil-temp::before {
  content: "\f614";
}

.fa-square-question::before {
  content: "\f2fd";
}

.fa-question-square::before {
  content: "\f2fd";
}

.fa-air-conditioner::before {
  content: "\f8f4";
}

.fa-angle-down::before {
  content: "\f107";
}

.fa-mountains::before {
  content: "\f6fd";
}

.fa-omega::before {
  content: "\f67a";
}

.fa-car-tunnel::before {
  content: "\e4de";
}

.fa-person-dolly-empty::before {
  content: "\f4d1";
}

.fa-pan-food::before {
  content: "\e42b";
}

.fa-head-side-cough::before {
  content: "\e061";
}

.fa-grip-lines::before {
  content: "\f7a4";
}

.fa-thumbs-down::before {
  content: "\f165";
}

.fa-user-lock::before {
  content: "\f502";
}

.fa-arrow-right-long::before {
  content: "\f178";
}

.fa-long-arrow-right::before {
  content: "\f178";
}

.fa-tickets-airline::before {
  content: "\e29b";
}

.fa-anchor-circle-xmark::before {
  content: "\e4ac";
}

.fa-ellipsis::before {
  content: "\f141";
}

.fa-ellipsis-h::before {
  content: "\f141";
}

.fa-nfc-slash::before {
  content: "\e1fc";
}

.fa-chess-pawn::before {
  content: "\f443";
}

.fa-kit-medical::before {
  content: "\f479";
}

.fa-first-aid::before {
  content: "\f479";
}

.fa-grid-2-plus::before {
  content: "\e197";
}

.fa-bells::before {
  content: "\f77f";
}

.fa-person-through-window::before {
  content: "\e5a9";
}

.fa-toolbox::before {
  content: "\f552";
}

.fa-envelope-dot::before {
  content: "\e16f";
}

.fa-envelope-badge::before {
  content: "\e16f";
}

.fa-hands-holding-circle::before {
  content: "\e4fb";
}

.fa-bug::before {
  content: "\f188";
}

.fa-bowl-chopsticks::before {
  content: "\e2e9";
}

.fa-credit-card::before {
  content: "\f09d";
}

.fa-credit-card-alt::before {
  content: "\f09d";
}

.fa-circle-s::before {
  content: "\e121";
}

.fa-box-ballot::before {
  content: "\f735";
}

.fa-car::before {
  content: "\f1b9";
}

.fa-automobile::before {
  content: "\f1b9";
}

.fa-hand-holding-hand::before {
  content: "\e4f7";
}

.fa-user-tie-hair::before {
  content: "\e45f";
}

.fa-podium-star::before {
  content: "\f758";
}

.fa-user-hair-mullet::before {
  content: "\e45c";
}

.fa-business-front::before {
  content: "\e45c";
}

.fa-party-back::before {
  content: "\e45c";
}

.fa-trian-balbot::before {
  content: "\e45c";
}

.fa-microphone-stand::before {
  content: "\f8cb";
}

.fa-book-open-reader::before {
  content: "\f5da";
}

.fa-book-reader::before {
  content: "\f5da";
}

.fa-family-dress::before {
  content: "\e301";
}

.fa-circle-x::before {
  content: "\e12e";
}

.fa-cabin::before {
  content: "\e46d";
}

.fa-mountain-sun::before {
  content: "\e52f";
}

.fa-chart-simple-horizontal::before {
  content: "\e474";
}

.fa-arrows-left-right-to-line::before {
  content: "\e4ba";
}

.fa-hand-back-point-left::before {
  content: "\e19f";
}

.fa-message-dots::before {
  content: "\f4a3";
}

.fa-comment-alt-dots::before {
  content: "\f4a3";
}

.fa-messaging::before {
  content: "\f4a3";
}

.fa-file-heart::before {
  content: "\e176";
}

.fa-beer-mug::before {
  content: "\e0b3";
}

.fa-beer-foam::before {
  content: "\e0b3";
}

.fa-dice-d20::before {
  content: "\f6cf";
}

.fa-drone::before {
  content: "\f85f";
}

.fa-truck-droplet::before {
  content: "\e58c";
}

.fa-file-circle-xmark::before {
  content: "\e5a1";
}

.fa-temperature-arrow-up::before {
  content: "\e040";
}

.fa-temperature-up::before {
  content: "\e040";
}

.fa-medal::before {
  content: "\f5a2";
}

.fa-bed::before {
  content: "\f236";
}

.fa-book-copy::before {
  content: "\e0be";
}

.fa-square-h::before {
  content: "\f0fd";
}

.fa-h-square::before {
  content: "\f0fd";
}

.fa-square-c::before {
  content: "\e266";
}

.fa-clock-two::before {
  content: "\e35a";
}

.fa-square-ellipsis-vertical::before {
  content: "\e26f";
}

.fa-calendar-users::before {
  content: "\e5e2";
}

.fa-podcast::before {
  content: "\f2ce";
}

.fa-bee::before {
  content: "\e0b2";
}

.fa-temperature-full::before {
  content: "\f2c7";
}

.fa-temperature-4::before {
  content: "\f2c7";
}

.fa-thermometer-4::before {
  content: "\f2c7";
}

.fa-thermometer-full::before {
  content: "\f2c7";
}

.fa-bell::before {
  content: "\f0f3";
}

.fa-candy-bar::before {
  content: "\e3e8";
}

.fa-chocolate-bar::before {
  content: "\e3e8";
}

.fa-xmark-large::before {
  content: "\e59b";
}

.fa-pinata::before {
  content: "\e3c3";
}

.fa-arrows-from-line::before {
  content: "\e0a4";
}

.fa-superscript::before {
  content: "\f12b";
}

.fa-bowl-spoon::before {
  content: "\e3e0";
}

.fa-hexagon-check::before {
  content: "\e416";
}

.fa-plug-circle-xmark::before {
  content: "\e560";
}

.fa-star-of-life::before {
  content: "\f621";
}

.fa-phone-slash::before {
  content: "\f3dd";
}

.fa-traffic-light-stop::before {
  content: "\f63a";
}

.fa-paint-roller::before {
  content: "\f5aa";
}

.fa-accent-grave::before {
  content: "\`";
}

.fa-handshake-angle::before {
  content: "\f4c4";
}

.fa-hands-helping::before {
  content: "\f4c4";
}

.fa-circle-0::before {
  content: "\e0ed";
}

.fa-dial-med-low::before {
  content: "\e160";
}

.fa-location-dot::before {
  content: "\f3c5";
}

.fa-map-marker-alt::before {
  content: "\f3c5";
}

.fa-crab::before {
  content: "\e3ff";
}

.fa-box-open-full::before {
  content: "\f49c";
}

.fa-box-full::before {
  content: "\f49c";
}

.fa-file::before {
  content: "\f15b";
}

.fa-greater-than::before {
  content: "\>";
}

.fa-quotes::before {
  content: "\e234";
}

.fa-pretzel::before {
  content: "\e441";
}

.fa-person-swimming::before {
  content: "\f5c4";
}

.fa-swimmer::before {
  content: "\f5c4";
}

.fa-arrow-down::before {
  content: "\f063";
}

.fa-user-robot-xmarks::before {
  content: "\e4a7";
}

.fa-message-quote::before {
  content: "\e1e4";
}

.fa-comment-alt-quote::before {
  content: "\e1e4";
}

.fa-candy-corn::before {
  content: "\f6bd";
}

.fa-folder-magnifying-glass::before {
  content: "\e18b";
}

.fa-folder-search::before {
  content: "\e18b";
}

.fa-notebook::before {
  content: "\e201";
}

.fa-droplet::before {
  content: "\f043";
}

.fa-tint::before {
  content: "\f043";
}

.fa-bullseye-pointer::before {
  content: "\f649";
}

.fa-eraser::before {
  content: "\f12d";
}

.fa-hexagon-image::before {
  content: "\e504";
}

.fa-earth-americas::before {
  content: "\f57d";
}

.fa-earth::before {
  content: "\f57d";
}

.fa-earth-america::before {
  content: "\f57d";
}

.fa-globe-americas::before {
  content: "\f57d";
}

.fa-crate-apple::before {
  content: "\f6b1";
}

.fa-apple-crate::before {
  content: "\f6b1";
}

.fa-person-burst::before {
  content: "\e53b";
}

.fa-game-board::before {
  content: "\f867";
}

.fa-hat-chef::before {
  content: "\f86b";
}

.fa-hand-back-point-right::before {
  content: "\e1a1";
}

.fa-dove::before {
  content: "\f4ba";
}

.fa-snowflake-droplets::before {
  content: "\e5c1";
}

.fa-battery-empty::before {
  content: "\f244";
}

.fa-battery-0::before {
  content: "\f244";
}

.fa-grid-4::before {
  content: "\e198";
}

.fa-socks::before {
  content: "\f696";
}

.fa-face-sunglasses::before {
  content: "\e398";
}

.fa-inbox::before {
  content: "\f01c";
}

.fa-square-0::before {
  content: "\e255";
}

.fa-section::before {
  content: "\e447";
}

.fa-square-this-way-up::before {
  content: "\f49f";
}

.fa-box-up::before {
  content: "\f49f";
}

.fa-gauge-high::before {
  content: "\f625";
}

.fa-tachometer-alt::before {
  content: "\f625";
}

.fa-tachometer-alt-fast::before {
  content: "\f625";
}

.fa-square-ampersand::before {
  content: "\e260";
}

.fa-envelope-open-text::before {
  content: "\f658";
}

.fa-lamp-desk::before {
  content: "\e014";
}

.fa-hospital::before {
  content: "\f0f8";
}

.fa-hospital-alt::before {
  content: "\f0f8";
}

.fa-hospital-wide::before {
  content: "\f0f8";
}

.fa-poll-people::before {
  content: "\f759";
}

.fa-whiskey-glass-ice::before {
  content: "\f7a1";
}

.fa-glass-whiskey-rocks::before {
  content: "\f7a1";
}

.fa-wine-bottle::before {
  content: "\f72f";
}

.fa-chess-rook::before {
  content: "\f447";
}

.fa-user-bounty-hunter::before {
  content: "\e2bf";
}

.fa-bars-staggered::before {
  content: "\f550";
}

.fa-reorder::before {
  content: "\f550";
}

.fa-stream::before {
  content: "\f550";
}

.fa-diagram-sankey::before {
  content: "\e158";
}

.fa-cloud-hail-mixed::before {
  content: "\f73a";
}

.fa-circle-up-left::before {
  content: "\e128";
}

.fa-dharmachakra::before {
  content: "\f655";
}

.fa-objects-align-left::before {
  content: "\e3be";
}

.fa-oil-can-drip::before {
  content: "\e205";
}

.fa-face-smiling-hands::before {
  content: "\e396";
}

.fa-broccoli::before {
  content: "\e3e2";
}

.fa-route-interstate::before {
  content: "\f61b";
}

.fa-ear-muffs::before {
  content: "\f795";
}

.fa-hotdog::before {
  content: "\f80f";
}

.fa-transporter-empty::before {
  content: "\e046";
}

.fa-person-walking-with-cane::before {
  content: "\f29d";
}

.fa-blind::before {
  content: "\f29d";
}

.fa-angle-90::before {
  content: "\e08d";
}

.fa-rectangle-terminal::before {
  content: "\e236";
}

.fa-kite::before {
  content: "\f6f4";
}

.fa-drum::before {
  content: "\f569";
}

.fa-scrubber::before {
  content: "\f2f8";
}

.fa-ice-cream::before {
  content: "\f810";
}

.fa-heart-circle-bolt::before {
  content: "\e4fc";
}

.fa-fish-bones::before {
  content: "\e304";
}

.fa-deer-rudolph::before {
  content: "\f78f";
}

.fa-fax::before {
  content: "\f1ac";
}

.fa-paragraph::before {
  content: "\f1dd";
}

.fa-head-side-heart::before {
  content: "\e1aa";
}

.fa-square-e::before {
  content: "\e26d";
}

.fa-meter-fire::before {
  content: "\e1eb";
}

.fa-cloud-hail::before {
  content: "\f739";
}

.fa-check-to-slot::before {
  content: "\f772";
}

.fa-vote-yea::before {
  content: "\f772";
}

.fa-money-from-bracket::before {
  content: "\e312";
}

.fa-star-half::before {
  content: "\f089";
}

.fa-car-bus::before {
  content: "\f85a";
}

.fa-speaker::before {
  content: "\f8df";
}

.fa-timer::before {
  content: "\e29e";
}

.fa-boxes-stacked::before {
  content: "\f468";
}

.fa-boxes::before {
  content: "\f468";
}

.fa-boxes-alt::before {
  content: "\f468";
}

.fa-grill-hot::before {
  content: "\e5a5";
}

.fa-ballot-check::before {
  content: "\f733";
}

.fa-link::before {
  content: "\f0c1";
}

.fa-chain::before {
  content: "\f0c1";
}

.fa-ear-listen::before {
  content: "\f2a2";
}

.fa-assistive-listening-systems::before {
  content: "\f2a2";
}

.fa-file-minus::before {
  content: "\f318";
}

.fa-tree-city::before {
  content: "\e587";
}

.fa-play::before {
  content: "\f04b";
}

.fa-font::before {
  content: "\f031";
}

.fa-cup-togo::before {
  content: "\f6c5";
}

.fa-coffee-togo::before {
  content: "\f6c5";
}

.fa-square-down-left::before {
  content: "\e26b";
}

.fa-burger-lettuce::before {
  content: "\e3e3";
}

.fa-rupiah-sign::before {
  content: "\e23d";
}

.fa-magnifying-glass::before {
  content: "\f002";
}

.fa-search::before {
  content: "\f002";
}

.fa-table-tennis-paddle-ball::before {
  content: "\f45d";
}

.fa-ping-pong-paddle-ball::before {
  content: "\f45d";
}

.fa-table-tennis::before {
  content: "\f45d";
}

.fa-person-dots-from-line::before {
  content: "\f470";
}

.fa-diagnoses::before {
  content: "\f470";
}

.fa-chevrons-down::before {
  content: "\f322";
}

.fa-chevron-double-down::before {
  content: "\f322";
}

.fa-trash-can-arrow-up::before {
  content: "\f82a";
}

.fa-trash-restore-alt::before {
  content: "\f82a";
}

.fa-signal-good::before {
  content: "\f68e";
}

.fa-signal-3::before {
  content: "\f68e";
}

.fa-location-question::before {
  content: "\f60b";
}

.fa-map-marker-question::before {
  content: "\f60b";
}

.fa-floppy-disk-circle-xmark::before {
  content: "\e181";
}

.fa-floppy-disk-times::before {
  content: "\e181";
}

.fa-save-circle-xmark::before {
  content: "\e181";
}

.fa-save-times::before {
  content: "\e181";
}

.fa-naira-sign::before {
  content: "\e1f6";
}

.fa-peach::before {
  content: "\e20b";
}

.fa-taxi-bus::before {
  content: "\e298";
}

.fa-bracket-curly::before {
  content: "\{";
}

.fa-bracket-curly-left::before {
  content: "\{";
}

.fa-lobster::before {
  content: "\e421";
}

.fa-cart-flatbed-empty::before {
  content: "\f476";
}

.fa-dolly-flatbed-empty::before {
  content: "\f476";
}

.fa-colon::before {
  content: "\:";
}

.fa-cart-arrow-down::before {
  content: "\f218";
}

.fa-wand::before {
  content: "\f72a";
}

.fa-walkie-talkie::before {
  content: "\f8ef";
}

.fa-file-pen::before {
  content: "\f31c";
}

.fa-file-edit::before {
  content: "\f31c";
}

.fa-receipt::before {
  content: "\f543";
}

.fa-table-picnic::before {
  content: "\e32d";
}

.fa-square-pen::before {
  content: "\f14b";
}

.fa-pen-square::before {
  content: "\f14b";
}

.fa-pencil-square::before {
  content: "\f14b";
}

.fa-circle-microphone-lines::before {
  content: "\e117";
}

.fa-microphone-circle-alt::before {
  content: "\e117";
}

.fa-display-slash::before {
  content: "\e2fa";
}

.fa-desktop-slash::before {
  content: "\e2fa";
}

.fa-suitcase-rolling::before {
  content: "\f5c1";
}

.fa-person-circle-exclamation::before {
  content: "\e53f";
}

.fa-transporter-2::before {
  content: "\e044";
}

.fa-hands-holding-diamond::before {
  content: "\f47c";
}

.fa-hand-receiving::before {
  content: "\f47c";
}

.fa-money-bill-simple-wave::before {
  content: "\e1f2";
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-battery-full::before {
  content: "\f240";
}

.fa-battery::before {
  content: "\f240";
}

.fa-battery-5::before {
  content: "\f240";
}

.fa-bell-plus::before {
  content: "\f849";
}

.fa-book-arrow-right::before {
  content: "\e0b9";
}

.fa-hospitals::before {
  content: "\f80e";
}

.fa-club::before {
  content: "\f327";
}

.fa-skull-crossbones::before {
  content: "\f714";
}

.fa-droplet-degree::before {
  content: "\f748";
}

.fa-dewpoint::before {
  content: "\f748";
}

.fa-code-compare::before {
  content: "\e13a";
}

.fa-list-ul::before {
  content: "\f0ca";
}

.fa-list-dots::before {
  content: "\f0ca";
}

.fa-hand-holding-magic::before {
  content: "\f6e5";
}

.fa-watermelon-slice::before {
  content: "\e337";
}

.fa-circle-ellipsis::before {
  content: "\e10a";
}

.fa-school-lock::before {
  content: "\e56f";
}

.fa-tower-cell::before {
  content: "\e585";
}

.fa-sd-cards::before {
  content: "\e240";
}

.fa-jug-bottle::before {
  content: "\e5fb";
}

.fa-down-long::before {
  content: "\f309";
}

.fa-long-arrow-alt-down::before {
  content: "\f309";
}

.fa-envelopes::before {
  content: "\e170";
}

.fa-phone-office::before {
  content: "\f67d";
}

.fa-ranking-star::before {
  content: "\e561";
}

.fa-chess-king::before {
  content: "\f43f";
}

.fa-nfc-pen::before {
  content: "\e1fa";
}

.fa-person-harassing::before {
  content: "\e549";
}

.fa-hat-winter::before {
  content: "\f7a8";
}

.fa-brazilian-real-sign::before {
  content: "\e46c";
}

.fa-landmark-dome::before {
  content: "\f752";
}

.fa-landmark-alt::before {
  content: "\f752";
}

.fa-bone-break::before {
  content: "\f5d8";
}

.fa-arrow-up::before {
  content: "\f062";
}

.fa-down-from-dotted-line::before {
  content: "\e407";
}

.fa-tv::before {
  content: "\f26c";
}

.fa-television::before {
  content: "\f26c";
}

.fa-tv-alt::before {
  content: "\f26c";
}

.fa-border-left::before {
  content: "\f84f";
}

.fa-circle-divide::before {
  content: "\e106";
}

.fa-shrimp::before {
  content: "\e448";
}

.fa-list-check::before {
  content: "\f0ae";
}

.fa-tasks::before {
  content: "\f0ae";
}

.fa-diagram-subtask::before {
  content: "\e479";
}

.fa-jug-detergent::before {
  content: "\e519";
}

.fa-circle-user::before {
  content: "\f2bd";
}

.fa-user-circle::before {
  content: "\f2bd";
}

.fa-square-y::before {
  content: "\e287";
}

.fa-user-doctor-hair::before {
  content: "\e458";
}

.fa-planet-ringed::before {
  content: "\e020";
}

.fa-mushroom::before {
  content: "\e425";
}

.fa-user-shield::before {
  content: "\f505";
}

.fa-megaphone::before {
  content: "\f675";
}

.fa-wreath-laurel::before {
  content: "\e5d2";
}

.fa-circle-exclamation-check::before {
  content: "\e10d";
}

.fa-wind::before {
  content: "\f72e";
}

.fa-box-dollar::before {
  content: "\f4a0";
}

.fa-box-usd::before {
  content: "\f4a0";
}

.fa-car-burst::before {
  content: "\f5e1";
}

.fa-car-crash::before {
  content: "\f5e1";
}

.fa-y::before {
  content: "Y";
}

.fa-user-headset::before {
  content: "\f82d";
}

.fa-arrows-retweet::before {
  content: "\f361";
}

.fa-retweet-alt::before {
  content: "\f361";
}

.fa-person-snowboarding::before {
  content: "\f7ce";
}

.fa-snowboarding::before {
  content: "\f7ce";
}

.fa-square-chevron-right::before {
  content: "\f32b";
}

.fa-chevron-square-right::before {
  content: "\f32b";
}

.fa-lacrosse-stick-ball::before {
  content: "\e3b6";
}

.fa-truck-fast::before {
  content: "\f48b";
}

.fa-shipping-fast::before {
  content: "\f48b";
}

.fa-user-magnifying-glass::before {
  content: "\e5c5";
}

.fa-star-sharp::before {
  content: "\e28b";
}

.fa-comment-heart::before {
  content: "\e5c8";
}

.fa-circle-1::before {
  content: "\e0ee";
}

.fa-circle-star::before {
  content: "\e123";
}

.fa-star-circle::before {
  content: "\e123";
}

.fa-fish::before {
  content: "\f578";
}

.fa-cloud-fog::before {
  content: "\f74e";
}

.fa-fog::before {
  content: "\f74e";
}

.fa-waffle::before {
  content: "\e466";
}

.fa-music-note::before {
  content: "\f8cf";
}

.fa-music-alt::before {
  content: "\f8cf";
}

.fa-hexagon-exclamation::before {
  content: "\e417";
}

.fa-cart-shopping-fast::before {
  content: "\e0dc";
}

.fa-object-union::before {
  content: "\e49f";
}

.fa-user-graduate::before {
  content: "\f501";
}

.fa-starfighter::before {
  content: "\e037";
}

.fa-circle-half-stroke::before {
  content: "\f042";
}

.fa-adjust::before {
  content: "\f042";
}

.fa-arrow-right-long-to-line::before {
  content: "\e3d5";
}

.fa-square-arrow-down::before {
  content: "\f339";
}

.fa-arrow-square-down::before {
  content: "\f339";
}

.fa-diamond-half-stroke::before {
  content: "\e5b8";
}

.fa-clapperboard::before {
  content: "\e131";
}

.fa-square-chevron-left::before {
  content: "\f32a";
}

.fa-chevron-square-left::before {
  content: "\f32a";
}

.fa-phone-intercom::before {
  content: "\e434";
}

.fa-link-horizontal::before {
  content: "\e1cb";
}

.fa-chain-horizontal::before {
  content: "\e1cb";
}

.fa-mango::before {
  content: "\e30f";
}

.fa-music-note-slash::before {
  content: "\f8d0";
}

.fa-music-alt-slash::before {
  content: "\f8d0";
}

.fa-circle-radiation::before {
  content: "\f7ba";
}

.fa-radiation-alt::before {
  content: "\f7ba";
}

.fa-face-tongue-sweat::before {
  content: "\e39e";
}

.fa-globe-stand::before {
  content: "\f5f6";
}

.fa-baseball::before {
  content: "\f433";
}

.fa-baseball-ball::before {
  content: "\f433";
}

.fa-circle-p::before {
  content: "\e11a";
}

.fa-award-simple::before {
  content: "\e0ab";
}

.fa-jet-fighter-up::before {
  content: "\e518";
}

.fa-diagram-project::before {
  content: "\f542";
}

.fa-project-diagram::before {
  content: "\f542";
}

.fa-pedestal::before {
  content: "\e20d";
}

.fa-chart-pyramid::before {
  content: "\e0e6";
}

.fa-sidebar::before {
  content: "\e24e";
}

.fa-snowman-head::before {
  content: "\f79b";
}

.fa-frosty-head::before {
  content: "\f79b";
}

.fa-copy::before {
  content: "\f0c5";
}

.fa-burger-glass::before {
  content: "\e0ce";
}

.fa-volume-xmark::before {
  content: "\f6a9";
}

.fa-volume-mute::before {
  content: "\f6a9";
}

.fa-volume-times::before {
  content: "\f6a9";
}

.fa-hand-sparkles::before {
  content: "\e05d";
}

.fa-bars-filter::before {
  content: "\e0ad";
}

.fa-paintbrush-pencil::before {
  content: "\e206";
}

.fa-party-bell::before {
  content: "\e31a";
}

.fa-user-vneck-hair::before {
  content: "\e462";
}

.fa-jack-o-lantern::before {
  content: "\f30e";
}

.fa-grip::before {
  content: "\f58d";
}

.fa-grip-horizontal::before {
  content: "\f58d";
}

.fa-share-from-square::before {
  content: "\f14d";
}

.fa-share-square::before {
  content: "\f14d";
}

.fa-keynote::before {
  content: "\f66c";
}

.fa-child-combatant::before {
  content: "\e4e0";
}

.fa-child-rifle::before {
  content: "\e4e0";
}

.fa-gun::before {
  content: "\e19b";
}

.fa-square-phone::before {
  content: "\f098";
}

.fa-phone-square::before {
  content: "\f098";
}

.fa-hat-beach::before {
  content: "\e606";
}

.fa-plus::before {
  content: "\+";
}

.fa-add::before {
  content: "\+";
}

.fa-expand::before {
  content: "\f065";
}

.fa-computer::before {
  content: "\e4e5";
}

.fa-fort::before {
  content: "\e486";
}

.fa-cloud-check::before {
  content: "\e35c";
}

.fa-xmark::before {
  content: "\f00d";
}

.fa-close::before {
  content: "\f00d";
}

.fa-multiply::before {
  content: "\f00d";
}

.fa-remove::before {
  content: "\f00d";
}

.fa-times::before {
  content: "\f00d";
}

.fa-face-smirking::before {
  content: "\e397";
}

.fa-arrows-up-down-left-right::before {
  content: "\f047";
}

.fa-arrows::before {
  content: "\f047";
}

.fa-chalkboard-user::before {
  content: "\f51c";
}

.fa-chalkboard-teacher::before {
  content: "\f51c";
}

.fa-rhombus::before {
  content: "\e23b";
}

.fa-claw-marks::before {
  content: "\f6c2";
}

.fa-peso-sign::before {
  content: "\e222";
}

.fa-face-smile-tongue::before {
  content: "\e394";
}

.fa-cart-circle-xmark::before {
  content: "\e3f4";
}

.fa-building-shield::before {
  content: "\e4d8";
}

.fa-circle-phone-flip::before {
  content: "\e11c";
}

.fa-phone-circle-alt::before {
  content: "\e11c";
}

.fa-baby::before {
  content: "\f77c";
}

.fa-users-line::before {
  content: "\e592";
}

.fa-quote-left::before {
  content: "\f10d";
}

.fa-quote-left-alt::before {
  content: "\f10d";
}

.fa-tractor::before {
  content: "\f722";
}

.fa-key-skeleton::before {
  content: "\f6f3";
}

.fa-trash-arrow-up::before {
  content: "\f829";
}

.fa-trash-restore::before {
  content: "\f829";
}

.fa-arrow-down-up-lock::before {
  content: "\e4b0";
}

.fa-arrow-down-to-bracket::before {
  content: "\e094";
}

.fa-lines-leaning::before {
  content: "\e51e";
}

.fa-square-q::before {
  content: "\e27b";
}

.fa-ruler-combined::before {
  content: "\f546";
}

.fa-symbols::before {
  content: "\f86e";
}

.fa-icons-alt::before {
  content: "\f86e";
}

.fa-copyright::before {
  content: "\f1f9";
}

.fa-flask-gear::before {
  content: "\e5f1";
}

.fa-highlighter-line::before {
  content: "\e1af";
}

.fa-bracket-square::before {
  content: "\[";
}

.fa-bracket::before {
  content: "\[";
}

.fa-bracket-left::before {
  content: "\[";
}

.fa-island-tropical::before {
  content: "\f811";
}

.fa-island-tree-palm::before {
  content: "\f811";
}

.fa-arrow-right-from-line::before {
  content: "\f343";
}

.fa-arrow-from-left::before {
  content: "\f343";
}

.fa-h2::before {
  content: "\f314";
}

.fa-equals::before {
  content: "\=";
}

.fa-cake-slice::before {
  content: "\e3e5";
}

.fa-shortcake::before {
  content: "\e3e5";
}

.fa-peanut::before {
  content: "\e430";
}

.fa-wrench-simple::before {
  content: "\e2d1";
}

.fa-blender::before {
  content: "\f517";
}

.fa-teeth::before {
  content: "\f62e";
}

.fa-tally-2::before {
  content: "\e295";
}

.fa-shekel-sign::before {
  content: "\f20b";
}

.fa-ils::before {
  content: "\f20b";
}

.fa-shekel::before {
  content: "\f20b";
}

.fa-sheqel::before {
  content: "\f20b";
}

.fa-sheqel-sign::before {
  content: "\f20b";
}

.fa-cars::before {
  content: "\f85b";
}

.fa-axe-battle::before {
  content: "\f6b3";
}

.fa-user-hair-long::before {
  content: "\e45b";
}

.fa-map::before {
  content: "\f279";
}

.fa-file-circle-info::before {
  content: "\e493";
}

.fa-face-disappointed::before {
  content: "\e36f";
}

.fa-lasso-sparkles::before {
  content: "\e1c9";
}

.fa-clock-eleven::before {
  content: "\e347";
}

.fa-rocket::before {
  content: "\f135";
}

.fa-siren-on::before {
  content: "\e02e";
}

.fa-clock-ten::before {
  content: "\e354";
}

.fa-candle-holder::before {
  content: "\f6bc";
}

.fa-video-arrow-down-left::before {
  content: "\e2c8";
}

.fa-photo-film::before {
  content: "\f87c";
}

.fa-photo-video::before {
  content: "\f87c";
}

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180";
}

.fa-save-circle-arrow-right::before {
  content: "\e180";
}

.fa-folder-minus::before {
  content: "\f65d";
}

.fa-planet-moon::before {
  content: "\e01f";
}

.fa-face-eyes-xmarks::before {
  content: "\e374";
}

.fa-chart-scatter::before {
  content: "\f7ee";
}

.fa-display-arrow-down::before {
  content: "\e164";
}

.fa-store::before {
  content: "\f54e";
}

.fa-arrow-trend-up::before {
  content: "\e098";
}

.fa-plug-circle-minus::before {
  content: "\e55e";
}

.fa-olive-branch::before {
  content: "\e317";
}

.fa-angle::before {
  content: "\e08c";
}

.fa-vacuum-robot::before {
  content: "\e04e";
}

.fa-sign-hanging::before {
  content: "\f4d9";
}

.fa-sign::before {
  content: "\f4d9";
}

.fa-square-divide::before {
  content: "\e26a";
}

.fa-signal-stream-slash::before {
  content: "\e250";
}

.fa-bezier-curve::before {
  content: "\f55b";
}

.fa-eye-dropper-half::before {
  content: "\e173";
}

.fa-store-lock::before {
  content: "\e4a6";
}

.fa-bell-slash::before {
  content: "\f1f6";
}

.fa-cloud-bolt-sun::before {
  content: "\f76e";
}

.fa-thunderstorm-sun::before {
  content: "\f76e";
}

.fa-camera-slash::before {
  content: "\e0d9";
}

.fa-comment-quote::before {
  content: "\e14c";
}

.fa-tablet::before {
  content: "\f3fb";
}

.fa-tablet-android::before {
  content: "\f3fb";
}

.fa-school-flag::before {
  content: "\e56e";
}

.fa-message-code::before {
  content: "\e1df";
}

.fa-glass-half::before {
  content: "\e192";
}

.fa-glass-half-empty::before {
  content: "\e192";
}

.fa-glass-half-full::before {
  content: "\e192";
}

.fa-fill::before {
  content: "\f575";
}

.fa-message-minus::before {
  content: "\f4a7";
}

.fa-comment-alt-minus::before {
  content: "\f4a7";
}

.fa-angle-up::before {
  content: "\f106";
}

.fa-dinosaur::before {
  content: "\e5fe";
}

.fa-drumstick-bite::before {
  content: "\f6d7";
}

.fa-link-horizontal-slash::before {
  content: "\e1cc";
}

.fa-chain-horizontal-slash::before {
  content: "\e1cc";
}

.fa-holly-berry::before {
  content: "\f7aa";
}

.fa-nose::before {
  content: "\e5bd";
}

.fa-chevron-left::before {
  content: "\f053";
}

.fa-bacteria::before {
  content: "\e059";
}

.fa-clouds::before {
  content: "\f744";
}

.fa-money-bill-simple::before {
  content: "\e1f1";
}

.fa-hand-lizard::before {
  content: "\f258";
}

.fa-table-pivot::before {
  content: "\e291";
}

.fa-filter-slash::before {
  content: "\e17d";
}

.fa-trash-can-undo::before {
  content: "\f896";
}

.fa-trash-can-arrow-turn-left::before {
  content: "\f896";
}

.fa-trash-undo-alt::before {
  content: "\f896";
}

.fa-notdef::before {
  content: "\e1fe";
}

.fa-disease::before {
  content: "\f7fa";
}

.fa-person-to-door::before {
  content: "\e433";
}

.fa-turntable::before {
  content: "\f8e4";
}

.fa-briefcase-medical::before {
  content: "\f469";
}

.fa-genderless::before {
  content: "\f22d";
}

.fa-chevron-right::before {
  content: "\f054";
}

.fa-signal-weak::before {
  content: "\f68c";
}

.fa-signal-1::before {
  content: "\f68c";
}

.fa-clock-five::before {
  content: "\e349";
}

.fa-retweet::before {
  content: "\f079";
}

.fa-car-rear::before {
  content: "\f5de";
}

.fa-car-alt::before {
  content: "\f5de";
}

.fa-pump-soap::before {
  content: "\e06b";
}

.fa-computer-classic::before {
  content: "\f8b1";
}

.fa-frame::before {
  content: "\e495";
}

.fa-video-slash::before {
  content: "\f4e2";
}

.fa-battery-quarter::before {
  content: "\f243";
}

.fa-battery-2::before {
  content: "\f243";
}

.fa-ellipsis-stroke::before {
  content: "\f39b";
}

.fa-ellipsis-h-alt::before {
  content: "\f39b";
}

.fa-radio::before {
  content: "\f8d7";
}

.fa-baby-carriage::before {
  content: "\f77d";
}

.fa-carriage-baby::before {
  content: "\f77d";
}

.fa-face-expressionless::before {
  content: "\e373";
}

.fa-down-to-dotted-line::before {
  content: "\e408";
}

.fa-cloud-music::before {
  content: "\f8ae";
}

.fa-traffic-light::before {
  content: "\f637";
}

.fa-cloud-minus::before {
  content: "\e35d";
}

.fa-thermometer::before {
  content: "\f491";
}

.fa-shield-minus::before {
  content: "\e249";
}

.fa-vr-cardboard::before {
  content: "\f729";
}

.fa-car-tilt::before {
  content: "\f5e5";
}

.fa-gauge-circle-minus::before {
  content: "\e497";
}

.fa-brightness-low::before {
  content: "\e0ca";
}

.fa-hand-middle-finger::before {
  content: "\f806";
}

.fa-percent::before {
  content: "\%";
}

.fa-percentage::before {
  content: "\%";
}

.fa-truck-moving::before {
  content: "\f4df";
}

.fa-glass-water-droplet::before {
  content: "\e4f5";
}

.fa-conveyor-belt::before {
  content: "\f46e";
}

.fa-location-check::before {
  content: "\f606";
}

.fa-map-marker-check::before {
  content: "\f606";
}

.fa-coin-vertical::before {
  content: "\e3fd";
}

.fa-display::before {
  content: "\e163";
}

.fa-person-sign::before {
  content: "\f757";
}

.fa-face-smile::before {
  content: "\f118";
}

.fa-smile::before {
  content: "\f118";
}

.fa-phone-hangup::before {
  content: "\e225";
}

.fa-signature-slash::before {
  content: "\e3cb";
}

.fa-thumbtack::before {
  content: "\f08d";
}

.fa-thumb-tack::before {
  content: "\f08d";
}

.fa-wheat-slash::before {
  content: "\e339";
}

.fa-trophy::before {
  content: "\f091";
}

.fa-clouds-sun::before {
  content: "\f746";
}

.fa-person-praying::before {
  content: "\f683";
}

.fa-pray::before {
  content: "\f683";
}

.fa-hammer::before {
  content: "\f6e3";
}

.fa-face-vomit::before {
  content: "\e3a0";
}

.fa-speakers::before {
  content: "\f8e0";
}

.fa-tty-answer::before {
  content: "\e2b9";
}

.fa-teletype-answer::before {
  content: "\e2b9";
}

.fa-mug-tea-saucer::before {
  content: "\e1f5";
}

.fa-diagram-lean-canvas::before {
  content: "\e156";
}

.fa-alt::before {
  content: "\e08a";
}

.fa-dial::before {
  content: "\e15b";
}

.fa-dial-med-high::before {
  content: "\e15b";
}

.fa-hand-peace::before {
  content: "\f25b";
}

.fa-circle-trash::before {
  content: "\e126";
}

.fa-trash-circle::before {
  content: "\e126";
}

.fa-rotate::before {
  content: "\f2f1";
}

.fa-sync-alt::before {
  content: "\f2f1";
}

.fa-circle-quarters::before {
  content: "\e3f8";
}

.fa-spinner::before {
  content: "\f110";
}

.fa-tower-control::before {
  content: "\e2a2";
}

.fa-arrow-up-triangle-square::before {
  content: "\f88a";
}

.fa-sort-shapes-up::before {
  content: "\f88a";
}

.fa-whale::before {
  content: "\f72c";
}

.fa-robot::before {
  content: "\f544";
}

.fa-peace::before {
  content: "\f67c";
}

.fa-party-horn::before {
  content: "\e31b";
}

.fa-gears::before {
  content: "\f085";
}

.fa-cogs::before {
  content: "\f085";
}

.fa-sun-bright::before {
  content: "\e28f";
}

.fa-sun-alt::before {
  content: "\e28f";
}

.fa-warehouse::before {
  content: "\f494";
}

.fa-conveyor-belt-arm::before {
  content: "\e5f8";
}

.fa-lock-keyhole-open::before {
  content: "\f3c2";
}

.fa-lock-open-alt::before {
  content: "\f3c2";
}

.fa-square-fragile::before {
  content: "\f49b";
}

.fa-box-fragile::before {
  content: "\f49b";
}

.fa-square-wine-glass-crack::before {
  content: "\f49b";
}

.fa-arrow-up-right-dots::before {
  content: "\e4b7";
}

.fa-square-n::before {
  content: "\e277";
}

.fa-splotch::before {
  content: "\f5bc";
}

.fa-face-grin-hearts::before {
  content: "\f584";
}

.fa-grin-hearts::before {
  content: "\f584";
}

.fa-meter::before {
  content: "\e1e8";
}

.fa-mandolin::before {
  content: "\f6f9";
}

.fa-dice-four::before {
  content: "\f524";
}

.fa-sim-card::before {
  content: "\f7c4";
}

.fa-transgender::before {
  content: "\f225";
}

.fa-transgender-alt::before {
  content: "\f225";
}

.fa-mercury::before {
  content: "\f223";
}

.fa-up-from-bracket::before {
  content: "\e590";
}

.fa-knife-kitchen::before {
  content: "\f6f5";
}

.fa-border-right::before {
  content: "\f852";
}

.fa-arrow-turn-down::before {
  content: "\f149";
}

.fa-level-down::before {
  content: "\f149";
}

.fa-spade::before {
  content: "\f2f4";
}

.fa-card-spade::before {
  content: "\e3ec";
}

.fa-line-columns::before {
  content: "\f870";
}

.fa-arrow-right-to-line::before {
  content: "\f340";
}

.fa-arrow-to-right::before {
  content: "\f340";
}

.fa-person-falling-burst::before {
  content: "\e547";
}

.fa-flag-pennant::before {
  content: "\f456";
}

.fa-pennant::before {
  content: "\f456";
}

.fa-conveyor-belt-empty::before {
  content: "\e150";
}

.fa-user-group-simple::before {
  content: "\e603";
}

.fa-award::before {
  content: "\f559";
}

.fa-ticket-simple::before {
  content: "\f3ff";
}

.fa-ticket-alt::before {
  content: "\f3ff";
}

.fa-building::before {
  content: "\f1ad";
}

.fa-angles-left::before {
  content: "\f100";
}

.fa-angle-double-left::before {
  content: "\f100";
}

.fa-camcorder::before {
  content: "\f8a8";
}

.fa-video-handheld::before {
  content: "\f8a8";
}

.fa-pancakes::before {
  content: "\e42d";
}

.fa-album-circle-user::before {
  content: "\e48d";
}

.fa-qrcode::before {
  content: "\f029";
}

.fa-dice-d10::before {
  content: "\f6cd";
}

.fa-fireplace::before {
  content: "\f79a";
}

.fa-browser::before {
  content: "\f37e";
}

.fa-pen-paintbrush::before {
  content: "\f618";
}

.fa-pencil-paintbrush::before {
  content: "\f618";
}

.fa-fish-cooked::before {
  content: "\f7fe";
}

.fa-chair-office::before {
  content: "\f6c1";
}

.fa-nesting-dolls::before {
  content: "\e3ba";
}

.fa-clock-rotate-left::before {
  content: "\f1da";
}

.fa-history::before {
  content: "\f1da";
}

.fa-trumpet::before {
  content: "\f8e3";
}

.fa-face-grin-beam-sweat::before {
  content: "\f583";
}

.fa-grin-beam-sweat::before {
  content: "\f583";
}

.fa-fire-smoke::before {
  content: "\f74b";
}

.fa-phone-missed::before {
  content: "\e226";
}

.fa-file-export::before {
  content: "\f56e";
}

.fa-arrow-right-from-file::before {
  content: "\f56e";
}

.fa-shield::before {
  content: "\f132";
}

.fa-shield-blank::before {
  content: "\f132";
}

.fa-arrow-up-short-wide::before {
  content: "\f885";
}

.fa-sort-amount-up-alt::before {
  content: "\f885";
}

.fa-arrows-repeat-1::before {
  content: "\f366";
}

.fa-repeat-1-alt::before {
  content: "\f366";
}

.fa-gun-slash::before {
  content: "\e19c";
}

.fa-avocado::before {
  content: "\e0aa";
}

.fa-binary::before {
  content: "\e33b";
}

.fa-glasses-round::before {
  content: "\f5f5";
}

.fa-glasses-alt::before {
  content: "\f5f5";
}

.fa-phone-plus::before {
  content: "\f4d2";
}

.fa-ditto::before {
  content: "\"";
}

.fa-person-seat::before {
  content: "\e21e";
}

.fa-house-medical::before {
  content: "\e3b2";
}

.fa-golf-ball-tee::before {
  content: "\f450";
}

.fa-golf-ball::before {
  content: "\f450";
}

.fa-circle-chevron-left::before {
  content: "\f137";
}

.fa-chevron-circle-left::before {
  content: "\f137";
}

.fa-house-chimney-window::before {
  content: "\e00d";
}

.fa-scythe::before {
  content: "\f710";
}

.fa-pen-nib::before {
  content: "\f5ad";
}

.fa-ban-parking::before {
  content: "\f616";
}

.fa-parking-circle-slash::before {
  content: "\f616";
}

.fa-tent-arrow-turn-left::before {
  content: "\e580";
}

.fa-face-diagonal-mouth::before {
  content: "\e47e";
}

.fa-diagram-cells::before {
  content: "\e475";
}

.fa-cricket-bat-ball::before {
  content: "\f449";
}

.fa-cricket::before {
  content: "\f449";
}

.fa-tents::before {
  content: "\e582";
}

.fa-wand-magic::before {
  content: "\f0d0";
}

.fa-magic::before {
  content: "\f0d0";
}

.fa-dog::before {
  content: "\f6d3";
}

.fa-pen-line::before {
  content: "\e212";
}

.fa-atom-simple::before {
  content: "\f5d3";
}

.fa-atom-alt::before {
  content: "\f5d3";
}

.fa-ampersand::before {
  content: "\&";
}

.fa-carrot::before {
  content: "\f787";
}

.fa-arrow-up-from-line::before {
  content: "\f342";
}

.fa-arrow-from-bottom::before {
  content: "\f342";
}

.fa-moon::before {
  content: "\f186";
}

.fa-pen-slash::before {
  content: "\e213";
}

.fa-wine-glass-empty::before {
  content: "\f5ce";
}

.fa-wine-glass-alt::before {
  content: "\f5ce";
}

.fa-square-star::before {
  content: "\e27f";
}

.fa-cheese::before {
  content: "\f7ef";
}

.fa-send-backward::before {
  content: "\f87f";
}

.fa-yin-yang::before {
  content: "\f6ad";
}

.fa-music::before {
  content: "\f001";
}

.fa-compass-slash::before {
  content: "\f5e9";
}

.fa-clock-one::before {
  content: "\e34e";
}

.fa-file-music::before {
  content: "\f8b6";
}

.fa-code-commit::before {
  content: "\f386";
}

.fa-temperature-low::before {
  content: "\f76b";
}

.fa-person-biking::before {
  content: "\f84a";
}

.fa-biking::before {
  content: "\f84a";
}

.fa-display-chart-up-circle-currency::before {
  content: "\e5e5";
}

.fa-skeleton::before {
  content: "\f620";
}

.fa-circle-g::before {
  content: "\e10f";
}

.fa-circle-arrow-up-left::before {
  content: "\e0fb";
}

.fa-coin-blank::before {
  content: "\e3fb";
}

.fa-broom::before {
  content: "\f51a";
}

.fa-vacuum::before {
  content: "\e04d";
}

.fa-shield-heart::before {
  content: "\e574";
}

.fa-card-heart::before {
  content: "\e3eb";
}

.fa-lightbulb-cfl-on::before {
  content: "\e5a7";
}

.fa-melon::before {
  content: "\e310";
}

.fa-gopuram::before {
  content: "\f664";
}

.fa-earth-oceania::before {
  content: "\e47b";
}

.fa-globe-oceania::before {
  content: "\e47b";
}

.fa-container-storage::before {
  content: "\f4b7";
}

.fa-face-pouting::before {
  content: "\e387";
}

.fa-square-xmark::before {
  content: "\f2d3";
}

.fa-times-square::before {
  content: "\f2d3";
}

.fa-xmark-square::before {
  content: "\f2d3";
}

.fa-face-explode::before {
  content: "\e2fe";
}

.fa-exploding-head::before {
  content: "\e2fe";
}

.fa-hashtag::before {
  content: "\#";
}

.fa-up-right-and-down-left-from-center::before {
  content: "\f424";
}

.fa-expand-alt::before {
  content: "\f424";
}

.fa-oil-can::before {
  content: "\f613";
}

.fa-t::before {
  content: "T";
}

.fa-transformer-bolt::before {
  content: "\e2a4";
}

.fa-hippo::before {
  content: "\f6ed";
}

.fa-chart-column::before {
  content: "\e0e3";
}

.fa-cassette-vhs::before {
  content: "\f8ec";
}

.fa-vhs::before {
  content: "\f8ec";
}

.fa-infinity::before {
  content: "\f534";
}

.fa-vial-circle-check::before {
  content: "\e596";
}

.fa-chimney::before {
  content: "\f78b";
}

.fa-object-intersect::before {
  content: "\e49d";
}

.fa-person-arrow-down-to-line::before {
  content: "\e538";
}

.fa-voicemail::before {
  content: "\f897";
}

.fa-block-brick::before {
  content: "\e3db";
}

.fa-wall-brick::before {
  content: "\e3db";
}

.fa-fan::before {
  content: "\f863";
}

.fa-bags-shopping::before {
  content: "\f847";
}

.fa-paragraph-left::before {
  content: "\f878";
}

.fa-paragraph-rtl::before {
  content: "\f878";
}

.fa-person-walking-luggage::before {
  content: "\e554";
}

.fa-caravan-simple::before {
  content: "\e000";
}

.fa-caravan-alt::before {
  content: "\e000";
}

.fa-turtle::before {
  content: "\f726";
}

.fa-pencil-mechanical::before {
  content: "\e5ca";
}

.fa-up-down::before {
  content: "\f338";
}

.fa-arrows-alt-v::before {
  content: "\f338";
}

.fa-cloud-moon-rain::before {
  content: "\f73c";
}

.fa-booth-curtain::before {
  content: "\f734";
}

.fa-calendar::before {
  content: "\f133";
}

.fa-box-heart::before {
  content: "\f49d";
}

.fa-trailer::before {
  content: "\e041";
}

.fa-user-doctor-message::before {
  content: "\f82e";
}

.fa-user-md-chat::before {
  content: "\f82e";
}

.fa-bahai::before {
  content: "\f666";
}

.fa-haykal::before {
  content: "\f666";
}

.fa-amp-guitar::before {
  content: "\f8a1";
}

.fa-sd-card::before {
  content: "\f7c2";
}

.fa-volume-slash::before {
  content: "\f2e2";
}

.fa-border-bottom::before {
  content: "\f84d";
}

.fa-wifi-weak::before {
  content: "\f6aa";
}

.fa-wifi-1::before {
  content: "\f6aa";
}

.fa-dragon::before {
  content: "\f6d5";
}

.fa-shoe-prints::before {
  content: "\f54b";
}

.fa-circle-plus::before {
  content: "\f055";
}

.fa-plus-circle::before {
  content: "\f055";
}

.fa-face-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-hand-holding::before {
  content: "\f4bd";
}

.fa-plug-circle-exclamation::before {
  content: "\e55d";
}

.fa-link-slash::before {
  content: "\f127";
}

.fa-chain-broken::before {
  content: "\f127";
}

.fa-chain-slash::before {
  content: "\f127";
}

.fa-unlink::before {
  content: "\f127";
}

.fa-clone::before {
  content: "\f24d";
}

.fa-person-walking-arrow-loop-left::before {
  content: "\e551";
}

.fa-arrow-up-z-a::before {
  content: "\f882";
}

.fa-sort-alpha-up-alt::before {
  content: "\f882";
}

.fa-fire-flame-curved::before {
  content: "\f7e4";
}

.fa-fire-alt::before {
  content: "\f7e4";
}

.fa-tornado::before {
  content: "\f76f";
}

.fa-file-circle-plus::before {
  content: "\e494";
}

.fa-delete-right::before {
  content: "\e154";
}

.fa-book-quran::before {
  content: "\f687";
}

.fa-quran::before {
  content: "\f687";
}

.fa-circle-quarter::before {
  content: "\e11f";
}

.fa-anchor::before {
  content: "\f13d";
}

.fa-border-all::before {
  content: "\f84c";
}

.fa-function::before {
  content: "\f661";
}

.fa-face-angry::before {
  content: "\f556";
}

.fa-angry::before {
  content: "\f556";
}

.fa-people-simple::before {
  content: "\e21b";
}

.fa-cookie-bite::before {
  content: "\f564";
}

.fa-arrow-trend-down::before {
  content: "\e097";
}

.fa-rss::before {
  content: "\f09e";
}

.fa-feed::before {
  content: "\f09e";
}

.fa-face-monocle::before {
  content: "\e380";
}

.fa-draw-polygon::before {
  content: "\f5ee";
}

.fa-scale-balanced::before {
  content: "\f24e";
}

.fa-balance-scale::before {
  content: "\f24e";
}

.fa-calendar-lines::before {
  content: "\e0d5";
}

.fa-calendar-note::before {
  content: "\e0d5";
}

.fa-arrow-down-big-small::before {
  content: "\f88c";
}

.fa-sort-size-down::before {
  content: "\f88c";
}

.fa-gauge-simple-high::before {
  content: "\f62a";
}

.fa-tachometer::before {
  content: "\f62a";
}

.fa-tachometer-fast::before {
  content: "\f62a";
}

.fa-do-not-enter::before {
  content: "\f5ec";
}

.fa-shower::before {
  content: "\f2cc";
}

.fa-dice-d8::before {
  content: "\f6d2";
}

.fa-desktop::before {
  content: "\f390";
}

.fa-desktop-alt::before {
  content: "\f390";
}

.fa-m::before {
  content: "M";
}

.fa-grip-dots-vertical::before {
  content: "\e411";
}

.fa-face-viewfinder::before {
  content: "\e2ff";
}

.fa-soft-serve::before {
  content: "\e400";
}

.fa-creemee::before {
  content: "\e400";
}

.fa-h5::before {
  content: "\e412";
}

.fa-hand-back-point-down::before {
  content: "\e19e";
}

.fa-table-list::before {
  content: "\f00b";
}

.fa-th-list::before {
  content: "\f00b";
}

.fa-comment-sms::before {
  content: "\f7cd";
}

.fa-sms::before {
  content: "\f7cd";
}

.fa-rectangle::before {
  content: "\f2fa";
}

.fa-rectangle-landscape::before {
  content: "\f2fa";
}

.fa-clipboard-list-check::before {
  content: "\f737";
}

.fa-turkey::before {
  content: "\f725";
}

.fa-book::before {
  content: "\f02d";
}

.fa-user-plus::before {
  content: "\f234";
}

.fa-ice-skate::before {
  content: "\f7ac";
}

.fa-check::before {
  content: "\f00c";
}

.fa-battery-three-quarters::before {
  content: "\f241";
}

.fa-battery-4::before {
  content: "\f241";
}

.fa-tomato::before {
  content: "\e330";
}

.fa-sword-laser::before {
  content: "\e03b";
}

.fa-house-circle-check::before {
  content: "\e509";
}

.fa-buildings::before {
  content: "\e0cc";
}

.fa-angle-left::before {
  content: "\f104";
}

.fa-cart-flatbed-boxes::before {
  content: "\f475";
}

.fa-dolly-flatbed-alt::before {
  content: "\f475";
}

.fa-diagram-successor::before {
  content: "\e47a";
}

.fa-truck-arrow-right::before {
  content: "\e58b";
}

.fa-square-w::before {
  content: "\e285";
}

.fa-arrows-split-up-and-left::before {
  content: "\e4bc";
}

.fa-lamp::before {
  content: "\f4ca";
}

.fa-airplay::before {
  content: "\e089";
}

.fa-hand-fist::before {
  content: "\f6de";
}

.fa-fist-raised::before {
  content: "\f6de";
}

.fa-shield-quartered::before {
  content: "\e575";
}

.fa-slash-forward::before {
  content: "\/";
}

.fa-location-pen::before {
  content: "\f607";
}

.fa-map-marker-edit::before {
  content: "\f607";
}

.fa-cloud-moon::before {
  content: "\f6c3";
}

.fa-pot-food::before {
  content: "\e43f";
}

.fa-briefcase::before {
  content: "\f0b1";
}

.fa-person-falling::before {
  content: "\e546";
}

.fa-image-portrait::before {
  content: "\f3e0";
}

.fa-portrait::before {
  content: "\f3e0";
}

.fa-user-tag::before {
  content: "\f507";
}

.fa-rug::before {
  content: "\e569";
}

.fa-print-slash::before {
  content: "\f686";
}

.fa-earth-europe::before {
  content: "\f7a2";
}

.fa-globe-europe::before {
  content: "\f7a2";
}

.fa-cart-flatbed-suitcase::before {
  content: "\f59d";
}

.fa-luggage-cart::before {
  content: "\f59d";
}

.fa-hand-back-point-ribbon::before {
  content: "\e1a0";
}

.fa-rectangle-xmark::before {
  content: "\f410";
}

.fa-rectangle-times::before {
  content: "\f410";
}

.fa-times-rectangle::before {
  content: "\f410";
}

.fa-window-close::before {
  content: "\f410";
}

.fa-tire-rugged::before {
  content: "\f634";
}

.fa-lightbulb-dollar::before {
  content: "\f670";
}

.fa-cowbell::before {
  content: "\f8b3";
}

.fa-baht-sign::before {
  content: "\e0ac";
}

.fa-corner::before {
  content: "\e3fe";
}

.fa-chevrons-right::before {
  content: "\f324";
}

.fa-chevron-double-right::before {
  content: "\f324";
}

.fa-book-open::before {
  content: "\f518";
}

.fa-book-journal-whills::before {
  content: "\f66a";
}

.fa-journal-whills::before {
  content: "\f66a";
}

.fa-inhaler::before {
  content: "\f5f9";
}

.fa-handcuffs::before {
  content: "\e4f8";
}

.fa-snake::before {
  content: "\f716";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.fa-exclamation-triangle::before {
  content: "\f071";
}

.fa-warning::before {
  content: "\f071";
}

.fa-note-medical::before {
  content: "\e200";
}

.fa-database::before {
  content: "\f1c0";
}

.fa-down-left::before {
  content: "\e16a";
}

.fa-share::before {
  content: "\f064";
}

.fa-arrow-turn-right::before {
  content: "\f064";
}

.fa-mail-forward::before {
  content: "\f064";
}

.fa-face-thinking::before {
  content: "\e39b";
}

.fa-turn-down-right::before {
  content: "\e455";
}

.fa-bottle-droplet::before {
  content: "\e4c4";
}

.fa-mask-face::before {
  content: "\e1d7";
}

.fa-hill-rockslide::before {
  content: "\e508";
}

.fa-scanner-keyboard::before {
  content: "\f489";
}

.fa-circle-o::before {
  content: "\e119";
}

.fa-grid-horizontal::before {
  content: "\e307";
}

.fa-message-dollar::before {
  content: "\f650";
}

.fa-comment-alt-dollar::before {
  content: "\f650";
}

.fa-right-left::before {
  content: "\f362";
}

.fa-exchange-alt::before {
  content: "\f362";
}

.fa-columns-3::before {
  content: "\e361";
}

.fa-paper-plane::before {
  content: "\f1d8";
}

.fa-road-circle-exclamation::before {
  content: "\e565";
}

.fa-dungeon::before {
  content: "\f6d9";
}

.fa-hand-holding-box::before {
  content: "\f47b";
}

.fa-input-text::before {
  content: "\e1bf";
}

.fa-window-flip::before {
  content: "\f40f";
}

.fa-window-alt::before {
  content: "\f40f";
}

.fa-align-right::before {
  content: "\f038";
}

.fa-scanner-gun::before {
  content: "\f488";
}

.fa-scanner::before {
  content: "\f488";
}

.fa-tire::before {
  content: "\f631";
}

.fa-engine::before {
  content: "\e16e";
}

.fa-money-bill-1-wave::before {
  content: "\f53b";
}

.fa-money-bill-wave-alt::before {
  content: "\f53b";
}

.fa-life-ring::before {
  content: "\f1cd";
}

.fa-hands::before {
  content: "\f2a7";
}

.fa-sign-language::before {
  content: "\f2a7";
}

.fa-signing::before {
  content: "\f2a7";
}

.fa-circle-caret-right::before {
  content: "\f330";
}

.fa-caret-circle-right::before {
  content: "\f330";
}

.fa-wheat::before {
  content: "\f72d";
}

.fa-file-spreadsheet::before {
  content: "\f65b";
}

.fa-audio-description-slash::before {
  content: "\e0a8";
}

.fa-calendar-day::before {
  content: "\f783";
}

.fa-water-ladder::before {
  content: "\f5c5";
}

.fa-ladder-water::before {
  content: "\f5c5";
}

.fa-swimming-pool::before {
  content: "\f5c5";
}

.fa-arrows-up-down::before {
  content: "\f07d";
}

.fa-arrows-v::before {
  content: "\f07d";
}

.fa-chess-pawn-piece::before {
  content: "\f444";
}

.fa-chess-pawn-alt::before {
  content: "\f444";
}

.fa-face-grimace::before {
  content: "\f57f";
}

.fa-grimace::before {
  content: "\f57f";
}

.fa-wheelchair-move::before {
  content: "\e2ce";
}

.fa-wheelchair-alt::before {
  content: "\e2ce";
}

.fa-turn-down::before {
  content: "\f3be";
}

.fa-level-down-alt::before {
  content: "\f3be";
}

.fa-square-s::before {
  content: "\e27d";
}

.fa-rectangle-barcode::before {
  content: "\f463";
}

.fa-barcode-alt::before {
  content: "\f463";
}

.fa-person-walking-arrow-right::before {
  content: "\e552";
}

.fa-square-envelope::before {
  content: "\f199";
}

.fa-envelope-square::before {
  content: "\f199";
}

.fa-dice::before {
  content: "\f522";
}

.fa-unicorn::before {
  content: "\f727";
}

.fa-bowling-ball::before {
  content: "\f436";
}

.fa-pompebled::before {
  content: "\e43d";
}

.fa-brain::before {
  content: "\f5dc";
}

.fa-watch-smart::before {
  content: "\e2cc";
}

.fa-book-user::before {
  content: "\f7e7";
}

.fa-sensor-cloud::before {
  content: "\e02c";
}

.fa-sensor-smoke::before {
  content: "\e02c";
}

.fa-clapperboard-play::before {
  content: "\e132";
}

.fa-bandage::before {
  content: "\f462";
}

.fa-band-aid::before {
  content: "\f462";
}

.fa-calendar-minus::before {
  content: "\f272";
}

.fa-circle-xmark::before {
  content: "\f057";
}

.fa-times-circle::before {
  content: "\f057";
}

.fa-xmark-circle::before {
  content: "\f057";
}

.fa-circle-4::before {
  content: "\e0f1";
}

.fa-gifts::before {
  content: "\f79c";
}

.fa-album-collection::before {
  content: "\f8a0";
}

.fa-hotel::before {
  content: "\f594";
}

.fa-earth-asia::before {
  content: "\f57e";
}

.fa-globe-asia::before {
  content: "\f57e";
}

.fa-id-card-clip::before {
  content: "\f47f";
}

.fa-id-card-alt::before {
  content: "\f47f";
}

.fa-magnifying-glass-plus::before {
  content: "\f00e";
}

.fa-search-plus::before {
  content: "\f00e";
}

.fa-thumbs-up::before {
  content: "\f164";
}

.fa-cloud-showers::before {
  content: "\f73f";
}

.fa-user-clock::before {
  content: "\f4fd";
}

.fa-onion::before {
  content: "\e427";
}

.fa-clock-twelve-thirty::before {
  content: "\e359";
}

.fa-arrow-down-to-dotted-line::before {
  content: "\e095";
}

.fa-hand-dots::before {
  content: "\f461";
}

.fa-allergies::before {
  content: "\f461";
}

.fa-file-invoice::before {
  content: "\f570";
}

.fa-window-minimize::before {
  content: "\f2d1";
}

.fa-rectangle-wide::before {
  content: "\f2fc";
}

.fa-comment-arrow-up::before {
  content: "\e144";
}

.fa-garlic::before {
  content: "\e40e";
}

.fa-mug-saucer::before {
  content: "\f0f4";
}

.fa-coffee::before {
  content: "\f0f4";
}

.fa-brush::before {
  content: "\f55d";
}

.fa-tree-decorated::before {
  content: "\f7dc";
}

.fa-mask::before {
  content: "\f6fa";
}

.fa-calendar-heart::before {
  content: "\e0d3";
}

.fa-magnifying-glass-minus::before {
  content: "\f010";
}

.fa-search-minus::before {
  content: "\f010";
}

.fa-flower::before {
  content: "\f7ff";
}

.fa-right-left-large::before {
  content: "\e5e1";
}

.fa-ruler-vertical::before {
  content: "\f548";
}

.fa-circles-overlap::before {
  content: "\e600";
}

.fa-user-large::before {
  content: "\f406";
}

.fa-user-alt::before {
  content: "\f406";
}

.fa-starship-freighter::before {
  content: "\e03a";
}

.fa-train-tram::before {
  content: "\e5b4";
}

.fa-bridge-suspension::before {
  content: "\e4cd";
}

.fa-trash-check::before {
  content: "\e2af";
}

.fa-user-nurse::before {
  content: "\f82f";
}

.fa-boombox::before {
  content: "\f8a5";
}

.fa-syringe::before {
  content: "\f48e";
}

.fa-cloud-sun::before {
  content: "\f6c4";
}

.fa-shield-exclamation::before {
  content: "\e247";
}

.fa-stopwatch-20::before {
  content: "\e06f";
}

.fa-square-full::before {
  content: "\f45c";
}

.fa-grip-dots::before {
  content: "\e410";
}

.fa-comment-exclamation::before {
  content: "\f4af";
}

.fa-pen-swirl::before {
  content: "\e214";
}

.fa-falafel::before {
  content: "\e40a";
}

.fa-circle-2::before {
  content: "\e0ef";
}

.fa-magnet::before {
  content: "\f076";
}

.fa-jar::before {
  content: "\e516";
}

.fa-gramophone::before {
  content: "\f8bd";
}

.fa-dice-d12::before {
  content: "\f6ce";
}

.fa-note-sticky::before {
  content: "\f249";
}

.fa-sticky-note::before {
  content: "\f249";
}

.fa-down::before {
  content: "\f354";
}

.fa-arrow-alt-down::before {
  content: "\f354";
}

.fa-hundred-points::before {
  content: "\e41c";
}

.fa-100::before {
  content: "\e41c";
}

.fa-paperclip-vertical::before {
  content: "\e3c2";
}

.fa-wind-warning::before {
  content: "\f776";
}

.fa-wind-circle-exclamation::before {
  content: "\f776";
}

.fa-location-pin-slash::before {
  content: "\f60c";
}

.fa-map-marker-slash::before {
  content: "\f60c";
}

.fa-face-sad-sweat::before {
  content: "\e38a";
}

.fa-bug-slash::before {
  content: "\e490";
}

.fa-cupcake::before {
  content: "\e402";
}

.fa-light-switch-off::before {
  content: "\e018";
}

.fa-toggle-large-off::before {
  content: "\e5b0";
}

.fa-pen-fancy-slash::before {
  content: "\e210";
}

.fa-truck-container::before {
  content: "\f4dc";
}

.fa-boot::before {
  content: "\f782";
}

.fa-arrow-up-from-water-pump::before {
  content: "\e4b6";
}

.fa-file-check::before {
  content: "\f316";
}

.fa-bone::before {
  content: "\f5d7";
}

.fa-cards-blank::before {
  content: "\e4df";
}

.fa-circle-3::before {
  content: "\e0f0";
}

.fa-bench-tree::before {
  content: "\e2e7";
}

.fa-keyboard-brightness-low::before {
  content: "\e1c1";
}

.fa-ski-boot-ski::before {
  content: "\e3cd";
}

.fa-brain-circuit::before {
  content: "\e0c6";
}

.fa-user-injured::before {
  content: "\f728";
}

.fa-block-brick-fire::before {
  content: "\e3dc";
}

.fa-firewall::before {
  content: "\e3dc";
}

.fa-face-sad-tear::before {
  content: "\f5b4";
}

.fa-sad-tear::before {
  content: "\f5b4";
}

.fa-plane::before {
  content: "\f072";
}

.fa-tent-arrows-down::before {
  content: "\e581";
}

.fa-exclamation::before {
  content: "\!";
}

.fa-arrows-spin::before {
  content: "\e4bb";
}

.fa-face-smile-relaxed::before {
  content: "\e392";
}

.fa-comment-xmark::before {
  content: "\f4b5";
}

.fa-comment-times::before {
  content: "\f4b5";
}

.fa-print::before {
  content: "\f02f";
}

.fa-turkish-lira-sign::before {
  content: "\e2bb";
}

.fa-try::before {
  content: "\e2bb";
}

.fa-turkish-lira::before {
  content: "\e2bb";
}

.fa-face-nose-steam::before {
  content: "\e382";
}

.fa-circle-waveform-lines::before {
  content: "\e12d";
}

.fa-waveform-circle::before {
  content: "\e12d";
}

.fa-dollar-sign::before {
  content: "\$";
}

.fa-dollar::before {
  content: "\$";
}

.fa-usd::before {
  content: "\$";
}

.fa-ferris-wheel::before {
  content: "\e174";
}

.fa-computer-speaker::before {
  content: "\f8b2";
}

.fa-skull-cow::before {
  content: "\f8de";
}

.fa-x::before {
  content: "X";
}

.fa-magnifying-glass-dollar::before {
  content: "\f688";
}

.fa-search-dollar::before {
  content: "\f688";
}

.fa-users-gear::before {
  content: "\f509";
}

.fa-users-cog::before {
  content: "\f509";
}

.fa-person-military-pointing::before {
  content: "\e54a";
}

.fa-building-columns::before {
  content: "\f19c";
}

.fa-bank::before {
  content: "\f19c";
}

.fa-institution::before {
  content: "\f19c";
}

.fa-museum::before {
  content: "\f19c";
}

.fa-university::before {
  content: "\f19c";
}

.fa-circle-t::before {
  content: "\e124";
}

.fa-sack::before {
  content: "\f81c";
}

.fa-grid-2::before {
  content: "\e196";
}

.fa-camera-cctv::before {
  content: "\f8ac";
}

.fa-cctv::before {
  content: "\f8ac";
}

.fa-umbrella::before {
  content: "\f0e9";
}

.fa-trowel::before {
  content: "\e589";
}

.fa-horizontal-rule::before {
  content: "\f86c";
}

.fa-bed-front::before {
  content: "\f8f7";
}

.fa-bed-alt::before {
  content: "\f8f7";
}

.fa-d::before {
  content: "D";
}

.fa-stapler::before {
  content: "\e5af";
}

.fa-masks-theater::before {
  content: "\f630";
}

.fa-theater-masks::before {
  content: "\f630";
}

.fa-kip-sign::before {
  content: "\e1c4";
}

.fa-face-woozy::before {
  content: "\e3a2";
}

.fa-cloud-question::before {
  content: "\e492";
}

.fa-pineapple::before {
  content: "\e31f";
}

.fa-hand-point-left::before {
  content: "\f0a5";
}

.fa-gallery-thumbnails::before {
  content: "\e3aa";
}

.fa-circle-j::before {
  content: "\e112";
}

.fa-eyes::before {
  content: "\e367";
}

.fa-handshake-simple::before {
  content: "\f4c6";
}

.fa-handshake-alt::before {
  content: "\f4c6";
}

.fa-page-caret-up::before {
  content: "\e42a";
}

.fa-file-caret-up::before {
  content: "\e42a";
}

.fa-jet-fighter::before {
  content: "\f0fb";
}

.fa-fighter-jet::before {
  content: "\f0fb";
}

.fa-comet::before {
  content: "\e003";
}

.fa-square-share-nodes::before {
  content: "\f1e1";
}

.fa-share-alt-square::before {
  content: "\f1e1";
}

.fa-shield-keyhole::before {
  content: "\e248";
}

.fa-barcode::before {
  content: "\f02a";
}

.fa-plus-minus::before {
  content: "\e43c";
}

.fa-square-sliders-vertical::before {
  content: "\f3f2";
}

.fa-sliders-v-square::before {
  content: "\f3f2";
}

.fa-video::before {
  content: "\f03d";
}

.fa-video-camera::before {
  content: "\f03d";
}

.fa-message-middle::before {
  content: "\e1e1";
}

.fa-comment-middle-alt::before {
  content: "\e1e1";
}

.fa-graduation-cap::before {
  content: "\f19d";
}

.fa-mortar-board::before {
  content: "\f19d";
}

.fa-hand-holding-medical::before {
  content: "\e05c";
}

.fa-person-circle-check::before {
  content: "\e53e";
}

.fa-square-z::before {
  content: "\e288";
}

.fa-message-text::before {
  content: "\e1e6";
}

.fa-comment-alt-text::before {
  content: "\e1e6";
}

.fa-turn-up::before {
  content: "\f3bf";
}

.fa-level-up-alt::before {
  content: "\f3bf";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fontawesome/fa-regular-400.woff2") format("woff2"), url("../fonts/fontawesome/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-weight: 400;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fontawesome/fa-solid-900.woff2") format("woff2"), url("../fonts/fontawesome/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fontawesome/fa-brands-400.woff2") format("woff2"), url("../fonts/fontawesome/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-aws:before {
  content: "\f375";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-cloudflare:before {
  content: "\e07d";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-modx:before {
  content: "\f285";
}

.fa-guilded:before {
  content: "\e07e";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-square-js:before {
  content: "\f3b9";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-orcid:before {
  content: "\f8d2";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-unity:before {
  content: "\e049";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-vk:before {
  content: "\f189";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-square-reddit:before {
  content: "\f1a2";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-square-font-awesome:before {
  content: "\e5ad";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-square-instagram:before {
  content: "\e055";
}

.fa-instagram-square:before {
  content: "\e055";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-square-hacker-news:before {
  content: "\f3af";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-edge:before {
  content: "\f282";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-square-snapchat:before {
  content: "\f2ad";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-safari:before {
  content: "\f267";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-square-font-awesome-stroke:before {
  content: "\f35c";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-watchman-monitoring:before {
  content: "\e087";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-square-viadeo:before {
  content: "\f2aa";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-centos:before {
  content: "\f789";
}

.fa-adn:before {
  content: "\f170";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-square-dribbble:before {
  content: "\f397";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-node:before {
  content: "\f419";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-instalod:before {
  content: "\e081";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-square-twitter:before {
  content: "\f081";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-golang:before {
  content: "\e40f";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-uncharted:before {
  content: "\e084";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-square-youtube:before {
  content: "\f431";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-rendact:before {
  content: "\f3e4";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-nfc-directional:before {
  content: "\e530";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-meta:before {
  content: "\e49b";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-hips:before {
  content: "\f452";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-discord:before {
  content: "\f392";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-mdb:before {
  content: "\f8ca";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-unsplash:before {
  content: "\e07c";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-square-steam:before {
  content: "\f1b7";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-square-vimeo:before {
  content: "\f194";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-flag:before {
  content: "\f2b4";
}

.fa-font-awesome-logo-full:before {
  content: "\f2b4";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-apple:before {
  content: "\f179";
}

.fa-hive:before {
  content: "\e07f";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-padlet:before {
  content: "\e4a0";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-square-github:before {
  content: "\f092";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-shopify:before {
  content: "\e057";
}

.fa-neos:before {
  content: "\f612";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-swift:before {
  content: "\f8e1";
}

.fa-angular:before {
  content: "\f420";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envira:before {
  content: "\f299";
}

.fa-square-gitlab:before {
  content: "\e5ae";
}

.fa-gitlab-square:before {
  content: "\e5ae";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-square-odnoklassniki:before {
  content: "\f264";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-sith:before {
  content: "\f512";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-hashnode:before {
  content: "\e499";
}

.fa-react:before {
  content: "\f41b";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-umbraco:before {
  content: "\f8e8";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-gg:before {
  content: "\f260";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-square-pinterest:before {
  content: "\f0d3";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-xing:before {
  content: "\f168";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-less:before {
  content: "\f41d";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-dailymotion:before {
  content: "\e052";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-nfc-symbol:before {
  content: "\e531";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-square-google-plus:before {
  content: "\f0d4";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-square-xing:before {
  content: "\f169";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-fly:before {
  content: "\f417";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-viber:before {
  content: "\f409";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-audible:before {
  content: "\f373";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-bilibili:before {
  content: "\e3d9";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-cotton-bureau:before {
  content: "\f89e";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-42-group:before {
  content: "\e080";
}

.fa-innosoft:before {
  content: "\e080";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-square-pied-piper:before {
  content: "\e01e";
}

.fa-pied-piper-square:before {
  content: "\e01e";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-tiktok:before {
  content: "\e07b";
}

.fa-square-facebook:before {
  content: "\f082";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-mixer:before {
  content: "\e056";
}

.fa-square-lastfm:before {
  content: "\f203";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-figma:before {
  content: "\f799";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-cmplid:before {
  content: "\e360";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-deezer:before {
  content: "\e077";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-docker:before {
  content: "\f395";
}

.fa-screenpal:before {
  content: "\e570";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-microblog:before {
  content: "\e01a";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-wirsindhandwerk:before {
  content: "\e2d0";
}

.fa-wsh:before {
  content: "\e2d0";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-octopus-deploy:before {
  content: "\e082";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-apper:before {
  content: "\f371";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ab";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-rust:before {
  content: "\e07a";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-square-behance:before {
  content: "\f1b5";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-space-awesome:before {
  content: "\e5ac";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-square-git:before {
  content: "\f1d2";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-square-tumblr:before {
  content: "\f174";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-trello:before {
  content: "\f181";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-perbyte:before {
  content: "\e083";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-android:before {
  content: "\f17b";
}

.fa-bots:before {
  content: "\e340";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-ideal:before {
  content: "\e013";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-uber:before {
  content: "\f402";
}

.fa-github:before {
  content: "\f09b";
}

.fa-php:before {
  content: "\f457";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-firefox-browser:before {
  content: "\e007";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-stubber:before {
  content: "\e5c7";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f2c6";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-odysee:before {
  content: "\e5c6";
}

.fa-square-whatsapp:before {
  content: "\f40c";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-edge-legacy:before {
  content: "\e078";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f198";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-usb:before {
  content: "\f287";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f23a";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-sitrox:before {
  content: "\e44a";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-buy-n-large:before {
  content: "\f8a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-strava:before {
  content: "\f428";
}

.fa-ember:before {
  content: "\f423";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-wodu:before {
  content: "\e088";
}

.fa-google-pay:before {
  content: "\e079";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-zhihu:before {
  content: "\f63f";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-pix:before {
  content: "\e43a";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-sharp: "Font Awesome 6 Sharp";
  --fa-font-sharp-light: normal 300 1em/1 "Font Awesome 6 Sharp";
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fontawesome/fa-sharp-light-300.woff2") format("woff2"), url("../fonts/fontawesome/fa-sharp-light-300.ttf") format("truetype");
}
.fasl,
.fa-light {
  font-weight: 300;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-sharp: "Font Awesome 6 Sharp";
  --fa-font-sharp-regular: normal 400 1em/1 "Font Awesome 6 Sharp";
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fontawesome/fa-sharp-regular-400.woff2") format("woff2"), url("../fonts/fontawesome/fa-sharp-regular-400.ttf") format("truetype");
}
.fasr,
.fa-regular {
  font-weight: 400;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-sharp: "Font Awesome 6 Sharp";
  --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fontawesome/fa-sharp-solid-900.woff2") format("woff2"), url("../fonts/fontawesome/fa-sharp-solid-900.ttf") format("truetype");
}
.fass,
.fa-solid {
  font-weight: 900;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-duotone: "Font Awesome 6 Duotone";
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fontawesome/fa-duotone-900.woff2") format("woff2"), url("../fonts/fontawesome/fa-duotone-900.ttf") format("truetype");
}
.fad,
.fa-duotone {
  position: relative;
  font-weight: 900;
  letter-spacing: normal;
}

.fad::before,
.fa-duotone::before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1);
}

.fad::after,
.fa-duotone::after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::before,
.fa-swap-opacity .fa-duotone::before,
.fad.fa-swap-opacity::before,
.fa-duotone.fa-swap-opacity::before {
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad::after,
.fa-swap-opacity .fa-duotone::after,
.fad.fa-swap-opacity::after,
.fa-duotone.fa-swap-opacity::after {
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute;
}

/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fontawesome/fa-thin-100.woff2") format("woff2"), url("../fonts/fontawesome/fa-thin-100.ttf") format("truetype");
}
.fat,
.fa-thin {
  font-weight: 100;
}

@font-face {
  font-family: "simple-line-icons";
  src: url("../fonts/simple-line-icons/Simple-Line-Icons.eot?v=2.4.0");
  src: url("../fonts/simple-line-icons/Simple-Line-Icons.eot?v=2.4.0#iefix") format("embedded-opentype"), url("../fonts/simple-line-icons/Simple-Line-Icons.woff2?v=2.4.0") format("woff2"), url("../fonts/simple-line-icons/Simple-Line-Icons.ttf?v=2.4.0") format("truetype"), url("../fonts/simple-line-icons/Simple-Line-Icons.woff?v=2.4.0") format("woff"), url("../fonts/simple-line-icons/Simple-Line-Icons.svg?v=2.4.0#simple-line-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
.si {
  font-family: "simple-line-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.si-user:before {
  content: "\e005";
}

.si-people:before {
  content: "\e001";
}

.si-user-female:before {
  content: "\e000";
}

.si-user-follow:before {
  content: "\e002";
}

.si-user-following:before {
  content: "\e003";
}

.si-user-unfollow:before {
  content: "\e004";
}

.si-login:before {
  content: "\e066";
}

.si-logout:before {
  content: "\e065";
}

.si-emotsmile:before {
  content: "\e021";
}

.si-phone:before {
  content: "\e600";
}

.si-call-end:before {
  content: "\e048";
}

.si-call-in:before {
  content: "\e047";
}

.si-call-out:before {
  content: "\e046";
}

.si-map:before {
  content: "\e033";
}

.si-location-pin:before {
  content: "\e096";
}

.si-direction:before {
  content: "\e042";
}

.si-directions:before {
  content: "\e041";
}

.si-compass:before {
  content: "\e045";
}

.si-layers:before {
  content: "\e034";
}

.si-menu:before {
  content: "\e601";
}

.si-list:before {
  content: "\e067";
}

.si-options-vertical:before {
  content: "\e602";
}

.si-options:before {
  content: "\e603";
}

.si-arrow-down:before {
  content: "\e604";
}

.si-arrow-left:before {
  content: "\e605";
}

.si-arrow-right:before {
  content: "\e606";
}

.si-arrow-up:before {
  content: "\e607";
}

.si-arrow-up-circle:before {
  content: "\e078";
}

.si-arrow-left-circle:before {
  content: "\e07a";
}

.si-arrow-right-circle:before {
  content: "\e079";
}

.si-arrow-down-circle:before {
  content: "\e07b";
}

.si-check:before {
  content: "\e080";
}

.si-clock:before {
  content: "\e081";
}

.si-plus:before {
  content: "\e095";
}

.si-minus:before {
  content: "\e615";
}

.si-close:before {
  content: "\e082";
}

.si-event:before {
  content: "\e619";
}

.si-exclamation:before {
  content: "\e617";
}

.si-organization:before {
  content: "\e616";
}

.si-trophy:before {
  content: "\e006";
}

.si-screen-smartphone:before {
  content: "\e010";
}

.si-screen-desktop:before {
  content: "\e011";
}

.si-plane:before {
  content: "\e012";
}

.si-notebook:before {
  content: "\e013";
}

.si-mustache:before {
  content: "\e014";
}

.si-mouse:before {
  content: "\e015";
}

.si-magnet:before {
  content: "\e016";
}

.si-energy:before {
  content: "\e020";
}

.si-disc:before {
  content: "\e022";
}

.si-cursor:before {
  content: "\e06e";
}

.si-cursor-move:before {
  content: "\e023";
}

.si-crop:before {
  content: "\e024";
}

.si-chemistry:before {
  content: "\e026";
}

.si-speedometer:before {
  content: "\e007";
}

.si-shield:before {
  content: "\e00e";
}

.si-screen-tablet:before {
  content: "\e00f";
}

.si-magic-wand:before {
  content: "\e017";
}

.si-hourglass:before {
  content: "\e018";
}

.si-graduation:before {
  content: "\e019";
}

.si-ghost:before {
  content: "\e01a";
}

.si-game-controller:before {
  content: "\e01b";
}

.si-fire:before {
  content: "\e01c";
}

.si-eyeglass:before {
  content: "\e01d";
}

.si-envelope-open:before {
  content: "\e01e";
}

.si-envelope-letter:before {
  content: "\e01f";
}

.si-bell:before {
  content: "\e027";
}

.si-badge:before {
  content: "\e028";
}

.si-anchor:before {
  content: "\e029";
}

.si-wallet:before {
  content: "\e02a";
}

.si-vector:before {
  content: "\e02b";
}

.si-speech:before {
  content: "\e02c";
}

.si-puzzle:before {
  content: "\e02d";
}

.si-printer:before {
  content: "\e02e";
}

.si-present:before {
  content: "\e02f";
}

.si-playlist:before {
  content: "\e030";
}

.si-pin:before {
  content: "\e031";
}

.si-picture:before {
  content: "\e032";
}

.si-handbag:before {
  content: "\e035";
}

.si-globe-alt:before {
  content: "\e036";
}

.si-globe:before {
  content: "\e037";
}

.si-folder-alt:before {
  content: "\e039";
}

.si-folder:before {
  content: "\e089";
}

.si-film:before {
  content: "\e03a";
}

.si-feed:before {
  content: "\e03b";
}

.si-drop:before {
  content: "\e03e";
}

.si-drawer:before {
  content: "\e03f";
}

.si-docs:before {
  content: "\e040";
}

.si-doc:before {
  content: "\e085";
}

.si-diamond:before {
  content: "\e043";
}

.si-cup:before {
  content: "\e044";
}

.si-calculator:before {
  content: "\e049";
}

.si-bubbles:before {
  content: "\e04a";
}

.si-briefcase:before {
  content: "\e04b";
}

.si-book-open:before {
  content: "\e04c";
}

.si-basket-loaded:before {
  content: "\e04d";
}

.si-basket:before {
  content: "\e04e";
}

.si-bag:before {
  content: "\e04f";
}

.si-action-undo:before {
  content: "\e050";
}

.si-action-redo:before {
  content: "\e051";
}

.si-wrench:before {
  content: "\e052";
}

.si-umbrella:before {
  content: "\e053";
}

.si-trash:before {
  content: "\e054";
}

.si-tag:before {
  content: "\e055";
}

.si-support:before {
  content: "\e056";
}

.si-frame:before {
  content: "\e038";
}

.si-size-fullscreen:before {
  content: "\e057";
}

.si-size-actual:before {
  content: "\e058";
}

.si-shuffle:before {
  content: "\e059";
}

.si-share-alt:before {
  content: "\e05a";
}

.si-share:before {
  content: "\e05b";
}

.si-rocket:before {
  content: "\e05c";
}

.si-question:before {
  content: "\e05d";
}

.si-pie-chart:before {
  content: "\e05e";
}

.si-pencil:before {
  content: "\e05f";
}

.si-note:before {
  content: "\e060";
}

.si-loop:before {
  content: "\e064";
}

.si-home:before {
  content: "\e069";
}

.si-grid:before {
  content: "\e06a";
}

.si-graph:before {
  content: "\e06b";
}

.si-microphone:before {
  content: "\e063";
}

.si-music-tone-alt:before {
  content: "\e061";
}

.si-music-tone:before {
  content: "\e062";
}

.si-earphones-alt:before {
  content: "\e03c";
}

.si-earphones:before {
  content: "\e03d";
}

.si-equalizer:before {
  content: "\e06c";
}

.si-like:before {
  content: "\e068";
}

.si-dislike:before {
  content: "\e06d";
}

.si-control-start:before {
  content: "\e06f";
}

.si-control-rewind:before {
  content: "\e070";
}

.si-control-play:before {
  content: "\e071";
}

.si-control-pause:before {
  content: "\e072";
}

.si-control-forward:before {
  content: "\e073";
}

.si-control-end:before {
  content: "\e074";
}

.si-volume-1:before {
  content: "\e09f";
}

.si-volume-2:before {
  content: "\e0a0";
}

.si-volume-off:before {
  content: "\e0a1";
}

.si-calendar:before {
  content: "\e075";
}

.si-bulb:before {
  content: "\e076";
}

.si-chart:before {
  content: "\e077";
}

.si-ban:before {
  content: "\e07c";
}

.si-bubble:before {
  content: "\e07d";
}

.si-camrecorder:before {
  content: "\e07e";
}

.si-camera:before {
  content: "\e07f";
}

.si-cloud-download:before {
  content: "\e083";
}

.si-cloud-upload:before {
  content: "\e084";
}

.si-envelope:before {
  content: "\e086";
}

.si-eye:before {
  content: "\e087";
}

.si-flag:before {
  content: "\e088";
}

.si-heart:before {
  content: "\e08a";
}

.si-info:before {
  content: "\e08b";
}

.si-key:before {
  content: "\e08c";
}

.si-link:before {
  content: "\e08d";
}

.si-lock:before {
  content: "\e08e";
}

.si-lock-open:before {
  content: "\e08f";
}

.si-magnifier:before {
  content: "\e090";
}

.si-magnifier-add:before {
  content: "\e091";
}

.si-magnifier-remove:before {
  content: "\e092";
}

.si-paper-clip:before {
  content: "\e093";
}

.si-paper-plane:before {
  content: "\e094";
}

.si-power:before {
  content: "\e097";
}

.si-refresh:before {
  content: "\e098";
}

.si-reload:before {
  content: "\e099";
}

.si-settings:before {
  content: "\e09a";
}

.si-star:before {
  content: "\e09b";
}

.si-symbol-female:before {
  content: "\e09c";
}

.si-symbol-male:before {
  content: "\e09d";
}

.si-target:before {
  content: "\e09e";
}

.si-credit-card:before {
  content: "\e025";
}

.si-paypal:before {
  content: "\e608";
}

.si-social-tumblr:before {
  content: "\e00a";
}

.si-social-twitter:before {
  content: "\e009";
}

.si-social-facebook:before {
  content: "\e00b";
}

.si-social-instagram:before {
  content: "\e609";
}

.si-social-linkedin:before {
  content: "\e60a";
}

.si-social-pinterest:before {
  content: "\e60b";
}

.si-social-github:before {
  content: "\e60c";
}

.si-social-google:before {
  content: "\e60d";
}

.si-social-reddit:before {
  content: "\e60e";
}

.si-social-skype:before {
  content: "\e60f";
}

.si-social-dribbble:before {
  content: "\e00d";
}

.si-social-behance:before {
  content: "\e610";
}

.si-social-foursqare:before {
  content: "\e611";
}

.si-social-soundcloud:before {
  content: "\e612";
}

.si-social-spotify:before {
  content: "\e613";
}

.si-social-stumbleupon:before {
  content: "\e614";
}

.si-social-youtube:before {
  content: "\e008";
}

.si-social-dropbox:before {
  content: "\e00c";
}

.si-social-vkontakte:before {
  content: "\e618";
}

.si-social-steam:before {
  content: "\e620";
}

.si-users:before {
  content: "\e001";
}

.si-bar-chart:before {
  content: "\e077";
}

.si-camcorder:before {
  content: "\e07e";
}

.si-emoticon-smile:before {
  content: "\e021";
}

.si-eyeglasses:before {
  content: "\e01d";
}

.si-moustache:before {
  content: "\e014";
}

.si-pointer:before {
  content: "\e096";
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-scrollbar {
  left: auto;
  right: 2px;
  width: 5px;
}

.simplebar-scrollbar::before {
  left: 0;
  right: 0;
  background: rgba(8, 10, 11, 0.75);
}
.sidebar-dark #sidebar .simplebar-scrollbar::before {
  background: #fff;
}

.colorpicker.dropdown-menu {
  min-width: auto;
}

.datepicker {
  z-index: 1056 !important;
}

.datepicker table thead th,
.datepicker table tbody td {
  padding: 0.375rem;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #faeab9;
  border-color: #faeab9;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #0284c7;
  border-color: #0284c7;
}

.cke_chrome,
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-toolbar {
  border-color: #e4e7ed !important;
}

.cke_top,
.ck.ck-toolbar {
  border-bottom-color: #e4e7ed !important;
  background: #f6f7f9 !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #e4e7ed !important;
}

.cke_bottom {
  border-top-color: #e4e7ed !important;
  background: #f6f7f9 !important;
}

.dropzone {
  min-height: 200px;
  background-color: #f6f7f9;
  border: 0.125rem dashed #d8dde5;
  border-radius: 0.5rem;
}
.dropzone .dz-message {
  margin: 4rem 0;
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  color: #3e444a;
}
.dropzone:hover {
  background-color: #fff;
  border-color: #0284c7;
}
.dropzone:hover .dz-message {
  color: #0284c7;
}

table.dataTable td,
table.dataTable th {
  box-sizing: border-box;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 1.25rem;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  display: none;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.375rem;
  bottom: 0.75rem;
  font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
  font-weight: 600;
  opacity: 0.4;
}
table.dataTable thead .sorting:after {
  content: "\f0dc";
}
table.dataTable thead .sorting_asc:after {
  content: "\f106";
}
table.dataTable thead .sorting_desc:after {
  content: "\f107";
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin-top: 0.75rem;
  justify-content: center;
}
@media (min-width: 768px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin-top: 0.125rem;
    justify-content: flex-end;
  }
}

.pie-chart {
  position: relative;
  display: block;
}
.pie-chart > canvas {
  display: block;
  margin: 0 auto;
}
.pie-chart > span {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  text-align: center;
  transform: translateY(-50%);
}

.fc-theme-standard a {
  color: #3e444a;
}
.fc-theme-standard .fc-button-primary {
  color: #3e444a;
  background-color: #e4e7ed;
  border-color: #e4e7ed;
}
.fc-theme-standard .fc-button-primary:not(:disabled):hover {
  color: #3e444a;
  background-color: #f0f2f5;
  border-color: #f0f2f5;
}
.fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active, .fc-theme-standard .fc-button-primary:not(:disabled):active {
  color: #3e444a;
  background-color: #f6f7f9;
  border-color: #f6f7f9;
}
.fc-theme-standard .fc-button-primary:focus, .fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active:focus, .fc-theme-standard .fc-button-primary:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 132, 199, 0.4);
}
.fc-theme-standard th,
.fc-theme-standard td,
.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard .fc-list {
  border-color: #e4e7ed;
}
.fc-theme-standard .fc-h-event {
  background-color: #0284c7;
  border: #0284c7;
}
.fc-theme-standard .fc-col-header-cell {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #f6f7f9;
}
.fc-theme-standard .fc-list-day-cushion {
  background-color: #f6f7f9;
}
@media (max-width: 1199.98px) {
  .fc-theme-standard .fc-toolbar.fc-header-toolbar {
    display: block;
  }
  .fc-theme-standard .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk > div,
  .fc-theme-standard .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk > .btn {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .fc-theme-standard .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:not(:first-child) {
    margin-top: 1rem;
  }
}

pre.pre-sh {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  border-radius: 0;
}
pre.pre-sh code.hljs {
  background: transparent;
}
pre.pre-sh code.pwrap {
  white-space: pre-wrap;
}

.irs.irs--round .irs-line,
.irs.irs--round .irs-bar {
  height: 0.25rem;
}
.irs.irs--round .irs-min,
.irs.irs--round .irs-max,
.irs.irs--round .irs-line,
.irs.irs--round .irs-grid-pol {
  background: #f0f2f5;
}
.irs.irs--round .irs-handle {
  border-color: #0284c7;
}
.irs.irs--round .irs-from:before,
.irs.irs--round .irs-to:before,
.irs.irs--round .irs-single:before {
  border-top-color: #0284c7;
}
.irs.irs--round .irs-bar,
.irs.irs--round .irs-from,
.irs.irs--round .irs-to,
.irs.irs--round .irs-single {
  background: #0284c7;
}

.jqstooltip {
  box-sizing: content-box;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border: none !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  border-radius: 0.25rem;
}

.jvectormap-tip {
  padding: 0.375rem 0.5rem;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background: #454c53;
  border: none;
  border-radius: 0;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  left: 1rem;
  padding: 0.25rem;
  line-height: 1rem;
  background: #454c53;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 1rem;
  height: 1rem;
}

.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover {
  opacity: 0.6;
}

.jvectormap-zoomout {
  top: 2.25rem;
}

.pw-strength-progress > .progress {
  height: 0.375rem;
}

.select2-container .select2-selection--single {
  height: calc(1.5em + 0.75rem + 2px);
}
.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
  margin: 0.25rem 0.25rem 0.25rem 0;
  height: 1.375rem;
  line-height: 1.375rem;
}
.select2-container .select2-dropdown {
  border-color: #d8dde5;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.select2-container .select2-dropdown .select2-search__field {
  padding: 0.25rem 0.75rem;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border-radius: 0.375rem;
  box-shadow: none;
}
.select2-container .select2-dropdown .select2-search__field:focus {
  border-color: #81c2e3;
  box-shadow: 0 0 0 0.25rem rgba(2, 132, 199, 0.25);
  outline: 0;
}

.select2-container--default .select2-selection--single {
  border-color: #d8dde5;
  border-radius: 0.375rem;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: calc(1.5em + 0.75rem + 2px);
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #6c757d;
}
.select2-container--default .select2-selection--multiple {
  display: flex;
  align-items: center;
  border-color: #d8dde5;
  border-radius: 0.375rem;
  min-height: calc(1.5em + 0.75rem + 2px);
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--single, .select2-container--default.select2-container--open .select2-selection--multiple, .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #81c2e3;
  box-shadow: 0 0 0 0.25rem rgba(2, 132, 199, 0.25);
  outline: 0;
}
.is-valid + .select2-container--default .select2-selection--single,
.is-valid + .select2-container--default .select2-selection--multiple, .is-valid + .select2-container--default.select2-container--focus .select2-selection--single,
.is-valid + .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #16A34A;
}
.is-valid + .select2-container--default.select2-container--focus .select2-selection--single,
.is-valid + .select2-container--default.select2-container--focus .select2-selection--multiple, .is-valid + .select2-container--default.select2-container--open .select2-selection--single,
.is-valid + .select2-container--default.select2-container--open .select2-selection--multiple {
  box-shadow: 0 0 0 0.25rem rgba(22, 163, 74, 0.25);
}
.is-invalid + .select2-container--default .select2-selection--single,
.is-invalid + .select2-container--default .select2-selection--multiple, .is-invalid + .select2-container--default.select2-container--focus .select2-selection--single,
.is-invalid + .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #dc2626;
}
.is-invalid + .select2-container--default.select2-container--focus .select2-selection--single,
.is-invalid + .select2-container--default.select2-container--focus .select2-selection--multiple, .is-invalid + .select2-container--default.select2-container--open .select2-selection--single,
.is-invalid + .select2-container--default.select2-container--open .select2-selection--multiple {
  box-shadow: 0 0 0 0.25rem rgba(220, 38, 38, 0.25);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin: 0.25rem 0.25rem 0.25rem 0;
  height: 1.375rem;
  line-height: 1.375rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: #0284c7;
  border: none;
  border-radius: 0.375rem;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.5);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: rgba(255, 255, 255, 0.75);
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #d8dde5;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #0284c7;
}
.select2-container--default .select2-search--inline .select2-search__field {
  padding-right: 0;
  padding-left: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  box-shadow: none;
}

.CodeMirror-fullscreen,
.editor-toolbar.fullscreen,
.editor-preview-side {
  z-index: 1057;
}

.editor-preview {
  z-index: 1056;
}

.editor-toolbar {
  border-color: #e4e7ed;
  background-color: #f6f7f9;
}

.CodeMirror {
  border-color: #e4e7ed;
}

.slick-slider .slick-slide {
  outline: none;
}
.slick-slider.slick-dotted {
  margin-bottom: 3rem;
}
.slick-slider.slick-dotted .slick-dots {
  bottom: -2rem;
}
.slick-slider.slick-dotted.slick-dotted-inner {
  margin-bottom: 0;
}
.slick-slider.slick-dotted.slick-dotted-inner .slick-dots {
  bottom: 0.625rem;
}
.slick-slider.slick-dotted.slick-dotted-white .slick-dots li button::before {
  color: #fff;
}
.slick-slider .slick-prev,
.slick-slider .slick-next {
  width: 2.5rem;
  height: 3.75rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.03);
  z-index: 2;
}
.slick-slider .slick-prev:hover,
.slick-slider .slick-next:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #454c53;
}
.slick-slider .slick-prev {
  left: 0;
}
.slick-slider .slick-prev::before {
  content: "\f104";
}
.slick-slider .slick-next {
  right: 0;
}
.slick-slider .slick-next::before {
  content: "\f105";
}
.slick-slider.slick-nav-white .slick-prev,
.slick-slider.slick-nav-white .slick-next {
  background-color: rgba(255, 255, 255, 0.5);
}
.slick-slider.slick-nav-white .slick-prev:hover,
.slick-slider.slick-nav-white .slick-next:hover {
  background-color: rgb(255, 255, 255);
}
.slick-slider.slick-nav-white .slick-prev:before,
.slick-slider.slick-nav-white .slick-next:before {
  color: #000;
}
.slick-slider.slick-nav-black .slick-prev,
.slick-slider.slick-nav-black .slick-next {
  background-color: rgba(0, 0, 0, 0.25);
}
.slick-slider.slick-nav-black .slick-prev:hover,
.slick-slider.slick-nav-black .slick-next:hover {
  background-color: rgb(0, 0, 0);
}
.slick-slider.slick-nav-black .slick-prev::before,
.slick-slider.slick-nav-black .slick-next::before {
  color: #fff;
}
.slick-slider.slick-nav-hover .slick-prev,
.slick-slider.slick-nav-hover .slick-next {
  opacity: 0;
  transition: opacity 0.25s ease-out;
}
.slick-slider.slick-nav-hover:hover .slick-prev,
.slick-slider.slick-nav-hover:hover .slick-next {
  opacity: 1;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-scrollbar {
  left: auto;
  right: 2px;
  width: 5px;
}

.simplebar-scrollbar::before {
  left: 0;
  right: 0;
  background: rgba(8, 10, 11, 0.75);
}
.sidebar-dark #sidebar .simplebar-scrollbar::before {
  background: #fff;
}

.dd,
.dd-item,
.dd-empty,
.dd-placeholder {
  font-size: 0.875rem;
}

.dd-handle {
  height: 2.25rem;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  color: #3e444a;
  background: #f6f7f9;
  border-color: #e4e7ed;
}
.dd-handle:hover {
  color: #171717;
}

.dd-item > button {
  height: 1.625rem;
}

.dd-empty,
.dd-placeholder {
  border-color: #1f2327;
  background: #aed8ed;
  opacity: 0.25;
}

.flatpickr-input.form-control:disabled,
.flatpickr-input.form-control[readonly],
.input.form-control:disabled,
.input.form-control[readonly] {
  color: #3e444a;
  background-color: #fff;
  border-color: #d8dde5;
}

.flatpickr-weekdays {
  height: 2rem;
  align-items: flex-end;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  border-color: #0284c7;
  background: #0284c7;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #0284c7;
}

#page-container.rtl-support {
  direction: rtl;
  text-align: right;
}
#page-container.rtl-support .list-inline-item:not(:last-child) {
  margin-right: 0;
  margin-left: 0.5rem;
}
@media (min-width: 992px) {
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar .content-side,
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar .content-header {
    transform: translateX(-176px) translateY(0) translateZ(0);
  }
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:hover .content-side,
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:hover .content-header {
    transform: translateX(0);
  }
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:not(:hover) .nav-main {
    transform: translateX(1.5rem);
  }
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:not(:hover) .nav-main .nav-main-link-icon {
    transform: translateX(-1rem);
  }
}
#page-container.rtl-support .nav-main {
  padding-left: auto;
  padding-right: 0;
}
#page-container.rtl-support .nav-main-link .nav-main-link-icon {
  margin-right: 0;
  margin-left: 0.625rem;
}
#page-container.rtl-support .nav-main-link .nav-main-link-badge {
  margin-right: 0.625rem;
  margin-left: auto;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  font-size: 0.75rem;
}
#page-container.rtl-support .nav-main-link.nav-main-link-submenu {
  padding-right: 1.5rem;
  padding-left: 2rem;
}
#page-container.rtl-support .nav-main-link.nav-main-link-submenu::before, #page-container.rtl-support .nav-main-link.nav-main-link-submenu::after {
  right: auto;
  left: 0.625rem;
}
#page-container.rtl-support .nav-main-link.nav-main-link-submenu::before {
  content: "\f105";
}
#page-container.rtl-support .nav-main-submenu {
  padding-right: 2.25rem;
  padding-left: 0;
}
#page-container.rtl-support .nav-main-submenu .nav-main-link {
  padding-right: 0;
  padding-left: 0.625rem;
}
#page-container.rtl-support .nav-main-submenu .nav-main-link.nav-main-link-submenu {
  padding-left: 2rem;
}
#page-container.rtl-support .nav-main-submenu .nav-main-submenu {
  padding-right: 0.75rem;
  padding-left: 0;
}
#page-container.rtl-support .nav-main-item.open > .nav-main-link-submenu::before {
  transform: rotate(90deg);
}
@media (min-width: 992px) {
  #page-container.rtl-support .nav-main-horizontal .nav-main-link-submenu::before {
    content: "\f107";
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-item > .nav-main-link {
    padding-right: 1rem;
    padding-left: 2rem;
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-item > .nav-main-link:not(.nav-main-link-submenu) {
    padding-left: 1rem;
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu {
    left: auto;
    right: 0;
    padding-left: auto;
    padding-right: 0;
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu.nav-main-submenu-left {
    right: auto;
    left: 0;
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu .nav-main-link {
    padding-left: 0.625rem;
    padding-right: 1rem;
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu .nav-main-link.nav-main-link-submenu::before {
    content: "\f104";
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu .nav-main-item.open > .nav-main-link-submenu::before {
    transform: scaleX(-1);
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-item.open > .nav-main-link-submenu::before {
    transform: scaleY(-1);
  }
  #page-container.rtl-support .nav-main-horizontal .nav-main-submenu .nav-main-submenu {
    left: -100%;
    right: auto;
  }
  #page-container.rtl-support .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu::before {
    transform: scaleY(-1);
  }
  #page-container.rtl-support .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover > .nav-main-link-submenu::before {
    transform: scaleX(-1);
  }
}

.dark-mode,
.dark-mode #side-overlay,
.dark-mode #page-loader {
  background-color: #1f2326;
  color: #a0aab3;
}
@media (min-width: 992px) {
  .dark-mode.page-header-modern.page-header-dark #page-header {
    background-color: #1f2326 !important;
  }
}
.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode .h1,
.dark-mode .h2,
.dark-mode .h3,
.dark-mode .h4,
.dark-mode .h5,
.dark-mode .h6 {
  color: #e4e7ed;
}
.dark-mode .content-heading {
  border-bottom-color: #333a40;
}
.dark-mode hr {
  border-top-color: #333a40;
}
.dark-mode code {
  color: #e685b5;
}
.dark-mode .story p,
.dark-mode p.story {
  color: #84909c;
}
.dark-mode a:not(.badge):not(.btn):not(.btn-block-option):not(.block):not(.dropdown-item):not(.nav-link):not(.page-link):not(.alert-link):not(.nav-main-link):not(.list-group-item-action):not(.close):not(.fc-event):not(.text-success-light):not(.text-danger-light):not(.text-warning-light):not(.text-info-light) {
  color: #03a5f9;
}
.dark-mode a:not(.badge):not(.btn):not(.btn-block-option):not(.block):not(.dropdown-item):not(.nav-link):not(.page-link):not(.alert-link):not(.nav-main-link):not(.list-group-item-action):not(.close):not(.fc-event):not(.text-success-light):not(.text-danger-light):not(.text-warning-light):not(.text-info-light).link-fx::before {
  background-color: #03a5f9;
}
.dark-mode a:not(.badge):not(.btn):not(.btn-block-option):not(.block):not(.dropdown-item):not(.nav-link):not(.page-link):not(.alert-link):not(.nav-main-link):not(.list-group-item-action):not(.close):not(.fc-event):not(.text-success-light):not(.text-danger-light):not(.text-warning-light):not(.text-info-light):hover {
  color: #0284c7;
}
.dark-mode .bg-body {
  background-color: #1f2326 !important;
}
.dark-mode a.bg-body:hover, .dark-mode a.bg-body:focus,
.dark-mode button.bg-body:hover,
.dark-mode button.bg-body:focus {
  background-color: #08090a !important;
}
.dark-mode .bg-body-light {
  background-color: #2c3237 !important;
}
.dark-mode a.bg-body-light:hover, .dark-mode a.bg-body-light:focus,
.dark-mode button.bg-body-light:hover,
.dark-mode button.bg-body-light:focus {
  background-color: #16181b !important;
}
.dark-mode .bg-body-dark {
  background-color: #1b1f22 !important;
}
.dark-mode a.bg-body-dark:hover, .dark-mode a.bg-body-dark:focus,
.dark-mode button.bg-body-dark:hover,
.dark-mode button.bg-body-dark:focus {
  background-color: #050506 !important;
}
.dark-mode .bg-body-extra-light {
  background-color: #262a2f !important;
}
.dark-mode a.bg-body-extra-light:hover, .dark-mode a.bg-body-extra-light:focus,
.dark-mode button.bg-body-extra-light:hover,
.dark-mode button.bg-body-extra-light:focus {
  background-color: #0f1113 !important;
}
.dark-mode .bg-muted {
  background-color: #9aa8bc !important;
}
.dark-mode a.bg-muted:hover, .dark-mode a.bg-muted:focus,
.dark-mode button.bg-muted:hover,
.dark-mode button.bg-muted:focus {
  background-color: #7c8da7 !important;
}
.dark-mode .text-primary {
  color: #0295e0 !important;
}
.dark-mode a.text-primary.link-fx::before {
  background-color: #0295e0 !important;
}
.dark-mode a.text-primary:hover, .dark-mode a.text-primary:focus {
  color: #0273ae !important;
}
.dark-mode .text-success {
  color: #19b954 !important;
}
.dark-mode a.text-success.link-fx::before {
  background-color: #19b954 !important;
}
.dark-mode a.text-success:hover, .dark-mode a.text-success:focus {
  color: #138d40 !important;
}
.dark-mode .text-warning {
  color: #f28507 !important;
}
.dark-mode a.text-warning.link-fx::before {
  background-color: #f28507 !important;
}
.dark-mode a.text-warning:hover, .dark-mode a.text-warning:focus {
  color: #c06905 !important;
}
.dark-mode .text-info {
  color: #09a5ca !important;
}
.dark-mode a.text-info.link-fx::before {
  background-color: #09a5ca !important;
}
.dark-mode a.text-info:hover, .dark-mode a.text-info:focus {
  color: #077d9a !important;
}
.dark-mode .text-danger {
  color: #e03c3c !important;
}
.dark-mode a.text-danger.link-fx::before {
  background-color: #e03c3c !important;
}
.dark-mode a.text-danger:hover, .dark-mode a.text-danger:focus {
  color: #c82020 !important;
}
.dark-mode .text-body-bg {
  color: #1f2326 !important;
}
.dark-mode a.text-body-bg.link-fx::before {
  background-color: #1f2326 !important;
}
.dark-mode a.text-body-bg:hover, .dark-mode a.text-body-bg:focus {
  color: #08090a !important;
}
.dark-mode .text-body-bg-dark {
  color: #181b1e !important;
}
.dark-mode a.text-body-bg-dark.link-fx::before {
  background-color: #181b1e !important;
}
.dark-mode a.text-body-bg-dark:hover, .dark-mode a.text-body-bg-dark:focus {
  color: #010102 !important;
}
.dark-mode .text-body-bg-light {
  color: #2c3237 !important;
}
.dark-mode a.text-body-bg-light.link-fx::before {
  background-color: #2c3237 !important;
}
.dark-mode a.text-body-bg-light:hover, .dark-mode a.text-body-bg-light:focus {
  color: #16181b !important;
}
.dark-mode .text-body-color {
  color: #a0aab3 !important;
}
.dark-mode a.text-body-color.link-fx::before {
  background-color: #a0aab3 !important;
}
.dark-mode a.text-body-color:hover, .dark-mode a.text-body-color:focus {
  color: #84909c !important;
}
.dark-mode .text-body-color-dark {
  color: #768491 !important;
}
.dark-mode a.text-body-color-dark.link-fx::before {
  background-color: #768491 !important;
}
.dark-mode a.text-body-color-dark:hover, .dark-mode a.text-body-color-dark:focus {
  color: #5e6a76 !important;
}
.dark-mode .text-body-color-light {
  color: #cbd0d5 !important;
}
.dark-mode a.text-body-color-light.link-fx::before {
  background-color: #cbd0d5 !important;
}
.dark-mode a.text-body-color-light:hover, .dark-mode a.text-body-color-light:focus {
  color: #afb7be !important;
}
.dark-mode .text-dark {
  color: #a7b2c4 !important;
}
.dark-mode a.text-dark.link-fx::before {
  background-color: #a7b2c4 !important;
}
.dark-mode a.text-dark:hover, .dark-mode a.text-dark:focus {
  color: #8898b0 !important;
}
.dark-mode .text-muted {
  color: #768491 !important;
}
.dark-mode a.text-muted.link-fx::before {
  background-color: #768491 !important;
}
.dark-mode a.text-muted:hover, .dark-mode a.text-muted:focus {
  color: #5e6a76 !important;
}
.dark-mode .btn-secondary,
.dark-mode .btn-alt-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3c444b;
  --bs-btn-border-color: #3c444b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #373d44;
  --bs-btn-hover-border-color: #373d44;
  --bs-btn-focus-shadow-rgb: 89, 96, 102;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #30363c;
  --bs-btn-active-border-color: #2d3338;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3c444b;
  --bs-btn-disabled-border-color: #3c444b;
}
.dark-mode .nav-link {
  color: #c5cdd8;
}
.dark-mode .nav-link:hover, .dark-mode .nav-link:focus {
  color: #0284c7;
}
.dark-mode .nav-pills .nav-link {
  color: #8898b0;
}
.dark-mode .nav-pills .nav-link:hover, .dark-mode .nav-pills .nav-link:focus {
  background-color: #383f45;
}
.dark-mode .nav-pills .nav-link.active,
.dark-mode .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0284c7;
}
.dark-mode .nav-tabs {
  border-bottom-color: #333a40;
}
.dark-mode .nav-tabs .nav-link:hover, .dark-mode .nav-tabs .nav-link:focus {
  border-color: #333a40 #333a40 #333a40;
}
.dark-mode .nav-tabs .nav-link.active,
.dark-mode .nav-tabs .nav-item.show .nav-link {
  color: #c5cdd8;
  background-color: transparent;
  border-color: #333a40 #333a40 #262a2f;
}
.dark-mode .nav-tabs-block {
  background-color: #333a40;
}
.dark-mode .nav-tabs-block .nav-link {
  border-color: transparent;
  color: #c5cdd8;
}
.dark-mode .nav-tabs-block .nav-link:hover, .dark-mode .nav-tabs-block .nav-link:focus {
  color: #0284c7;
  background-color: #2c3237;
  border-color: transparent;
}
.dark-mode .nav-tabs-block .nav-link.active,
.dark-mode .nav-tabs-block .nav-item.show .nav-link {
  color: #c5cdd8;
  background-color: #262a2f;
  border-color: transparent;
}
.dark-mode .nav-tabs-alt {
  border-bottom-color: #2c3237;
}
.dark-mode .nav-tabs-alt .nav-link {
  color: #c5cdd8;
  background-color: transparent;
  border-color: transparent;
}
.dark-mode .nav-tabs-alt .nav-link:hover, .dark-mode .nav-tabs-alt .nav-link:focus {
  color: #0284c7;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #0284c7;
}
.dark-mode .nav-tabs-alt .nav-link.active,
.dark-mode .nav-tabs-alt .nav-item.show .nav-link {
  color: #c5cdd8;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #0284c7;
}
.dark-mode .page-item.active .page-link {
  color: #fff;
  background-color: #0284c7;
  border-color: #0284c7;
}
.dark-mode .page-item.disabled .page-link {
  color: #54657e;
  background-color: transparent;
  border-color: transparent;
}
.dark-mode .page-link {
  color: #8898b0;
  background-color: #1f2326;
  border-color: #1f2326;
}
.dark-mode .page-link:hover {
  color: #8898b0;
  background-color: #131618;
  border-color: #131618;
}
.dark-mode .page-link:focus {
  background-color: #181b1e;
  border-color: #181b1e;
}
.dark-mode .list-group-item-action {
  color: #c5cdd8;
}
.dark-mode .list-group-item-action:hover, .dark-mode .list-group-item-action:focus {
  color: #c5cdd8;
  background-color: #30363c;
}
.dark-mode .list-group-item-action:active {
  color: #c5cdd8;
  background-color: #1f2326;
}
.dark-mode .list-group-item-action.disabled {
  color: #697d9b;
}
.dark-mode .list-group-item {
  color: #c5cdd8;
  background-color: #2c3237;
  border-color: #1b1f22;
}
.dark-mode .list-group-item.active {
  color: #fff;
  background-color: #0284c7;
  border-color: #0284c7;
}
.dark-mode .popover {
  border-color: #1b1f22;
  background-color: #2c3237;
}
.dark-mode .bs-popover-top .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=top] .popover-arrow::before {
  border-top-color: #1b1f22;
}
.dark-mode .bs-popover-top .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=top] .popover-arrow::after {
  border-top-color: #2c3237;
}
.dark-mode .bs-popover-end .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=right] .popover-arrow::before {
  border-right-color: #1b1f22;
}
.dark-mode .bs-popover-end .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=right] .popover-arrow::after {
  border-right-color: #2c3237;
}
.dark-mode .bs-popover-bottom .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=bottom] .popover-arrow::before {
  border-bottom-color: #1b1f22;
}
.dark-mode .bs-popover-bottom .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=bottom] .popover-arrow::after {
  border-bottom-color: #2c3237;
}
.dark-mode .bs-popover-start .popover-arrow::before, .dark-mode .bs-popover-auto[x-placement^=left] .popover-arrow::before {
  border-left-color: #1b1f22;
}
.dark-mode .bs-popover-start .popover-arrow::after, .dark-mode .bs-popover-auto[x-placement^=left] .popover-arrow::after {
  border-left-color: #2c3237;
}
.dark-mode .popover-header {
  color: #e4e7ed;
  background-color: #2c3237;
  border-bottom-color: #1b1f22;
}
.dark-mode .popover-body {
  color: #c5cdd8;
  background-color: #2c3237;
}
.dark-mode .dropdown-menu {
  color: #c5cdd8;
  background-color: #262a2f;
  border-color: #2c3237;
  box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.25);
}
.dark-mode .dropdown-menu .dropdown-item {
  color: #b6c0ce;
}
.dark-mode .dropdown-menu .dropdown-item:hover, .dark-mode .dropdown-menu .dropdown-item:focus {
  color: #c5cdd8;
  background-color: #383f45;
}
.dark-mode .dropdown-menu .dropdown-item.active, .dark-mode .dropdown-menu .dropdown-item:active {
  color: #d4dae3;
  background-color: #414951;
}
.dark-mode .dropdown-menu .dropdown-item.disabled, .dark-mode .dropdown-menu .dropdown-item:disabled {
  color: #54657e;
}
.dark-mode .dropdown-menu .dropdown-divider {
  border-color: #3c444b;
}
.dark-mode .dropdown-menu .dropdown-item-text {
  color: #c5cdd8;
}
.dark-mode .dropdown-menu .dropdown-header {
  color: #97a5ba !important;
}
.dark-mode .table {
  --bs-table-striped-color: #f6f7f9;
  --bs-table-striped-bg: #22262b;
  --bs-table-active-color: #f6f7f9;
  --bs-table-active-bg: #333a40;
  --bs-table-hover-color: #f6f7f9;
  --bs-table-hover-bg: #202428;
  color: #f6f7f9;
  border-color: #181b1e;
}
.dark-mode .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #181b1e;
}
.dark-mode .table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(27, 31, 34, 0.75);
  --bs-table-border-color: rgba(63, 66, 69, 0.775);
  --bs-table-striped-bg: rgba(45, 49, 52, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(63, 66, 69, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(54, 58, 60, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .table-primary {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(2, 106, 159, 0.75);
  --bs-table-border-color: rgba(42, 129, 174, 0.775);
  --bs-table-striped-bg: rgba(22, 118, 167, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(42, 129, 174, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(32, 124, 170, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .table-info {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(30, 58, 138, 0.75);
  --bs-table-border-color: rgba(65, 89, 156, 0.775);
  --bs-table-striped-bg: rgba(48, 74, 147, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(65, 89, 156, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(57, 81, 152, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .table-success {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(20, 83, 45, 0.75);
  --bs-table-border-color: rgba(57, 110, 78, 0.775);
  --bs-table-striped-bg: rgba(39, 97, 62, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(57, 110, 78, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(48, 103, 70, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .table-danger {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(77, 51, 51, 0.75);
  --bs-table-border-color: rgba(105, 83, 83, 0.775);
  --bs-table-striped-bg: rgba(91, 67, 67, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(105, 83, 83, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(98, 75, 75, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .table-warning {
  --bs-table-color: #fff;
  --bs-table-bg: rgba(113, 63, 18, 0.75);
  --bs-table-border-color: rgba(135, 93, 55, 0.775);
  --bs-table-striped-bg: rgba(124, 78, 37, 0.7625);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgba(135, 93, 55, 0.775);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgba(130, 86, 46, 0.76875);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
.dark-mode .form-text {
  color: #768491;
}
.dark-mode .form-control::placeholder {
  color: #768491;
}
.dark-mode .form-floating > .form-control::placeholder {
  color: transparent;
}
.dark-mode .form-control,
.dark-mode .form-select {
  color: #c5cdd8;
  background-color: #1b1f22;
  border-color: #383f45;
}
.dark-mode .form-control:focus,
.dark-mode .form-select:focus {
  color: #fff;
  background-color: #1b1f22;
  border-color: #0284c7;
}
.dark-mode .form-control:disabled, .dark-mode .form-control[readonly],
.dark-mode .form-select:disabled,
.dark-mode .form-select[readonly] {
  background-color: #2a2f35;
  border-color: #2a2f35;
}
.dark-mode .form-select:focus::-ms-value {
  color: #c5cdd8;
  background-color: #1b1f22;
}
.dark-mode .form-control-plaintext {
  color: #c5cdd8;
}
.dark-mode .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  color: #1cd05e;
}
.dark-mode .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(28, 208, 94, 0.9);
  border-radius: 0.375rem;
}
.was-validated .dark-mode:valid ~ .valid-feedback,
.was-validated .dark-mode:valid ~ .valid-tooltip, .dark-mode.is-valid ~ .valid-feedback,
.dark-mode.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .dark-mode .form-control:valid, .dark-mode .form-control.is-valid {
  border-color: #1cd05e;
}
.was-validated .dark-mode .form-control:valid:focus, .dark-mode .form-control.is-valid:focus {
  border-color: #1cd05e;
  box-shadow: 0 0 0 0.25rem rgba(28, 208, 94, 0.25);
}
.was-validated .dark-mode .form-select:valid, .dark-mode .form-select.is-valid {
  border-color: #1cd05e;
}
.was-validated .dark-mode .form-select:valid:focus, .dark-mode .form-select.is-valid:focus {
  border-color: #1cd05e;
  box-shadow: 0 0 0 0.25rem rgba(28, 208, 94, 0.25);
}
.was-validated .dark-mode .form-check-input:valid, .dark-mode .form-check-input.is-valid {
  border-color: #1cd05e;
}
.was-validated .dark-mode .form-check-input:valid:checked, .dark-mode .form-check-input.is-valid:checked {
  background-color: #1cd05e;
}
.was-validated .dark-mode .form-check-input:valid:focus, .dark-mode .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 208, 94, 0.25);
}
.was-validated .dark-mode .form-check-input:valid ~ .form-check-label, .dark-mode .form-check-input.is-valid ~ .form-check-label {
  color: #1cd05e;
}
.dark-mode .form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.was-validated .dark-mode .input-group > .form-control:not(:focus):valid, .dark-mode .input-group > .form-control:not(:focus).is-valid,
.was-validated .dark-mode .input-group > .form-select:not(:focus):valid,
.dark-mode .input-group > .form-select:not(:focus).is-valid,
.was-validated .dark-mode .input-group > .form-floating:not(:focus-within):valid,
.dark-mode .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}
.dark-mode .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  color: #e76868;
}
.dark-mode .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(231, 104, 104, 0.9);
  border-radius: 0.375rem;
}
.was-validated .dark-mode:invalid ~ .invalid-feedback,
.was-validated .dark-mode:invalid ~ .invalid-tooltip, .dark-mode.is-invalid ~ .invalid-feedback,
.dark-mode.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .dark-mode .form-control:invalid, .dark-mode .form-control.is-invalid {
  border-color: #e76868;
}
.was-validated .dark-mode .form-control:invalid:focus, .dark-mode .form-control.is-invalid:focus {
  border-color: #e76868;
  box-shadow: 0 0 0 0.25rem rgba(231, 104, 104, 0.25);
}
.was-validated .dark-mode .form-select:invalid, .dark-mode .form-select.is-invalid {
  border-color: #e76868;
}
.was-validated .dark-mode .form-select:invalid:focus, .dark-mode .form-select.is-invalid:focus {
  border-color: #e76868;
  box-shadow: 0 0 0 0.25rem rgba(231, 104, 104, 0.25);
}
.was-validated .dark-mode .form-check-input:invalid, .dark-mode .form-check-input.is-invalid {
  border-color: #e76868;
}
.was-validated .dark-mode .form-check-input:invalid:checked, .dark-mode .form-check-input.is-invalid:checked {
  background-color: #e76868;
}
.was-validated .dark-mode .form-check-input:invalid:focus, .dark-mode .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(231, 104, 104, 0.25);
}
.was-validated .dark-mode .form-check-input:invalid ~ .form-check-label, .dark-mode .form-check-input.is-invalid ~ .form-check-label {
  color: #e76868;
}
.dark-mode .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.was-validated .dark-mode .input-group > .form-control:not(:focus):invalid, .dark-mode .input-group > .form-control:not(:focus).is-invalid,
.was-validated .dark-mode .input-group > .form-select:not(:focus):invalid,
.dark-mode .input-group > .form-select:not(:focus).is-invalid,
.was-validated .dark-mode .input-group > .form-floating:not(:focus-within):invalid,
.dark-mode .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}
.dark-mode .form-control.form-control-alt {
  color: #c5cdd8;
  border-color: #1f2326;
  background-color: #1f2326;
}
.dark-mode .form-control.form-control-alt:focus {
  color: #fff;
  border-color: #1b1f22;
  background-color: #1b1f22;
  box-shadow: none;
}
.dark-mode .form-control.form-control-alt.is-valid {
  border-color: rgba(20, 83, 45, 0.85);
  background-color: rgba(20, 83, 45, 0.85);
}
.dark-mode .form-control.form-control-alt.is-valid::placeholder {
  color: #768491;
}
.dark-mode .form-control.form-control-alt.is-valid:focus {
  border-color: #14532d;
  background-color: #14532d;
}
.dark-mode .form-control.form-control-alt.is-invalid {
  border-color: rgba(127, 29, 29, 0.85);
  background-color: rgba(127, 29, 29, 0.85);
}
.dark-mode .form-control.form-control-alt.is-invalid::placeholder {
  color: #768491;
}
.dark-mode .form-control.form-control-alt.is-invalid:focus {
  border-color: #7f1d1d;
  background-color: #7f1d1d;
}
.dark-mode .input-group-text {
  color: #c5cdd8;
  background-color: #1f2326;
  border-color: #383f45;
}
.dark-mode .input-group-text.input-group-text-alt {
  background-color: #1b1f22;
  border-color: #1b1f22;
}
.dark-mode .is-valid ~ .valid-feedback,
.dark-mode .is-valid ~ .valid-tooltip,
.dark-mode .was-validated :valid ~ .valid-feedback,
.dark-mode .was-validated :valid ~ .valid-tooltip,
.dark-mode .is-invalid ~ .invalid-feedback,
.dark-mode .is-invalid ~ .invalid-tooltip,
.dark-mode .was-validated :invalid ~ .invalid-feedback,
.dark-mode .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.dark-mode .form-check-input {
  background-color: #1f2326;
  border-color: #383f45;
}
.dark-mode .form-check-input:focus {
  border-color: #0284c7;
}
.dark-mode .form-check-input:checked {
  background-color: #0284c7;
  border-color: #0284c7;
}
.dark-mode .form-block .form-check-label {
  border-color: #383f45;
}
.dark-mode .form-block .form-check-label:hover {
  border-color: #3b424a;
}
.dark-mode .form-block .form-check-label::before {
  background-color: #0284c7;
}
.dark-mode .form-block .form-check-input:checked ~ .form-check-label {
  border-color: #0284c7;
}
.dark-mode .form-block .form-check-input:focus ~ .form-check-label {
  border-color: #0284c7;
  box-shadow: 0 0 0 0.25rem rgba(2, 132, 199, 0.25);
}
.dark-mode .form-block .form-check-input:disabled:not([checked]) + .form-check-label:hover,
.dark-mode .form-block .form-check-input[readonly]:not([checked]) + .form-check-label:hover {
  border-color: #1b1f22;
}
.dark-mode .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.25);
}
.dark-mode .breadcrumb.breadcrumb-alt .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.25);
}
.dark-mode .alert-primary {
  --bs-alert-color: #fff;
  --bs-alert-bg: #0284c7;
  --bs-alert-border-color: #0284c7;
}
.dark-mode .alert-primary .alert-link {
  color: #cccccc;
}
.dark-mode .alert-secondary {
  --bs-alert-color: #fff;
  --bs-alert-bg: #333a40;
  --bs-alert-border-color: #333a40;
}
.dark-mode .alert-secondary .alert-link {
  color: #cccccc;
}
.dark-mode .alert-success {
  --bs-alert-color: #fff;
  --bs-alert-bg: #117a38;
  --bs-alert-border-color: #117a38;
}
.dark-mode .alert-success .alert-link {
  color: #cccccc;
}
.dark-mode .alert-info {
  --bs-alert-color: #fff;
  --bs-alert-bg: #066d86;
  --bs-alert-border-color: #066d86;
}
.dark-mode .alert-info .alert-link {
  color: #cccccc;
}
.dark-mode .alert-warning {
  --bs-alert-color: #fff;
  --bs-alert-bg: #a35905;
  --bs-alert-border-color: #a35905;
}
.dark-mode .alert-warning .alert-link {
  color: #cccccc;
}
.dark-mode .alert-danger {
  --bs-alert-color: #fff;
  --bs-alert-bg: #a51d1d;
  --bs-alert-border-color: #a51d1d;
}
.dark-mode .alert-danger .alert-link {
  color: #cccccc;
}
.dark-mode .alert-dark {
  --bs-alert-color: #fff;
  --bs-alert-bg: #181b1e;
  --bs-alert-border-color: #181b1e;
}
.dark-mode .alert-dark .alert-link {
  color: #cccccc;
}
.dark-mode .alert-light {
  --bs-alert-color: #fff;
  --bs-alert-bg: #414951;
  --bs-alert-border-color: #414951;
}
.dark-mode .alert-light .alert-link {
  color: #cccccc;
}
.dark-mode .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.dark-mode .progress {
  background-color: #1b1f22;
}
.dark-mode .modal-header {
  border-bottom-color: #333a40;
}
.dark-mode .modal-content {
  background: #262a2f;
}
.dark-mode .modal-footer {
  border-top-color: #333a40;
}
.dark-mode .toast {
  background-color: #262a2f;
}
.dark-mode .toast-header {
  color: #c5cdd8;
  background-color: #333a40;
}
.dark-mode .border {
  border-color: #333a40 !important;
}
.dark-mode .border-top {
  border-top-color: #333a40 !important;
}
.dark-mode .border-end {
  border-right-color: #333a40 !important;
}
.dark-mode .border-bottom {
  border-bottom-color: #333a40 !important;
}
.dark-mode .border-start {
  border-left-color: #333a40 !important;
}
.dark-mode .border-primary {
  border-color: #0284c7 !important;
}
.dark-mode .border-white {
  border-color: #fff !important;
}
.dark-mode .border-white-op {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.dark-mode .border-black-op {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.dark-mode .block {
  background-color: #262a2f;
  box-shadow: 0 1px 2px rgba(29, 32, 36, 0.5), 0 1px 2px rgba(29, 32, 36, 0.5);
}
.dark-mode .block.block-bordered {
  border: 1px solid #333a40;
  box-shadow: none;
}
.dark-mode .block .block-header-default {
  background-color: #333a40 !important;
}
.dark-mode .block .block-title small {
  color: #9aa8bc;
}
.dark-mode .block.block-mode-loading::before {
  background-color: rgba(51, 58, 64, 0.85);
}
.dark-mode .block.block-mode-loading::after {
  color: #fff;
}
.dark-mode .block.block-transparent {
  background-color: transparent;
  box-shadow: none;
}
.dark-mode .block.block-mode-fullscreen.block-transparent {
  background-color: #262a2f;
}
.dark-mode .block .block,
.dark-mode .content-side .block {
  box-shadow: none;
}
.dark-mode a.block {
  color: #c5cdd8;
}
.dark-mode a.block.block-link-pop:hover {
  box-shadow: 0 0.5rem 2.5rem #131618;
}
.dark-mode a.block.block-link-pop:active {
  box-shadow: 0 0.375rem 0.55rem #23282c;
}
.dark-mode a.block.block-link-shadow:hover {
  box-shadow: 0 0 1.5rem #131618;
}
.dark-mode a.block.block-link-shadow:active {
  box-shadow: 0 0 0.75rem #1a1e21;
}
.dark-mode .btn-block-option {
  color: #9aa8bc;
}
.block-header-default .dark-mode .btn-block-option {
  color: #8b9ab2;
}
.dark-mode .btn-block-option:hover, .dark-mode .btn-block-option:focus {
  color: #7c8da7;
}
.dark-mode .btn-block-option:active {
  color: #9aa8bc;
}
.dark-mode a.btn-block-option:focus,
.dark-mode .active > a.btn-block-option,
.dark-mode .show > button.btn-block-option {
  color: #7c8da7;
}
.dark-mode .block.block-themed .btn-block-option,
.dark-mode .block.block-themed .btn-block-option:hover,
.dark-mode .block.block-themed .btn-block-option:focus,
.dark-mode .block.block-themed .btn-block-option:active,
.dark-mode .block.block-themed a.btn-block-option:focus,
.dark-mode .block.block-themed .active > a.btn-block-option,
.dark-mode .block.block-themed .show > button.btn-block-option {
  color: #fff;
}
.dark-mode .nav-users a {
  border-bottom-color: #1f2326;
}
.dark-mode .nav-users a:hover {
  background-color: #2c3237;
}
.dark-mode .nav-items a:hover {
  background-color: #383f45;
}
.dark-mode .nav-items a:active {
  background-color: transparent;
}
.dark-mode .nav-items > li:not(:last-child) > a {
  border-bottom-color: #333a40;
}
.dark-mode .list-activity > li:not(:last-child) {
  border-bottom-color: #1b1f22;
}
.dark-mode .timeline .timeline-event-time {
  background-color: #333a40;
}
@media (min-width: 768px) {
  .dark-mode .timeline-modern::before {
    background-color: #333a40;
  }
  .dark-mode .timeline-modern .timeline-event-time {
    background-color: transparent;
  }
}
.dark-mode .img-thumb {
  background-color: #333a40;
}
.dark-mode .swal2-popup {
  background-color: #262a2f;
}
.dark-mode .swal2-html-container {
  color: #c5cdd8;
}
.dark-mode .jvectormap-container {
  background-color: #262a2f !important;
}
.dark-mode .ck.ck-editor {
  color: #3e444a;
}
.dark-mode .dropzone {
  background-color: #2c3237;
  border-color: #333a40;
}
.dark-mode .dropzone .dz-message {
  color: #c5cdd8;
}
.dark-mode .dropzone:hover {
  background-color: #333a40;
  border-color: #0284c7;
}
.dark-mode .dropzone:hover .dz-message {
  color: #0284c7;
}
.dark-mode .dropzone .dz-preview.dz-image-preview {
  background-color: transparent;
}
.dark-mode .fc-theme-standard a {
  color: #b6c0ce;
}
.dark-mode .fc-theme-standard .fc-button-primary {
  color: #b6c0ce;
  background-color: #1b1f22;
  border-color: #1b1f22;
}
.dark-mode .fc-theme-standard .fc-button-primary:not(:disabled):hover {
  color: #c5cdd8;
  background-color: #2c3237;
  border-color: #2c3237;
}
.dark-mode .fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active, .dark-mode .fc-theme-standard .fc-button-primary:not(:disabled):active {
  color: #c5cdd8;
  background-color: #2c3237;
  border-color: #2c3237;
}
.dark-mode .fc-theme-standard .fc-button-primary:focus, .dark-mode .fc-theme-standard .fc-button-primary:not(:disabled).fc-button-active:focus, .dark-mode .fc-theme-standard .fc-button-primary:not(:disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 132, 199, 0.4);
}
.dark-mode .fc-theme-standard .fc-list, .dark-mode .fc-theme-standard .fc-scrollgrid,
.dark-mode .fc-theme-standard th,
.dark-mode .fc-theme-standard td {
  border-color: #181b1e;
}
.dark-mode .fc-theme-standard .fc-list-day-cushion,
.dark-mode .fc-theme-standard .fc-col-header-cell {
  background-color: #2c3237;
}
.dark-mode .fc-theme-standard .fc-list-event:hover td {
  background-color: #202428;
}
.dark-mode .irs,
.dark-mode .irs--round .irs-grid-text {
  color: #c5cdd8;
}
.dark-mode .irs.irs--round .irs-min,
.dark-mode .irs.irs--round .irs-max,
.dark-mode .irs.irs--round .irs-line,
.dark-mode .irs.irs--round .irs-grid-pol,
.dark-mode .irs.irs--round .irs-handle {
  color: #c5cdd8;
  background: #1f2326;
}
.dark-mode .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #768491;
}
.dark-mode .select2-container--default .select2-selection--single,
.dark-mode .select2-container--default .select2-selection--multiple {
  background-color: #1b1f22;
  border-color: #383f45;
}
.dark-mode .select2-container--default.select2-container--focus .select2-selection--multiple, .dark-mode .select2-container--default.select2-container--focus .select2-selection--single, .dark-mode .select2-container--default.select2-container--open .select2-selection--multiple, .dark-mode .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #0284c7;
}
.dark-mode .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #c5cdd8;
}
.dark-mode .select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #383f45;
}
.dark-mode .select2-container--default .select2-dropdown .select2-search__field:focus {
  border-color: #0284c7;
}
.dark-mode .select2-container--default .select2-dropdown {
  background-color: #1b1f22;
  border-color: #383f45;
}
.dark-mode .select2-container--default .select2-search--dropdown .select2-search__field {
  color: #c5cdd8;
  background-color: #1b1f22;
  border-color: #383f45;
}
.dark-mode .select2-container--default .select2-results__option[aria-selected=true] {
  color: #fff;
  background-color: #0284c7;
}
.dark-mode .select2-container--default .select2-search__field::placeholder {
  color: #768491;
}
.dark-mode .is-valid + .select2-container--default .select2-selection--single,
.dark-mode .is-valid + .select2-container--default .select2-selection--multiple,
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #1cd05e;
}
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-valid + .select2-container--default.select2-container--focus .select2-selection--multiple,
.dark-mode .is-valid + .select2-container--default.select2-container--open .select2-selection--single,
.dark-mode .is-valid + .select2-container--default.select2-container--open .select2-selection--multiple {
  box-shadow: 0 0 0 0.25rem rgba(28, 208, 94, 0.25);
}
.dark-mode .is-invalid + .select2-container--default .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default .select2-selection--multiple,
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #e35252;
}
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default.select2-container--focus .select2-selection--multiple,
.dark-mode .is-invalid + .select2-container--default.select2-container--open .select2-selection--single,
.dark-mode .is-invalid + .select2-container--default.select2-container--open .select2-selection--multiple {
  box-shadow: 0 0 0 0.25rem rgba(227, 82, 82, 0.25);
}
.dark-mode .datepicker .datepicker-switch:hover,
.dark-mode .datepicker .next:hover,
.dark-mode .datepicker .prev:hover,
.dark-mode .datepicker tfoot tr th:hover,
.dark-mode .datepicker table tr td.day:hover,
.dark-mode .datepicker table tr td.focused {
  background-color: #1b1f22;
}
.dark-mode .datepicker table tr td.selected,
.dark-mode .datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #1b1f22;
  border-color: #1b1f22;
}
.dark-mode .datepicker table tr td.range {
  color: #c5cdd8;
  background-color: #1b1f22;
  border-color: #1b1f22;
}
.dark-mode .datepicker table tr td.active:hover,
.dark-mode .datepicker table tr td.active:hover:hover,
.dark-mode .datepicker table tr td.active.disabled:hover,
.dark-mode .datepicker table tr td.active.disabled:hover:hover,
.dark-mode .datepicker table tr td.active:focus,
.dark-mode .datepicker table tr td.active:hover:focus,
.dark-mode .datepicker table tr td.active.disabled:focus,
.dark-mode .datepicker table tr td.active.disabled:hover:focus,
.dark-mode .datepicker table tr td.active:active,
.dark-mode .datepicker table tr td.active:hover:active,
.dark-mode .datepicker table tr td.active.disabled:active,
.dark-mode .datepicker table tr td.active.disabled:hover:active,
.dark-mode .datepicker table tr td.active.active,
.dark-mode .datepicker table tr td.active:hover.active,
.dark-mode .datepicker table tr td.active.disabled.active,
.dark-mode .datepicker table tr td.active.disabled:hover.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active:hover,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active.disabled,
.dark-mode .open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.dark-mode .datepicker table tr td span.active:hover,
.dark-mode .datepicker table tr td span.active:hover:hover,
.dark-mode .datepicker table tr td span.active.disabled:hover,
.dark-mode .datepicker table tr td span.active.disabled:hover:hover,
.dark-mode .datepicker table tr td span.active:focus,
.dark-mode .datepicker table tr td span.active:hover:focus,
.dark-mode .datepicker table tr td span.active.disabled:focus,
.dark-mode .datepicker table tr td span.active.disabled:hover:focus,
.dark-mode .datepicker table tr td span.active:active,
.dark-mode .datepicker table tr td span.active:hover:active,
.dark-mode .datepicker table tr td span.active.disabled:active,
.dark-mode .datepicker table tr td span.active.disabled:hover:active,
.dark-mode .datepicker table tr td span.active.active,
.dark-mode .datepicker table tr td span.active:hover.active,
.dark-mode .datepicker table tr td span.active.disabled.active,
.dark-mode .datepicker table tr td span.active.disabled:hover.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active:hover,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active.disabled,
.dark-mode .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #0284c7;
  border-color: #0284c7;
}
.dark-mode .flatpickr-input.form-control:disabled,
.dark-mode .flatpickr-input.form-control[readonly],
.dark-mode .input.form-control:disabled,
.dark-mode .input.form-control[readonly] {
  color: #c5cdd8;
  background-color: #1b1f22;
  border-color: #383f45;
}
.dark-mode .flatpickr-day.selected,
.dark-mode .flatpickr-day.startRange,
.dark-mode .flatpickr-day.endRange,
.dark-mode .flatpickr-day.selected.inRange,
.dark-mode .flatpickr-day.startRange.inRange,
.dark-mode .flatpickr-day.endRange.inRange,
.dark-mode .flatpickr-day.selected:focus,
.dark-mode .flatpickr-day.startRange:focus,
.dark-mode .flatpickr-day.endRange:focus,
.dark-mode .flatpickr-day.selected:hover,
.dark-mode .flatpickr-day.startRange:hover,
.dark-mode .flatpickr-day.endRange:hover,
.dark-mode .flatpickr-day.selected.prevMonthDay,
.dark-mode .flatpickr-day.startRange.prevMonthDay,
.dark-mode .flatpickr-day.endRange.prevMonthDay,
.dark-mode .flatpickr-day.selected.nextMonthDay,
.dark-mode .flatpickr-day.startRange.nextMonthDay,
.dark-mode .flatpickr-day.endRange.nextMonthDay {
  border-color: #0284c7;
  background: #0284c7;
}
.dark-mode .flatpickr-months .flatpickr-prev-month:hover svg,
.dark-mode .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #0284c7;
}
.dark-mode .dd-handle {
  color: #c5cdd8;
  background: #2c3237;
  border-color: #1b1f22;
}
.dark-mode .dd-handle:hover {
  color: #a7b2c4;
}
.dark-mode .dd-empty,
.dark-mode .dd-placeholder {
  border-color: #aed8ed;
  background: #262a2f;
}

/*!
 * bootstrap-fileinput v5.5.2
 * http://plugins.krajee.com/file-input
 *
 * Krajee default styling for bootstrap-fileinput.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2022, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD-3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */
input[type=file].file-loading, .file-loading input[type=file] {
  width: 0;
  height: 0;
}

.file-input-ajax-new .no-browse .input-group-btn, .file-input-ajax-new .fileinput-upload-button, .file-input-ajax-new .fileinput-remove-button, .file-input-new .no-browse .input-group-btn, .file-input-new .fileinput-upload-button, .file-input-new .fileinput-remove-button, .file-input-new .glyphicon-file, .file-input-new .close, .file-input-new .file-preview, .file-zoom-dialog .modal-header:after, .file-zoom-dialog .modal-header:before, .file-caption-icon, .hide-content .kv-file-content, .kv-hidden {
  display: none;
}

.file-zoom-dialog .floating-buttons, .file-zoom-dialog .btn-navigate, .krajee-default .file-thumb-progress, .file-preview .fileinput-remove, .file-caption-icon, .btn-file input[type=file], .file-no-browse {
  position: absolute;
}

.file-zoom-dialog .modal-dialog, .krajee-default .file-thumbnail-footer, .krajee-default.file-preview-frame, .file-preview, .file-caption, .btn-file, .file-loading:before, .file-input {
  position: relative;
}

.krajee-default .file-other-error, .krajee-default .file-actions, .file-error-message ul, .file-error-message pre {
  text-align: left;
}

.file-error-message ul, .file-error-message pre {
  margin: 0;
}

.krajee-default .file-upload-indicator, .krajee-default .file-drag-handle {
  float: left;
  margin-top: 10px;
  width: 16px;
  height: 16px;
}

.krajee-default .file-thumb-progress .progress-bar, .krajee-default .file-thumb-progress .progress {
  height: 11px;
  font-size: 9px;
}

.krajee-default .file-size-info, .krajee-default .file-caption-info {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  height: 15px;
  margin: auto;
}

.file-zoom-content > .file-object.type-image, .file-zoom-content > .file-object.type-flash, .file-zoom-content > .file-object.type-video {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

.file-zoom-content > .file-object.type-flash, .file-zoom-content > .file-object.type-video {
  height: 100%;
}

.file-zoom-content > .file-object.type-default, .file-zoom-content > .file-object.type-text, .file-zoom-content > .file-object.type-html, .file-zoom-content > .file-object.type-pdf {
  width: 100%;
}

.file-no-browse {
  left: 50%;
  bottom: 20%;
  width: 1px;
  height: 1px;
  font-size: 0;
  opacity: 0;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
}

.file-loading:before {
  content: " Loading...";
  display: inline-block;
  padding-left: 20px;
  line-height: 16px;
  font-size: 13px;
  font-variant: small-caps;
  color: #999;
  background: transparent url(../img/loading.gif) top left no-repeat;
}

.file-object {
  margin: 0 0 -5px 0;
  padding: 0;
}

.btn-file {
  overflow: hidden;
}
.btn-file input[type=file] {
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  opacity: 0;
  background: none repeat scroll 0 0 transparent;
  cursor: inherit;
  display: block;
}
.btn-file ::-ms-browse {
  font-size: 10000px;
  width: 100%;
  height: 100%;
}

.file-caption .input-group {
  align-items: center;
}
.file-caption .file-caption-name {
  width: 100%;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: none;
  background: none;
  outline: none;
}
.file-caption.icon-visible .file-caption-icon {
  display: inline-block;
}
.file-caption.icon-visible .file-caption-name {
  padding-left: 1.875rem;
}
.file-caption.icon-visible > .input-group-lg .file-caption-name {
  padding-left: 2.1rem;
}
.file-caption.icon-visible > .input-group-sm .file-caption-name {
  padding-left: 1.5rem;
}

.file-caption-icon {
  left: 8px;
  padding: 0.5rem;
}

.input-group-lg .file-caption-icon {
  font-size: 1.25rem;
}

.input-group-sm .file-caption-icon {
  font-size: 0.875rem;
  padding: 0.25rem;
}

.file-error-message {
  color: #a94442;
  background-color: #f2dede;
  margin: 5px;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  padding: 15px;
}
.file-error-message pre {
  margin: 5px 0;
}
.file-caption-disabled {
  background-color: #eee;
  cursor: not-allowed;
  opacity: 1;
}

.file-preview {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 8px;
  width: 100%;
  margin-bottom: 5px;
}
.file-preview .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.file-preview .fileinput-remove {
  top: 1px;
  right: 1px;
  line-height: 10px;
}
.file-preview .clickable {
  cursor: pointer;
}
.file-preview .kv-zoom-cache {
  display: none;
}

.file-preview-image {
  font: 40px Impact, Charcoal, sans-serif;
  color: #198754;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.krajee-default.file-preview-frame {
  margin: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2);
  padding: 6px;
  float: left;
  text-align: center;
}
.krajee-default.file-preview-frame .kv-file-content {
  width: 160px;
  height: 70px;
}
.krajee-default.file-preview-frame .kv-file-content.kv-pdf-rendered {
  width: 400px;
}
.krajee-default.file-preview-frame .file-thumbnail-footer {
  height: 70px;
}
.krajee-default.file-preview-frame:not(.file-preview-error):hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
.krajee-default.file-preview-frame[data-template=audio] .kv-file-content {
  width: 240px;
  height: 55px;
}
.krajee-default .file-preview-text {
  display: block;
  color: #428bca;
  border: 1px solid #ddd;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  outline: none;
  padding: 8px;
  resize: none;
}
.krajee-default .file-preview-html {
  border: 1px solid #ddd;
  padding: 8px;
  overflow: auto;
}
.krajee-default .file-other-icon {
  font-size: 3em;
  line-height: 1;
}
.krajee-default .file-footer-buttons {
  float: right;
}
.krajee-default .file-footer-caption {
  display: block;
  text-align: center;
  padding-top: 4px;
  font-size: 11px;
  color: #999;
  margin-bottom: 15px;
}
.krajee-default .file-preview-error {
  opacity: 0.65;
  box-shadow: none;
}
.krajee-default .file-thumb-progress {
  height: 11px;
  top: 37px;
  left: 0;
  right: 0;
}
.krajee-default .file-thumb-progress .progress {
  color: #ccc;
}
.krajee-default .file-thumb-progress .progress-bar {
  font-family: Verdana, Helvetica, sans-serif;
}
.krajee-default.kvsortable-ghost {
  background: #e1edf7;
  border: 2px solid #a1abff;
}
.krajee-default .file-preview-other:hover {
  opacity: 0.8;
}
.krajee-default .file-preview-frame:not(.file-preview-error) .file-footer-caption:hover {
  color: #000;
}

.file-upload-stats {
  font-size: 9px;
  text-align: center;
  width: 100%;
}

.kv-upload-progress .progress {
  height: 20px;
  line-height: 20px;
  margin: 10px 0;
  overflow: hidden;
  color: #ccc;
}
.kv-upload-progress .progress-bar {
  height: 20px;
  font-family: Verdana, Helvetica, sans-serif;
}
.kv-upload-progress .file-upload-stats {
  font-size: 11px;
  margin: -10px 0 5px;
}

.file-thumb-progress .progress {
  background-color: #ccc;
}
.file-thumb-progress .progress-bar {
  font-size: 0.7rem;
  font-family: Verdana, Helvetica, sans-serif;
}

.file-zoom-dialog .file-other-icon {
  font-size: 22em;
  font-size: 50vmin;
}
.file-zoom-dialog .modal-dialog {
  width: auto;
}
.file-zoom-dialog .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file-zoom-dialog .btn-navigate {
  margin: -1.2rem 0.1rem 0;
  padding: 0;
  font-size: 1.2rem;
  width: 2.4rem;
  height: 2.4rem;
  top: 50%;
  border-radius: 50%;
  text-align: center;
}
.file-zoom-dialog .btn-navigate * {
  width: auto;
}
.file-zoom-dialog .floating-buttons {
  top: 5px;
  right: 10px;
}
.file-zoom-dialog .btn-kv-prev {
  left: 0;
}
.file-zoom-dialog .btn-kv-next {
  right: 0;
}
.file-zoom-dialog .kv-zoom-header {
  padding: 0.5rem;
}
.file-zoom-dialog .kv-zoom-body {
  padding: 0.25rem 0.5rem 0.25rem 0;
}
.file-zoom-dialog .kv-zoom-description {
  position: absolute;
  opacity: 0.8;
  font-size: 0.8rem;
  background-color: #1a1a1a;
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  color: #fff;
  left: 15%;
  right: 15%;
  bottom: 15%;
}
.file-zoom-dialog .kv-desc-hide {
  float: right;
  color: #fff;
  padding: 0 0.1rem;
  background: none;
  border: none;
}
.file-zoom-dialog .kv-desc-hide:hover,
.file-zoom-dialog .kv-desc-hide:focus {
  opacity: 0.7;
}

.file-input-new .no-browse .form-control {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.file-input-ajax-new .no-browse .form-control {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.file-caption-main {
  width: 100%;
}

.file-thumb-loading {
  background: transparent url(../img/loading.gif) no-repeat scroll center center content-box !important;
}

.file-drop-zone {
  border: 1px dashed #aaa;
  border-radius: 4px;
  min-height: 260px;
  text-align: center;
  vertical-align: middle;
  margin: 12px 15px 12px 12px;
  padding: 5px;
}
.file-drop-zone.clickable:hover {
  border: 2px dashed #999;
}
.file-drop-zone.clickable:focus {
  border: 2px solid #5acde2;
}
.file-drop-zone .file-preview-thumbnails {
  cursor: default;
}

.file-drop-zone-title {
  color: #aaa;
  font-size: 1.6em;
  padding: 85px 10px;
  cursor: default;
}

.file-highlighted {
  border: 2px dashed #999 !important;
  background-color: #eee;
}

.file-uploading {
  background: url(../img/loading-sm.gif) no-repeat center bottom 10px;
  opacity: 0.65;
}

.file-zoom-fullscreen .modal-dialog {
  min-width: 100%;
  margin: 0;
}
.file-zoom-fullscreen .modal-content {
  border-radius: 0;
  box-shadow: none;
  min-height: 100vh;
}
.file-zoom-fullscreen .kv-zoom-body {
  overflow-y: auto;
}

.floating-buttons {
  z-index: 3000;
}
.floating-buttons .btn-kv {
  margin-left: 3px;
  z-index: 3000;
}

.kv-zoom-actions {
  min-width: 140px;
}

.kv-zoom-actions .btn-kv {
  margin-left: 3px;
}

.file-zoom-content {
  min-height: 300px;
  text-align: center;
  white-space: nowrap;
}
.file-zoom-content .file-preview-image {
  max-height: 100%;
}
.file-zoom-content .file-preview-video {
  max-height: 100%;
}
.file-zoom-content > .file-object.type-image {
  height: auto;
  min-height: inherit;
}
.file-zoom-content > .file-object.type-audio {
  width: auto;
  height: 30px;
}
.file-zoom-content:hover {
  background: transparent;
}

@media (min-width: 576px) {
  .file-zoom-dialog .modal-dialog {
    max-width: 500px;
  }
}
@media (min-width: 992px) {
  .file-zoom-dialog .modal-lg {
    max-width: 800px;
  }
}
@media (max-width: 767px) {
  .file-preview-thumbnails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .file-zoom-dialog .modal-header {
    flex-direction: column;
  }
}
@media (max-width: 350px) {
  .krajee-default.file-preview-frame:not([data-template=audio]) .kv-file-content {
    width: 160px;
  }
}
@media (max-width: 420px) {
  .krajee-default.file-preview-frame .kv-file-content.kv-pdf-rendered {
    width: 100%;
  }
}
.file-loading[dir=rtl]:before {
  background: transparent url(../img/loading.gif) top right no-repeat;
  padding-left: 0;
  padding-right: 20px;
}

.clickable .file-drop-zone-title {
  cursor: pointer;
}

.file-sortable .file-drag-handle {
  cursor: grab;
  opacity: 1;
}
.file-sortable .file-drag-handle:hover {
  opacity: 0.7;
}

.file-grabbing, .file-grabbing * {
  cursor: not-allowed !important;
}

.file-grabbing .file-preview-thumbnails * {
  cursor: grabbing !important;
}

.file-preview-initial.sortable-chosen {
  background-color: #d9edf7;
  border-color: #17a2b8;
  box-shadow: none;
}

.file-preview-other-frame, .file-preview-object, .kv-file-content, .kv-zoom-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-kv-rotate {
  display: none;
}

.kv-file-rotate {
  display: none;
}

.rotatable:not(.hide-rotate) .btn-kv-rotate {
  display: inline-block;
}
.rotatable:not(.hide-rotate) .kv-file-rotate {
  display: inline-block;
}
.rotatable .file-zoom-detail {
  transform-origin: center center;
}
.rotatable .kv-file-content {
  transform-origin: center center;
}
.rotatable .kv-file-content > :first-child {
  transform-origin: center center;
}

.rotate-animate {
  transition: transform 0.3s ease;
}

.kv-overflow-hidden {
  overflow: hidden;
}

/*!
 * bootstrap-fileinput v5.5.2
 * http://plugins.krajee.com/file-input
 *
 * Krajee RTL (Right To Left) default styling for bootstrap-fileinput.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2022, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD-3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */
.kv-rtl .krajee-default .file-other-error, .kv-rtl .krajee-default .file-actions, .kv-rtl .close {
  float: left;
}

.kv-rtl .krajee-default .file-upload-indicator, .kv-rtl .krajee-default .file-drag-handle, .kv-rtl .krajee-default.file-preview-frame {
  float: right;
}

.kv-rtl .file-error-message ul, .kv-rtl .file-error-message pre, .kv-rtl .file-zoom-dialog {
  text-align: right;
}

.kv-rtl {
  direction: rtl;
}
.kv-rtl .floating-buttons {
  left: 10px;
  right: auto;
}
.kv-rtl .floating-buttons .btn-kv {
  margin-left: 0;
  margin-right: 3px;
}
.kv-rtl .file-caption-icon {
  left: auto;
  right: 8px;
}
.kv-rtl .kv-desc-hide {
  float: left;
}
.kv-rtl .file-drop-zone {
  margin: 12px 12px 12px 15px;
}
.kv-rtl .btn-kv-prev {
  right: 0;
  left: auto;
}
.kv-rtl .btn-kv-next {
  left: 0;
  right: auto;
}
.kv-rtl .pull-right, .kv-rtl .float-right {
  float: left !important;
}
.kv-rtl .pull-left, .kv-rtl .float-left {
  float: right !important;
}
.kv-rtl .kv-zoom-title {
  direction: ltr;
}
.kv-rtl .krajee-default.file-preview-frame {
  box-shadow: 0 0 0px 0 #a2958a;
}
.kv-rtl .krajee-default.file-preview-frame:not(.file-preview-error):hover {
  box-shadow: 0px 0px 0px 0 #333;
}
.kv-rtl .kv-zoom-actions .btn-kv {
  margin-left: 0;
  margin-right: 3px;
}
.kv-rtl .file-caption.icon-visible .file-caption-name {
  padding-left: 0;
  padding-right: 15px;
}
.kv-rtl .input-group > .input-group-btn:last-child > .btn:last-child {
  border-radius: 4px 0 0 4px !important;
}
.kv-rtl .input-group > .input-group-btn:first-child > .btn:first-child {
  border-radius: 0 4px 4px 0 !important;
}
.kv-rtl .input-group > .input-group-append:last-child > .btn:last-child,
.kv-rtl .input-group > .btn:last-child,
.kv-rtl .input-group > .form-control:last-child {
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.kv-rtl .input-group > .input-group-prepend:first-child > .btn:first-child,
.kv-rtl .input-group > .input-group > .btn:first-child,
.kv-rtl .input-group > .form-control:first-child {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.kv-rtl .input-group .file-caption-icon:first-child ~ .form-control:last-child {
  border-radius: 0.25rem !important;
}
.kv-rtl .btn-file input[type=file] {
  left: auto;
  right: 0;
  text-align: left;
  background: none repeat scroll 100% 0 transparent;
}

/*!
 * bootstrap-fileinput v5.5.2
 * http://plugins.krajee.com/file-input
 *
 * Krajee Explorer Font Awesome 6.x theme style for bootstrap-fileinput. Load this theme file after loading
 * `fileinput.css`.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2022, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD-3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */
.theme-explorer-fa6 .file-preview-frame {
  border: 1px solid #ddd;
  margin: 2px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.theme-explorer-fa6 .file-preview-frame samp {
  font-size: 0.9rem;
}
.theme-explorer-fa6 .file-actions {
  text-align: center;
}
.theme-explorer-fa6 .file-upload-indicator,
.theme-explorer-fa6 .file-drag-handle {
  text-align: center;
  position: absolute;
  display: inline-block;
  bottom: 8px;
  right: 4px;
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.theme-explorer-fa6 .explorer-frame .kv-file-content {
  width: 160px;
  height: 80px;
  padding: 5px;
  text-align: left;
}
.theme-explorer-fa6 .file-thumb-progress .progress {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
}
.theme-explorer-fa6 .explorer-caption {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
  color: #777;
}
.theme-explorer-fa6 .file-footer-buttons {
  padding: 5px;
  text-align: right;
}
.theme-explorer-fa6 .kvsortable-ghost {
  opacity: 0.6;
  background: #e1edf7;
  border: 2px solid #a1abff;
}
.theme-explorer-fa6 .file-preview .table {
  margin: 0;
}
.theme-explorer-fa6 .file-error-message ul {
  padding: 5px 0 0 20px;
}
.theme-explorer-fa6 .file-details-cell {
  width: 60%;
  font-size: 0.95rem;
  text-align: left;
  margin-right: auto;
}
.theme-explorer-fa6 .file-actions-cell {
  position: relative;
  height: 80px;
  width: 200px;
}

.explorer-frame .file-preview-other {
  text-align: center;
}
.explorer-frame .file-preview-text {
  display: inline-block;
  color: #428bca;
  border: 1px solid #ddd;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  outline: none;
  padding: 8px;
  resize: none;
}
.explorer-frame .file-preview-html {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 8px;
  overflow: auto;
}
.explorer-frame .file-other-icon {
  font-size: 2.6em;
}
.explorer-frame:not(.kv-zoom-body):hover {
  background-color: #f5f5f5;
}

.file-zoom-dialog .explorer-frame .file-other-icon {
  font-size: 22em;
  font-size: 50vmin;
}

@media only screen and (max-width: 1249px) {
  .theme-explorer-fa6 .file-preview-frame .file-details-cell {
    width: 40%;
  }
}
@media only screen and (max-width: 1023px) {
  .theme-explorer-fa6 .file-preview-frame .file-details-cell {
    width: 30%;
  }
}
@media only screen and (max-width: 767px) {
  .theme-explorer-fa6 .file-preview-frame .file-details-cell {
    width: 200px;
  }
}
@media only screen and (max-width: 575px) {
  .theme-explorer-fa6 .file-preview-frame {
    flex-direction: column;
  }
  .theme-explorer-fa6 .file-preview-frame .kv-file-content {
    width: auto;
    text-align: center;
  }
  .theme-explorer-fa6 .file-details-cell {
    width: 100px;
    text-align: center;
    margin-right: 0;
  }
  .theme-explorer-fa6 .file-actions-cell {
    width: 100%;
    height: auto;
  }
  .theme-explorer-fa6 .file-footer-buttons {
    text-align: left;
  }
}